import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RepaymentFollowupFieldTeamList from './List'
import RepaymentFollowupFieldTeamView from './View'

function RepaymentFollowupFieldTeam() {
  return (
    <Switch>
      <Route
        exact
        path="/dash/repayment-followup/field-team"
        component={RepaymentFollowupFieldTeamList}
      />
      <Route
        path="/dash/repayment-followup/field-team/:applicationId"
        component={RepaymentFollowupFieldTeamView}
      />
    </Switch>
  )
}

export default RepaymentFollowupFieldTeam
