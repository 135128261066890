import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { fetchAccountsByEloanId } from './reducer'
import { api } from 'api'

export function useAccountsByApplicationId(applicationId) {
  const byApplicationIds = useSelector(
    (state) => state.accounts.byApplicationIds
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!byApplicationIds[applicationId]) {
      dispatch(fetchAccountsByEloanId(applicationId))
    }
  }, [applicationId, byApplicationIds, dispatch])

  return byApplicationIds[applicationId]
}

export function useAccountRedxTransactions(applicationId) {
  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api(
          '/eloan/v1/accounts/client/{applicationId}/transactions/redx',
          {
            applicationId,
          }
        )
        setData(data.transactions || [])
      } catch {}
    }
    if (applicationId) {
      fetchData()
    }
  }, [applicationId])

  return data
}
