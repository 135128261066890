import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPartners } from 'store/partners'

export function usePartners(forceRefresh = false) {
  const partners = useSelector((state) => state.partners)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!partners.allIds.length || forceRefresh) {
      dispatch(getAllPartners())
    }
  }, [dispatch, forceRefresh, partners.allIds.length])

  return partners
}

export function usePartner(partnerId) {
  const partner = useSelector((state) => state.partners.byId[partnerId])

  return partner
}
