import { Heading, Stack, Tag, Text } from '@chakra-ui/core'
import StatusBadge from 'components/Application/StatusBadge'
import ApplicaitonStatuses from 'components/Application/Statuses'
import { get } from 'lodash-es'
import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Flex } from 'reflexbox'
import { useApplication } from 'store/applications/hooks'
import ApplicationInformation from './Information'

const fields = [
  '*',
  'meta.*',
  'offer.*',
  'retailer.*',
  'retailerBankInfo.*',
  'rlAccount.*',
  'odAccount.*',
].join(',')

function ApplicationView() {
  const { applicationId } = useParams()

  const application = useApplication(applicationId, fields)

  const type = get(application, 'type')

  return application ? (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        mb={10}
      >
        <Stack spacing={1} mb={2}>
          <Heading fontSize={5}>
            Retailer ID: {get(application, 'retailer.externalId')} | Retailer
            Name: {get(application, 'retailer.ownerName')}
          </Heading>
          <Text>
            {type ? (
              <Tag size="md" variantColor="blue" mr={4} as="span">
                {type}
              </Tag>
            ) : null}
            Details information about eLoan Application
          </Text>
          <Flex>
            <ApplicaitonStatuses application={application} />
            {get(application, 'rlAccount.isOverdue') && (
              <StatusBadge ml={2} text="OVERDUE" variantColor="red" />
            )}
          </Flex>
        </Stack>

        {/* <Stack isInline spacing={2} flexWrap="wrap"> */}
        {/*   <Switch> */}
        {/*     <Route exact path="/dash/unilever-applications/:applicationId"> */}
        {/*       {!get(application, 'offer') && ( */}
        {/*         <Button as={NavLink} to={`${applicationId}/create-offer`}> */}
        {/*           Create Offer */}
        {/*         </Button> */}
        {/*       )} */}
        {/*     </Route> */}
        {/*   </Switch> */}
        {/* </Stack> */}
      </Flex>

      <Switch>
        {/* <Route */}
        {/*   path="/dash/unilever-applications/:applicationId/create-offer" */}
        {/*   component={CreateOffer} */}
        {/* /> */}
        <Route
          path="/dash/unilever-applications/:applicationId"
          component={ApplicationInformation}
        />
      </Switch>
    </>
  ) : null
}

export default ApplicationView
