import {
  Badge,
  Box,
  Heading,
  SimpleGrid,
  Tooltip,
  IconButton,
} from '@chakra-ui/core'
import {
  DataTable,
  InfoCard,
  toRounded2F2PNumber,
  displayBDT,
} from '@eloan/shared'
import { capitalize, get } from 'lodash-es'
import { DateTime } from 'luxon'
import {
  nobodarIdDocMap,
  nobodarPreferredInstitutionMap,
} from 'pages/nobodar-applications/constants'
import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { useNobodarApplication } from 'store/nobodar/hooks'
import { MdChatBubbleOutline } from 'react-icons/md'
import { emptyArray } from 'utils/defaults'
import { getDate } from 'utils/getDate'
import {
  bkashTransactionStatusDisplayText,
  bkashTransactionStatusVariantColor,
} from 'utils/meta'

const getColumns = () => [
  {
    Header: 'Trx ID',
    accessor: 'transactionId',
  },
  {
    Header: 'Status',
    accessor: 'bkashTransaction.status',
    Cell: ({ cell: { value } }) => {
      return (
        <Badge variantColor={bkashTransactionStatusVariantColor[value]}>
          {bkashTransactionStatusDisplayText[value]}
        </Badge>
      )
    },
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ cell: { value } }) => displayBDT(value),
  },
  {
    Header: 'Submitted by',
    accessor: 'bkashTransaction.submitter',
    Cell: ({ cell: { value } }) => get(value, 'fullName', 'Client'),
  },
  {
    Header: 'Submission Date',
    accessor: 'bkashTransaction.createdAt',
    Cell: ({ cell: { value } }) => getDate(value, DateTime.DATETIME_MED),
  },
  {
    Header: 'Verified by',
    accessor: 'bkashTransaction.verifier.fullName',
  },
  {
    Header: 'Verification Date',
    accessor: 'bkashTransaction.verifiedAt',
    Cell: ({ cell: { value } }) => getDate(value, DateTime.DATETIME_MED),
  },
  {
    Header: 'Note',
    accessor: 'bkashTransaction.note',
    Cell: ({ cell: { value } }) =>
      value && (
        <Tooltip label={value} hasArrow>
          <IconButton
            icon={MdChatBubbleOutline}
            variant="ghost"
            type="button"
          />
        </Tooltip>
      ),
  },
]

function ApplicantInfo({ applicationId }) {
  const application = useNobodarApplication(applicationId)

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => get(application, 'feeDeposits', []), [application])

  const isPersonal = useMemo(
    () => get(application, 'loanPurpose') === 'personal',
    [application]
  )
  const isBusiness = useMemo(
    () => get(application, 'loanPurpose') === 'business',
    [application]
  )

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <Box>
      <SimpleGrid columns={4} gridColumnGap={4} gridRowGap={10}>
        <InfoCard label="Name" value={get(application, 'fullName')} />
        <InfoCard
          label="Email address (if any)"
          value={get(application, 'email')}
        />
        <InfoCard
          label="Avaiable Identification Docs"
          value={(get(application, 'availableIdDocs') ?? emptyArray)
            .map((idDoc) => nobodarIdDocMap[idDoc])
            .join(', ')}
        />
        <InfoCard
          label="Date of birth according to ID Docs"
          value={getDate(get(application, 'dob'))}
        />
        <InfoCard
          label="Why do you need this loan?"
          value={capitalize(get(application, 'loanPurpose'))}
        />
        <InfoCard
          label={`Loan Reason`}
          value={
            get(application, 'loanReason') === 'other'
              ? get(application, 'loanReasonOther')
              : get(application, 'loanReason')
          }
        />
        {isPersonal && (
          <InfoCard
            label="Occupation"
            value={capitalize(get(application, 'profession'))}
          />
        )}
        {isBusiness && (
          <InfoCard
            label="Do you have a trade license?"
            value={get(application, 'hasTradeLicense') ? 'Yes' : 'No'}
          />
        )}
        <InfoCard
          label={`Monthly ${isPersonal ? 'income' : 'net income/profit'}`}
          value={`BDT ${toRounded2F2PNumber(
            get(application, 'monthlyIncome')
          )}`}
        />
        <InfoCard
          label={`Do you have bKash number?`}
          value={get(application, 'bkashNumber')}
        />
        <InfoCard
          label={`${isPersonal ? 'Personal' : 'Business'} address`}
          value={get(application, 'address')}
        />
        <InfoCard
          label={`Needed Loan Amount`}
          value={`BDT ${toRounded2F2PNumber(
            get(application, 'neededLoanAmount', '')
          )}`}
        />
        <InfoCard
          label={`Intended Loan Tenure`}
          value={
            get(application, 'intendedLoanTenure', 0) < 12
              ? `${get(application, 'intendedLoanTenure', 0)} Month(s)`
              : `${get(application, 'intendedLoanTenure', 0) / 12} Year(s)`
          }
        />
        <InfoCard
          label={`Preferred Institution`}
          value={
            nobodarPreferredInstitutionMap[
              get(application, 'preferredInstitution')
            ]
          }
        />
      </SimpleGrid>

      <Box mt={10}>
        <Heading
          size="lg"
          borderBottomWidth="1px"
          borderBottomColor="gray.200"
          py={2}
          mb={4}
        >
          Payment Information
        </Heading>
        <DataTable {...table} />
      </Box>
    </Box>
  )
}

export default ApplicantInfo
