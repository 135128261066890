import { Button, Box, Image, Link, Stack, Tag, useToast } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  acceptApplicationImage,
  getApplicationData,
  rejectApplicationImage,
} from 'store/applications'
import { useApplication } from 'store/applications/hooks'
import { getPrivateImageSrc } from 'utils/getPrivateImageSrc'
import { MdCheck, MdClose } from 'react-icons/md'
import { toUpper } from 'utils/caseStyle'

const variantColor = {
  rejected: 'red',
  accepted: 'green',
  pending: 'yellow',
}

function NIDPhoto({ applicationId, photoField, statusField, children }) {
  const toast = useToast()

  const fields = useMemo(() => [photoField, statusField].join(','), [
    photoField,
    statusField,
  ])

  const application = useApplication(applicationId, fields)

  const dispatch = useDispatch()

  const refresh = useCallback(() => {
    dispatch(getApplicationData(applicationId, { fields }))
  }, [applicationId, dispatch, fields])

  const accept = useCallback(
    async (photoValue) => {
      try {
        await dispatch(
          acceptApplicationImage(applicationId, {
            photoField,
            photoValue,
          })
        )
      } catch (err) {
        if (get(err.error, 'code') === 409) {
          refresh()
        }

        handleAPIError(err, { toast })
      }
    },
    [applicationId, dispatch, photoField, refresh, toast]
  )

  const reject = useCallback(
    async (photoValue) => {
      try {
        await dispatch(
          rejectApplicationImage(applicationId, {
            photoField,
            photoValue,
          })
        )
      } catch (err) {
        if (get(err.error, 'code') === 409) {
          refresh()
        }

        handleAPIError(err, { toast })
      }
    },
    [applicationId, dispatch, photoField, refresh, toast]
  )

  const photoValue = get(application, photoField)
  const statusValue = (get(application, statusField) || '').toLowerCase()

  return (
    <Box>
      <Box
        mb={4}
        as={Link}
        display="block"
        href={getPrivateImageSrc(photoValue)}
        target="_blank"
        size={64}
        position="relative"
      >
        <Image
          size="100%"
          objectFit="cover"
          src={getPrivateImageSrc(photoValue)}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
        <Tag
          size="sm"
          position="absolute"
          bottom={0}
          right={0}
          variantColor={variantColor[statusValue]}
        >
          {toUpper(statusValue)}
        </Tag>
      </Box>

      <Stack isInline spacing={4}>
        <Authorize permissions="eloan:AnyApplication:update,eloan:ApplicationPhoto:update">
          <Button
            type="button"
            variantColor="red"
            variant={statusValue === 'rejected' ? 'solid' : 'outline'}
            isDisabled={statusValue === 'rejected'}
            onClick={() => reject(photoValue)}
            title="Reject Photo"
          >
            <Box as={MdClose} />
          </Button>
        </Authorize>
        <Authorize permissions="eloan:AnyApplication:update,eloan:ApplicationPhoto:update">
          <Box>
            <Button
              type="button"
              variantColor="green"
              variant={statusValue === 'accepted' ? 'solid' : 'outline'}
              isDisabled={statusValue === 'accepted'}
              onClick={() => accept(photoValue)}
              title="Accept Photo"
            >
              <Box as={MdCheck} />
            </Button>
          </Box>
        </Authorize>

        {children}
      </Stack>
    </Box>
  )
}

export default NIDPhoto
