import { useState, useEffect } from 'react'
import { emptyArray } from 'utils/defaults'
import { api } from 'api'

export function useAllCollectionSummary({ loanProductId }) {
  const [data, setData] = useState(emptyArray)

  useEffect(() => {
    async function fetchData() {
      const response = await api(
        `/legacy/v4/eloan/loan-products/{loanProductId}/summary/collections`,
        {
          loanProductId,
        }
      )

      setData(response.data)
    }

    fetchData()
  }, [loanProductId])

  return data
}

export function useCollections(applicationId) {
  const [data, setData] = useState(emptyArray)

  useEffect(() => {
    async function fetchData() {
      const response = await api(
        `/legacy/v4/eloan/applications/{applicationId}/collections`,
        {
          applicationId,
        }
      )

      setData(response.data.items)
    }

    fetchData()
  }, [applicationId])

  return data
}

export function useRedXCollection({
  limit,
  offset,
  invoiceId,
  shopId,
  eloanId,
  startDate,
  endDate,
}) {
  const [data, setData] = useState(emptyArray)
  useEffect(() => {
    async function fetchData() {
      const {
        data,
      } = await api(
        `/eloan/v1/redx/collections{?limit,offset,invoiceId,shopId,eloanId,startDate,endDate}`,
        { limit, offset, invoiceId, shopId, eloanId, startDate, endDate }
      )

      setData([data.items, data.totalItems])
    }

    fetchData()
  }, [limit, offset, invoiceId, shopId, eloanId, startDate, endDate])

  return data
}
