export function handleAPIError(err, { form, toast }) {
  if (!err.error) {
    console.error(err)
    return
  }

  const { error } = err

  if (error.errors && form) {
    error.errors.forEach(({ location, message }) => {
      form.setError(location, 'FORM', message)
    })
  }

  if (error.message) {
    toast({
      title: error.message,
      status: 'error',
      duration: 2500,
      isClosable: true,
    })
  }
}
