import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import React from 'react'
import { Table } from '@eloan/shared'
import { mapScorecardComponentValues } from 'utils/application-scorecard'

const _getScorecardComponentsTable = (scoreComponents) => {
  if (!scoreComponents) {
    return (
      <Text alignContent="center">
        Components for final score not available
      </Text>
    )
  }
  const components = mapScorecardComponentValues(scoreComponents)
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Component</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
          <Table.HeaderCell>Score</Table.HeaderCell>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.HeaderCell>Weighted Score</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {components.map((component, index) => {
          return (
            <Table.Row key={`scorecard-component-table-key-${index}`}>
              <Table.Cell>{component.fieldName}</Table.Cell>
              <Table.Cell>{component.value}</Table.Cell>
              <Table.Cell>{component.score}</Table.Cell>
              <Table.Cell>{component.weight}</Table.Cell>
              <Table.Cell>{component.weightedScore}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

function LoanScorecardComponentModalView({
  applicationId,
  scorecard,
  isOpen,
  onClose,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
      scrollBehavior="outside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Loan Scorecard Components for Eloan ID: {applicationId}
        </ModalHeader>

        <ModalCloseButton type="button" />

        <ModalBody>
          <Stack height={500} overflowY={'scroll'} width={'100%'}>
            <Text fontWeight="bold" fontSize="md">
              Final Score: {scorecard.score}
            </Text>
            <br />
            <hr />
            {_getScorecardComponentsTable(scorecard.scoreComponents)}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button type="button" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

function LoanScorecardComponentsModal({
  buttonTitle,
  applicationId,
  scorecard,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Authorize permissions="eloan:AnyApplication:read,eloan:Application:read">
      <Button onClick={onOpen}>{buttonTitle}</Button>
      <LoanScorecardComponentModalView
        applicationId={applicationId}
        scorecard={scorecard}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Authorize>
  )
}

export default LoanScorecardComponentsModal
