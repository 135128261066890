import { Divider, Heading, Stack, useToast } from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import FormTextarea from 'components/Form/Textarea'
import { DataTable } from '@eloan/shared/components/Table'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { createApplicationPACallLog } from 'store/paCallLogs'
import { useApplicationPACallLogs } from 'store/paCallLogs/hooks'
import { getPACallStatusOptions } from 'utils/selectOptions'
import * as Yup from 'yup'
import DocumentRequirements from './sections/DocumentRequirements'
import { useTable } from 'react-table'

const getDefaultValues = () => ({
  note: '',
  status: '',
})

const getValidationSchema = ({ validPACallStatus }) => {
  return Yup.object({
    note: Yup.string().required(`required`),
    status: Yup.string().oneOf(validPACallStatus).required(`required`),
  })
}

function CreateCallLog({ applicationId }) {
  const toast = useToast()

  const paCallStatusOptions = useMemo(() => getPACallStatusOptions(), [])
  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(
    () =>
      getValidationSchema({
        validPACallStatus: Object.keys(paCallStatusOptions),
      }),
    [paCallStatusOptions]
  )

  const form = useForm({ defaultValues, validationSchema })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ note, status }) => {
      try {
        await dispatch(
          createApplicationPACallLog(applicationId, { note, status })
        )
        form.reset(getDefaultValues())
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <FormSelect
        name="status"
        label={'Status'}
        options={paCallStatusOptions}
      />
      <FormTextarea
        name="note"
        label="Note"
        placeholder="Write your note here..."
      />

      <FormButton type="submit" mt={2}>
        Record Call Log
      </FormButton>
    </Form>
  )
}

const columns = [
  {
    Header: 'Date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) =>
      DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
  },
  { Header: 'PA', accessor: 'pa.fullName' },
  { Header: 'Status', accessor: 'status' },
  { Header: 'Note', accessor: 'note' },
]

function CallInfo({ applicationId }) {
  const paCallLogs = useApplicationPACallLogs(applicationId)

  const data = useMemo(() => {
    return paCallLogs.allIds.map((id) => paCallLogs.byId[id])
  }, [paCallLogs.allIds, paCallLogs.byId])

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <Stack isInline flexWrap="wrap">
      <DocumentRequirements applicationId={applicationId} flexGrow="1" mb={8} />

      <Box flexGrow="1">
        <Heading fontSize={4} mb={3}>
          PA Call Logs
        </Heading>

        <Stack spacing={10}>
          <Box>
            <DataTable {...table} />
          </Box>

          <Divider />

          <Box>
            <CreateCallLog applicationId={applicationId} />
          </Box>
        </Stack>
      </Box>
    </Stack>
  )
}

export default CallInfo
