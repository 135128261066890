import { api } from 'api'
import { keyBy } from 'lodash-es'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'

const initialQuestionnaireState = {
  loading: true,
  questions: { byId: {}, allIds: [] },
}

function reducer(state = initialQuestionnaireState, { type, data }) {
  switch (type) {
    case 'set_questions':
      return {
        loading: false,
        questions: {
          byId: keyBy(data.items, 'id'),
          allIds: data.itemIds,
        },
      }
    case 'set_answer':
      return {
        ...state,
        questions: {
          ...state.questions,
          byId: {
            ...state.questions.byId,
            [data.questionId]: {
              ...state.questions.byId[data.questionId],
              answer: data,
            },
          },
        },
      }
    default:
      throw new Error()
  }
}

const AssessmentQuestionnaire = createContext()

export function NobodarAssessmentQuestionnaireProvider({
  applicationId,
  children,
}) {
  const [state, dispatch] = useReducer(reducer, initialQuestionnaireState)

  useEffect(() => {
    if (applicationId) {
      api(
        `GET /legacy/v3/eloan/nobodar/applications/{applicationId}/assessment-questionnaire`,
        {
          applicationId,
        }
      )
        .then(({ data }) => {
          dispatch({ type: 'set_questions', data })
        })
        .catch((err) => {
          throw err
        })
    }
  }, [applicationId])

  const recordAnswer = useCallback(
    async ({ questionId, data: _data, meta }) => {
      const {
        data,
      } = await api(
        `POST /legacy/v3/eloan/nobodar/applications/{applicationId}/assessment-questionnaire/action/record-answer`,
        { applicationId, questionId, data: _data, meta }
      )
      dispatch({ type: 'set_answer', data })
    },
    [applicationId]
  )

  const refreshVirtualAnswer = useCallback(
    async ({ questionId }) => {
      const {
        data,
      } = await api(
        `POST /legacy/v3/eloan/nobodar/applications/{applicationId}/assessment-questionnaire/action/refresh-virtual-answer`,
        { applicationId, questionId }
      )
      dispatch({ type: 'set_answer', data })
    },
    [applicationId]
  )

  return (
    <AssessmentQuestionnaire.Provider
      value={{
        applicationId,
        state,
        recordAnswer,
        refreshVirtualAnswer,
      }}
    >
      {children}
    </AssessmentQuestionnaire.Provider>
  )
}

export function useNobodarAssessmentQuestionnaire() {
  return useContext(AssessmentQuestionnaire)
}

export function evaluateHidden(state, questionId) {
  return state.questions.byId[questionId]?.meta.state?.hidden.every(
    (andPart) => {
      return andPart.some((orPart) => {
        const [[qId, comparisonObj]] = Object.entries(orPart)

        if (!state.questions.byId[qId] || !state.questions.byId[qId].answer) {
          return true
        }

        const [[operator, value]] = Object.entries(comparisonObj)

        switch (operator) {
          case '=':
            return state.questions.byId[qId].answer.data.value === value
          case '!=':
            return state.questions.byId[qId].answer.data.value !== value
          case '<':
            return state.questions.byId[qId].answer.data.value < value
          case '>':
            return state.questions.byId[qId].answer.data.value > value
          default:
            return false
        }
      })
    }
  )
}
