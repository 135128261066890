import { Box } from '@chakra-ui/core'
import { getDate } from '@eloan/shared'
import { DataTable } from '@eloan/shared/components/Table'
import { useCollectionByApplication } from 'hooks/temp/useCollection'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { useTable } from 'react-table'

const getColumns = () =>
  [
    {
      Header: 'Collection Date',
      accessor: 'createdAt',
      Cell: ({ cell: { value } }) =>
        DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED),
    },
    { Header: 'Collection Method', accessor: 'collectionMethod' },
    { Header: 'Repayment Amount', accessor: 'collectionAmount' },
    {
      Header: 'Settlement Date',
      accessor: ({ settlementRecordedAt }) => getDate(settlementRecordedAt),
    },
  ].filter(Boolean)

function Repayment({ applicationId }) {
  const items = useCollectionByApplication({
    applicationId,
    isSettled: true,
    loanProductId: 2,
  })

  const columns = useMemo(() => getColumns(), [])

  const table = useTable({
    data: items,
    columns: columns,
  })

  return (
    <Box>
      <DataTable {...table} />
    </Box>
  )
}

export default Repayment
