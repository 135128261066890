import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { Form, FormButton, FormInput, handleAPIError } from '@eloan/shared'
import Authorize from 'components/Authorize'
import InfoBox from 'components/Box/InfoBox'
import NIDPhoto from 'components/Image/NIDPhoto'
import { get } from 'lodash-es'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { MdFileUpload } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { uploadTradeLicensePhoto } from 'store/applications'

function TradeLicensePhoto({ application, ...props }) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const form = useForm()

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ file }) => {
      try {
        await dispatch(
          uploadTradeLicensePhoto(get(application, 'id'), {
            file: file[0],
          })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { toast, form })
      }
    },
    [application, dispatch, form, onClose, toast]
  )

  const photo = get(application, 'metaExtra.tradeLicensePhoto')

  return (
    <Authorize permissions="eloan:AnyApplication:read,eloan:Application:read">
      <Box {...props}>
        <InfoBox label="Trade License Photo">
          <NIDPhoto
            applicationId={get(application, 'id')}
            photoField="metaExtra.tradeLicensePhoto"
            statusField="metaExtra.tradeLicensePhotoStatus"
          >
            {!photo && (
              <Authorize permissions="eloan:Application:update">
                <Box>
                  <Button
                    onClick={onOpen}
                    title="Upload Photo"
                    variantColor="blue"
                  >
                    <Box as={MdFileUpload} />
                  </Button>

                  <Modal isOpen={isOpen} onClose={onClose} size="xl">
                    <ModalOverlay />
                    <ModalContent>
                      <Form form={form} onSubmit={onSubmit}>
                        <ModalHeader>Trade License Photo</ModalHeader>

                        <ModalCloseButton type="button" />

                        <ModalBody>
                          <FormInput
                            name="file"
                            type="file"
                            label="Trade License"
                          />
                        </ModalBody>

                        <ModalFooter>
                          <Button type="button" mr={3} onClick={onClose}>
                            Close
                          </Button>
                          <FormButton type="submit">Upload</FormButton>
                        </ModalFooter>
                      </Form>
                    </ModalContent>
                  </Modal>
                </Box>
              </Authorize>
            )}
          </NIDPhoto>
        </InfoBox>
      </Box>
    </Authorize>
  )
}

export default TradeLicensePhoto
