import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { Form, FormButton, FormMultiSelect, FormSelect } from '@eloan/shared'
import { api } from 'api'
import { handleAPIError } from 'components/Form/helpers'
import { zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

const statusOptions = { active: 'Active', on_leave: 'On Leave' }

function UpdatePALeave({ paId, dataByPaId, onSuccess }) {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const paIdOptions = useMemo(() => {
    const paIds = Object.keys(dataByPaId).filter((id) => +id !== paId)
    return zipObject(
      paIds,
      paIds.map((paId) => dataByPaId[paId].fullName)
    )
  }, [dataByPaId, paId])

  const data = useMemo(() => {
    return dataByPaId[paId]
  }, [dataByPaId, paId])

  const defaultValues = useMemo(
    () => ({
      status: data.transferredAssignments.length ? 'on_leave' : 'active',
      temporaryPaIds: data.transferredAssignments.map((item) => item.id),
    }),
    [data.transferredAssignments]
  )

  const form = useForm({ defaultValues })

  const onSubmit = useCallback(
    async ({ status, temporaryPaIds }) => {
      try {
        await api(
          'POST /legacy/v4/eloan/applications/pa-assignments/action/update-pa-leave',
          {
            paId,
            status,
            temporaryPaIds: status === 'active' ? undefined : temporaryPaIds,
          }
        )
        onSuccess()
        toast({
          title: 'Successfully updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [paId, onSuccess, toast, onClose, form]
  )

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const status = form.watch('status')

  return (
    <Box>
      <Button
        px={6}
        variantColor="blue"
        variant="outline"
        size="sm"
        onClick={onOpen}
      >
        Change
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change PA Leave Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Form form={form} onSubmit={onSubmit} spacing={4}>
              <Box>
                <FormSelect
                  name="status"
                  label={`Change Current Status`}
                  options={statusOptions}
                />
              </Box>

              {status === 'on_leave' && (
                <Box>
                  <FormMultiSelect
                    name="temporaryPaIds"
                    options={paIdOptions}
                    label="Add PAs"
                  />
                </Box>
              )}

              <Stack isInline mt={4}>
                <Button
                  flex={1}
                  variant="outline"
                  variantColor="red"
                  onClick={() => {
                    formReset(defaultValues)
                    onClose()
                  }}
                >
                  Cancel
                </Button>
                <FormButton type="submit" flex={1} variantColor="green" ml={4}>
                  Confirm
                </FormButton>
              </Stack>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default UpdatePALeave
