import { Stack } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React from 'react'
import {
  applicationStatusDisplayText,
  applicationSubStatusDisplayText,
  getNewApplicationStatus,
} from 'utils/application-status'
import { offerStatusDisplayText } from 'utils/offer-status'
import StatusBadge from './StatusBadge'

function ApplicaitonStatuses({ application }) {
  const status = getNewApplicationStatus(get(application, 'status'))
  const subStatus = get(application, 'subStatus')
  const offerStatus = get(application, 'offer.status')

  return (
    <Stack isInline flexWrap="wrap">
      {status && (
        <StatusBadge
          status={status}
          text={applicationStatusDisplayText[status]}
        />
      )}
      {subStatus && (
        <StatusBadge
          status={subStatus}
          text={applicationSubStatusDisplayText[subStatus]}
          prefix="Reason:"
        />
      )}
      {offerStatus && (
        <StatusBadge
          status={offerStatus}
          text={offerStatusDisplayText[offerStatus]}
          prefix="Offer:"
        />
      )}
    </Stack>
  )
}

export default ApplicaitonStatuses
