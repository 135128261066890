import {
  Stack,
  Text,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { assignFAToApplication } from 'store/applications'
import { useAreas } from 'store/areas/hooks'
import {
  useFieldAgentUserIdsByAreaId,
  useFieldAgentUsers,
} from 'store/users/hooks'
import * as Yup from 'yup'

const getDefaultValues = (userId = '') => ({
  areaId: '',
  userId,
})

const getValidationSchema = () => {
  return Yup.object({
    areaId: Yup.number().integer().required(`required`),
    userId: Yup.number().integer().required(`required`),
  })
}

function SetFieldAgentAssignment({
  applicationId,
  application,
  isOpen,
  onClose,
}) {
  const defaultValues = useMemo(
    () => getDefaultValues(get(application, 'faAssignment.userId')),
    [application]
  )
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const areaId = form.watch('areaId')

  const toast = useToast()

  const areas = useAreas()
  const areaOptions = useMemo(() => {
    return zipObject(
      areas.allIds,
      areas.allIds.map((id) => areas.byId[id].name)
    )
  }, [areas.allIds, areas.byId])

  const fieldAgentUsers = useFieldAgentUsers()

  const fieldAgentUserIds = useMemo(() => {
    return fieldAgentUsers.allIds
  }, [fieldAgentUsers.allIds])
  const fieldAgentUserIdsByAreaId = useFieldAgentUserIdsByAreaId(
    fieldAgentUserIds
  )

  const fieldAgentUserOptions = useMemo(() => {
    const ids = fieldAgentUserIdsByAreaId[areaId] ?? []
    return zipObject(
      ids,
      ids.map((id) => get(fieldAgentUsers.byId[id], 'fullName'))
    )
  }, [areaId, fieldAgentUserIdsByAreaId, fieldAgentUsers.byId])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ userId }) => {
      try {
        await dispatch(assignFAToApplication({ applicationId, userId }))

        toast({
          title: `Assigned FieldAgent(${get(
            fieldAgentUsers.byId[userId],
            'fullName'
          )}) to Application(${applicationId})`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        form.reset()
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, fieldAgentUsers.byId, form, onClose, toast]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Form form={form} onSubmit={onSubmit}>
          <ModalHeader>
            Change Field Agent for Application: {applicationId}
          </ModalHeader>

          <ModalCloseButton type="button" />

          <ModalBody>
            <Stack>
              <Box fontSize={2}>
                <Text>Current Field Agent: </Text>
                {get(
                  fieldAgentUsers.byId[get(application, 'faAssignment.userId')],
                  'fullName'
                )}
              </Box>
              <FormSelect name="areaId" options={areaOptions} label={`Area:`} />
              <FormSelect
                name="userId"
                options={fieldAgentUserOptions}
                label={`Field Agent:`}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button type="button" mr={3} onClick={onClose}>
              Close
            </Button>
            <FormButton type="submit" variantColor="green">
              Assign
            </FormButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}

function AssignFieldAgent({ applicationId, application, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Authorize permissions="eloan:AnyApplication:update,eloan:Application:update">
      <Box {...props}>
        <Button onClick={onOpen}>Field Agent</Button>
        <SetFieldAgentAssignment
          applicationId={applicationId}
          application={application}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Box>
    </Authorize>
  )
}

export default AssignFieldAgent
