import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormMultiSelect from 'components/Form/MultiSelect'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { createFilter } from 'react-select'
import { useAreas } from 'store/areas/hooks'
import { setFieldAgentAreas } from 'store/users'
import { useFieldAgentAreaIds } from 'store/users/hooks'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    areaIds: Yup.array().of(Yup.number().integer()),
  })
}

function FieldAgentAreas({ userId }) {
  const toast = useToast()

  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const areas = useAreas()

  const user = useSelector((state) => state.users.byId[userId])

  const assignedAreaIds = useFieldAgentAreaIds(userId)

  const areaOptions = useMemo(() => {
    return zipObject(
      areas.allIds,
      areas.allIds.map((id) => areas.byId[id].name)
    )
  }, [areas.allIds, areas.byId])

  const getFilterOption = useCallback(
    (selectedAreaIds) => (...args) => {
      return !selectedAreaIds.includes(args[0].value) && createFilter()(...args)
    },
    []
  )

  const defaultValues = useMemo(() => ({ areaIds: assignedAreaIds }), [
    assignedAreaIds,
  ])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    if (isOpen) {
      formReset(defaultValues)
    }
  }, [isOpen, defaultValues, formReset])

  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ areaIds }) => {
      try {
        if (areaIds.length) {
          await dispatch(setFieldAgentAreas(userId, { areaIds }))
        }
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, toast, userId]
  )

  const selectedAreaIds = form.watch('areaIds').map(String)

  return (
    <Authorize permissions="eloan:FieldAgentArea:set">
      <Button onClick={onOpen}>Areas</Button>

      <Modal id={userId} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Assign Areas for {get(user, 'fullName')}</ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <FormMultiSelect
                name="areaIds"
                options={areaOptions}
                filterOption={getFilterOption(selectedAreaIds)}
                isClearable={false}
              />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <FormButton type="submit" variantColor="green">
                Assign Areas
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default FieldAgentAreas
