import { keyBy, map, union } from 'lodash-es'
import {
  AREA_LOADING_ALL,
  AREA_SET_ALL,
  DISTRICT_SET_ALL,
  DISTRICT_LOADING_ALL,
} from 'store/areas'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: false,
  districts: {
    byId: emptyObject,
    allIds: emptyArray,
    loading: false,
  },
}

const areasReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case AREA_LOADING_ALL:
      return {
        ...state,
        loading: true,
      }
    case AREA_SET_ALL:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        loading: false,
      }
    case DISTRICT_LOADING_ALL:
      return {
        ...state,
        districts: {
          ...state.districts,
          loading: true,
        },
      }
    case DISTRICT_SET_ALL:
      return {
        ...state,
        districts: {
          ...state.districts,
          byId: {
            ...state.districts.byId,
            ...keyBy(data.items, idKey),
          },
          allIds: union(state.districts.allIds, map(data.items, idKey)),
          loading: false,
        },
      }
    default:
      return state
  }
}

export default areasReducer
