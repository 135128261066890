import React from 'react'
import { Spinner, Flex } from '@chakra-ui/core'

function Loader() {
  return (
    <Flex justify="center" width="100%">
      <Spinner color="primary" />
    </Flex>
  )
}

export default Loader
