import { defaultsDeep } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { emptyObject } from 'utils/defaults'
import { getFilterString } from 'utils/getFilterString'
import { getLinkWithPageQuery } from 'utils/getLinkWithPageQuery'
import { loadState, saveState } from 'utils/localStorage'

const getFilterQueryObject = (
  fixedFilterObject = emptyObject,
  filterObject = emptyObject,
  filterTypes = emptyObject
) => {
  const {
    meta: metaFilterValues,
    metaExtra: metaExtraFilterValues,
    offer: offerFilterValues,
    paAssignment: paAssignmentFilterValues,
    paymentDetails: paymentDetailsFilterValues,
    ...filterValues
  } = defaultsDeep({}, fixedFilterObject, filterObject)

  const filter = getFilterString(filterValues, filterTypes)

  const metaFilter = getFilterString(metaFilterValues, filterTypes.meta)

  const metaExtraFilter = getFilterString(
    metaExtraFilterValues,
    filterTypes.metaExtra
  )

  const offerFilter = getFilterString(offerFilterValues, filterTypes.offer)

  const paymentDetailsFilter = getFilterString(paymentDetailsFilterValues)

  const paAssignmentFilter = getFilterString(
    paAssignmentFilterValues,
    filterTypes.paAssignment
  )

  return {
    filter,
    metaFilter,
    metaExtraFilter,
    offerFilter,
    paAssignmentFilter,
    paymentDetailsFilter,
  }
}

function useApplicationListQuery(
  defaultQueryObject,
  fixedFilterObject,
  filterTypes,
  filterCacheKey
) {
  const history = useHistory()

  const [filterObject, setFilterObject] = useState(() =>
    filterCacheKey ? loadState(filterCacheKey) : undefined
  )

  const filterQueryObject = useMemo(
    () => getFilterQueryObject(fixedFilterObject, filterObject, filterTypes),
    [filterObject, filterTypes, fixedFilterObject]
  )

  const onFilterObjectChange = useCallback(
    (newFilterObject) => {
      history.push(getLinkWithPageQuery(window.location, null))
      setFilterObject(newFilterObject)
      if (filterCacheKey) {
        saveState(filterCacheKey, newFilterObject)
      }
    },
    [filterCacheKey, history]
  )

  const queryObject = useMemo(
    () => defaultsDeep({}, filterQueryObject, defaultQueryObject),
    [defaultQueryObject, filterQueryObject]
  )

  return [queryObject, onFilterObjectChange, filterObject]
}

export default useApplicationListQuery
