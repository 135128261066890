import { SimpleGrid } from '@chakra-ui/core'
import { CountUpNumber } from '@eloan/shared/components/Animated'
import { toRounded2F2PNumber } from '@eloan/shared/utils'
import InfoBox from 'components/Box/InfoBox'
import { get, sum } from 'lodash-es'
import React, { useMemo } from 'react'

function Summary({ summary }) {
  const totalInterest = useMemo(() => {
    const items = [
      get(summary, 'totalInterestRepaid'),
      get(summary, 'totalPenaltyRepaid'),
    ]

    if (items.every((item) => typeof item === 'number')) {
      return sum(items)
    }
  }, [summary])

  const isLoading = useMemo(() => {
    return typeof totalInterest === 'undefined'
  }, [totalInterest])

  return (
    <SimpleGrid columns={5} my={6} gridColumnGap={4}>
      <InfoBox
        label="Total Collection"
        content={
          <CountUpNumber
            loading={isLoading}
            number={get(summary, 'totalRepaid')}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        }
      />
      <InfoBox
        label="Total Prinicipal Received"
        content={
          <CountUpNumber
            loading={isLoading}
            number={get(summary, 'totalPrincipalRepaid')}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        }
      />
      <InfoBox
        label="Total Interest"
        content={
          <CountUpNumber
            loading={isLoading}
            number={totalInterest}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        }
      />
      <InfoBox
        label="Total OD Accrued Interest"
        content={
          <CountUpNumber
            loading={isLoading}
            number={get(summary, 'totalOverdraftAccruedInterest')}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        }
      />
    </SimpleGrid>
  )
}

export default Summary
