import { api } from 'api'
import { get, isUndefined, keyBy, omitBy } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicationNotes } from 'store/notes'
import useSWR from 'swr'
import { emptyArray } from 'utils/defaults'

export function useApplicationNotes(applicationId) {
  const byId = useSelector((state) => state.notes.byId)
  const allIds = useSelector((state) =>
    get(state.notes.idsByApplication, applicationId, emptyArray)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApplicationNotes(applicationId))
  }, [applicationId, dispatch])

  const notes = useMemo(() => ({ byId, allIds }), [allIds, byId])

  return notes
}

const fetcher = async (endpointUrl, params) => {
  const { data } = await api(endpointUrl, { ...params })
  const byId = keyBy(data.items, 'id')
  const allIds = data.items.map((item) => item.id)
  return { byId, allIds }
}

export function useParterNotes(applicationId, { filter, fields } = {}) {
  const params = useMemo(
    () => omitBy({ applicationId, filter, fields }, isUndefined),
    [applicationId, fields, filter]
  )

  const swr = useSWR(
    [`/legacy/v4/eloan/applications/{applicationId}/partner-notes`, params],
    fetcher
  )

  return swr
}
