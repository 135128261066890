import { Box } from '@chakra-ui/core'
import FormSelect from 'components/Form/Select'
import { zipObject } from 'lodash-es'
import React, { useMemo } from 'react'
import { usePartners } from 'store/partners/hooks'

function PartnerFilter({ ...props }) {
  const partners = usePartners()

  const partnerOptions = useMemo(() => {
    return zipObject(
      partners.allIds,
      partners.allIds.map((id) => partners.byId[id].name)
    )
  }, [partners.allIds, partners.byId])

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="offer.loanPartner"
        label={`Filter by Partner`}
        options={partnerOptions}
      />
    </Box>
  )
}

export default PartnerFilter
