import FormSelect from 'components/Form/Select'
import React, { useMemo } from 'react'
import { Box } from 'reflexbox'
import {
  applicationStatusDisplayText,
  getOldApplicationStatus,
} from 'utils/application-status'

function ApplicationStatusFilter({ ...props }) {
  const statusOptions = useMemo(
    () => ({
      preparing: applicationStatusDisplayText['preparing'],
      troubleshooting: applicationStatusDisplayText['troubleshooting'],
      quarantine: applicationStatusDisplayText['quarantine'],
      [getOldApplicationStatus('closed')]: applicationStatusDisplayText[
        'closed'
      ],
      digitization_pending:
        applicationStatusDisplayText['digitization_pending'],
      nid_verification_pending:
        applicationStatusDisplayText['nid_verification_pending'],
      call_verification_pending:
        applicationStatusDisplayText['call_verification_pending'],
      onsite_verification_pending:
        applicationStatusDisplayText['onsite_verification_pending'],
      verification_approval_pending:
        applicationStatusDisplayText['verification_approval_pending'],
      accepted: applicationStatusDisplayText['accepted'],
    }),
    []
  )

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="status"
        label={`Filter by Status`}
        options={statusOptions}
      />
    </Box>
  )
}

export default ApplicationStatusFilter
