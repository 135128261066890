import React, { useMemo } from 'react'
import { Button } from '@chakra-ui/core'
import { useLoanProducts } from 'store/admin/hooks'
import { Link } from 'react-router-dom'
import ListTable from 'components/ListTable/ListTable'
import { useTable } from 'react-table'

const getColumns = () => [
  {
    Header: 'Product Name',
    accessor: 'productName',
  },
  {
    Header: 'User Product Type',
    accessor: ({ productType }) => productType.split('_')?.[0],
  },
  {
    Header: 'FI Product Type',
    accessor: ({ productType }) => productType.split('_')?.[1],
  },
  {
    Header: '',
    id: 'action_button',
    accessor: ({ id }) => (
      <Button
        as={Link}
        to={`/dash/admin/loan-product/single/${id}`}
        variantColor="cyan"
        px={6}
        size="sm"
      >
        VIEW CONFIG
      </Button>
    ),
  },
]

function ProductList() {
  const columns = useMemo(() => getColumns(), [])

  const loanProducts = useLoanProducts()

  const data = useMemo(
    () => loanProducts.allIds.map((id) => loanProducts.byId[id]),
    [loanProducts]
  )

  const table = useTable({ data, columns })

  return <ListTable table={table} />
}

export default ProductList
