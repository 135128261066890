import React, { useState, useEffect } from 'react'
import { Box, Text, Input, Flex } from '@chakra-ui/core'
import ReactDatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import Authorize from 'components/Authorize'

function RepaymentFilter({ onChange }) {
  const [date, setDate] = useState()

  useEffect(() => {
    if (date) {
      const preparedDate = DateTime.fromJSDate(date).toISO()
      onChange(preparedDate)
    }
  }, [date, onChange])

  return (
    <Authorize permissions="eloan:RepaymentView:backdate">
      <Flex mb={6} justify="flex-end">
        <Box align="center">
          <Text color="gray.700" mb={2}>
            SELECT SUMMARY DATE
          </Text>
          <ReactDatePicker
            selected={date}
            placeholderText="Date..."
            onChange={setDate}
            customInput={<Input h="2.4rem" />}
          />
        </Box>
      </Flex>
    </Authorize>
  )
}

export default RepaymentFilter
