import { Badge } from '@chakra-ui/core'
import React from 'react'
import { getOldApplicationStatus } from 'utils/application-status'

const badgeColor = {
  preparing: 'yellow',
  troubleshooting: 'orange',
  checking: 'blue',
  accepted: 'green',
  quarantine: 'orange',
  closed: 'red',
  [getOldApplicationStatus('closed')]: 'red',

  fraudulent: 'gray',
  ineligible: 'gray',
  not_interested: 'gray',
  out_of_area: 'gray',
  spam: 'gray',

  offer_negotiating: 'orange',
  offer_tweaking: 'yellow',
  loanee_agreement_pending: 'cyan',
  loanee_agreement_signed: 'teal',
  'shopup-approved': 'teal',
  'partner-approved': 'green',
  financed: 'green',
  active: 'orange',
  ended: 'black',
  'shopup-rejected': 'red',
  'partner-rejected': 'red',
}

function StatusBadge({ status, text, prefix = '', ...props }) {
  return (
    <>
      <Badge variantColor={badgeColor[status]} p={1} {...props}>
        {`${prefix}${text || status}`}
      </Badge>
    </>
  )
}

export default StatusBadge
