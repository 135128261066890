import {
  Heading,
  SimpleGrid,
  useToast,
  Button,
  IconButton,
  Input,
  FormLabel,
  FormControl,
} from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { updateApplicationMetaJson } from 'store/applications'
import FormInput from 'components/Form/Input'
import ReactDatePicker from 'react-datepicker'
import { toDate } from 'utils/cast'

const getDefaultValues = ({ meta }, fieldName) =>
  get(meta, `${fieldName}.items`, [''])

function LongTermLoanForm({ application, fieldName }) {
  const toast = useToast()

  const defaultValues = useMemo(
    () => ({
      longTermLoan: getDefaultValues(application, fieldName),
    }),
    [application, fieldName]
  )

  const form = useForm({
    defaultValues,
  })

  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: 'longTermLoan',
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ longTermLoan }) => {
      try {
        await dispatch(
          updateApplicationMetaJson(get(application, 'id'), {
            fieldName,
            items: longTermLoan,
          })
        )
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [application, dispatch, form, toast, fieldName]
  )

  return (
    <Box mb={10}>
      <Heading fontSize={4} color="gray.600">
        Long Term Loan (Active)
      </Heading>
      <Form form={form} onSubmit={onSubmit}>
        {fields.map((field, index) => (
          <SimpleGrid
            key={field.id}
            columns={6}
            spacing={4}
            borderBottomWidth={1}
            borderBottomColor="gray.200"
            py={4}
            alignItems="flex-end"
          >
            <FormInput
              defaultValue={field.nameOfConcern}
              placeholder="Name of Concern"
              label="Name of Concern"
              name={`longTermLoan[${index}].nameOfConcern`}
              required
            />
            <FormInput
              defaultValue={field.fiName}
              placeholder="Name of Bank"
              label="Name of Bank"
              name={`longTermLoan[${index}].fiName`}
              required
            />
            <FormInput
              defaultValue={field.facility}
              placeholder="Facility"
              label="Facility"
              name={`longTermLoan[${index}].facility`}
              required
            />
            <FormInput
              defaultValue={field.amount}
              placeholder="Loan Amount"
              label="Loan Amount"
              name={`longTermLoan[${index}].amount`}
              required
            />
            <FormInput
              defaultValue={field.term}
              placeholder="Term (Month)"
              label="Term (Month)"
              name={`longTermLoan[${index}].term`}
              required
            />
            <Box>
              <FormControl isRequired>
                <FormLabel>Disbursement Date</FormLabel>
                <Controller
                  defaultValue={toDate(field.disbursementDate)}
                  as={ReactDatePicker}
                  control={form.control}
                  valueName="selected"
                  onChange={([selected]) => selected}
                  name={`longTermLoan[${index}].disbursementDate`}
                  placeholderText="Disbursement Date"
                  customInput={<Input />}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl isRequired>
                <FormLabel>Statement Date</FormLabel>
                <Controller
                  defaultValue={toDate(field.statementDate)}
                  as={ReactDatePicker}
                  control={form.control}
                  valueName="selected"
                  onChange={([selected]) => selected}
                  name={`longTermLoan[${index}].statementDate`}
                  placeholderText="Statement Date"
                  customInput={<Input />}
                />
              </FormControl>
            </Box>
            <FormInput
              defaultValue={field.installment}
              placeholder="Installment per Month"
              label="Installment per Month"
              name={`longTermLoan[${index}].installment`}
              required
            />
            <FormInput
              defaultValue={field.installmentPaid}
              placeholder="Installment paid"
              label="Installment paid"
              name={`longTermLoan[${index}].installmentPaid`}
            />
            <FormInput
              defaultValue={field.rate}
              placeholder="Rate"
              label="Rate"
              name={`longTermLoan[${index}].rate`}
            />
            <FormInput
              defaultValue={field.urpa}
              placeholder="URPA"
              label="URPA"
              name={`longTermLoan[${index}].urpa`}
            />
            <IconButton
              justifySelf="center"
              onClick={() => remove(index)}
              variantColor="red"
              icon="delete"
              variant="outline"
            />
          </SimpleGrid>
        ))}
        <Button
          leftIcon="small-add"
          mt={4}
          onClick={() => append()}
          variantColor="blue"
          variant="outline"
          size="sm"
        >
          Add more
        </Button>
        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default LongTermLoanForm
