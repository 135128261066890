import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import FormTextarea from 'components/Form/Textarea'
import { get } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { closeApplication, quarantineApplication } from 'store/applications'
import {
  applicationSubStatusDisplayText,
  getOldApplicationStatus,
} from 'utils/application-status'
import { toLower } from 'utils/caseStyle'
import * as Yup from 'yup'

const closedSubStatusOptions = {
  fraudulent: applicationSubStatusDisplayText['fraudulent'],
  ineligible: applicationSubStatusDisplayText['ineligible'],
  not_interested: applicationSubStatusDisplayText['not_interested'],
  out_of_area: applicationSubStatusDisplayText['out_of_area'],
  spam: applicationSubStatusDisplayText['spam'],
}

const subStatusToDirectlyClose = ['fraudulent']

const getDefaultValues = () => ({
  subStatus: '',
  note: '',
})

const getValidationSchema = () => {
  return Yup.object({
    subStatus: Yup.string()
      .oneOf(Object.keys(closedSubStatusOptions), 'Invalid reason')
      .required(`required`),
    note: Yup.string()
      .min(10, `Please write detailed note`)
      .required(`required`),
  })
}

function CloseApplication({ applicationId, application, ...props }) {
  const isClosed = useMemo(() => {
    const currentState = toLower(get(application, 'status'))
    return ['closed', getOldApplicationStatus('closed'), 'quarantine'].includes(
      currentState
    )
  }, [application])

  const [skipQuarantine, setSkipQuarantine] = useState(false)

  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ subStatus, note }) => {
      try {
        if (subStatusToDirectlyClose.includes(subStatus) || skipQuarantine) {
          await dispatch(closeApplication(applicationId, { subStatus, note }))
        } else {
          await dispatch(
            quarantineApplication(applicationId, { subStatus, note })
          )
        }
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, skipQuarantine, toast]
  )

  if (isClosed) {
    return null
  }

  return (
    <Authorize permissions="eloan:AnyApplication:quarantine, eloan:Application:quarantine">
      <Box {...props}>
        <Button variantColor="red" onClick={onOpen}>
          Close Application
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader color="red.500">Close Application</ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <Stack spacing={2}>
                  <Box>
                    <FormSelect
                      name="subStatus"
                      label={`Reason`}
                      options={closedSubStatusOptions}
                    />
                  </Box>
                  <Box>
                    <FormTextarea name="note" label={`Note`} />
                  </Box>

                  <Authorize permissions="eloan:AnyApplication:close">
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box flexShrink="1" p={4}>
                        <Checkbox
                          isChecked={skipQuarantine}
                          onChange={() => {
                            setSkipQuarantine((skip) => !skip)
                          }}
                        >
                          Skip Quarantine
                        </Checkbox>
                      </Box>
                      <Box flexGrow="1">
                        {skipQuarantine && (
                          <Text color="red.500" fontWeight="bold">
                            Think again, Do you really want to do this?!! <br />
                            Skipping Quarantine is a one way road!!!
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  </Authorize>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <FormButton type="submit" variantColor="red">
                  Close
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default CloseApplication
