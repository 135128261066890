import { get, keyBy, map, union } from 'lodash-es'
import { PA_CALL_LOG_ADD, PA_CALL_LOG_ADD_BULK } from 'store/paCallLogs'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  idsByApplication: emptyObject,
}

const paCallLogsreducer = (
  state = initialState,
  { type, data, applicationId }
) => {
  switch (type) {
    case PA_CALL_LOG_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [data[idKey]]),
        idsByApplication: {
          ...state.idsByApplication,
          [applicationId]: union(
            get(state.idsByApplication, applicationId, emptyArray),
            [data[idKey]]
          ),
        },
      }

    case PA_CALL_LOG_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        idsByApplication: {
          ...state.idsByApplication,
          [applicationId]: union(
            get(state.idsByApplication, applicationId, emptyArray),
            map(data.items, idKey)
          ),
        },
      }

    default:
      return state
  }
}

export default paCallLogsreducer
