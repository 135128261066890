import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import {
  Form,
  FormButton,
  FormCheckbox,
  FormDateRangePicker,
  FormInput,
  handleAPIError,
  FormSelect,
  FormTextarea,
} from '@eloan/shared'
import { get } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  recalculateRedxShopOffer,
  modifyRedxShopOfferAmount,
} from 'store/redx-shop-offers'
import { useRedxShopOffer } from 'store/redx-shop-offers/hooks'
import { modifyRedxShopOfferStatus } from 'store/redx-shop-offers'

function Recalculate({ redxShopOffer, onDone }) {
  console.log(redxShopOffer)
  const toast = useToast()
  const defaultValues = useMemo(() => {
    return {
      historyDate: {
        start: get(redxShopOffer, 'historyStartDate'),
        end: get(redxShopOffer, 'historyEndDate'),
      },
      minLoanAmount: get(redxShopOffer, 'minLoanAmount'),
      maxLoanAmount: get(redxShopOffer, 'maxLoanAmount'),
    }
  }, [redxShopOffer])

  const form = useForm({
    defaultValues,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ minLoanAmount, maxLoanAmount, historyDate }) => {
      try {
        await dispatch(
          recalculateRedxShopOffer({
            shopOfferId: redxShopOffer.id,
            historyStartDate: historyDate.start,
            historyEndDate: historyDate.end,
            minLoanAmount,
            maxLoanAmount,
          })
        )
        onDone()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onDone, redxShopOffer.id, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <FormDateRangePicker name="historyDate" label="History Date Range" />
      <FormInput name="minLoanAmount" label="Min Loan Amount" />
      <FormInput name="maxLoanAmount" label="Max Loan Amount" />
      <ButtonGroup display="flex" justifyContent="space-around">
        <FormButton type="submit" flexGrow="1" variantColor="green">
          Recalculate
        </FormButton>
      </ButtonGroup>
    </Form>
  )
}

// ----------------------------------------------------------------

function ModifyAmount({ redxShopOffer, onDone }) {
  const [selectedWaitingPeriod, setSelectedWaitingPeriod] = useState(
    get(redxShopOffer, 'canSelectWaitingPeriod')
  )

  const [waiveOnboardingFee, setWaiveOnboardingFee] = useState(
    get(redxShopOffer, 'waiveOnboardingFee', false)
  )

  const waitingPeriodOption = {
    1: 'One Days',
    7: 'Seven Days',
    14: 'Fourteen Days ',
  }

  const toast = useToast()
  const defaultValues = useMemo(() => {
    return {
      minLoanAmount: get(redxShopOffer, 'minLoanAmount'),
      maxLoanAmount: get(redxShopOffer, 'maxLoanAmount'),
      paymentDeductionPercentage: get(
        redxShopOffer,
        'paymentDeductionPercentage'
      ),
      canSelectWaitingPeriod: get(redxShopOffer, 'canSelectWaitingPeriod'),
      deductionWaitingPeriodInDays: get(
        redxShopOffer,
        'deductionWaitingPeriodInDays'
      ),
      waiveOnboardingFee: get(redxShopOffer, 'waiveOnboardingFee', false),
    }
  }, [redxShopOffer])

  const form = useForm({
    defaultValues,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({
      minLoanAmount,
      maxLoanAmount,
      paymentDeductionPercentage,
      canSelectWaitingPeriod,
      deductionWaitingPeriodInDays,
      waiveOnboardingFee,
    }) => {
      try {
        await dispatch(
          modifyRedxShopOfferAmount({
            shopOfferId: redxShopOffer.id,
            minLoanAmount,
            maxLoanAmount,
            paymentDeductionPercentage,
            canSelectWaitingPeriod,
            deductionWaitingPeriodInDays: canSelectWaitingPeriod
              ? deductionWaitingPeriodInDays
              : 1,
            waiveOnboardingFee,
          })
        )
        onDone()
        window.location.reload()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onDone, redxShopOffer.id, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <FormInput name="minLoanAmount" label="Min Loan Amount" />
      <FormInput name="maxLoanAmount" label="Max Loan Amount" />
      <FormInput
        name="paymentDeductionPercentage"
        label="Payment Deduction Percentage"
      />
      <FormCheckbox
        name="canSelectWaitingPeriod"
        onChange={() => setSelectedWaitingPeriod(!selectedWaitingPeriod)}
      >
        Waiting Period
      </FormCheckbox>

      <FormCheckbox
        name="waiveOnboardingFee"
        onChange={() => setWaiveOnboardingFee(!waiveOnboardingFee)}
      >
        Waive Onboarding Fee
      </FormCheckbox>

      {selectedWaitingPeriod && (
        <div style={{ marginBottom: 10 }}>
          <FormSelect
            name="deductionWaitingPeriodInDays"
            label={`Select Waiting Period Deduction`}
            options={waitingPeriodOption}
          />
        </div>
      )}

      <ButtonGroup display="flex" justifyContent="space-around">
        <FormButton type="submit" flexGrow="1" variantColor="green">
          Modify Offer
        </FormButton>
      </ButtonGroup>
    </Form>
  )
}

function UpdateRedxShopOffer({ id, ...props }) {
  const redxShopOffer = useRedxShopOffer(id)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [status, setStatus] = useState(null)

  const form = useForm()
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ comment }) => {
      try {
        await dispatch(
          modifyRedxShopOfferStatus({
            shopOfferId: id,
            status: status,
            comment,
          })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { form })
      }
    },
    [dispatch, form, id, onClose, status]
  )

  return (
    <>
      <Button size="sm" variantColor="yellow" onClick={onOpen} {...props}>
        Update
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>{`Update & approve REDX shop offer`}</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs isFitted>
              <TabList>
                <Tab>Recalculate</Tab>
                <Tab>Modify</Tab>
                {['repeat-pending'].includes(props.status) && (
                  <Tab>Confirm/Reject</Tab>
                )}
              </TabList>
              <TabPanels p={4}>
                <TabPanel>
                  <Recalculate redxShopOffer={redxShopOffer} onDone={onClose} />
                </TabPanel>
                <TabPanel>
                  <ModifyAmount
                    redxShopOffer={redxShopOffer}
                    onDone={onClose}
                  />
                </TabPanel>
                {['repeat-pending'].includes(props.status) && (
                  <TabPanel>
                    <Form form={form} onSubmit={onSubmit}>
                      <FormTextarea
                        label={<Text color="black">Comments</Text>}
                        name="comment"
                        placeholder="Enter a reason"
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <FormButton
                          type="submit"
                          mt={2}
                          variantColor="red"
                          onClick={() => setStatus('rejected')}
                        >
                          Reject
                        </FormButton>
                        <FormButton
                          type="submit"
                          mt={2}
                          variantColor="cyan"
                          onClick={() => setStatus('eligible')}
                        >
                          Accept
                        </FormButton>
                      </div>
                    </Form>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UpdateRedxShopOffer
