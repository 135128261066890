import React, { useCallback } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  useDisclosure,
  Button,
  ModalFooter,
  useToast,
} from '@chakra-ui/core'
import { useDispatch } from 'react-redux'
import { removeCCA } from 'store/repaymentFollowup/ccaSlice'
import CCADetails from './CCADetails'
import { get } from 'lodash-es'

function RemoveCCA({ application }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch()
  const toast = useToast()
  const remove = useCallback(async () => {
    try {
      onClose()
      await dispatch(removeCCA(get(application, 'applicationId')))
    } catch {
      toast({
        title: 'An error occurred.',
        description: 'Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [dispatch, onClose, toast, application])

  return (
    <Box>
      <Button
        variant="outline"
        variantColor="red"
        size="sm"
        onClick={onOpen}
        px={6}
      >
        Remove
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Remove Loan ID: {get(application, 'applicationId')}
          </ModalHeader>
          <ModalCloseButton type="button" />
          <ModalBody>
            <Text>
              Are you sure you want to remove this user from your list?
            </Text>
            <CCADetails application={application} />
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button onClick={onClose} variantColor="green" mr={3}>
              Cancel
            </Button>
            <Button variantColor="red" onClick={remove}>
              REMOVE ID
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default RemoveCCA
