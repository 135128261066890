import _ from 'lodash-es'

export function keyByDeep(arr, key) {
  return _(arr)
    .map(function (o) {
      // map each object in the array
      return _.mapValues(o, function (v) {
        // map the properties of the object
        return _.isArray(v) ? keyByDeep(v, key) : v // if the property value is an array, run deepKeyBy() on it
      })
    })
    .keyBy(key)
    .value() // index the object by the key
}
