import React, { useState, useCallback, useMemo } from 'react'
import Form from 'components/Form/Form'
import { useForm } from 'react-hook-form'
import FormSelect from 'components/Form/Select'
import {
  Stack,
  Box,
  Text,
  Radio,
  CheckboxGroup,
  Checkbox,
  Button,
  Flex,
  FormLabel,
  useDisclosure,
  useToast,
  Alert,
  AlertIcon,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/core'
import FormInput from 'components/Form/Input'
import FormButton from 'components/Form/Button'
import CreateConfigurationDrawer from './Modals/CreateConfiguration'
import {
  isEmpty,
  zipObject,
  get,
  some,
  map,
  unionWith,
  isEqual,
} from 'lodash-es'
import * as Yup from 'yup'
import Configuration from 'pages/admin/components/Configuration/Configuration'
import ConfigItem, {
  LoanProductFiClientConfig,
} from 'pages/admin/components/Configuration/ConfigItem'
import { useFinancialInstitutions, useLoanConfigs } from 'store/admin/hooks'
import { useDispatch } from 'react-redux'
import { createLoanProduct } from 'store/admin'
import { emptyObject } from 'utils/defaults'
import { handleAPIError } from 'components/Form/helpers'
import { ConfirmLoanProduct } from './Modals/ConfirmLoanProduct'
import { shopUpFinancialInstitutionId } from 'pages/admin/constants'
import InfoBox from 'components/Box/InfoBox'
import Authorize from 'components/Authorize'
import CreateFinancialInstitution from './Modals/CreateFinancialInstitution'

function ConfigurationSelect({ data, onAdd }) {
  const [fiConfig, setFiConfig] = useState()
  const [suConfig, setSuConfig] = useState()

  const submit = useCallback(() => {
    const fiClientConfigs = []

    for (let item of suConfig) {
      fiClientConfigs.push({
        fiConfigType: get(data, 'fiConfigType'),
        clientConfigType: get(data, 'clientConfigType'),
        fiConfigId: fiConfig,
        clientConfigId: item,
      })
    }

    const config = {
      financialInstitutionId: get(data, 'financialInstitutionId'),
      fiClientConfigs,
      minCreditLimit: get(data, 'minCreditLimit'),
      maxCreditLimit: get(data, 'maxCreditLimit'),
    }

    onAdd({ ...config })
    setFiConfig(undefined)
    setSuConfig(undefined)
  }, [fiConfig, suConfig, onAdd, data])

  return (
    <Box mb={6}>
      {some(data, isEmpty) && (
        <Alert
          status="warning"
          mb={4}
          borderRadius="4px"
          borderWidth="1px"
          borderColor="orange.200"
        >
          <AlertIcon />
          To add configuration you have to select{' '}
          <Text as="strong" mx={1}>
            Max. Credit Limit, Min. Credit Limit
          </Text>{' '}
          &{' '}
          <Text as="strong" mx={1}>
            Bank or FI's
          </Text>
        </Alert>
      )}
      <Stack isInline spacing={4}>
        <Box width="100%">
          <Flex justify="space-between">
            <Text>FI Configuration</Text>
            <CreateConfigurationDrawer
              type="fi"
              financialInstitutionId={get(data, 'financialInstitutionId')}
            />
          </Flex>
          <Stack
            borderWidth="1px"
            borderColor="gray.200"
            p={2}
            mt={2}
            spacing={3}
          >
            {get(data, 'selectedFiConfigAllIds', []).map((id, index) => (
              <Flex key={id} justify="space-between">
                <Radio
                  isChecked={fiConfig === id}
                  onChange={() => setFiConfig(id)}
                  isFullWidth
                >
                  <ConfigItem
                    config={get(data, 'loanConfigs').byId[id]}
                    serial={index + 1}
                  />
                </Radio>
                <Configuration
                  title="FI Configuration"
                  config={get(data, 'loanConfigs').byId[id]}
                />
              </Flex>
            ))}
          </Stack>
        </Box>
        {fiConfig && (
          <Box width="100%">
            <Flex justify="space-between">
              <Text>ShopUp Configuration</Text>
              <CreateConfigurationDrawer
                type="shopup"
                financialInstitutionId={shopUpFinancialInstitutionId}
              />
            </Flex>
            <CheckboxGroup
              onChange={(value) => setSuConfig(value)}
              borderWidth="1px"
              borderColor="gray.200"
              p={2}
              mt={2}
            >
              {get(data, 'shopUpConfigAllIds', []).map((id, index) => (
                <Flex key={id} justify="space-between" mb={1}>
                  <Checkbox value={id.toString()}>
                    <ConfigItem
                      config={get(data, 'loanConfigs').byId[id]}
                      serial={index + 1}
                    />
                  </Checkbox>
                  <Configuration
                    title="ShopUp Configuration"
                    config={get(data, 'loanConfigs').byId[id]}
                  />
                </Flex>
              ))}
            </CheckboxGroup>
          </Box>
        )}
      </Stack>
      <Button
        mt={6}
        size="sm"
        px={6}
        variantColor="gray"
        disabled={!fiConfig || isEmpty(suConfig) || some(data, isEmpty)}
        onClick={submit}
      >
        ADD CONFIG
      </Button>
    </Box>
  )
}

const getDefaultValues = () => ({
  clientConfigType: 'TERM',
  fiConfigType: 'TERM',
})

const getValidationSchema = () =>
  Yup.object({
    productName: Yup.string().required('Required.'),
    financialInstitutionId: Yup.string().required('Required.'),
    minCreditLimit: Yup.number()
      .min(500, 'Minimum 500.')
      .typeError('Must be number.')
      .required('Required.'),
    maxCreditLimit: Yup.number()
      .min(500, 'Minimum 500.')
      .moreThan(Yup.ref('minCreditLimit'), 'Max should be more than min.')
      .typeError('Must be number.')
      .required('Required.'),
  })

const termProductType = {
  TERM: 'Term',
}

function CreateProduct() {
  const [configs, setConfigs] = useState({})

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({ defaultValues, validationSchema })

  const { watch, setError, clearError, reset, triggerValidation } = form

  const clientConfigType = watch('clientConfigType')
  const fiConfigType = watch('fiConfigType')
  const financialInstitutionId = watch('financialInstitutionId')
  const minCreditLimit = watch('minCreditLimit')
  const maxCreditLimit = watch('maxCreditLimit')

  const loanConfigs = useLoanConfigs()

  const selectedFiConfigAllIds = useMemo(
    () =>
      loanConfigs.allIds.filter(
        (id) =>
          get(loanConfigs.byId[id], 'financialInstitution.id') ===
          parseInt(financialInstitutionId)
      ),
    [financialInstitutionId, loanConfigs]
  )

  const shopUpConfigAllIds = useMemo(
    () =>
      loanConfigs.allIds.filter(
        (id) =>
          get(loanConfigs.byId[id], 'financialInstitution.id') ===
          shopUpFinancialInstitutionId
      ),
    [loanConfigs]
  )

  const configSelectData = useMemo(
    () => ({
      loanConfigs,
      selectedFiConfigAllIds,
      shopUpConfigAllIds,
      fiConfigType,
      clientConfigType,
      financialInstitutionId,
      minCreditLimit,
      maxCreditLimit,
    }),
    [
      loanConfigs,
      selectedFiConfigAllIds,
      shopUpConfigAllIds,
      fiConfigType,
      clientConfigType,
      financialInstitutionId,
      minCreditLimit,
      maxCreditLimit,
    ]
  )

  const addConfigsData = useCallback(
    async (configData) => {
      const isValid = await triggerValidation([
        'minCreditLimit',
        'maxCreditLimit',
      ])
      if (!isValid) {
        return
      }

      const currentConfigs = { ...configs }

      if (currentConfigs[configData.financialInstitutionId]) {
        const fiClientConfigs = unionWith(
          currentConfigs[configData.financialInstitutionId].fiClientConfigs,
          configData.fiClientConfigs,
          isEqual
        )

        currentConfigs[configData.financialInstitutionId] = {
          ...currentConfigs[configData.financialInstitutionId],
          fiClientConfigs,
        }
      } else {
        currentConfigs[configData.financialInstitutionId] = {
          financialInstitutionId: configData.financialInstitutionId,
          fiClientConfigs: [...configData.fiClientConfigs],
          minCreditLimit: configData.minCreditLimit,
          maxCreditLimit: configData.maxCreditLimit,
        }
      }

      setConfigs(currentConfigs)
    },
    [configs, triggerValidation]
  )

  const removeConfigsData = useCallback(
    (key) => {
      const currentConfigs = { ...configs }
      delete currentConfigs[key]
      setConfigs(currentConfigs)
    },
    [configs]
  )

  const financialInstitutions = useFinancialInstitutions()

  const fiOptions = useMemo(
    () =>
      zipObject(
        financialInstitutions.allIds,
        financialInstitutions.allIds.map(
          (id) => financialInstitutions.byId[id].name
        )
      ),
    [financialInstitutions]
  )

  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure()

  const [submitData, setSubmitData] = useState()
  const [isSubmitting, setIsSubmitting] = useState()

  const dispatch = useDispatch()
  const toast = useToast()

  const handleReset = useCallback(() => {
    reset()
    setSubmitData(null)
    setConfigs(emptyObject)
    setIsConfigsError(false)
  }, [reset])

  const onConfirm = useCallback(async () => {
    try {
      setIsSubmitting(true)

      await dispatch(createLoanProduct(submitData))
      toast({
        title: 'Loan product has been created.',
        status: 'success',
      })
      onCloseConfirm()
      handleReset()
    } catch (error) {
      handleAPIError(error, { toast })
    }
    setIsSubmitting(false)
  }, [submitData, dispatch, toast, onCloseConfirm, handleReset])

  const [isConfigsError, setIsConfigsError] = useState(false)

  const onSubmit = useCallback(
    ({ clientConfigType, fiConfigType, productName }) => {
      if (isEmpty(configs)) {
        setIsConfigsError(true)
        return
      }

      const productType = [clientConfigType, fiConfigType].join('_')
      if (!['TERM_TERM', 'RL_OD'].includes(productType)) {
        setError(
          'clientConfigType',
          'equal',
          'Combination must be between Term-Term AND RL-OD'
        )
        return
      }

      clearError()
      setIsConfigsError(false)

      setSubmitData({
        productType,
        productName,
        configs: Object.values(configs),
      })
      onOpenConfirm()
    },
    [onOpenConfirm, setError, clearError, configs]
  )

  return (
    <Authorize permissions="eloan:LoanProduct:write">
      <Form id="create-product" form={form} width="80%" onSubmit={onSubmit}>
        <Stack isInline spacing={4} mb={6}>
          <Box width="100%">
            <FormSelect
              label="Client loan type"
              name="clientConfigType"
              options={termProductType}
            />
          </Box>
          <Box width="100%">
            <FormSelect
              label="FI or, bank loan type"
              name="fiConfigType"
              options={termProductType}
            />
          </Box>
        </Stack>
        <Stack isInline spacing={4} mb={6}>
          <Box width="100%">
            <FormInput label="Enter product name" name="productName" />
          </Box>
          <Box mb={6} width="100%">
            <Flex justify="space-between" align="center">
              <FormLabel>Select bank or FI's</FormLabel>
              <CreateFinancialInstitution />
            </Flex>
            <FormSelect name="financialInstitutionId" options={fiOptions} />
          </Box>
        </Stack>
        <Stack isInline spacing={4} mb={6}>
          <Box width="100%">
            <FormInput
              name="minCreditLimit"
              type="number"
              label="Min. credit limit"
            />
          </Box>
          <Box width="100%">
            <FormInput
              name="maxCreditLimit"
              type="number"
              label="Max. credit limit"
            />
          </Box>
        </Stack>

        <Box mb={4}>
          <Accordion allowMultiple>
            {map(configs, (field, index) => (
              <AccordionItem key={index} mb={4} borderBottomWidth="1px">
                <AccordionHeader
                  bg="gray.50"
                  borderLeftWidth="1px"
                  borderRightWidth="1px"
                >
                  <Box flex="1" textAlign="left" color="blue.500">
                    {
                      financialInstitutions.byId[field.financialInstitutionId]
                        .name
                    }
                  </Box>
                  <AccordionIcon />
                </AccordionHeader>
                <AccordionPanel
                  py={2}
                  bordrBottomWidth="1px"
                  borderLeftWidth="1px"
                  borderRightWidth="1px"
                >
                  <Stack isInline spacing={10}>
                    <InfoBox label="Min Credit Limit">
                      {get(field, 'minCreditLimit')}
                    </InfoBox>
                    <InfoBox label="Max Credit Limit">
                      {get(field, 'maxCreditLimit')}
                    </InfoBox>
                  </Stack>
                  {map(field.fiClientConfigs, (item, innerIndex) => (
                    <LoanProductFiClientConfig
                      key={innerIndex}
                      fiConfigType={item.fiConfigType}
                      clientConfigType={item.clientConfigType}
                      fiConfig={loanConfigs.byId[item.fiConfigId]}
                      clientConfig={loanConfigs.byId[item.clientConfigId]}
                    />
                  ))}
                  <Button
                    onClick={() => removeConfigsData(index)}
                    variant="link"
                    variantColor="red"
                    size="xs"
                  >
                    REMOVE
                  </Button>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
          {isConfigsError && (
            <Box color="red.500">
              Atleast one configuration needs to be selected.
            </Box>
          )}
        </Box>

        <ConfigurationSelect data={configSelectData} onAdd={addConfigsData} />

        <FormButton type="submit" variantColor="cyan" px={10}>
          CREATE LOAN PRODUCT
        </FormButton>
      </Form>
      <ConfirmLoanProduct
        onConfirm={onConfirm}
        isOpen={isOpenConfirm}
        onClose={onCloseConfirm}
        isLoading={isSubmitting}
      />
    </Authorize>
  )
}

export default CreateProduct
