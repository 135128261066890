import camelcaseKeys from 'camelcase-keys'
import { batch } from 'react-redux'

export const AREA_LOADING_ALL = 'AREA_LOADING_ALL'
export const AREA_SET_ALL = 'AREA_SET_ALL'

export const DISTRICT_LOADING_ALL = 'DISTRICT_LOADING_ALL'
export const DISTRICT_SET_ALL = 'DISTRICT_SET_ALL'

export const getAllAreas = () => async (dispatch, getState, { api }) => {
  const { areas } = getState()

  if (!areas.loading) {
    dispatch({ type: AREA_LOADING_ALL })

    const { data } = await api(`/legacy/v1/shop/0/area`)

    dispatch({
      type: AREA_SET_ALL,
      data: {
        items: data.areas.map((item) => camelcaseKeys(item)),
        totalItems: data.areas.length,
      },
    })
  }
}

export const getAllAreasAndDistricts = () => async (
  dispatch,
  getState,
  { api }
) => {
  const { areas } = getState()

  if (!areas.districts.loading) {
    dispatch({ type: DISTRICT_LOADING_ALL })

    const { data } = await api(`/legacy/v2/areas`)

    const areaItems = []
    const districtItems = []

    for (const district of data.areas) {
      const areas = district.AREAS
      delete district.AREAS

      district.id = district.DISTRICT_ID
      delete district.DISTRICT_ID

      district.name = district.DISTRICT_NAME
      delete district.DISTRICT_NAME

      areaItems.push(...areas)
      district.areaIds = areas.map((area) => area.ID)
      districtItems.push(district)
    }

    batch(() => {
      dispatch({
        type: AREA_SET_ALL,
        data: {
          items: areaItems.map((item) => camelcaseKeys(item)),
          totalItems: areaItems.length,
        },
      })
      dispatch({
        type: DISTRICT_SET_ALL,
        data: {
          items: districtItems.map((item) => camelcaseKeys(item)),
          totalItems: districtItems.length,
        },
      })
    })
  }
}
