import { Duration } from 'luxon'
import { get } from 'lodash-es'
import { calculateEMI } from '@shopuptech/credit-calc'

export function convertToDays({ unit, value }) {
  return {
    unit: 'DAY',
    value: Duration.fromObject({
      ...(unit === 'YEAR' && { years: value }),
      ...(unit === 'MONTH' && { months: value }),
      ...(unit === 'WEEK' && { weeks: value }),
      ...(unit === 'DAY' && { days: value }),
    }).as('days'),
  }
}

export function findInstallmentCount(installment, tenure) {
  installment = convertToDays(installment)
  tenure = convertToDays(tenure)

  return tenure.value / installment.value
}

export function getNumberOfInstallments(draftAccount) {
  const numberOfInstallments = findInstallmentCount(
    {
      unit: get(
        draftAccount,
        'loanProductFiClientConfig.clientConfig.installmentPeriodUnit'
      ),
      value: get(
        draftAccount,
        'loanProductFiClientConfig.clientConfig.installmentPeriod'
      ),
    },
    {
      unit: get(
        draftAccount,
        'loanProductFiClientConfig.clientConfig.tenureUnit'
      ),
      value: get(draftAccount, 'clientTenure'),
    }
  )

  return numberOfInstallments
}

export function getEMI(draftAccount) {
  const numberOfInstallments = getNumberOfInstallments(draftAccount)

  let emiAmount

  if (numberOfInstallments) {
    emiAmount = +calculateEMI({
      repaymentMethod: get(
        draftAccount,
        'loanProductFiClientConfig.clientConfig.interestCalculationMethod'
      ),
      numberOfInstallments,
      principal: get(draftAccount, 'offerAmount'),
      compoundFactor:
        get(
          draftAccount,
          'loanProductFiClientConfig.clientConfig.interestType'
        ) === 'SIMPLE'
          ? 'NONE'
          : get(
              draftAccount,
              'loanProductFiClientConfig.clientConfig.interestChargeFrequency'
            ),
      installmentLength: convertToDays({
        unit: get(
          draftAccount,
          'loanProductFiClientConfig.clientConfig.installmentPeriodUnit'
        ),
        value: get(
          draftAccount,
          'loanProductFiClientConfig.clientConfig.installmentPeriod'
        ),
      })?.value,
      yearlyInterestRate: get(
        draftAccount,
        'loanProductFiClientConfig.clientConfig.interestRate'
      ),
      daysInYear: get(
        draftAccount,
        'loanProductFiClientConfig.clientConfig.daysInYear'
      ),
    })
  }

  return emiAmount
}
