import { useMemo } from 'react'
import { useQuery } from './useQuery'

const initial = 20
const max = 500

function useLimitQuery({ maxLimit = max, initialLimit = initial } = {}) {
  const query = useQuery()

  const limit = useMemo(() => {
    if (!query.get('limit')) {
      return initialLimit
    }
    return query.get('limit') < maxLimit ? query.get('limit') : maxLimit
  }, [query, initialLimit, maxLimit])

  return limit
}

export default useLimitQuery
