import { api } from 'api'

export const APP_BKASH_PAYMENTS_SET_ALL = 'APP_BKASH_PAYMENTS_SET_ALL'

export const getAppBkashPayments = ({ toDate, fromDate }) => async (
  dispatch,
  _getState
) => {
  const { data } = await api(
    `GET /legacy/v4/eloan/app-bkash-payments{?fromDate,toDate}`,
    {
      toDate,
      fromDate,
    }
  )

  dispatch({ type: APP_BKASH_PAYMENTS_SET_ALL, data })

  return data
}
