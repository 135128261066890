import { Heading, SimpleGrid, useToast } from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import { FormDatePicker } from 'components/Form/DatePicker'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import FormTextarea from 'components/Form/Textarea'
import { get, set } from 'lodash-es'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { updateApplicationRCAInfo } from 'store/applications'
import { useApplication } from 'store/applications/hooks'
import {
  getEducationOptions,
  getGenderOptions,
  getMaritalOptions,
  getOwnershipOptions,
  getProfessionOptions,
} from 'utils/selectOptions'
import DecoratedFormInput from '../components/DecoratedFormInput'

const maritalStatusOptions = getMaritalOptions()
const genderOptions = getGenderOptions()
const professionOptions = getProfessionOptions()
const educationOptions = getEducationOptions()
const ownershipOptions = getOwnershipOptions()

const fields = [
  'type',
  'meta.loaneeNid',
  'meta.loaneeNidName',
  'meta.loaneeDob',
  'meta.loaneeNidDob',
  'meta.loaneeGender',
  'meta.loaneePhoneNumber',
  'meta.loaneePhoneNumberAdditional',
  'meta.loaneeEmail',
  'meta.loaneePlaceOfBirth',
  'meta.loaneeProfession',
  'meta.loaneeEducation',
  'meta.loaneeDesignation',
  'meta.spouseName',
  'meta.loaneeSpouseGender',
  'meta.fatherName',
  'meta.motherName',
  'meta.loanPurpose',
  'meta.loanPurposeDescription',
  'meta.loaneePresentAddress',
  'metaExtra.loaneeSpouseDob',
  'metaExtra.yearsAtLoaneePresentAddress',
  'meta.loaneePermanentAddress',
  'meta.maritalStatus',
  'meta.loaneePremiseOwnershipStatus',
  'meta.loaneePersonalNetWorth',
  'meta.loaneeMonthlyNonBusinessIncome',
  'meta.numberOfChildren',
  'shop.shopName',
  'meta.businessType',
].join(',')

const getDefaultValues = (application) => {
  return fields.split(',').reduce(
    (values, field) => {
      set(values, field, get(application, field) || '')
      return values
    },
    { meta: {}, metaExtra: {}, shop: {} }
  )
}

function LoaneeRCA({ applicationId, ...props }) {
  const toast = useToast()

  const application = useApplication(applicationId, fields)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])

  const form = useForm({
    defaultValues,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ meta, metaExtra, shop }) => {
      try {
        await dispatch(
          updateApplicationRCAInfo(applicationId, { meta, metaExtra, shop })
        )
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, toast]
  )

  const isIdlc = application.type === 'daraz-idlc'

  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Loanee Information
      </Heading>

      <Form form={form} onSubmit={onSubmit}>
        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="meta.loaneeNid"
            label={`NID Number`}
            value={get(application, 'meta.loaneeNid')}
          />
          <DecoratedFormInput
            name="meta.loaneeNidName"
            label={`(NID) Full Name`}
            value={get(application, 'meta.loaneeNidName')}
          />
          <FormDatePicker name="meta.loaneeDob" label={`Date of Birth`} />
          <FormDatePicker
            name="meta.loaneeNidDob"
            label={`(NID) Date of Birth`}
          />
          <FormSelect
            name="meta.loaneeGender"
            label={`Gender`}
            options={genderOptions}
            required={isIdlc}
          />
          <DecoratedFormInput
            name="meta.loaneePhoneNumber"
            label={`Phone Number`}
            value={get(application, 'meta.loaneePhoneNumber')}
          />
          <DecoratedFormInput
            name="meta.loaneePhoneNumberAdditional"
            label={`Additional Phone Number`}
            value={get(application, 'meta.loaneePhoneNumberAdditional')}
          />
          <DecoratedFormInput
            name="meta.loaneeEmail"
            label={`Email`}
            value={get(application, 'meta.loaneeEmail')}
          />
          <DecoratedFormInput
            name="meta.loaneePlaceOfBirth"
            label={`Place of Birth`}
            value={get(application, 'meta.loaneePlaceOfBirth')}
          />

          <FormSelect
            name="meta.loaneeProfession"
            label={`Profession`}
            options={professionOptions}
          />

          <FormSelect
            name="meta.loaneeEducation"
            label={`Education`}
            options={educationOptions}
            required={isIdlc}
          />

          <DecoratedFormInput
            name="meta.loaneeDesignation"
            label={`Designation`}
            value={get(application, 'meta.loaneeDesignation')}
          />
          <DecoratedFormInput
            name="shop.shopName"
            label={`Business Name`}
            value={get(application, 'shop.shopName')}
          />
          <DecoratedFormInput
            name="meta.businessType"
            label={`Business Type`}
            value={get(application, 'meta.businessType')}
          />
          <FormSelect
            name="meta.maritalStatus"
            label={`Marital Status`}
            options={maritalStatusOptions}
          />
          <DecoratedFormInput
            name="meta.spouseName"
            label={`Spouse Name`}
            value={get(application, 'meta.spouseName')}
          />
          <FormDatePicker
            name="metaExtra.loaneeSpouseDob"
            label={`Spouse Date of Birth`}
          />
          <DecoratedFormInput
            name="meta.loaneeSpouseGender"
            label={`Spouse Gender`}
            value={get(application, 'meta.loaneeSpouseGender')}
          />
          <DecoratedFormInput
            name="meta.fatherName"
            label={`Father Name`}
            value={get(application, 'meta.fatherName')}
          />
          <DecoratedFormInput
            name="meta.motherName"
            label={`Mother Name`}
            value={get(application, 'meta.motherName')}
          />
          <DecoratedFormInput
            name="meta.loanPurpose"
            label={`Purpose of Loan`}
            value={get(application, 'meta.loanPurpose')}
          />
          <DecoratedFormInput
            name="meta.loanPurposeDescription"
            label={`Description of Loan Purpose`}
            value={get(application, 'meta.loanPurposeDescription')}
          />
          <FormSelect
            name="meta.loaneePremiseOwnershipStatus"
            label={`Ownership Status of Residence`}
            options={ownershipOptions}
            required={isIdlc}
          />
          <DecoratedFormInput
            name="meta.loaneePersonalNetWorth"
            label={`Personal Net Worth`}
            value={get(application, 'meta.loaneePersonalNetWorth')}
          />
          <DecoratedFormInput
            name="meta.loaneeMonthlyNonBusinessIncome"
            label={`Non Business Income`}
            value={get(application, 'meta.loaneeMonthlyNonBusinessIncome')}
          />
          <DecoratedFormInput
            name="meta.numberOfChildren"
            label={`Number of Dependents`}
            value={get(application, 'meta.numberOfChildren')}
          />
          <FormTextarea
            name="meta.loaneePresentAddress"
            label={`Present Address`}
          />
          <DecoratedFormInput
            name="metaExtra.yearsAtLoaneePresentAddress"
            label={`Years at Present Address`}
            value={get(application, 'metaExtra.yearsAtLoaneePresentAddress')}
          />
          <FormTextarea
            name="meta.loaneePermanentAddress"
            label={`Parmanent Address`}
          />
        </SimpleGrid>

        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default memo(LoaneeRCA)
