import React from 'react'
import StatusBadge from './StatusBadge'

function StatusTransition({ from, to, displayText, prefix, ...props }) {
  return (
    <>
      <StatusBadge
        status={from}
        text={displayText[from]}
        prefix={prefix}
        {...props}
      />
      {` ⇒ `}
      <StatusBadge
        status={to}
        text={displayText[to]}
        prefix={prefix}
        {...props}
      />
    </>
  )
}

export default StatusTransition
