import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/core'
import { useQuery } from 'hooks/useQuery'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Repayment from './sections/Repayment'
import RetailerInfo from './sections/RetailerInfo'
import RLAccount from './sections/RLAccount'

const tabs = [
  { id: 'retailer-info', name: 'Retailer Info' },
  { id: 'rl-account', name: 'Revolving Loan' },
  { id: 'repayments', name: 'Repayments' },
]

function ApplicationInformation() {
  const { applicationId } = useParams()

  const history = useHistory()

  const query = useQuery()

  const onTabChange = useCallback(
    (index) => {
      history.replace(
        `/dash/unilever-applications/${applicationId}?tab=${tabs[index].id}`
      )
    },
    [applicationId, history]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === query.get('tab'))
    return index < 0 ? 0 : index
  }, [query])

  useEffect(() => {
    onTabChange(tabIndex)
  }, [onTabChange, tabIndex])

  return (
    <Tabs index={tabIndex} onChange={onTabChange}>
      <Box sx={{ position: 'relative' }}>
        <TabList mb={4}>
          {tabs.map((tab) => (
            <Tab id={tab.id} key={tab.id}>
              {tab.name}
            </Tab>
          ))}
        </TabList>
      </Box>

      <TabPanels p={4}>
        <TabPanel>
          <RetailerInfo applicationId={applicationId} />
        </TabPanel>
        <TabPanel>
          <RLAccount applicationId={applicationId} />
        </TabPanel>
        <TabPanel>
          <Repayment applicationId={applicationId} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default ApplicationInformation
