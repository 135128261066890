import { Badge, Checkbox, Flex, Heading, Text } from '@chakra-ui/core'
import { DataTable } from '@eloan/shared/components/Table'
import Authorize from 'components/Authorize'
import Loader from 'components/Loader/Loader'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTable } from 'react-table'
import { Box } from 'reflexbox'
import { getSettlements } from 'store/settlements'
import { emptyObject } from 'utils/defaults'
import { getDate } from 'utils/getDate'
import Settle from './Settle'

const getColumns = (onCheckedChange, onCheckedChangeAll, checkedItems) => [
  {
    Header: ({ data }) => {
      return (
        <Checkbox
          isChecked={
            data.length && data.length === Object.keys(checkedItems).length
          }
          onChange={(e) => onCheckedChangeAll(e.target.checked, data)}
        />
      )
    },
    id: 'checkbox',
    accessor: (row) => (
      <Checkbox
        isChecked={!!checkedItems[row.id]}
        onChange={(e) => onCheckedChange(e.target.checked, row)}
      />
    ),
  },
  {
    Header: 'Date',
    accessor: ({ createdAt }) => getDate(createdAt),
  },
  {
    Header: 'Retailer Name',
    accessor: 'ownerName',
  },
  {
    Header: 'Retailer Phone',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Retailer Route Code',
    accessor: 'routeCode',
  },
  {
    Header: 'Collection Amount',
    accessor: 'collectionAmount',
  },
  {
    Header: 'Collection Method',
    accessor: 'collectionMethod',
  },
  {
    Header: 'Settlement Amount',
    accessor: 'principalRepaidAmount',
  },
]

const defaultQueryObject = {
  loanProductId: 2,
}

function UnileverSettlementsPage() {
  const [checkedItems, setCheckedItems] = useState(emptyObject)

  const onCheckedChange = useCallback(
    (isChecked, { id, ...rest }) => {
      let items = { ...checkedItems }
      if (isChecked) {
        items[id] = { id, ...rest }
      } else {
        delete items[id]
      }
      setCheckedItems(items)
    },
    [checkedItems]
  )

  const onCheckedChangeAll = useCallback((isChecked, data) => {
    let items = {}
    if (isChecked) {
      for (let item of data) {
        items[item.id] = item
      }
    }
    setCheckedItems(items)
  }, [])

  const columns = useMemo(
    () => getColumns(onCheckedChange, onCheckedChangeAll, checkedItems),
    [onCheckedChange, onCheckedChangeAll, checkedItems]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSettlements(defaultQueryObject))
  }, [dispatch])

  const { byId, allIds, loading } = useSelector((state) => state.settlements)

  useEffect(() => {
    setCheckedItems(emptyObject)
  }, [allIds])

  const data = useMemo(() => allIds.map((id) => byId[id]), [allIds, byId])

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <Authorize permissions="">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Unilever Settlements</Heading>
          <Text>Settlements List for Unilever</Text>
        </Box>
        <Box>
          <Settle items={checkedItems} />
        </Box>
      </Flex>

      <Badge variantColor="gray" fontSize="sm" mb={2} py={1} px={2}>
        Selected Items: {Object.keys(checkedItems).length}
      </Badge>
      {loading ? <Loader /> : <DataTable {...table} />}
    </Authorize>
  )
}

export default UnileverSettlementsPage
