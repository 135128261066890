import { ShopUp } from '@shopuptech/api-client'

const extractBody = async (response) => {
  const contentType = response.headers.get('content-type') || ''

  if (/application\/json/.test(contentType)) {
    const json = await response.json()

    if (json.statusCode === 400 && !json.error) {
      return {
        ...json,
        error: {
          ...json,
        },
      }
    }
    return json
  }

  if (!contentType || /^text\/|charset=utf-8$/.test(contentType)) {
    return response.text()
  }

  return response.arrayBuffer()
}

export const getShopUpLiteApiClient = ({ baseUrl = '/api' }) => {
  const apiClient = ShopUp({
    baseUrl,
    request: {
      fetch: window.fetch,
    },
    response: {
      body: extractBody,
    },
  })

  apiClient.requestHook.before((options) => {
    if (options.url.includes('/auth/v0/'))
      options.headers = {
        ...options.headers,
        'Cache-Control': 'no-cache',
      }
    if (options.filter && typeof options.filter === 'object') {
      options.filter = Object.keys(options.filter).reduce((filter, key) => {
        filter[`filter[${key}]`] = options.filter[key]
        return filter
      }, {})
    }

    if (options.fields && typeof options.fields === 'object') {
      options.fields = Object.keys(options.fields).reduce((fields, key) => {
        fields[`fields[${key}]`] = options.fields[key]
        return fields
      }, {})
    }
  })

  const api = apiClient.request

  return { apiClient, api }
}
