import FormSelect from 'components/Form/Select'
import React, { useMemo } from 'react'
import { Box } from 'reflexbox'
import { offerStatusDisplayText } from 'utils/offer-status'

function OfferStatusFilter({ ...props }) {
  const statusOptions = useMemo(() => ({ ...offerStatusDisplayText }), [])

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="offer.status"
        label={`Filter by Offer Status`}
        options={statusOptions}
      />
    </Box>
  )
}

export default OfferStatusFilter
