import Authorize from 'components/Authorize'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CreateDistributor from './Create'
import EditDistributor from './Edit'
import DistributorList from './List'

function DistributorsPage() {
  return (
    <Authorize permissions="eloan:AnyDistributor:read">
      <Switch>
        <Route exact path="/dash/distributors" component={DistributorList} />
        <Route path="/dash/distributors/create" component={CreateDistributor} />
        <Route
          path="/dash/distributors/:distributorId/edit"
          component={EditDistributor}
        />
      </Switch>
    </Authorize>
  )
}

export default DistributorsPage
