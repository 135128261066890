import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '.'

const defaultGetProps = (props, _) => props

/**
 * DISCLAIMER: THIS COMPONENT IS EXPERIMENTAL
 */
export function ExperimentalDataTable({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  prepareRow,

  tableProps,
  tableBodyProps,
  headerProps,
  headerGroupProps,
  footerProps,
  footerGroupProps,
  rowProps,
  cellProps,

  getHeaderGroupProps = defaultGetProps,
  getHeaderProps = defaultGetProps,
  getFooterGroupProps = defaultGetProps,
  getFooterProps = defaultGetProps,
  getRowProps = defaultGetProps,
  getCellProps = defaultGetProps,

  enableFooter,
}) {
  return (
    <Table {...getTableProps(tableProps)}>
      <TableHeader>
        {headerGroups.map((headerGroup) => (
          <TableRow
            children={headerGroup.headers.map((column) => (
              <TableHeaderCell
                children={column.render('Header')}
                {...column.getHeaderProps(getHeaderProps(headerProps, column))}
              />
            ))}
            {...headerGroup.getHeaderGroupProps(
              getHeaderGroupProps(headerGroupProps, headerGroup)
            )}
          />
        ))}
      </TableHeader>

      <TableBody {...getTableBodyProps(tableBodyProps)}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <TableRow
              children={row.cells.map((cell) => (
                <TableCell
                  children={cell.render('Cell')}
                  {...cell.getCellProps(getCellProps(cellProps, cell))}
                />
              ))}
              {...row.getRowProps(getRowProps(rowProps, row))}
            />
          )
        })}
      </TableBody>

      {enableFooter && (
        <TableFooter>
          {footerGroups.map((footerGroup) => (
            <TableRow
              children={footerGroup.headers.map((column) => (
                <TableCell
                  children={column.render('Footer')}
                  {...column.getFooterProps(
                    getFooterProps(footerProps, column)
                  )}
                />
              ))}
              {...footerGroup.getFooterGroupProps(
                getFooterGroupProps(footerGroupProps, footerGroup)
              )}
            />
          ))}
        </TableFooter>
      )}
    </Table>
  )
}
