import { combineReducers } from 'redux'
import callTeamReducer from './callTeamSlice'
import fieldTeamReducer from './fieldTeamSlice'
import ccaReducer from './ccaSlice'

const repaymentFollowupReducer = combineReducers({
  callTeam: callTeamReducer,
  fieldTeam: fieldTeamReducer,
  cca: ccaReducer,
})

export default repaymentFollowupReducer
