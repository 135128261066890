import {
  Button,
  ButtonGroup,
  IconButton,
  InputRightElement,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { Form, FormButton, FormInput, handleAPIError } from '@eloan/shared'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { loginWithEmail } from 'store/currentUser'
import * as Yup from 'yup'

function EmailPassLogin() {
  const toast = useToast()

  const {
    isOpen: showPassword,
    onToggle: onToggleShowPassword,
  } = useDisclosure(false)

  const defaultValues = useMemo(() => ({ email: '', password: '' }), [])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string().email('invalid email').required(`required`),
        password: Yup.string().min(1, 'can not be empty').required(`required`),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ email, password }) => {
      try {
        await dispatch(loginWithEmail({ email, password }))
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <FormInput type="email" name="email" label={`Email`} />

        <FormInput
          type={showPassword ? 'text' : 'password'}
          name="password"
          label={`Password`}
          InputRight={
            <InputRightElement
              children={
                <IconButton
                  icon={showPassword ? 'view' : 'view-off'}
                  variant="outline"
                  onClick={onToggleShowPassword}
                />
              }
            />
          }
        />

        <ButtonGroup spacing={2} display="flex" mt={2}>
          <Button
            type="button"
            variant="outline"
            as={Link}
            to="/login/forgot-password"
          >
            Forgot Password?
          </Button>
          <FormButton type="submit" variantColor="blue" flexGrow="1">
            Log In
          </FormButton>
        </ButtonGroup>
      </Stack>
    </Form>
  )
}

export default EmailPassLogin
