import React, { useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataTable } from '@eloan/shared/components/Table'
import { useTable, useRowSelect } from 'react-table'
import Authorize from 'components/Authorize'
import ApplicationListHeader from 'components/ApplicationList/Header'
import { Text, Button, Checkbox } from '@chakra-ui/core'
import { displayBDT } from '@eloan/shared'
import useLimitQuery from 'hooks/useLimitQuery'
import LimitBasedFilter from 'components/LimitBasedFilter/LimitBasedFilter'
import { get, take, find, filter } from 'lodash-es'
import { emptyArray, emptyObject } from 'utils/defaults'
import AssignAgent from 'pages/repayment-followup/AssignAgent'
import { useAreas } from 'store/areas/hooks'
import {
  fetchFieldTeamLoanList,
  selectAllFieldTeam,
} from 'store/repaymentFollowup/fieldTeamSlice'
import { teamOptionsFieldTeam } from 'pages/repayment-followup/constants'
import { useFieldAgentUsers } from 'store/users/hooks'
import RepaymentFollowupFieldTeamFilter from './Filter'
import { Link } from 'react-router-dom'
import { useCurrentUser } from 'store/currentUser/hooks'

const getColumns = () => [
  {
    Header: ({ getToggleAllRowsSelectedProps }) => {
      const { checked, ...props } = getToggleAllRowsSelectedProps()
      return <Checkbox isChecked={checked} {...props} mt={1} />
    },
    accessor: 'id',
    Cell: ({ row }) => {
      const { checked, ...props } = row.getToggleRowSelectedProps()
      return <Checkbox isChecked={checked} {...props} mt={1} />
    },
  },
  { Header: 'eloan ID', accessor: 'applicationId' },
  { Header: 'Loanee Name', accessor: 'loaneeName' },
  { Header: 'Phone Number', accessor: 'phoneNumber' },
  { Header: 'Area', accessor: 'areaName' },
  {
    Header: 'Disbursed Amount',
    accessor: ({ loanAmount }) => displayBDT(loanAmount),
  },
  {
    Header: 'Due Till Date',
    accessor: ({ dueAmount }) => (
      <Text color="red.500">{displayBDT(dueAmount)}</Text>
    ),
  },
  { Header: 'DPD', accessor: 'dpd' },
  { Header: 'Visit Status', accessor: 'lastFollowupStatus' },
  { Header: 'Total Visit', accessor: 'totalVisits' },
  { Header: 'Assigned To', accessor: 'agentName' },
  {
    Header: '',
    id: 'action_button',
    accessor: ({ applicationId }) => (
      <Authorize permissions="eloan:AnyApplication:read">
        <Button
          as={Link}
          size="sm"
          variant="link"
          variantColor="cyan"
          rightIcon="chevron-right"
          to={`/dash/repayment-followup/field-team/${applicationId}`}
          target="_blank"
        >
          VIEW
        </Button>
      </Authorize>
    ),
  },
]

const getRowId = (row) => row.applicationId

const getQueryObject = ({ agentId, minDpd, maxDpd }, limit) => ({
  limit,
  agentId,
  minDpd,
  maxDpd,
})

function RepaymentFollowupFieldTeamList() {
  const [selectedIds, setSelectedIds] = useState(emptyArray)

  const [filterValues, setFilterValues] = useState(emptyObject)

  const limit = useLimitQuery()

  const queryObject = useMemo(() => getQueryObject(filterValues, limit), [
    filterValues,
    limit,
  ])

  const user = useCurrentUser()

  const isFieldAgent = useMemo(
    () => find(get(user, 'roles', []), (role) => role.id === 7),
    [user]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchFieldTeamLoanList({
        // temp hack to filter by agent
        ...queryObject,
        agentId: isFieldAgent ? user.id : queryObject.agentId,
      })
    )
  }, [queryObject, dispatch, isFieldAgent, user])

  const fieldTeamLoanList = useSelector(selectAllFieldTeam)

  const areas = useAreas()

  const fieldAgentUsers = useFieldAgentUsers()

  const data = useMemo(() => {
    const filterAttributes = {
      ...(filterValues.eloanId && {
        applicationId: parseInt(filterValues.eloanId),
      }),
      ...(filterValues.areaId && {
        shopAreaId: parseInt(filterValues.areaId),
      }),
    }

    const listFiltered = filter(fieldTeamLoanList, filterAttributes)

    const listMapped = listFiltered.map((item) => ({
      ...item,
      agentName: get(fieldAgentUsers.byId[item.agentId], 'name'),
      areaName: get(areas.byId[get(item, 'shopAreaId')], 'name'),
    }))

    return {
      items: listMapped,
      itemsWithLimit: take(listMapped, limit),
    } // handle total count for now
  }, [
    fieldTeamLoanList,
    limit,
    areas,
    fieldAgentUsers,
    filterValues.areaId,
    filterValues.eloanId,
  ])

  const columns = useMemo(() => getColumns(), [])

  const table = useTable(
    {
      data: data.itemsWithLimit,
      columns,
      getRowId,
    },
    useRowSelect
  )

  useEffect(() => {
    setSelectedIds(
      Object.keys(table.state.selectedRowIds).filter(
        (id) => table.state.selectedRowIds[id]
      )
    )
  }, [table.state.selectedRowIds])

  return (
    <Authorize>
      <ApplicationListHeader
        title={`Repayment Field Team`}
        Right={
          <Authorize permissions="eloan:AnyApplication:update">
            <AssignAgent
              applicationIds={selectedIds}
              teamOptions={teamOptionsFieldTeam}
            />
          </Authorize>
        }
      />
      <RepaymentFollowupFieldTeamFilter
        isFieldAgent={isFieldAgent}
        onSubmit={setFilterValues}
        agents={fieldAgentUsers}
        areas={areas}
      />
      <DataTable {...table} />
      <LimitBasedFilter
        count={data.itemsWithLimit?.length}
        total={data.items?.length} // handle total count mismatch for now
        limit={limit}
      />
    </Authorize>
  )
}

export default RepaymentFollowupFieldTeamList
