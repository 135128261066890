import {
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import InfoBox from 'components/Box/InfoBox'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { setGuarantorRelationship } from 'store/applications'

const relationshipMap = {
  OTHER: 'Other',
}

const GuarantorAgreedAt = memo(({ guarantorAgreedAt }) => {
  return (
    <Tag cursor="pointer">
      <Tooltip
        hasArrow
        placement="top"
        label={
          guarantorAgreedAt
            ? DateTime.fromISO(guarantorAgreedAt).toLocaleString(
                DateTime.DATETIME_MED
              )
            : 'N/A'
        }
      >
        {guarantorAgreedAt ? 'Agreed' : 'Pending'}
      </Tooltip>
    </Tag>
  )
})

const getDefaultValues = (application) => ({
  relationship: get(application, 'meta.tpGuarantorRelationship') || '',
})

function UpdateGuarantorRelationship({ application, applicationId, ...props }) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])
  const form = useForm({
    defaultValues,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ relationship }) => {
      try {
        await dispatch(
          setGuarantorRelationship(applicationId, { relationship, tp: true })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, onClose, toast]
  )

  return (
    <Authorize permissions="eloan:Application:update">
      <Box display="inline-block" ml={2} {...props}>
        <IconButton
          variantColor="blue"
          aria-label="Update Guarantor Relationship"
          size="xs"
          icon="edit"
          onClick={onOpen}
        />

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader></ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <FormSelect
                  name="relationship"
                  label={`Guarantor Relationship`}
                  options={relationshipMap}
                />
              </ModalBody>

              <ModalFooter>
                <Button type="button" mr={3} onClick={onClose}>
                  Close
                </Button>
                <FormButton type="submit">Update</FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

function TPGuarantorInfo({
  application,
  title = '3rd Party Guarantor Info',
  ...props
}) {
  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        {title}
      </Heading>

      <Flex flexDirection="row" flexWrap="wrap">
        <InfoBox
          label="Name"
          content={get(application, 'metaExtra.tpGuarantorName')}
        />
        <InfoBox
          label="Phone Number"
          content={get(application, 'meta.tpGuarantorPhoneNumber')}
        />
        <InfoBox label="Relationship">
          {relationshipMap[get(application, 'meta.tpGuarantorRelationship')] ||
            'N/A'}

          <UpdateGuarantorRelationship
            application={application}
            applicationId={get(application, 'id')}
          />
        </InfoBox>
        <InfoBox label="Agreement">
          <GuarantorAgreedAt
            guarantorAgreedAt={get(
              application,
              'metaExtra.tpGuarantorAgreedAt'
            )}
          />
        </InfoBox>
      </Flex>
    </Box>
  )
}

export default memo(TPGuarantorInfo)
