import {
  Box,
  Stack,
  ButtonGroup,
  IconButton,
  Flex,
  Text,
} from '@chakra-ui/core'
import React, { useCallback } from 'react'
import Form from 'components/Form/Form'
import FormButton from 'components/Form/Button'
import { useForm } from 'react-hook-form'
import FormInput from 'components/Form/Input'

function RepaymentFollowupCCAFilter({ onSubmit }) {
  const form = useForm()

  const onFormSubmit = useCallback(
    ({ eloanId, minDpd, maxDpd }) => {
      const filterObject = {}

      if (minDpd || maxDpd) {
        filterObject.minDpd = minDpd || maxDpd
        filterObject.maxDpd = maxDpd || minDpd
      }

      if (eloanId) {
        filterObject.eloanId = eloanId
      }

      onSubmit({ ...filterObject })
    },
    [onSubmit]
  )

  const formReset = form.reset

  const onReset = useCallback(() => {
    formReset()
    onSubmit({})
  }, [formReset, onSubmit])

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack isInline spacing={4} marginBottom={6} align="flex-end">
        <Box>
          <FormInput
            label={
              <Text color="gray.700" fontSize="14px">
                Search
              </Text>
            }
            name="eloanId"
            placeholder="eLoan ID"
          />
        </Box>
        <Box>
          <Text color="gray.700" mb={1}>
            Select DPD Range
          </Text>
          <Flex>
            <FormInput placeholder="DPD Min" name="minDpd" />
            <FormInput placeholder="DPD Max" name="maxDpd" />
          </Flex>
        </Box>
        <Box>
          <ButtonGroup spacing={2}>
            <IconButton type="button" icon="small-close" onClick={onReset} />
            <FormButton type="submit" variantColor="cyan">
              SEARCH
            </FormButton>
          </ButtonGroup>
        </Box>
      </Stack>
    </Form>
  )
}

export default RepaymentFollowupCCAFilter
