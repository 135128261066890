import { isEmpty } from 'lodash-es'
import { batch } from 'react-redux'

export const REDX_SHOP_OFFER_ADD = 'REDX_SHOP_OFFER_ADD'
export const REDX_SHOP_OFFER_UPDATE = 'REDX_SHOP_OFFER_UPDATE'
export const REDX_SHOP_OFFER_ADD_BULK = 'REDX_SHOP_OFFER_ADD_BULK'

export const REDX_SHOP_OFFER_PAGE_ADD = 'REDX_SHOP_OFFER_PAGE_ADD'
export const REDX_SHOP_OFFER_PAGE_REMOVE = 'REDX_SHOP_OFFER_PAGE_REMOVE'
export const REDX_SHOP_OFFER_PAGE_REQUEST = 'REDX_SHOP_OFFER_PAGE_REQUEST'
export const REDX_SHOP_OFFER_PAGINATION_PURGE =
  'REDX_SHOP_OFFER_PAGINATION_PURGE'
export const REDX_SHOP_OFFER_STATUS_UPDATE = 'REDX_SHOP_OFFER_STATUS_UPDATE'

export const getRedxShopOfferPage = (
  { page = 1, limit, shopId, shopName, status },
  queryHash
) => async (dispatch, _getState, { api }) => {
  dispatch({ type: REDX_SHOP_OFFER_PAGE_REQUEST, page, queryHash })

  try {
    const { data } = await api(
      `/legacy/v3/eloan/redx-shopup/shop-offers{?limit,page,shopId,shopName,status}`,
      {
        limit,
        page,
        shopId: shopId || undefined,
        shopName: shopName || undefined,
        status: status || undefined,
      }
    )

    batch(() => {
      dispatch({ type: REDX_SHOP_OFFER_ADD_BULK, data })
      dispatch({ type: REDX_SHOP_OFFER_PAGE_ADD, page, data, queryHash })
    })

    return data
  } catch (err) {
    if (err.error && err.error.status >= 500) {
      dispatch({ type: REDX_SHOP_OFFER_PAGE_REMOVE, page, queryHash })
    }
  }
}

export const bulkCreateRedxShopOffer = ({
  shopIds,
  historyStartDate,
  historyEndDate,
  minLoanAmount,
  maxLoanAmount,
  maxLoanAmountFactor,
  canSelectWaitingPeriod,
}) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/redx-shopup/shop-offers/action/bulk-create`,
    {
      shopIds,
      historyStartDate,
      historyEndDate,
      minLoanAmount,
      maxLoanAmount,
      maxLoanAmountFactor,
      canSelectWaitingPeriod,
    }
  )

  batch(() => {
    dispatch({ type: REDX_SHOP_OFFER_ADD_BULK, data })
    dispatch({ type: REDX_SHOP_OFFER_PAGINATION_PURGE })
  })

  return data
}

export const modifyRedxShopOfferAmount = ({
  shopOfferId,
  minLoanAmount,
  maxLoanAmount,
  paymentDeductionPercentage,
  canSelectWaitingPeriod,
  waiveOnboardingFee,
}) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    'POST /legacy/v3/eloan/redx-shopup/shop-offers/{shopOfferId}/action/modify-amount',
    {
      shopOfferId,
      minLoanAmount,
      maxLoanAmount,
      paymentDeductionPercentage: !isEmpty(paymentDeductionPercentage)
        ? paymentDeductionPercentage
        : undefined,
      canSelectWaitingPeriod: canSelectWaitingPeriod || undefined,
      waiveOnboardingFee: waiveOnboardingFee || undefined,
    }
  )

  dispatch({ type: REDX_SHOP_OFFER_UPDATE, data })

  return data
}

export const recalculateRedxShopOffer = ({
  shopOfferId,
  historyStartDate,
  historyEndDate,
  minLoanAmount,
  maxLoanAmount,
}) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    'POST /legacy/v3/eloan/redx-shopup/shop-offers/{shopOfferId}/action/recalculate',
    {
      shopOfferId,
      historyStartDate,
      historyEndDate,
      minLoanAmount,
      maxLoanAmount,
    }
  )

  dispatch({ type: REDX_SHOP_OFFER_UPDATE, data })

  return data
}

export const modifyRedxShopOfferStatus = ({
  shopOfferId,
  status,
  comment,
}) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    'PUT /legacy/v3/eloan/redx-shopup/shop-offers/update-status',
    {
      shopOfferId,
      status,
      comment,
    }
  )

  dispatch({ type: REDX_SHOP_OFFER_STATUS_UPDATE, data })

  return data
}
