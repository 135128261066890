import { Box, Button, Flex, Heading, SimpleGrid, Stack } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import React from 'react'
import { Link } from 'react-router-dom'
import { useDistributors } from 'store/distributors/hooks'

function DistributorList() {
  const distributors = useDistributors({ include: 'bankInfo' })

  return (
    <Authorize permissions="eloan:AnyDistributor:read">
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Distributors</Heading>
        </Box>

        <Box>
          <Authorize permissions="eloan:Distributor:create">
            <Button as={Link} to={'distributors/create'}>
              Create
            </Button>
          </Authorize>
        </Box>
      </Stack>

      <Stack spacing={2}>
        {distributors.allIds.map((id) => (
          <Box key={id} borderWidth={1} borderRadius={2} shadow="md" p={4}>
            <Stack isInline spacing={4} justifyContent="space-between">
              <Box>
                <Heading size="md" as="h4" mb={2}>
                  {get(distributors.byId[id], 'name')}
                </Heading>
              </Box>

              <Authorize permissions="eloan:Distributor:update">
                <Box>
                  <Button as={Link} to={`/dash/distributors/${id}/edit`}>
                    Edit
                  </Button>
                </Box>
              </Authorize>
            </Stack>

            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Stack flexGrow={1}>
                <Box>
                  <strong>Area Name</strong>:{' '}
                  {get(distributors.byId[id], 'areaName')}
                </Box>
                <SimpleGrid columns={5}>
                  <InfoBox
                    label="Bank Name"
                    content={get(
                      distributors.byId[id],
                      'bankInfo.bank.bankName'
                    )}
                  />
                  <InfoBox
                    label="Branch Name"
                    content={get(
                      distributors.byId[id],
                      'bankInfo.branch.branchName'
                    )}
                  />
                  <InfoBox
                    label="Routing Number"
                    content={get(
                      distributors.byId[id],
                      'bankInfo.branch.routingNumber'
                    )}
                  />
                  <InfoBox
                    label="Account Name"
                    content={get(distributors.byId[id], 'bankInfo.accountName')}
                  />
                  <InfoBox
                    label="Account Number"
                    content={get(
                      distributors.byId[id],
                      'bankInfo.accountNumber'
                    )}
                  />
                </SimpleGrid>
              </Stack>
            </Flex>
          </Box>
        ))}
      </Stack>
    </Authorize>
  )
}

export default DistributorList
