import { Button, ButtonGroup, IconButton } from '@chakra-ui/core'
import ApplicationCriteriaFilter from 'components/ApplicationFilters/ApplicationCriteriaFilter'
import AreaFilter from 'components/ApplicationFilters/AreaFilter'
import CreationDateFilter from 'components/ApplicationFilters/CreationDateFilter'
import PAAssignmentFilter from 'components/ApplicationFilters/PAAssignmentFilter'
import PACallStatusFilter from 'components/ApplicationFilters/PACallStatusFilter'
import ApplicationStatusFilter from 'components/ApplicationFilters/StatusFilter'
import ApplicationSubStatusFilter from 'components/ApplicationFilters/SubStatusFilter'
import Form from 'components/Form/Form'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Flex } from 'reflexbox'

const getDefaultValues = () => ({
  createdAt: {
    start: '',
    end: '',
  },

  status: '',
  subStatus: '',

  meta: {
    shopAreaId: '',

    paCallStatus: '',

    loaneeNidSelfie: '',
    loaneeNidPhoto: '',
    loaneeNidPhotoBack: '',
    guarantorPhoneNumber: '',
    guarantorNidSelfie: '',
    guarantorNidPhoto: '',
    guarantorNidPhotoBack: '',
    guarantorAgreedAt: '',
    initialHomePhoto: '',
    initialShopPhoto: '',
    initialMarketplacePhoto: '',
  },

  paAssignment: {
    paId: '',
  },
})

function ApplicationListFilters({ onChange }) {
  const defaultValues = useMemo(() => getDefaultValues(), [])

  const form = useForm({
    defaultValues,
  })

  const onReset = useCallback(() => {
    form.reset(defaultValues)
  }, [defaultValues, form])

  return (
    <Form form={form} onSubmit={onChange}>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
        mb={8}
      >
        <ApplicationStatusFilter m={1} />

        <ApplicationSubStatusFilter m={1} />

        <CreationDateFilter m={1} />

        <ApplicationCriteriaFilter m={1} />

        <AreaFilter m={1} />

        <PACallStatusFilter m={1} />

        <PAAssignmentFilter m={1} />

        <ButtonGroup spacing={0} m={1}>
          <IconButton type="button" icon="small-close" onClick={onReset} />
          <Button type="submit" minWidth="6rem" variantColor="green">
            Filter
          </Button>
        </ButtonGroup>
      </Flex>
    </Form>
  )
}

export default ApplicationListFilters
