import { api } from 'api'
import { shopUpFinancialInstitutionId } from 'pages/admin/constants'
import { useEffect, useState } from 'react'

export function useCounts() {
  const [counts, setCounts] = useState([0, 0, 0, 0])

  useEffect(() => {
    async function fetchData() {
      const responses = await Promise.all([
        api('/legacy/v4/eloan/applications{?filter,offerFilter}', {
          filter: 'type==redx-shopup',
          offerFilter: `status==shopup-approved;loanPartner==${shopUpFinancialInstitutionId}`,
        }),
        api('/legacy/v4/eloan/applications{?filter,offerFilter}', {
          filter: 'type==redx-shopup',
          offerFilter: `status~={financed|active};loanPartner==${shopUpFinancialInstitutionId}`,
        }),
        api('/legacy/v4/eloan/applications{?filter,offerFilter}', {
          filter: 'type==redx-shopup',
          offerFilter: `status==need_revision;loanPartner==${shopUpFinancialInstitutionId}`,
        }),
        api('/legacy/v4/eloan/applications{?filter,offerFilter}', {
          filter: 'type==redx-shopup',
          offerFilter: `status==ended;loanPartner==${shopUpFinancialInstitutionId}`,
        }),
      ])

      const counts = responses.map((response) => response.data.totalItems)

      setCounts(counts)
    }
    fetchData()
  }, [])

  return counts
}
