import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import camelcaseKeys from 'camelcase-keys'

const adapter = createEntityAdapter({
  selectId: (item) => item.applicationId,
})

const callTeamSlice = createSlice({
  name: 'callTeam',
  initialState: adapter.getInitialState(),
  reducers: {
    add(state, action) {
      adapter.setAll(state, action.payload.items)
    },
    upsertOne: adapter.upsertOne,
    update(state, { payload }) {
      const { applicationId: id, changes } = payload
      adapter.updateOne(state, { id, changes: { histories: changes } })
    },
    assign(state, action) {
      adapter.upsertMany(
        state,
        action.payload.applicationIds.map((id) => ({
          applicationId: id,
          callAgent: {
            agentId: action.payload.agentId,
          },
        }))
      )
    },
    remove(state, action) {
      adapter.removeMany(state, action.payload)
    },
  },
})

export const {
  add,
  upsertOne,
  update,
  assign,
  remove: removeFromCallTeam,
} = callTeamSlice.actions

export const {
  selectAll: selectAllCallTeamLoan,
  selectById: selectCallTeamLoanById,
} = adapter.getSelectors((state) => state.repaymentFollowup.callTeam)

export default callTeamSlice.reducer

export const fetchCallTeamLoanList = ({
  limit,
  agentId,
  minDpd,
  maxDpd,
}) => async (dispatch, getState, { api }) => {
  const { data } = await api(
    `/legacy/v3/repayment/calls{?limit,minDpd,maxDpd,agentID}`,
    {
      limit,
      agentID: agentId,
      minDpd,
      maxDpd,
    }
  )

  dispatch(add(camelcaseKeys(data, { deep: true })))

  return data
}

export const fetchCallTeamLoan = (callListId) => async (
  dispatch,
  getState,
  { api }
) => {
  const { data } = await api(`/legacy/v3/repayment/calls/{callListId}`, {
    callListId,
  })

  if (data) {
    // not handled in api
    dispatch(upsertOne(camelcaseKeys(data, { deep: true })))
  }

  return data
}

export const assignCallAgent = ({ applicationIds, agentId }) => async (
  dispatch,
  getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/repayment/calls/applications/actions/assign-agent`,
    {
      applicationIds,
      agentId,
    }
  )

  dispatch(assign({ applicationIds, agentId }))

  return data
}

export const updateFollowupCall = (
  callListId,
  { offerId, callStatus, note, committedDate }
) => async (dispatch, getState, { api }) => {
  const { data } = await api(`POST /legacy/v3/repayment/calls/{callListId}`, {
    callListId,
    offerID: offerId,
    callStatus,
    note,
    committedDate,
  })

  return data
}
