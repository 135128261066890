import {
  Box,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useNobodarApplication } from 'store/nobodar/hooks'
import { useUser } from 'store/users/hooks'
import {
  nobodarApplicationShortlistOptions,
  nobodarApplicationStatusOptions,
} from '../constants'
import NobodarAssessment from './Assessment'
import CallLog from './CallLog'
import ApplicantInfo from './Info'
import ChangeApplicationStatus from './modals/ChangeApplicationStatus'

const tabs = [
  { id: 'applicant-info', name: 'Applicant info' },
  { id: 'pa-call-assessment', name: 'PA Call Assessment' },
  { id: 'call-log', name: 'Call log' },
]

function ApplicationView() {
  const { applicationId, tabId } = useParams()

  const application = useNobodarApplication(applicationId)

  const history = useHistory()
  const onTabChange = useCallback(
    (index) => {
      history.replace(
        `/dash/nobodar-applications/${applicationId}/${tabs[index].id}`
      )
    },
    [applicationId, history]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === tabId)
    return index < 0 ? 0 : index
  }, [tabId])

  const pa = useUser(get(application, 'paId'))

  if (!application) {
    return null
  }

  return (
    <Authorize permissions="eloan:AnyNobodarApplication:read,eloan:OwnNobodarApplication:read">
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        p={2}
        mb={4}
      >
        <Stack>
          <Heading fontSize={6}>
            {get(application, 'fullName')} | {get(application, 'phone')}
          </Heading>
          <Box>
            {get(application, 'status') ? (
              <Tag size="sm" variantColor="blue" fontSize={1}>
                {nobodarApplicationStatusOptions[get(application, 'status')]}
                {get(application, 'status') === 'shortlisted'
                  ? ` for
                ${
                  nobodarApplicationShortlistOptions[
                    get(application, 'shortlist')
                  ]
                }`
                  : ''}
              </Tag>
            ) : null}
          </Box>
        </Stack>
        <Stack>
          <ChangeApplicationStatus applicationId={applicationId} />
        </Stack>
      </Stack>

      <Tabs index={tabIndex} onChange={onTabChange}>
        <Stack
          isInline
          justifyContent="space-between"
          position="relative"
          mb={4}
        >
          <TabList mb={4} flexGrow="1">
            {tabs.map((tab) => (
              <Tab id={tab.id} key={tab.id}>
                {tab.name}
              </Tab>
            ))}
          </TabList>

          <Box
            position="absolute"
            right="2"
            bottom="0"
            textAlign="right"
            fontSize={1}
            mb={5}
          >
            <Stack spacing={0}>
              <Text fontSize={1} fontWeight="bold">
                Nobodar Portfolio Associate
              </Text>
              <Text fontSize={2}>{get(pa, 'name')}</Text>
            </Stack>
          </Box>
        </Stack>

        <TabPanels p={4}>
          <TabPanel>
            <ApplicantInfo applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <NobodarAssessment applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <CallLog applicationId={applicationId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Authorize>
  )
}

export default ApplicationView
