import React, { useMemo, useCallback, useState } from 'react'
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Stack,
  useToast,
} from '@chakra-ui/core'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormSelect from 'components/Form/Select'
import FormButton from 'components/Form/Button'
import { useLoanProducts, useLoanProductById } from 'store/admin/hooks'
import { zipObject, get } from 'lodash-es'
import { useHistory } from 'react-router-dom'
import { api } from 'api'
import { handleAPIError } from 'components/Form/helpers'
import { useMaterializedInfo } from 'store/beftn/hooks'
import Loader from 'components/Loader/Loader'
import { getDate } from '@eloan/shared'
import { DateTime } from 'luxon'
import Authorize from 'components/Authorize'

function GotoSettlementList() {
  const form = useForm()

  const { watch } = form

  const loanProductId = watch('loanProductId')

  const loanProduct = useLoanProductById(loanProductId)

  const fiOptions = useMemo(
    () =>
      (get(loanProduct, 'loanProductConfigs') || []).reduce(
        (obj, { financialInstitution }) => ({
          ...obj,
          [financialInstitution.id]: financialInstitution.name,
        }),
        {}
      ),
    [loanProduct]
  )

  const loanProducts = useLoanProducts()

  const loanProductOptions = useMemo(
    () =>
      zipObject(
        loanProducts.allIds,
        loanProducts.allIds.map((id) => loanProducts.byId[id].productName)
      ),
    [loanProducts]
  )

  const history = useHistory()

  const onSubmit = useCallback(
    ({ loanProductId, fiId }) => {
      if (loanProductId && fiId) {
        history.push(
          `/dash/admin/partner-settlement?loanProductId=${loanProductId}&fiId=${fiId}`
        )
      }
    },
    [history]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack spacing={4}>
        <Box>
          <FormSelect
            name="loanProductId"
            label="Select Product Name"
            options={loanProductOptions}
          />
        </Box>
        <Box>
          <FormSelect name="fiId" label="Select FIs" options={fiOptions} />
        </Box>
        <Flex justify="center">
          <FormButton type="submit" variantColor="cyan" size="sm" px={6}>
            GO TO SETTLEMENT LIST
          </FormButton>
        </Flex>
      </Stack>
    </Form>
  )
}

function PartnerSettlementGeneration() {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const materializedInfo = useMaterializedInfo()

  const onGenerateSettlement = useCallback(async () => {
    try {
      setIsLoading(true)
      await api(`POST /eloan/v1/materialized-data/term-loans`)
      toast({
        title: 'Generated successfully. Please come back after 5 minutes',
        status: 'success',
        duration: 9000,
      })
    } catch (error) {
      handleAPIError(error, { toast })
    }

    setIsLoading(false)
  }, [toast])

  return (
    <Flex align="center" justify="center" textAlign="center" p={10}>
      <Box>
        <Heading size="md" mb={10}>
          Partner Settlement
        </Heading>
        {materializedInfo ? (
          <Box borderBottomWidth="1px" mb={6} pb={6}>
            <Text fontSize="xs" color="gray.500">
              Last Synced Time
            </Text>
            <Text>
              {get(materializedInfo, 'generationTime')
                ? getDate(
                    get(materializedInfo, 'generationTime'),
                    DateTime.DATETIME_FULL
                  )
                : ''}
            </Text>

            <Authorize permissions="eloan:MaterializedTermLoans:write">
              <Button
                variantColor="cyan"
                mt={6}
                size="sm"
                px={6}
                isLoading={isLoading}
                disabled={get(materializedInfo, 'isGenerating')}
                onClick={onGenerateSettlement}
              >
                GENERATE SETTLEMENT LIST
              </Button>
            </Authorize>

            {get(materializedInfo, 'isGenerating') && (
              <Box mt={4}>
                <Text>It will take a bit of time to prepare the list.</Text>
                <Text color="green.500">
                  So, please come back after 5 minutes.
                </Text>
              </Box>
            )}
          </Box>
        ) : (
          <Box mb={6}>
            <Loader />
          </Box>
        )}
        <Box textAlign="left">
          <GotoSettlementList />
        </Box>
      </Box>
    </Flex>
  )
}

export default PartnerSettlementGeneration
