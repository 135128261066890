import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { get } from 'lodash-es'
import React from 'react'
import { Box } from 'reflexbox'
import SendToDigitization from '../parts/SendToDigitization'

function ChangeApplicationStatus({ applicationId, application, ...props }) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  return (
    <Authorize permissions="eloan:Application:requestDigitization, eloan:ApplicationDocSubmissionMethod:update">
      <Box {...props}>
        <Button variantColor="green" onClick={onOpen}>
          Change Application Status
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <Tabs variant="enclosed" isFitted>
                <TabList mb={4}>
                  <Tab fontWeight="bold">Digitization</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <SendToDigitization
                      applicationId={applicationId}
                      toast={toast}
                      currentStatus={get(application, 'status')}
                      onDone={onClose}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default ChangeApplicationStatus
