const allowedNamespacePattern = /^[a-z_-]{2,}$/

export const getLocalStorage = (namespace) => {
  if (!namespace || !allowedNamespacePattern.test(namespace)) {
    throw new Error(
      `Invalid localStorage namespace! Expected pattern: ${allowedNamespacePattern}`
    )
  }

  const localStorageNamespace = `shopup:${namespace}`
  const localStorageNamespacePattern = new RegExp(`^${localStorageNamespace}`)

  const loadState = (key) => {
    try {
      const serializedState = localStorage.getItem(
        `${localStorageNamespace}:${key}`
      )
      return serializedState !== null ? JSON.parse(serializedState) : undefined
    } catch (err) {
      console.error(err)
    }
  }

  const saveState = (key, state) => {
    try {
      const serializedState = JSON.stringify(state)
      localStorage.setItem(`${localStorageNamespace}:${key}`, serializedState)
    } catch (err) {
      console.error(err)
    }
  }

  const clearState = () => {
    const keys = Object.keys(localStorage)
    for (const key of keys) {
      if (localStorageNamespacePattern.test(key)) {
        localStorage.removeItem(key)
      }
    }
  }

  return {
    loadState,
    saveState,
    clearState,
  }
}
