import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import FormTextarea from 'components/Form/Textarea'
import { capitalize, get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { updateApplicationDocSubmissionMethod } from 'store/applications'
import * as Yup from 'yup'

const getDefaultValues = (application) => ({
  docSubmissionMethod: get(application, 'meta.docSubmissionMethod') || '',
  note: '',
})

const getValidationSchemaForNote = () => {
  return Yup.object({
    docSubmissionMethod: Yup.string()
      .oneOf(['online', 'offline'])
      .required(`required`),
    note: Yup.string().min(5, `provide a bit more info`).required(`required`),
  })
}

const options = {
  online: 'Online',
  offline: 'Offline',
}

function ChangeDocSubmissionMethod({ applicationId, application, ...props }) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])
  const validationSchema = useMemo(() => getValidationSchemaForNote(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const selectedDocSubmissionMethod = form.watch('docSubmissionMethod')

  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ docSubmissionMethod, note }) => {
      try {
        await dispatch(
          updateApplicationDocSubmissionMethod(applicationId, {
            docSubmissionMethod,
            note,
          })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, onClose, toast]
  )

  return (
    <Authorize permissions="eloan:AnyApplicationDocSubmissionMethod:update, eloan:ApplicationDocSubmissionMethod:update">
      <Box {...props}>
        <Button variantColor="orange" onClick={onOpen}>
          Change Doc Submission Method
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader>Change Doc Submission Method</ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <Stack spacing={2}>
                  <Box>
                    <FormSelect
                      name="docSubmissionMethod"
                      label={`Doc Submission Method`}
                      options={options}
                    />
                  </Box>

                  <Box>
                    <FormTextarea name="note" label={`Note`} />
                  </Box>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <FormButton type="submit">
                  Set to {capitalize(selectedDocSubmissionMethod || '---')}
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default ChangeDocSubmissionMethod
