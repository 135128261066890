import { Children, cloneElement, useMemo } from 'react'
import { useAuthorization } from 'store/currentUser/hooks'

function Authorize({ permissions = '', children, ...props }) {
  const permissionIds = useMemo(() => {
    return permissions
      .split(',')
      .map((permission) => permission.trim())
      .filter(Boolean)
  }, [permissions])

  const authorized = useAuthorization(permissionIds)

  return authorized
    ? Children.toArray(children).map((child) => cloneElement(child, props))
    : null
}

export default Authorize
