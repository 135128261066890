import { Flex, Heading } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import React, { memo, useMemo } from 'react'
import { Box } from 'reflexbox'
import { useBusinessMediums } from 'store/businessMediums/hooks'
import { usePAUsers } from 'store/users/hooks'
import { emptyArray } from 'utils/defaults'

function LoanInfo({ application, ...props }) {
  const businessMediums = useBusinessMediums()

  const paUsers = usePAUsers()
  const pa = useMemo(() => {
    return get(paUsers.byId, get(application, 'paAssignment.paId'))
  }, [application, paUsers.byId])

  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Loan Info
      </Heading>

      <Flex flexDirection="row" flexWrap="wrap">
        <InfoBox label="Portfolio Associate" content={get(pa, 'name')} />
        <InfoBox label="Amount" content={get(application, 'loanAmount')} />
        <InfoBox label="Tenure" content={get(application, 'tenure')} />
        <InfoBox
          label="Business Name"
          content={get(application, 'shop.shopName')}
        />
        <InfoBox
          label="Business Mediums"
          content={(get(application, 'shop.businessMediums') || emptyArray)
            .map((id) => get(businessMediums.byId[id], 'name'))
            .join(', ')}
        />
        <InfoBox
          label="Business Note"
          content={get(application, 'shop.businessNote')}
        />
      </Flex>
    </Box>
  )
}

export default memo(LoanInfo)
