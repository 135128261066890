import { sortBy, get, keyBy } from 'lodash-es'

const defaultRedxProperties = {
  type: 'RedX',
  updatedBy: { fullName: 'auto' },
}

export function prepareLoaneeRepayment({
  repayment,
  collections,
  redxTransactions,
}) {
  const emiList = get(repayment, 'EMIList', [])

  const collectionsByKey = keyBy(collections, 'id')
  const redxTransactionsByKy = keyBy(redxTransactions, 'id')

  // map deposits within array to keep consistency with emiList structure
  const depositsAfterEMIPeriodWithCollection = get(
    repayment,
    'depositsAfterEMI',
    []
  ).map((deposit) => ({
    deposits: [
      {
        ...deposit,
        collection: {
          ...(redxTransactionsByKy[deposit.transactionID]
            ? {
                ...redxTransactionsByKy[deposit.transactionID],
                ...defaultRedxProperties,
              }
            : collectionsByKey[deposit.collectionID]),
        },
      },
    ],
  }))

  const emiListWithCollections = emiList.map((emi) => ({
    ...emi,
    deposits: emi.deposits.map((deposit) => ({
      ...deposit,
      collection: {
        ...(redxTransactionsByKy[deposit.transactionID]
          ? {
              ...redxTransactionsByKy[deposit.transactionID],
              ...defaultRedxProperties,
            }
          : collectionsByKey[deposit.collectionID]),
      },
    })),
  }))

  return [...emiListWithCollections, ...depositsAfterEMIPeriodWithCollection]
}

export function preparePartnerRepayment(repayment) {
  const emiList = get(repayment, 'partner.EMIList', [])

  const partnerEMIList = emiList.map((emi) => {
    // hide virtual deposits
    return {
      ...emi,
      deposits: emi.deposits.filter((d) => !d.isVirtual),
    }
  })

  // map to keep consistency with emiList structure
  const clientDepositsAfterEMIPeriod = get(
    repayment,
    'clientDepositsAfterEMI',
    []
  ).map(
    (deposit) => ({
      clientDeposits: [deposit],
    }),
    []
  )

  const partnerDepositsAfterEMIPeriod = get(
    repayment,
    'partner.depositsAfterEMI',
    []
  )
    .map(
      (deposit) => ({
        deposits: [deposit],
      }),
      []
    )
    .filter((d) => !d.isVirtual) // hide virtual deposits

  const depositsAfterEMIPeriod = sortBy(
    [...clientDepositsAfterEMIPeriod, ...partnerDepositsAfterEMIPeriod],
    'transactionDate'
  )

  return [...partnerEMIList, ...depositsAfterEMIPeriod]
}
