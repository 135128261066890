import { Heading, Stack, Tag, Text } from '@chakra-ui/core'
import ApplicaitonStatuses from 'components/Application/Statuses'
import { get } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Flex } from 'reflexbox'
import { getApplicationData } from 'store/applications'
import LoanDetails from './LoanDetails'
import SendToTweaking from './modals/SendToTweaking'
import Notes from './Notes'
import CloseApplication from 'components/ApplicationModals/CloseApplication'

const fields = [
  'id',
  'type',
  'status',
  'subStatus',

  'phoneNumber',
  'loanAmount',
  'tenure',

  'meta.loaneeName',
  'meta.shopAreaId',

  'meta.loaneeNidName',

  'meta.guarantorName',
  'meta.guarantorPhoneNumber',
  'meta.guarantorRelationship',
  'meta.guarantorAgreedAt',

  'meta.guarantorNidName',

  'offer.*',

  'paAssignment.paId',

  'shop.id',
  'shop.shopName',
  'shop.businessMediums',
  'shop.businessNote',
].join(',')

function ApplicationView() {
  const [unauthorized, setUnauthorized] = useState(false)

  const { applicationId } = useParams()

  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApplicationData(applicationId, { fields })).catch((err) => {
      if (get(err.error, 'code') === 403) {
        setUnauthorized(true)
      }
    })
  }, [applicationId, dispatch])

  const type = get(application, 'type')

  return unauthorized ? (
    <Flex alignItems="center" justifyContent="center" height="100%">
      <Text fontSize={6} color="red.500">
        You are not authorized to access this eLoan Application ({applicationId}
        )
      </Text>
    </Flex>
  ) : application ? (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        mb={10}
      >
        <Stack spacing={1} mb={2}>
          <Heading fontSize={5}>
            Application ID: {applicationId} | {get(application, 'phoneNumber')}
          </Heading>
          <Text>
            {type ? (
              <Tag size="md" variantColor="blue" mr={4}>
                {type}
              </Tag>
            ) : null}
            eLoan Application with Loan Offer
          </Text>
          <ApplicaitonStatuses application={application} />s
        </Stack>

        <Stack isInline spacing={2} flexWrap="wrap">
          <CloseApplication
            application={application}
            applicationId={applicationId}
            mb={1}
          />
          <SendToTweaking
            application={application}
            applicationId={applicationId}
            mb={1}
          />
        </Stack>
      </Flex>

      <Stack spacing={6}>
        <LoanDetails applicationId={applicationId} width="100%" />

        <Notes applicationId={applicationId} />
      </Stack>
    </>
  ) : null
}

export default ApplicationView
