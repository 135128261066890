const newStatusByOldStatus = {
  rejected: 'closed',
}

const oldStatusByNewStatus = {
  digitization_pending: 'checking',
  closed: 'rejected',
}

export const getNewApplicationStatus = (oldStatus) => {
  return newStatusByOldStatus[oldStatus] || oldStatus
}

export const getOldApplicationStatus = (newStatus) => {
  return oldStatusByNewStatus[newStatus] || newStatus
}

export const applicationStatusDisplayText = {
  closed: 'Closed',
  [getOldApplicationStatus('closed')]: 'Closed',
  preparing: 'Preparing',
  troubleshooting: 'Troubleshooting',

  digitization_pending: 'Digitizing',
  nid_verification_pending: 'NID Verification',
  call_verification_pending: 'Call Verification',
  onsite_verification_pending: 'Onsite Verification',
  verification_approval_pending: 'Verification Approval',

  accepted: 'Accepted',

  quarantine: 'Quarantine',
}

export const applicationSubStatusDisplayText = {
  fraudulent: 'Fraudulent',
  ineligible: 'Ineligible',
  not_interested: 'Not Interested',
  out_of_area: 'Out of Dhaka',
  spam: 'Spam',
}
