import React, { useMemo } from 'react'
import { Box, Heading, Badge, SimpleGrid, Button } from '@chakra-ui/core'
import { useDraftAccount } from 'store/admin/hooks'
import InfoBox from 'components/Box/InfoBox'
import { get, isEmpty } from 'lodash-es'
import { LoanProductFiClientConfig } from 'pages/admin/components/Configuration/ConfigItem'
import { displayBDT } from '@eloan/shared'
import ContentLoader from 'react-content-loader'
import Authorize from 'components/Authorize'
import { Link } from 'react-router-dom'
import { getNumberOfInstallments, getEMI } from '@eloan/shared/utils/emi'

const LoaderPlaceholder = () => (
  // wip
  <Box as={ContentLoader} viewBox="0 0 475 100" mt={6}>
    <rect x="0" y="0" rx="3" ry="3" width="95" height="50" />
    <rect x="100" y="0" rx="3" ry="3" width="95" height="50" />
    <rect x="200" y="0" rx="3" ry="3" width="95" height="50" />
    <rect x="300" y="0" rx="3" ry="3" width="95" height="50" />
    <rect x="400" y="0" rx="3" ry="3" width="95" height="50" />

    <rect x="0" y="60" rx="3" ry="3" width="500" height="10" />
    <rect x="0" y="75" rx="3" ry="3" width="400" height="10" />
    <rect x="0" y="90" rx="3" ry="3" width="300" height="10" />
  </Box>
)

function DraftAccountInfo({ title, applicationId }) {
  const { draftAccount, loading } = useDraftAccount(applicationId)

  const computedEMIValues = useMemo(() => {
    const numberOfInstallments = getNumberOfInstallments(draftAccount)

    const emiAmount = getEMI(draftAccount)

    return { numberOfInstallments, emiAmount }
  }, [draftAccount])

  if (!loading && isEmpty(draftAccount)) return null

  return (
    <Authorize permissions="eloan:DraftAccount:read">
      <Box mt={4}>
        {loading ? (
          <LoaderPlaceholder />
        ) : (
          <>
            <Heading size="md">{title}</Heading>
            <Badge variant="solid">
              {get(draftAccount, 'draftAccountStatus')}
            </Badge>

            <SimpleGrid columns={5} spacing={6} mt={6}>
              <InfoBox label="Loan Product">
                <Button
                  variant="link"
                  as={Link}
                  variantColor="cyan"
                  size="sm"
                  target="_blank"
                  to={`/dash/admin/loan-product/single/${get(
                    draftAccount,
                    'loanProductFiClientConfig.loanProductConfig.loanProduct.id'
                  )}`}
                >
                  View (
                  {get(
                    draftAccount,
                    'loanProductFiClientConfig.loanProductConfig.loanProduct.productName'
                  )}
                  )
                </Button>
              </InfoBox>
              <InfoBox label="Offer Amount">
                {displayBDT(get(draftAccount, 'offerAmount'))}
              </InfoBox>
              <InfoBox label="Client Tenure">
                {get(draftAccount, 'clientTenure')}{' '}
                {get(
                  draftAccount,
                  'loanProductFiClientConfig.clientConfig.installmentPeriodUnit'
                )}
              </InfoBox>
              <InfoBox label="FI Tenure">
                {get(draftAccount, 'fiTenure')}{' '}
                {get(
                  draftAccount,
                  'loanProductFiClientConfig.fiConfig.installmentPeriodUnit'
                )}
              </InfoBox>
              <InfoBox label="Number of Installments">
                {get(computedEMIValues, 'numberOfInstallments')}
              </InfoBox>
              <InfoBox label="EMI Amount">
                {displayBDT(get(computedEMIValues, 'emiAmount'))}
              </InfoBox>
            </SimpleGrid>
            <LoanProductFiClientConfig
              fiConfigType={get(
                draftAccount,
                'loanProductFiClientConfig.fiLoanConfigType'
              )}
              clientConfigType={get(
                draftAccount,
                'loanProductFiClientConfig.clientLoanConfigType'
              )}
              fiConfig={get(draftAccount, 'loanProductFiClientConfig.fiConfig')}
              clientConfig={get(
                draftAccount,
                'loanProductFiClientConfig.clientConfig'
              )}
            />
          </>
        )}
      </Box>
    </Authorize>
  )
}

export default DraftAccountInfo
