import React from 'react'
import { Input, Button, Box } from '@chakra-ui/core'

export function FileUpload({
  label = 'Upload',
  onChange,
  uploading,
  accept,
  ...props
}) {
  let fileInput = null
  return (
    <Box>
      <Input
        hidden
        type="file"
        ref={(el) => (fileInput = el)}
        onChange={(e) => onChange(e.target.files[0])}
        accept={accept}
      />
      <Button
        variantColor="blue"
        variant="outline"
        px={5}
        textTransform="uppercase"
        onClick={() => fileInput.click()}
        isLoading={uploading}
        {...props}
      >
        {label}
      </Button>
    </Box>
  )
}
