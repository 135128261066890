import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkPermissions } from 'store/currentUser'
import { emptyArray } from 'utils/defaults'

export function useCurrentUser() {
  const user = useSelector((state) => state.user.data)

  return user
}

export function usePermit(permissionId) {
  const permit = useSelector((state) => state.user.permits[permissionId])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkPermissions({ permissionIds: [permissionId] }))
  }, [dispatch, permissionId])

  return permit
}

export function usePermits(permissionIds = emptyArray) {
  const permits = useSelector((state) => state.user.permits)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkPermissions({ permissionIds }))
  }, [dispatch, permissionIds])

  return permits
}

export function useAuthorization(permissionIds = emptyArray) {
  const permits = usePermits(permissionIds)

  const authorized = useMemo(() => {
    return permissionIds.length
      ? permissionIds.some((permissionId) => permits[permissionId])
      : true
  }, [permissionIds, permits])

  return authorized
}
