import React from 'react'
import { Route, useRouteMatch, Redirect, Switch } from 'react-router-dom'
import PartnerSettlementGeneration from './View/PartnerSettlementGeneration'
import PartnerSettlementList from './List'

function EloanPartnerSettlementPage() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}`} component={PartnerSettlementList} />
      <Route
        exact
        path={`${path}/generate`}
        component={PartnerSettlementGeneration}
      />
      <Redirect to={`${path}/generate`} />
    </Switch>
  )
}

export default EloanPartnerSettlementPage
