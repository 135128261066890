import { saveAs } from 'file-saver'
const XLSX = require('xlsx')

// this function create a buffer for excel file with one sheet with your data
export function createExcel(data) {
  const workbook = XLSX.utils.book_new()
  for (const sheet of data.sheets) {
    const workSheet = XLSX.utils.aoa_to_sheet(sheet.rows, {
      header: sheet.header,
      skipHeader: sheet.skipHeader,
    })

    const rowKeys = Object.values(sheet.rows[0])

    workSheet['!cols'] = rowKeys.map((key) => ({ wch: key.length + 5 }))

    XLSX.utils.book_append_sheet(workbook, workSheet, sheet.name)
  }

  const output = XLSX.write(workbook, {
    type: data.type || 'buffer',
    bookType: data.bookType || 'xlsx',
    Props: workbook.Props,
  })

  return output
}

export const downloadExcelFile = ({ data, filename }) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })

  saveAs(blob, filename)
}
