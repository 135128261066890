import {
  CURRENT_USER_LOGIN_REQUEST,
  CURRENT_USER_LOGOUT,
  CURRENT_USER_PERMIT_SET_BULK,
  CURRENT_USER_REMOVE,
  CURRENT_USER_UPDATE,
} from 'store/currentUser'
import { emptyObject } from 'utils/defaults'

const initialState = {
  data: null,
  permits: emptyObject,
  status: {
    authed: null,
    loading: true,
  },
}

const currentUserReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CURRENT_USER_LOGIN_REQUEST:
      return {
        data: null,
        permits: emptyObject,
        status: {
          authed: false,
          loading: true,
        },
      }
    case CURRENT_USER_UPDATE:
      return {
        ...state,
        data,
        status: {
          authed: true,
          loading: false,
        },
      }
    case CURRENT_USER_LOGOUT:
      return {
        data: null,
        permits: emptyObject,
        status: {
          authed: false,
          loading: false,
        },
      }
    case CURRENT_USER_REMOVE:
      return {
        data: null,
        permits: emptyObject,
        status: {
          authed: false,
          loading: false,
        },
      }
    case CURRENT_USER_PERMIT_SET_BULK:
      return {
        ...state,
        permits: {
          ...state.permits,
          ...data,
        },
      }
    default:
      return state
  }
}

export default currentUserReducer
