import React, { useMemo, useState } from 'react'
import { Button, Box, Heading, Badge, Text } from '@chakra-ui/core'
import { Link } from 'react-router-dom'
import { useTable } from 'react-table'
import ListTable from 'components/ListTable/ListTable'
import { useCloseableAccounts } from 'store/finance/hooks'
import CloseLoanFilter from './Filter'
import { emptyObject } from 'utils/defaults'
import { defaults } from 'lodash-es'
import Authorize from 'components/Authorize'
import { displayBDT } from '@eloan/shared'
import LimitBasedFilter from 'components/LimitBasedFilter/LimitBasedFilter'
import useLimitQuery from 'hooks/useLimitQuery'

const getColumns = () => [
  {
    Header: 'eloan ID',
    accessor: 'eloanId',
  },
  {
    Header: 'Name',
    accessor: 'applicantName',
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
  },
  {
    Header: 'FI Name',
    accessor: 'fiName',
  },
  {
    Header: 'Disbursed Amount',
    accessor: ({ disbursedAmount }) => displayBDT(+disbursedAmount),
  },
  {
    Header: 'Collection Amount',
    accessor: ({ collectionAmount }) => (
      <Text color="green.500">{displayBDT(+collectionAmount)}</Text>
    ),
  },
  {
    Header: 'ShopUp Outstanding',
    accessor: ({ shopupOutstanding }) => (
      <Text color="red.500">{displayBDT(+shopupOutstanding)}</Text>
    ),
  },
  {
    Header: 'Paid to Partner',
    accessor: ({ paidToPartner }) => (
      <Text color="green.500">{displayBDT(+paidToPartner)}</Text>
    ),
  },
  {
    Header: 'Partner Outstanding',
    accessor: ({ partnerOutstanding }) => (
      <Text color="red.500">{displayBDT(+partnerOutstanding)}</Text>
    ),
  },
  {
    Header: 'DPD',
    accessor: 'dpd',
  },
  {
    Header: 'Loan Status',
    accessor: ({ loanStatus }) => (
      <Badge variantColor="green" variant="solid">
        {loanStatus}
      </Badge>
    ),
  },
  {
    Header: '',
    id: 'action_button',
    accessor: ({ eloanId }) => (
      <Button
        as={Link}
        to={`/dash/eloan-applications/${eloanId}`}
        variantColor="cyan"
        variant="link"
        rightIcon="chevron-right"
        px={6}
        size="sm"
        target="_blank"
      >
        VIEW
      </Button>
    ),
  },
]

const defaultQueryObject = {
  limit: 25,
  outstandingThreshold: 1000,
}

const getQueryObject = ({ limit, threshold }) => {
  return defaults(
    {
      limit,
      outstandingThreshold: threshold,
    },
    defaultQueryObject
  )
}

function FinanceCloseLoanList() {
  const [filterValues, setFilterValues] = useState(emptyObject)

  const limit = useLimitQuery()

  const queryObject = useMemo(
    () => getQueryObject({ limit, ...filterValues }),
    [filterValues, limit]
  )

  const closeableAccounts = useCloseableAccounts(queryObject)

  const columns = useMemo(() => getColumns(), [])

  const data = useMemo(() => closeableAccounts, [closeableAccounts])

  const table = useTable({ data, columns })

  return (
    <Authorize permissions="eloan:AnyApplication:close">
      <Box>
        <Heading size="lg" mb={10}>
          Close Loan
        </Heading>
        <CloseLoanFilter onSubmit={setFilterValues} />
        <ListTable table={table} />
        <LimitBasedFilter limit={limit} count={closeableAccounts.length} />
      </Box>
    </Authorize>
  )
}

export default FinanceCloseLoanList
