import { get, keyBy, map, union } from 'lodash-es'
import {
  DISTRIBUTOR_ADD,
  DISTRIBUTOR_LOADING_ALL,
  DISTRIBUTOR_SET_ALL,
  DISTRIBUTOR_UPDATE,
} from 'store/distributors'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: false,
}

const distributorsReducer = (
  state = initialState,
  { type, data, distributorId }
) => {
  switch (type) {
    case DISTRIBUTOR_LOADING_ALL:
      return {
        ...state,
        loading: true,
      }
    case DISTRIBUTOR_SET_ALL:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        loading: false,
      }
    case DISTRIBUTOR_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [data.id]),
      }
    case DISTRIBUTOR_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [distributorId]: {
            ...get(state.byId, distributorId, emptyObject),
            ...data,
          },
        },
      }
    default:
      return state
  }
}

export default distributorsReducer
