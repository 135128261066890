import { useState, useEffect } from 'react'
import { emptyArray } from 'utils/defaults'
import { api } from 'api'
import camelcaseKeys from 'camelcase-keys'

export function useRepaymentOfflineFollowupHistory(applicationId) {
  const [data, setData] = useState(emptyArray)

  useEffect(() => {
    async function fetchData() {
      const {
        data,
      } = await api(
        `/legacy/v3/repayment/loans/{applicationId}/offline-followup-history`,
        { applicationId }
      )

      setData(camelcaseKeys(data, { deep: true }))
    }

    fetchData()
  }, [applicationId])

  return data
}

export function useCallTeamFollowupHistory(applicationId) {
  const [data, setData] = useState(emptyArray)

  useEffect(() => {
    async function fetchData() {
      const {
        data,
      } = await api(`/legacy/v3/repayment/loans/{applicationId}/call-history`, {
        applicationId,
      })

      setData(camelcaseKeys(data, { deep: true }))
    }

    fetchData()
  }, [applicationId])

  return data
}
