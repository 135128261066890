import { get } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { emptyArray } from 'utils/defaults'
import { getApplicationDocRequirements } from 'store/docRequirements'

export function useApplicationDocRequirements(applicationId) {
  const byId = useSelector((state) => state.docRequirements.byId)
  const allIds = useSelector((state) =>
    get(state.docRequirements.idsByApplication, applicationId, emptyArray)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApplicationDocRequirements(applicationId))
  }, [applicationId, dispatch])

  const docRequirements = useMemo(() => ({ byId, allIds }), [allIds, byId])

  return docRequirements
}
