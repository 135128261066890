import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { Form, FormButton, FormSelect, handleAPIError } from '@eloan/shared'
import Authorize from 'components/Authorize'
import { zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { setPaAssignmentsForNobodarApplications } from 'store/nobodar'
import { useNobodarPAUsers } from 'store/users/hooks'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    paId: Yup.number().integer(),
  })
}

const getDefaultValues = () => ({
  paId: '',
})

function SetPAAssignment({ applicationIds, ...props }) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const paUsers = useNobodarPAUsers()

  const paOptions = useMemo(() => {
    return zipObject(
      paUsers.allIds,
      paUsers.allIds.map((id) => paUsers.byId[id].name)
    )
  }, [paUsers.allIds, paUsers.byId])

  const toast = useToast()

  const defaultValues = useMemo(() => getDefaultValues(applicationIds), [
    applicationIds,
  ])
  const validationSchema = useMemo(getValidationSchema, [])

  const form = useForm({ defaultValues, validationSchema })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ paId }) => {
      try {
        await dispatch(
          setPaAssignmentsForNobodarApplications({ applicationIds, paId })
        )
        onClose()
      } catch (error) {
        handleAPIError(error, { form, toast })
      }
    },
    [applicationIds, dispatch, form, onClose, toast]
  )

  return (
    <Authorize permissions="eloan:AnyNobodarApplicationPAAssignment:set">
      <Button
        size="sm"
        variantColor="green"
        {...props}
        onClick={onOpen}
        isDisabled={applicationIds.length < 1}
      >
        Assign PA
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Set Portfolio Associate Assignment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <Box>
                  <FormSelect
                    name="paId"
                    label={'Portfolio Associate'}
                    options={paOptions}
                  />
                </Box>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button mr={4} variantColor="green" onClick={onClose}>
                Cancel
              </Button>
              <FormButton type="submit" variantColor="cyan">
                Assign
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default SetPAAssignment
