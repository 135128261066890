import { Button, Stack, Tag } from '@chakra-ui/core'
import ApplicationListHeader from 'components/ApplicationList/Header'
import ApplicationListTable from 'components/ApplicationList/Table'
import Authorize from 'components/Authorize'
import NavLink from 'components/Link/NavLink'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import useApplicationListQuery from 'hooks/useApplicationListQuery'
import usePagination from 'hooks/usePagination'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicationPage } from 'store/applications'
import { useAreas } from 'store/areas/hooks'
import { usePermit } from 'store/currentUser/hooks'
import { usePartners } from 'store/partners/hooks'
import { usePAUsers } from 'store/users/hooks'
import { emptyArray } from 'utils/defaults'
import OfferListFilters from './Filters'
import ApplicationIdOpenerFetch from 'components/Application/IdOpenerFetch'

const getColumns = (areasById, canSeeAnyApplication, paUsers, partners) =>
  [
    { Header: 'ID', accessor: 'id' },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ cell: { value } }) =>
        value ? <Tag size="sm">{value}</Tag> : null,
    },
    {
      Header: 'Partner',
      accessor: 'offer.loanPartner',
      Cell: ({ cell: { value } }) => get(partners.byId[value], 'name', null),
    },
    { Header: 'Name', accessor: 'meta.loaneeName' },
    { Header: 'Phone', accessor: 'phoneNumber' },
    {
      Header: 'Offer Date',
      id: 'offer.createdAt',
      accessor: (row) =>
        DateTime.fromISO(get(row, 'offer.createdAt')).toLocaleString(
          DateTime.DATE_MED
        ),
    },
    {
      Header: 'Area',
      id: 'meta.shopAreaId',
      accessor: (row) =>
        get(areasById, [get(row, 'meta.shopAreaId'), 'name'], 'N/A'),
    },
    { Header: 'Loan Amount', accessor: 'offer.loanAmount' },
    { Header: 'Tenure', accessor: 'offer.shopupSelectedTenure' },
    canSeeAnyApplication && {
      Header: 'PA',
      accessor: (row) =>
        get(paUsers.byId[get(row, 'paAssignment.paId')], 'name', 'N/A'),
    },
    {
      Header: '',
      accessor: 'id',
      id: 'open-button',
      Cell: ({ cell: { value, row } }) => (
        <Button
          as={NavLink}
          to={
            get(row, 'original.isNewEloan')
              ? `/dash/eloan-applications/${value}`
              : `/dash/offers/${value}`
          }
        >
          Open
        </Button>
      ),
    },
  ].filter(Boolean)

const defaultQueryObject = {
  length: 20,
  fields: `id,type,phoneNumber,loanAmount,tenure,createdAt,isNewEloan,meta.loaneeName,meta.loaneeNidName,meta.shopAreaId,offer.*,paAssignment.paId`,
}

const fixedFilterObject = {
  offer: {
    id: 'null',
  },
}

const filterTypes = {
  offer: {
    id: '!=',
    createdAt: 'date-range',
  },
}

const filterCacheKey = 'offers:filters'

function OfferList() {
  const paUsers = usePAUsers()

  const canSeeAnyApplication = usePermit('eloan:AnyApplication:read')

  const queryOptions = useMemo(
    () => ({
      default: defaultQueryObject,
      fixedFilter: fixedFilterObject,
      filterTypes: filterTypes,
      filterCacheKey,
    }),
    []
  )

  const [
    queryObject,
    onFilterObjectChange,
    filterObject,
  ] = useApplicationListQuery(
    queryOptions.default,
    queryOptions.fixedFilter,
    queryOptions.filterTypes,
    queryOptions.filterCacheKey
  )

  const dispatch = useDispatch()
  const fetchPage = useCallback(
    (...args) => {
      dispatch(getApplicationPage(...args))
    },
    [dispatch]
  )

  const pagination = useSelector((state) => state.pagination.applications)
  const [page] = usePagination(pagination, fetchPage, queryObject)

  const areas = useAreas()
  const partners = usePartners()
  const columns = useMemo(
    () => getColumns(areas.byId, canSeeAnyApplication, paUsers, partners),
    [areas.byId, canSeeAnyApplication, paUsers, partners]
  )

  const applications = useSelector((state) => state.applications)
  const data = useMemo(() => {
    return get(pagination.pages[page], 'itemIds', emptyArray).map(
      (id) => applications.byId[id]
    )
  }, [pagination.pages, page, applications.byId])

  return (
    <Authorize permissions="eloan:AnyApplication:read, eloan:Application:read">
      <ApplicationListHeader
        title={`eLoan Offers`}
        subtitle={`List of eLoan Offers`}
        Right={
          <Stack isInline spacing={4} alignItems="flex-end">
            <ApplicationIdOpenerFetch />
          </Stack>
        }
      />

      <OfferListFilters
        defaultValues={filterObject}
        onChange={onFilterObjectChange}
      />

      <ApplicationListTable
        loading={pagination.loading}
        data={data}
        columns={columns}
      />

      <PageBasedSwitcher
        pageIndex={page}
        totalPages={pagination.totalPages}
        totalItems={pagination.totalItems}
      />
    </Authorize>
  )
}

export default OfferList
