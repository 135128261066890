import { Box, Heading, SimpleGrid, useToast } from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import { get, set, zipObject } from 'lodash-es'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { updateApplicationRCAInfo } from 'store/applications'
import { useApplication } from 'store/applications/hooks'
import { useBankBranches, useBanks } from 'store/banks/hooks'
import DecoratedFormInput from '../components/DecoratedFormInput'
import { FormDatePicker } from 'components/Form/DatePicker'
import FormSwitch from 'components/Form/Switch'
import FormTextarea from 'components/Form/Textarea'
import { getOwnershipOptions } from 'utils/selectOptions'

const fields = [
  'type',
  'meta.businessNature',
  'meta.businessYearlySale',
  'meta.businessSecurityDeposit',
  'meta.businessStartDate',
  'meta.businessHasSuccessionPlan',
  'meta.businessSuccessorName',
  'meta.businessHasBookkeeping',
  'meta.businessLegalStatus',
  'meta.businessPhoneNumber',
  'meta.businessEmail',
  'meta.taxIdentificationNumber',
  'meta.tradeLicenceNumber',
  'meta.tradeLicenceExpiresAt',
  'meta.tradeLicenceBusinessAddress',
  'metaExtra.yearsAtBusinessPresentAddress',
  'meta.businessEmployeeFemaleCount',
  'meta.businessEmployeeMaleCount',
  'meta.businessTotalLiability',
  'meta.businessTotalEquity',
  'meta.businessLandAndBuildingValue',
  'meta.businessLongTermLiability',
  'meta.businessType',
  'meta.businessDescription',
  'meta.businessPresentAddress',
  'meta.businessTotalFixedAssets',
  'meta.businessOtherAssets',
  'meta.avgMonthlySale',
  'meta.staffs',
  'meta.incomeSource',
  'metaExtra.businessMonthlyDaysCreditFromSupplier',
  'metaExtra.businessProductCategory',
  'metaExtra.businessProductOrigin',
  'metaExtra.businessBankMfsTransactionActivity',
  'metaExtra.hasPreviousLoanWithFinancialInstitution',
  'metaExtra.tradeLicenseBusinessAddressPostcode',
  'metaExtra.initialInvestment',
  'metaExtra.businessPremiseOwnershipStatus',
  'bankDetails.bankId',
  'bankDetails.branchId',
  'bankDetails.bankAccountName',
  'bankDetails.bankAccountNumber',
  'bankDetails.routingNumber',
  'meta.highestSaleInMonth',
  'meta.lowestSaleInMonth',
  'metaExtra.sellingPriceOfHundredTakaProduct',
  'bankDetails.bkashAccountNumber',
  'bankDetails.bkashAccountType',
].join(',')

const getDefaultValues = (application) => {
  return fields.split(',').reduce(
    (values, field) => {
      set(values, field, get(application, field) || '')
      return values
    },
    { meta: {} }
  )
}

const bkashAccountTypeOptions = {
  personal: 'Personal',
  merchant: 'Merchant',
}

function BusinessInfoRCA({ applicationId, ...props }) {
  const toast = useToast()

  const application = useApplication(applicationId, fields)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])

  const form = useForm({
    defaultValues,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const banks = useBanks()

  const bankOptions = useMemo(() => {
    return zipObject(
      banks.allIds,
      banks.allIds.map((id) => get(banks.byId[id], 'bankName'))
    )
  }, [banks])

  const bankId = form.watch('bankDetails.bankId')
  const bankBranches = useBankBranches(bankId)

  const bankBranchOptions = useMemo(() => {
    return zipObject(
      bankBranches.allIds,
      bankBranches.allIds.map((id) => get(bankBranches.byId[id], 'branchName'))
    )
  }, [bankBranches])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ bankDetails, meta, metaExtra, shop }) => {
      try {
        await dispatch(
          updateApplicationRCAInfo(applicationId, {
            bankDetails,
            meta,
            metaExtra,
            shop,
          })
        )
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, toast]
  )

  const isIdlc = application.type === 'daraz-idlc'

  return (
    <Box {...props}>
      <Form form={form} onSubmit={onSubmit}>
        <Heading fontSize={5} mb={4}>
          Business Information
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <FormDatePicker
            name="meta.businessStartDate"
            label="Business Since"
            showYearDropdown
            required={isIdlc}
          />
          <FormSwitch
            name="meta.businessHasSuccessionPlan"
            label="Has Business Succession Plan"
          />
          <DecoratedFormInput
            name="meta.businessSuccessorName"
            label={`Business Successor Name`}
            value={get(application, 'meta.businessSuccessorName')}
          />
          <FormSwitch
            name="meta.businessHasBookkeeping"
            label="Has Business Book keeping"
          />
          <FormSelect
            name="meta.businessNature"
            label={`Business Nature`}
            options={{
              retailer: 'Retailer',
              wholesaler: 'Wholesaler',
              manufacturer: 'Manufacturer',
              importer: 'Importer',
              other: 'Other',
            }}
            required
          />
          <DecoratedFormInput
            name="meta.businessLegalStatus"
            label={`Business Legal Status`}
            value={get(application, 'meta.businessLegalStatus')}
          />
          <DecoratedFormInput
            name="meta.businessPhoneNumber"
            label={`Business Phone Number`}
            value={get(application, 'meta.businessPhoneNumber')}
          />
          <DecoratedFormInput
            name="meta.businessEmail"
            label={`Business Email`}
            value={get(application, 'meta.businessEmail')}
          />
          <DecoratedFormInput
            name="meta.taxIdentificationNumber"
            label={`Tax Identification Number`}
            value={get(application, 'meta.taxIdentificationNumber')}
          />

          <DecoratedFormInput
            name="meta.tradeLicenceNumber"
            label={`Trade License Number`}
            value={get(application, 'meta.tradeLicenceNumber')}
          />
          <FormDatePicker
            name="meta.tradeLicenceExpiresAt"
            label="Trade License Expires At"
            showYearDropdown
          />
          <FormTextarea
            name="meta.tradeLicenceBusinessAddress"
            label={`Trade License Business Address`}
          />
          <DecoratedFormInput
            name="metaExtra.tradeLicenseBusinessAddressPostcode"
            label={`Trade License Business Address Postal Code`}
            value={get(
              application,
              'metaExtra.tradeLicenseBusinessAdressPostcode'
            )}
          />

          <FormSelect
            name="metaExtra.businessPremiseOwnershipStatus"
            label="Business Premise Ownership Status"
            options={getOwnershipOptions()}
          />

          <DecoratedFormInput
            name="metaExtra.initialInvestment"
            label="Initial Investment"
            value={get(application, 'metaExtra.initialInvestment')}
          />

          <DecoratedFormInput
            name="meta.businessTotalLiability"
            label={`Business Total Liability`}
            value={get(application, 'meta.businessTotalLiability')}
          />
          <DecoratedFormInput
            name="meta.businessTotalEquity"
            label={`Business Total Equity`}
            value={get(application, 'meta.businessTotalEquity')}
          />
          <DecoratedFormInput
            name="meta.businessLandAndBuildingValue"
            label={`Business Land and Building Worth`}
            value={get(application, 'meta.businessLandAndBuildingValue')}
          />
          <DecoratedFormInput
            name="meta.businessLongTermLiability"
            label={`Business Long Term Liability`}
            value={get(application, 'meta.businessLongTermLiability')}
          />
          <DecoratedFormInput
            name="meta.businessYearlySale"
            label={`Business Yearly Sale`}
            value={get(application, 'meta.businessYearlySale')}
          />
          <DecoratedFormInput
            name="meta.businessSecurityDeposit"
            label={`Business Security Deposit`}
            value={get(application, 'meta.businessSecurityDeposit')}
          />
          <DecoratedFormInput
            name="meta.businessDescription"
            label={`Business Description`}
            value={get(application, 'meta.businessDescription')}
          />
          <DecoratedFormInput
            name="meta.businessTotalFixedAssets"
            label={`Total Fixed Assets`}
            value={get(application, 'meta.businessTotalFixedAssets')}
          />
          <DecoratedFormInput
            name="meta.businessOtherAssets"
            label={`Other Assets`}
            value={get(application, 'meta.businessOtherAssets')}
          />
          <DecoratedFormInput
            name="meta.avgMonthlySale"
            label={`Average Monthly Sale`}
            value={get(application, 'meta.avgMonthlySale')}
            readonly
          />
          <DecoratedFormInput
            name="meta.staffs"
            label={`Number of Employees`}
            value={get(application, 'meta.staffs')}
          />
          <DecoratedFormInput
            name="meta.businessEmployeeMaleCount"
            label={`Number of Employees (Male)`}
            value={get(application, 'meta.businessEmployeeMaleCount')}
          />
          <DecoratedFormInput
            name="meta.businessEmployeeFemaleCount"
            label={`Number of Employees (Female)`}
            value={get(application, 'meta.businessEmployeeFemaleCount')}
          />

          <DecoratedFormInput
            name="meta.incomeSource"
            label={`Income Source`}
            value={get(application, 'meta.incomeSource')}
          />

          <FormTextarea
            name="meta.businessPresentAddress"
            label={`Business Address`}
          />
          <DecoratedFormInput
            name="metaExtra.yearsAtBusinessPresentAddress"
            label={`Years at Business Address`}
            value={get(application, 'metaExtra.yearsAtBusinessPresentAddress')}
          />

          <DecoratedFormInput
            name="metaExtra.businessMonthlyDaysCreditFromSupplier"
            label={`Monthly Days Credit From Supplier`}
            value={get(
              application,
              'metaExtra.businessMonthlyDaysCreditFromSupplier'
            )}
            required={isIdlc}
          />

          <FormSelect
            name="metaExtra.businessProductCategory"
            label={`Product Category`}
            options={{
              cosmetics_and_personal_care: 'Cosmetics and Personal Care',
              electronic_accessories: 'Electronic Accessories',
              mobile_phones_and_pcs: 'Mobile Phones and PCS',
              toys: 'Toys',
              books: 'Books',
              home_decor: 'Home Décor',
              grocery: 'Grocery',
            }}
            required={isIdlc}
          />

          <FormSelect
            name="metaExtra.businessProductOrigin"
            label={`Product Origin`}
            options={{
              local: 'Local',
              imported: 'Imported',
            }}
            required={isIdlc}
          />

          <FormSelect
            name="metaExtra.businessBankMfsTransactionActivity"
            label={`Bank / MFS Transaction Activity`}
            options={{
              none: 'No Transaction',
              transaction_for_last_one_year: 'Transaction for Last 1 Year',
              transaction_for_more_than_one_year:
                'Transaction for More than 1 Year',
            }}
            required={isIdlc}
          />

          <FormSelect
            name="metaExtra.hasPreviousLoanWithFinancialInstitution"
            label={`Has Previous Loan with Financial Instituion`}
            options={{
              no: 'No',
              yes: 'Yes',
            }}
            required={isIdlc}
          />

          <DecoratedFormInput
            name="meta.lowestSaleInMonth"
            label={`Lowest Sale in a Month`}
            value={get(application, 'meta.lowestSaleInMonth')}
          />

          <DecoratedFormInput
            name="meta.highestSaleInMonth"
            label={`Highest Sale in a Month`}
            value={get(application, 'meta.highestSaleInMonth')}
          />

          <DecoratedFormInput
            name="metaExtra.sellingPriceOfHundredTakaProduct"
            label={`Selling Price for 100 Taka Product`}
            value={get(
              application,
              'metaExtra.sellingPriceOfHundredTakaProduct'
            )}
          />
        </SimpleGrid>

        <Heading fontSize={5} mb={4} mt={10}>
          Bank Information
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <FormSelect
            name="bankDetails.bankId"
            label={`Bank Name`}
            options={bankOptions}
          />

          <FormSelect
            name="bankDetails.branchId"
            label={`Branch Name`}
            options={bankBranchOptions}
          />

          <DecoratedFormInput
            name="bankDetails.bankAccountName"
            label={`Account Name`}
            value={get(application, 'bankDetails.bankAccountName')}
          />

          <DecoratedFormInput
            name="bankDetails.bankAccountNumber"
            label={`Account Number`}
            value={get(application, 'bankDetails.bankAccountNumber')}
          />

          <DecoratedFormInput
            name="bankDetails.routingNumber"
            label={`Routing Number`}
            value={get(application, 'bankDetails.routingNumber')}
          />

          <DecoratedFormInput
            name="bankDetails.bkashAccountNumber"
            label={`Bkash Account Number`}
            value={get(application, 'bankDetails.bkashAccountNumber')}
          />

          <FormSelect
            name="bankDetails.bkashAccountType"
            label={`Bkash Account Type`}
            options={bkashAccountTypeOptions}
          />
        </SimpleGrid>

        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default memo(BusinessInfoRCA)
