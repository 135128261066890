import { Box, Button, Heading, Stack } from '@chakra-ui/core'
import { Loader } from '@eloan/shared'
import { api } from 'api'
import React, { useEffect, useState } from 'react'

function DocTemplates({ applicationId }) {
  const [docs, setDocs] = useState(null)

  useEffect(() => {
    api('/legacy/v3/eloan/google-doc-templates').then(({ data }) => {
      setDocs(data.items)
    })
  }, [])

  return (
    <Stack spacing={6} width="100%">
      <Box>
        <Heading>Download Templated Documents</Heading>
      </Box>

      {docs ? (
        docs.map((doc) => (
          <Box key={doc.id}>
            <Button
              variant="ghost"
              leftIcon="download"
              as="a"
              href={`/api/legacy/v3/eloan/applications/${applicationId}/download-generated-pdf-from-google-doc-templates?docId=${doc.id}`}
              target="_blank"
            >
              {doc.name}
            </Button>
          </Box>
        ))
      ) : (
        <Loader />
      )}
    </Stack>
  )
}

export default DocTemplates
