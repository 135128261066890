/**
 * Converts string to lowercase
 */
export const toLower = (string) =>
  typeof string === 'string' ? string.toLowerCase() : string

/**
 * Converts string to UPPERCASE
 */
export const toUpper = (string) =>
  typeof string === 'string' ? string.toUpperCase() : string
