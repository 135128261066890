import { Box, Button, Heading, Stack, Text, Tooltip } from '@chakra-ui/core'
import { ExperimentalDataTable } from '@eloan/shared'
import ApplicaitonStatuses from 'components/Application/Statuses'
import Authorize from 'components/Authorize'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import usePagination from 'hooks/usePagination'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTable } from 'react-table'
import { getRedxShopOfferPage } from 'store/redx-shop-offers'
import RedXShopOfferListFilter from './Filter'
import UpdateRedxShopOffer from './Modals/UpdateRedxShopOffer'

const getColumns = () => [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'ShopID',
    accessor: 'shopId',
  },
  {
    Header: 'Shop Name',
    accessor: 'shop.shopName',
  },
  {
    id: 'history-date-range',
    Header: 'History Date Range',
    Cell: ({ row: { original } }) => {
      return `${DateTime.fromISO(original.historyStartDate).toLocaleString(
        DateTime.DATE_MED
      )} — ${DateTime.fromISO(original.historyEndDate).toLocaleString(
        DateTime.DATE_MED
      )}`
    },
  },
  {
    Header: 'Daily Avg. Parcel Count',
    accessor: 'dailyAvgParcelCount',
  },
  {
    Header: 'Min Amount',
    accessor: 'minLoanAmount',
    Cell: ({ row: { original }, cell: { value } }) => {
      return original.isManuallyModified ? (
        <Tooltip label="Manually Modified!">
          <Text color="orange.600">{value}</Text>
        </Tooltip>
      ) : (
        value
      )
    },
  },
  {
    Header: 'Max Amount',
    accessor: 'maxLoanAmount',
    Cell: ({ row: { original }, cell: { value } }) => {
      return original.isManuallyModified ? (
        <Tooltip label="Manually Modified!">
          <Text color="orange.600">{value}</Text>
        </Tooltip>
      ) : (
        value
      )
    },
  },
  {
    Header: 'eLoan ID',
    accessor: 'eloanId',
    Cell: ({ cell: { value } }) => {
      return <Link to={`/dash/applications/${value}`}>{value}</Link>
    },
  },
  {
    Header: 'Application Status',
    accessor: 'application',
    Cell: ({ cell: { value } }) => {
      return <ApplicaitonStatuses application={value} />
    },
  },
  {
    Header: 'Payment Deduction Percentage',
    accessor: 'paymentDeductionPercentage',
    Cell: ({ cell: { value } }) => {
      return value ? `${value}%` : ''
    },
  },
  {
    Header: '',
    id: 'actions',
    accessor: ({ id, status }) => (
      <UpdateRedxShopOffer id={id} status={status} />
    ),
  },
]

export function RedxShopOfferList() {
  const dispatch = useDispatch()

  const [listFilters, setListFilters] = useState({
    shopId: '',
    shopName: '',
    status: '',
  })

  const fetchPage = useCallback(
    (...args) => {
      dispatch(getRedxShopOfferPage(...args))
    },
    [dispatch, listFilters]
  )

  const queryObject = useMemo(
    () => ({
      shopId: listFilters.shopId,
      shopName: listFilters.shopName,
      status: listFilters.status,
    }),
    [listFilters]
  )

  const pagination = useSelector((state) => state.pagination.redxShopOffers)
  const [page] = usePagination(pagination, fetchPage, queryObject)

  const redxShopOffers = useSelector((state) => state.redxShopOffers)
  const data = useMemo(() => {
    return get(pagination.pages[page], 'itemIds', []).map(
      (id) => redxShopOffers.byId[id]
    )
  }, [pagination.pages, page, redxShopOffers.byId])

  const columns = useMemo(() => getColumns(), [])

  const table = useTable({
    data,
    columns,
  })

  return (
    <Authorize permissions="eloan:RedxShopOffer:manage">
      <Stack isInline justifyContent="space-between">
        <Heading as="h2" mb={4}>
          RedX Shop Offers
        </Heading>
        <Box>
          <Button as={Link} to="/dash/admin/redx-shop-offers/create">
            Create
          </Button>
        </Box>
      </Stack>

      <Stack isInline justifyContent="space-between" marginTop={4}>
        <RedXShopOfferListFilter onSubmit={setListFilters} />
      </Stack>

      <ExperimentalDataTable {...table} />

      <PageBasedSwitcher
        pageIndex={page}
        totalPages={pagination.totalPages}
        totalItems={pagination.totalItems}
      />
    </Authorize>
  )
}
