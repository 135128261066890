import { keyBy, map } from 'lodash-es'
import { emptyArray, emptyObject } from 'utils/defaults'
import { BEFTN_SET_ALL, BEFTN_UPDATE } from 'store/beftn'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: true,
}
const idKey = 'id'

const beftnReducer = (state = initialState, { type, data, id }) => {
  switch (type) {
    case BEFTN_SET_ALL:
      return {
        ...state,
        byId: keyBy(data.items, idKey),
        allIds: map(data.items, idKey),
        loading: false,
      }
    case BEFTN_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId[id],
            ...data,
          },
        },
      }
    default:
      return state
  }
}

export default beftnReducer
