import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import {
  Form,
  FormButton,
  FormInput,
  FormTextarea,
  handleAPIError,
  InfoCard,
} from '@eloan/shared'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { addCibPayment } from 'store/applications'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    transactionId: Yup.string().required(`required`),
    note: Yup.string(),
    attachment: Yup.mixed(),
  })
}

const getDefaultValues = () => ({
  transactionId: '',
  note: '',
  attachment: [],
})

const bkashButtonProps = {
  bg: 'white',
  color: '#e2136e',
  _hover: { bg: '#e2136e', color: 'white' },
  _active: { bg: '#e2136e', color: 'white' },
  borderWidth: 1,
  borderColor: '#e2136e',
}

function AddCibBKashTransaction({ applicationId, application, ...props }) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const toast = useToast()

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(getValidationSchema, [])

  const form = useForm({ defaultValues, validationSchema })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ transactionId, note, attachment }) => {
      try {
        await dispatch(
          addCibPayment(applicationId, {
            paymentMethod: 'bkash',
            transactionId: transactionId.trim(),
            note,
            attachment: attachment[0],
          })
        )
        onClose()
      } catch (error) {
        handleAPIError(error, { form, toast })
      }
    },
    [applicationId, dispatch, form, onClose, toast]
  )

  const needPayment = useMemo(
    () =>
      ['unpaid', 'partially_paid'].includes(
        get(application, 'metaExtra.cibPaymentStatus')
      ),
    [application]
  )

  if (!needPayment) {
    return null
  }

  return (
    <>
      <Button
        leftIcon="plus-square"
        {...bkashButtonProps}
        {...props}
        onClick={onOpen}
      >
        Add Payment
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Add bKash Transaction</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack isInline spacing={5} mb={6}>
                <InfoCard
                  label="eLoan ID"
                  value={applicationId}
                  valueFontSize="md"
                />
                <InfoCard
                  label="Name"
                  value={get(application, 'meta.loaneeName')}
                  valueFontSize="md"
                />
                <InfoCard
                  label="Phone number"
                  value={get(application, 'phoneNumber', '').slice(-11)}
                  valueFontSize="md"
                />
              </Stack>

              <Stack spacing={4}>
                <Box>
                  <FormInput
                    name="transactionId"
                    label={`bKash Transaction ID`}
                  />
                </Box>

                <Box>
                  <FormInput
                    name="attachment"
                    label={`bKash Transaction Slip`}
                    type="file"
                  />
                </Box>

                <Box>
                  <FormTextarea name="note" label={`Note`} />
                </Box>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button mr={4} variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <FormButton type="submit" {...bkashButtonProps}>
                Add bKash Transaction ID
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddCibBKashTransaction
