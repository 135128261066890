import { get } from 'lodash-es'

export const TRADE_LICENSE_KEYS = [8, 23, 36]
export const TIN_KEYS = [12, 28, 48]

export const LOANEE_PASSPORT_KEYS = [16, 32, 52]
export const GUARANTOR_PASSPORT_KEYS = [16, 40, 62]
export const TP_GUARANTOR_PASSPORT_KEYS = [16, 41, 63]

export const LOANEE_BUSINESS_CARD_FRONT_KEYS = [16, 42, 64]
export const LOANEE_BUSINESS_CARD_BACK_KEYS = [16, 42, 65]

export const LOANEE_BANK_STATEMENT_KEYS = [5, 9, 9]
export const LOANEE_BANK_CHEQUE_KEYS = [7, 22, 35]

export const SIGNED_AGREEMENT_KEYS = [18, 57, 80]

export function getDocDataByKey(docs, [docId, staticEntityId, fieldId]) {
  const value = get(
    docs,
    `${docId}.staticEntities.${staticEntityId}.fields.${fieldId}.value.value`
  )
  return value ? get(JSON.parse(value), '0.filename') : ''
}
