export const teams = {
  call_team: 'call_team',
  field_team: 'field_team',
  cca: 'cca',
}

export const teamOptionsCallTeam = {
  [teams.call_team]: 'Call Team',
  [teams.field_team]: 'Field Team',
  [teams.cca]: 'CCA',
}

export const teamOptionsFieldTeam = {
  [teams.field_team]: 'Field Team',
  [teams.cca]: 'CCA',
}

export const teamOptionsCCA = {
  [teams.field_team]: 'Field Team',
}

export const callStatusMapper = {
  reached: 'Reached',
  unreachable: 'Unreachable',
  committed: 'Committed',
  skipped: 'Skipped',
}
