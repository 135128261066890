import React, { useState, useMemo, useCallback, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Flex,
  ModalFooter,
  useToast,
} from '@chakra-ui/core'
import { FileUpload } from '@eloan/shared'
import { useDispatch } from 'react-redux'
import { updateBeftn } from 'store/beftn'
import { handleAPIError } from 'components/Form/helpers'

function UploadSlip({ beftnId }) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [file, setFile] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()

  useEffect(() => {
    if (isOpen) {
      setFile(null)
      setIsSubmitting(false)
    }
  }, [isOpen])

  const onConfirm = useCallback(async () => {
    setIsSubmitting(true)

    const formData = new FormData()

    formData.append('referenceDocument', file)
    formData.append('isTransferred', true)

    try {
      await dispatch(updateBeftn(beftnId, formData))
    } catch (error) {
      handleAPIError(error, { toast })
    }

    setIsSubmitting(false)

    onClose()
  }, [file, beftnId, dispatch, onClose, toast])

  const fileName = useMemo(() => file?.name, [file])

  return (
    <>
      <Button
        onClick={onOpen}
        rightIcon="chevron-right"
        variant="link"
        variantColor="cyan"
        isLoading={isSubmitting}
      >
        UPLOAD SLIP
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent px={4} py={6}>
          <ModalHeader>Upload Slip</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={4}>
            <Flex align="center" borderWidth="1px" p={2} borderRadius="5px">
              <FileUpload onChange={setFile} size="sm" />
              <Text ml={6} color="green.500">
                {fileName}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="flex-start">
            <Button
              mr={4}
              size="sm"
              px={6}
              variant="outline"
              onClick={onClose}
              variantColor="red"
            >
              CANCEL
            </Button>
            <Button
              variantColor="green"
              size="sm"
              px={6}
              disabled={!file}
              isLoading={isSubmitting}
              onClick={onConfirm}
            >
              CONFIRM
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default UploadSlip
