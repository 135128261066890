import { get, keyBy, map, union } from 'lodash-es'
import {
  RETAILER_ADD,
  RETAILER_LOADING_ALL,
  RETAILER_SET_ALL,
  RETAILER_UPDATE,
} from 'store/retailers'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: false,
}

const retailersReducer = (state = initialState, { type, data, retailerId }) => {
  switch (type) {
    case RETAILER_LOADING_ALL:
      return {
        ...state,
        loading: true,
      }
    case RETAILER_SET_ALL:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        loading: false,
      }
    case RETAILER_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [data.id]),
      }
    case RETAILER_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [retailerId]: {
            ...get(state.byId, retailerId, emptyObject),
            ...data,
          },
        },
      }
    default:
      return state
  }
}

export default retailersReducer
