import camelcaseKeys from 'camelcase-keys'
import { batch } from 'react-redux'

export const APPLICATION_DATA_LOADING = 'APPLICATION_DATA_LOADING'
export const APPLICATION_DATA_LOADED = 'APPLICATION_DATA_LOADED'

export const APPLICATION_UPDATE = 'APPLICATION_UPDATE'

export const APPLICATION_ADD = 'APPLICATION_ADD'
export const APPLICATION_ADD_BULK = 'APPLICATION_ADD_BULK'
export const APPLICATION_UPDATE_BULK = 'APPLICATION_UPDATE_BULK'

export const APPLICATION_PAGE_ADD = 'APPLICATION_PAGE_ADD'
export const APPLICATION_PAGE_REMOVE = 'APPLICATION_PAGE_REMOVE'
export const APPLICATION_PAGE_REQUEST = 'APPLICATION_PAGE_REQUEST'
export const APPLICATION_PAGINATION_PURGE = 'APPLICATION_PAGINATION_PURGE'

export const getApplicationData = (applicationId, { fields }) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch({ type: APPLICATION_DATA_LOADING, applicationId, fields })

  const { data } = await api(
    `/legacy/v4/eloan/applications/{applicationId}/{?fields}`,
    {
      applicationId,
      fields,
    }
  )

  dispatch({ type: APPLICATION_DATA_LOADED, data, applicationId, fields })

  return data
}

export const createOfferForApplication = (
  applicationId,
  {
    partnerId,
    productId,
    minLoanAmount,
    maxLoanAmount,
    shopUpTenure,
    partnerTenure,
    shopUpInterestRate,
    partnerInterestRate,
  }
) => async (dispatch, _getState, { api }) => {
  const THIS_IS_DEPRECATED = true

  if (THIS_IS_DEPRECATED) {
    throw new Error('WHY WOULD YOU USE THIS!!!')
  }

  const fields = 'id,offer.*'

  dispatch({
    type: APPLICATION_DATA_LOADING,
    applicationId,
    fields,
  })

  const { data } = await api(
    `POST /legacy/v4/eloan/applications/{applicationId}/offer`,
    {
      applicationId,
      partnerId,
      productId,
      minLoanAmount,
      maxLoanAmount,
      shopUpTenure,
      partnerTenure,
      shopUpInterestRate,
      partnerInterestRate,
    }
  )

  dispatch({ type: APPLICATION_DATA_LOADED, data, applicationId, fields })

  return data
}

export const updateApplicationRCAInfo = (
  applicationId,
  { bankDetails, meta, metaExtra, shop }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `PATCH /legacy/v3/eloan/applications/{applicationId}/rca-info`,
    {
      applicationId,
      bankDetails,
      meta,
      metaExtra,
      shop,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })

  return data
}

export const updateApplicationMetaJson = (
  applicationId,
  { fieldName, items }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `PUT /legacy/v3/eloan/applications/{applicationId}/meta/json-field/{fieldName}`,
    {
      applicationId,
      fieldName,
      items,
    }
  )

  // dispatch({ type: APPLICATION_UPDATE, data, applicationId })

  return data
}

export const assignFAToApplication = ({ applicationId, userId }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v4/eloan/applications/{applicationId}/fa-assignments`,
    {
      applicationId,
      userId,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const assignPAsToApplications = ({ applicationIds, paId }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v4/eloan/applications/pa-assignments`,
    {
      applicationIds,
      paId,
    }
  )

  dispatch({ type: APPLICATION_PAGINATION_PURGE })

  const items = data.items.map((item) => {
    return {
      id: item.applicationId,
      paAssignment: {
        ...item,
      },
    }
  })

  dispatch({ type: APPLICATION_UPDATE_BULK, data: { items } })
}

export const getApplicationPage = (
  {
    page = 1,
    limit,
    fields,
    filter,
    metaFilter,
    metaExtraFilter,
    offerFilter,
    paAssignmentFilter,
    paymentDetailsFilter,
    includeOnboardingFees,
  },
  queryHash
) => async (dispatch, _getState, { api }) => {
  dispatch({ type: APPLICATION_PAGE_REQUEST, page, queryHash })

  try {
    const { data } = await api(
      `/legacy/v4/eloan/applications{?limit,page,fields,filter,metaFilter,metaExtraFilter,offerFilter,paAssignmentFilter,paymentDetailsFilter,includeOnboardingFees}`,
      {
        limit,
        page,
        fields,
        filter,
        metaFilter,
        metaExtraFilter,
        offerFilter,
        paAssignmentFilter,
        paymentDetailsFilter,
        includeOnboardingFees,
      }
    )

    batch(() => {
      dispatch({ type: APPLICATION_ADD_BULK, data })
      dispatch({ type: APPLICATION_PAGE_ADD, page, data, queryHash })
    })

    return data
  } catch (err) {
    if (err.error && err.error.status >= 500) {
      dispatch({ type: APPLICATION_PAGE_REMOVE, page, queryHash })
    }
  }
}

export const getApplicationPageForLoanProduct = (
  { loanProductId, page = 1, limit, filter },
  queryHash
) => async (dispatch, _getState, { api }) => {
  dispatch({ type: APPLICATION_PAGE_REQUEST, page, queryHash })

  try {
    const { data } = await api(
      `/legacy/v4/eloan/loan-products/{loanProductId}/applications{?limit,page,fields,filter*}`,
      {
        loanProductId,
        limit,
        page,
        filter,
      }
    )

    batch(() => {
      dispatch({ type: APPLICATION_ADD_BULK, data })
      dispatch({ type: APPLICATION_PAGE_ADD, page, data, queryHash })
    })

    return data
  } catch (err) {
    if (err.error && err.error.status >= 500) {
      dispatch({ type: APPLICATION_PAGE_REMOVE, page, queryHash })
    }
  }
}

export const acceptApplicationImage = (
  applicationId,
  { photoField, photoValue }
) => async (dispatch, _getState, { api }) => {
  const {
    data,
  } = await api(
    `POST /legacy/v4/eloan/applications/{applicationId}/actions/accept-photo`,
    { applicationId, photoField, photoValue }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })

  return data
}

export const rejectApplicationImage = (
  applicationId,
  { photoField, photoValue }
) => async (dispatch, _getState, { api }) => {
  const {
    data,
  } = await api(
    `POST /legacy/v4/eloan/applications/{applicationId}/actions/reject-photo`,
    { applicationId, photoField, photoValue }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })

  return data
}

export const updateApplicationDocSubmissionMethod = (
  applicationId,
  { docSubmissionMethod, note }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `PUT /legacy/v4/eloan/applications/{applicationId}/docSubmissionMethod`,
    {
      applicationId,
      docSubmissionMethod,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const setGuarantorRelationship = (
  applicationId,
  { relationship, tp }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `PUT /legacy/v4/eloan/applications/{applicationId}/guarantor/relationship`,
    {
      applicationId,
      relationship,
      tp,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const resetApplicationGuarantor = (applicationId, { tp } = {}) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data: _data } = await api(
    `DELETE /legacy/v3/eloan/applications/{applicationId}/guarantor`,
    {
      applicationId,
      tp,
    }
  )

  delete _data._status

  const data = {
    id: applicationId,
    meta: camelcaseKeys(_data),
  }

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const resetApplicationNidInfo = (applicationId, { nidOwner }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v4/eloan/applications/{applicationId}/actions/reset-nid-info`,
    {
      applicationId,
      nidOwner,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const sendApplicationToDigitization = (
  applicationId,
  { note }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/actions/send-to-digitization`,
    {
      applicationId,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const closeApplication = (applicationId, { subStatus, note }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/actions/close-application`,
    {
      applicationId,
      subStatus,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const quarantineApplication = (
  applicationId,
  { subStatus, note }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/actions/quarantine-application`,
    {
      applicationId,
      subStatus,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const refreshApplicationStatus = (applicationId, { note }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/actions/refresh-verification-status`,
    {
      applicationId,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const sendOfferToShopupApproved = (applicationId, { note }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/offer/actions/send-to-shopup-approved`,
    {
      applicationId,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const reviveApplication = (applicationId, { subStatus, note }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/actions/revive-application`,
    {
      applicationId,
      subStatus,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const sendApplicationToVerificationApproval = (
  applicationId,
  { note }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/actions/send-to-verification-approval`,
    {
      applicationId,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const acceptRedxShopupApplication = (applicationId, { note }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/redx-shopup/{applicationId}/actions/accept-application`,
    {
      applicationId,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const updateApplicationStatus = (
  applicationId,
  { status, note }
) => async (dispatch, _getState, { api }) => {
  const {
    data,
  } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/actions/change-status`,
    { applicationId, status, note }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const sendOfferToTweaking = (applicationId, { note }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/offer/actions/send-to-tweaking`,
    {
      applicationId,
      note,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const updateOfferStatus = (applicationId, { status, note }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `PUT /legacy/v4/eloan/applications/{applicationId}/offer-status`,
    {
      applicationId,
      status,
      note,
    }
  )

  dispatch({
    type: APPLICATION_UPDATE,
    data: { offer: { status: data.status } },
    applicationId,
  })
}

export const generateApplication = (body) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(`POST /legacy/v3/eloan/applications`, {
    body,
  })

  batch(() => {
    dispatch({ type: APPLICATION_ADD, data, applicationId: data.id })
    dispatch({ type: APPLICATION_PAGINATION_PURGE, data })
  })

  return data
}

export const generateShwanirbhorApplication = ({ shopId }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v4/eloan/actions/generate-shwanirbhor-application`,
    {
      shopId,
    }
  )

  batch(() => {
    dispatch({ type: APPLICATION_ADD, data, applicationId: data.id })
    dispatch({ type: APPLICATION_PAGINATION_PURGE, data })
  })
}

export const generateFinalScorecardForApplication = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v4/eloan/applications/{applicationId}/daraz-idlc-final-offer`,
    {
      applicationId,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const uploadTradeLicensePhoto = (applicationId, { file }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const formData = new FormData()
  formData.set('file', file)

  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/action/upload-trade-license-photo`,
    {
      applicationId,
      body: formData,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const addCibPayment = (
  applicationId,
  { paymentMethod, transactionId, note, attachment }
) => async (dispatch, _getState, { api }) => {
  const formData = new FormData()
  formData.set('paymentMethod', paymentMethod)
  formData.set('transactionId', transactionId)
  formData.set('note', note)
  if (attachment) {
    formData.set('attachment', attachment)
  }

  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/cib-payments`,
    {
      applicationId,
      body: formData,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const uploadCibPaymentAttachment = (
  applicationId,
  cibPaymentId,
  { attachment }
) => async (dispatch, _getState, { api }) => {
  const formData = new FormData()
  formData.set('file', attachment)

  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/cib-payments/{cibPaymentId}/attachment`,
    {
      applicationId,
      cibPaymentId,
      body: formData,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const manuallyVerifyCibPayment = (
  applicationId,
  cibPaymentId,
  { paymentMethod, amount, correctTransactionId, note, attachment }
) => async (dispatch, _getState, { api }) => {
  const formData = new FormData()
  formData.set('paymentMethod', paymentMethod)
  formData.set('amount', amount)
  if (correctTransactionId) {
    formData.set('correctTransactionId', correctTransactionId)
  }
  formData.set('note', note)
  if (attachment) {
    formData.set('attachment', attachment)
  }

  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/cib-payments/{cibPaymentId}/action/manually-verify`,
    {
      applicationId,
      cibPaymentId,
      body: formData,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const uploadCibSignedImage = (
  applicationId,
  { fieldName, file }
) => async (dispatch, _getState, { api }) => {
  const formData = new FormData()
  formData.set('file', file)
  formData.set('fieldName', fieldName)

  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/action/upload-cib-signed-image`,
    {
      applicationId,
      body: formData,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const uploadCibPaymentBankSlip = (applicationId, { file }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const formData = new FormData()
  formData.set('file', file)

  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/action/upload-cib-payment-bank-slip`,
    {
      applicationId,
      body: formData,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const sendToFiForCibApproval = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/action/send-to-fi-for-cib-approval`,
    {
      applicationId,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}

export const uploadNidSelfie = (applicationId, formData) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/nid-selfie`,
    {
      applicationId,
      body: formData,
    }
  )

  dispatch({
    type: APPLICATION_UPDATE,
    data: camelcaseKeys({ meta: data, metaExtra: data.extra }, { deep: true }),
    applicationId,
  })

  return data
}

export const uploadNidPhoto = (applicationId, formData) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/applications/{applicationId}/nid-photo`,
    {
      applicationId,
      body: formData,
    }
  )

  dispatch({
    type: APPLICATION_UPDATE,
    data: camelcaseKeys({ meta: data, metaExtra: data.extra }, { deep: true }),
    applicationId,
  })

  return data
}

export const setLoaneePhoneNumber = (
  applicationId,
  { loaneePhoneNumber }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `PUT /legacy/v4/eloan/applications/{applicationId}/loanee-phone-number`,
    {
      applicationId,
      loaneePhoneNumber,
    }
  )

  dispatch({ type: APPLICATION_UPDATE, data, applicationId })
}
