import { get } from 'lodash-es'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBankBranches, getAllBanks } from 'store/banks'
import { initialState } from './reducer'

export function useBanks(forceRefresh = false) {
  const banks = useSelector((state) => state.banks)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!banks.allIds.length || forceRefresh) {
      dispatch(getAllBanks())
    }
  }, [banks.allIds.length, dispatch, forceRefresh])

  return banks
}

export function useBankBranches(bankId, forceRefresh = false) {
  const branches = useSelector((state) =>
    get(state.banks.byId[bankId], 'branches', initialState)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (bankId && (branches === initialState || forceRefresh)) {
      dispatch(getAllBankBranches(bankId))
    }
  }, [bankId, branches, dispatch, forceRefresh])

  return branches
}
