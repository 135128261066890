import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RepaymentFollowupCallTeamList from './List'
import RepaymentFollowupCallTeamView from './View'

function RepaymentFollowupCallTeam() {
  return (
    <Switch>
      <Route
        exact
        path="/dash/repayment-followup/call-team"
        component={RepaymentFollowupCallTeamList}
      />
      <Route
        path="/dash/repayment-followup/call-team/:callListId"
        component={RepaymentFollowupCallTeamView}
      />
    </Switch>
  )
}

export default RepaymentFollowupCallTeam
