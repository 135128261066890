import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Heading,
} from '@chakra-ui/core'
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Authorize from 'components/Authorize'
import { useMemo } from 'react'
import { useCallback } from 'react'
import LoanModifierConfiguration from './Configuration/Configuration'
import LoanModifierConfigurationList from './List'

const tabs = [
  {
    id: '',
    name: 'Configuration',
  },
  {
    id: 'list',
    name: 'View All Modifier',
  },
]

function LoanModifierPage() {
  const { tabId } = useParams()

  const history = useHistory()

  const onTabChange = useCallback(
    (index) => {
      history.replace(`/dash/admin/loan-modifier/${tabs[index].id}`)
    },
    [history]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === tabId)
    return index < 0 ? 0 : index
  }, [tabId])

  return (
    <Authorize>
      <Heading size="md" mb={10}>
        Loan Modifier
      </Heading>
      <Tabs index={tabIndex} onChange={onTabChange} variantColor="cyan">
        <TabList mb={4}>
          {tabs.map((tab) => (
            <Tab id={tab.id} key={tab.id} px={0} pt={0} mr={4}>
              {tab.name}
            </Tab>
          ))}
        </TabList>

        <TabPanels py={4}>
          <TabPanel>
            <LoanModifierConfiguration />
          </TabPanel>
          <TabPanel>
            <LoanModifierConfigurationList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Authorize>
  )
}

export default LoanModifierPage
