import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/core'
import {
  Form,
  FormButton,
  FormDateRangePicker,
  FormTextarea,
  handleAPIError,
} from '@eloan/shared'
import Authorize from 'components/Authorize'
import FormInput from 'components/Form/Input'
import FormCheckbox from 'components/Form/Checkbox'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { bulkCreateRedxShopOffer } from 'store/redx-shop-offers'

const getDefaultValues = () => {
  const today = DateTime.local().startOf('day')

  return {
    historyDate: {
      start: today.minus({ month: 5 }).startOf('month'),
      end: today.minus({ month: 1 }).endOf('month'),
    },
    minLoanAmount: 20000,
    maxLoanAmount: 200000,
    maxLoanAmountFactor: 50,
  }
}
export function RedxShopOfferCreate() {
  const history = useHistory()

  const toast = useToast()
  const defaultValues = useMemo(() => getDefaultValues(), [])

  const form = useForm({
    defaultValues,
  })

  const shopIdsString = form.watch('shopIds')
  const shopIds = useMemo(() => {
    return (shopIdsString ?? '')
      .split(/[\s,]+/)
      .map((id) => id.trim())
      .filter(Boolean)
      .map((id) => Number(id))
  }, [shopIdsString])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({
      historyDate,
      minLoanAmount,
      maxLoanAmount,
      maxLoanAmountFactor,
      canSelectWaitingPeriod,
    }) => {
      try {
        await dispatch(
          bulkCreateRedxShopOffer({
            shopIds,
            historyStartDate: historyDate.start,
            historyEndDate: historyDate.end,
            minLoanAmount,
            maxLoanAmount,
            maxLoanAmountFactor,
            canSelectWaitingPeriod,
          })
        )
        history.push('/dash/admin/redx-shop-offers')
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, history, shopIds, toast]
  )
  return (
    <Authorize permissions="eloan:RedxShopOffer:manage">
      <Heading as="h2" mb={4}>
        Create RedX Shop Offers
      </Heading>

      <Form form={form} onSubmit={onSubmit} spacing={2}>
        <Stack isInline justifyContent="space-between" flexWrap="wrap">
          <Box flexGrow="1">
            <FormTextarea
              name="shopIds"
              label="Shop IDs"
              rows="10"
              height="auto"
            />
          </Box>
          <Stack spacing={2}>
            <FormDateRangePicker
              name="historyDate"
              label="History Date Range"
            />
            <FormInput name="minLoanAmount" label="Min Loan Amount" />
            <FormInput name="maxLoanAmount" label="Max Loan Amount" />
            <FormInput
              name="maxLoanAmountFactor"
              label="Max Loan Amount Factor"
            />
            <div style={{ display: 'flex', paddingBottom: 10 }}>
              <FormCheckbox
                name="canSelectWaitingPeriod"
                style={{ marginTop: 3 }}
              />
              <div
                style={{
                  marginLeft: 10,
                  frontSize: 20,
                  fontWeight: 'bold',
                }}
              >
                Waiting Period
              </div>
            </div>
            <ButtonGroup display="flex">
              <Button as={Link} to="/dash/admin/redx-shop-offers">
                Go Back
              </Button>
              <FormButton type="submit" variantColor="blue" flexGrow="1">
                Create
              </FormButton>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Form>
    </Authorize>
  )
}
