import { Heading, Icon, Link } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import React from 'react'
import { Box, Flex } from 'reflexbox'

function LegacySection({ application, ...props }) {
  return (
    <>
      <Box {...props}>
        <Heading fontSize={5} mb={4}>
          Link to SAP
        </Heading>

        <Flex flexDirection="row" flexWrap="wrap">
          <InfoBox>
            <Link
              fontSize={2}
              href={`${window.location.origin.replace(
                '//eloan.',
                '//sap.'
              )}/shop/capital?applicationId=${application.id}`}
              isExternal
            >
              Open in SAP <Icon name="external-link" mx="2px" />
            </Link>
          </InfoBox>
        </Flex>
      </Box>
    </>
  )
}

export default LegacySection
