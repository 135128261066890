import { Flex, Heading } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { get, capitalize } from 'lodash-es'
import React, { memo } from 'react'
import { Box } from 'reflexbox'
import { useBusinessMediums } from 'store/businessMediums/hooks'
import { emptyArray } from 'utils/defaults'

function LoanInfo({ application, ...props }) {
  const applicationType = get(application, 'type')

  const businessMediums = useBusinessMediums()

  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Loan Info
      </Heading>

      <Flex flexDirection="row" flexWrap="wrap">
        <InfoBox label="Amount" content={get(application, 'loanAmount')} />
        <InfoBox label="Tenure" content={get(application, 'tenure')} />

        {applicationType === 'redx-shopup' && (
          <>
            <InfoBox
              label="RedX Payment Deduction %"
              content={get(
                application,
                'redxShopData.paymentDeductionPercentage'
              )}
            />

            <InfoBox
              label="Waiting Period"
              content={
                get(
                  application,
                  'redxShopData.deductionWaitingPeriodInDays',
                  0
                ) + ' Days'
              }
            />
          </>
        )}

        <InfoBox
          label="Business Name"
          content={get(application, 'shop.shopName')}
        />
        <InfoBox
          label="Business Mediums"
          content={(get(application, 'shop.businessMediums') || emptyArray)
            .map((id) => get(businessMediums.byId[id], 'name'))
            .join(', ')}
        />
        <InfoBox
          label="Business Note"
          content={get(application, 'shop.businessNote') || 'N/A'}
        />
        <InfoBox
          label="Doc Submission Method"
          content={
            capitalize(get(application, 'meta.docSubmissionMethod')) || 'N/A'
          }
        />
        <InfoBox
          label="Offer Loan Amount"
          content={get(application, 'offer.loanAmount')}
        />
      </Flex>
    </Box>
  )
}

export default memo(LoanInfo)
