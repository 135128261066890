import { Heading, Stack } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import ResetNIDInfoButton from 'components/Button/ResetNIDInfoButton'
import NIDPhoto from 'components/Image/NIDPhoto'
import PorichoyTag from 'components/Tag/PorichoyTag'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Flex } from 'reflexbox'
import { useAreas } from 'store/areas/hooks'
import LoaneeInfo from './sections/LoaneeInfo'
import LoanInfo from './sections/LoanInfo'
import UploadNID, { nidTypes } from './UploadNID'

function LoaneeNID({ applicationId }) {
  const areas = useAreas()

  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const shopArea = useMemo(() => {
    return areas.byId[get(application, 'meta.shopAreaId', null)]
  }, [application, areas.byId])

  return (
    <Stack spacing={8} width="100%">
      <LoanInfo application={application} />

      <LoaneeInfo application={application} shopArea={shopArea} />

      <Box>
        <Heading fontSize={5} mb={4}>
          Loanee NID Info
        </Heading>

        <Flex flexDirection="row" flexWrap="wrap">
          <InfoBox
            label="NID Number"
            content={get(application, 'meta.loaneeNid')}
          />
          <InfoBox
            label="Name"
            content={get(application, 'meta.loaneeNidName')}
          />
          <InfoBox
            label="Date of Birth"
            content={
              get(application, 'meta.loaneeNidDob')
                ? DateTime.fromISO(
                    get(application, 'meta.loaneeNidDob')
                  ).toLocaleString(DateTime.DATE_MED)
                : null
            }
          />
          <InfoBox
            label="Porichoy Status"
            content={
              <PorichoyTag
                valid={get(application, 'meta.loaneeNidPorichoyValid')}
              />
            }
          />
        </Flex>

        <Flex flexDirection="row" mt={2}>
          <ResetNIDInfoButton applicationId={applicationId} nidOwner="loanee" />
        </Flex>
      </Box>

      <Box>
        <Heading fontSize={5} mb={4}>
          Loanee NID Photos
        </Heading>

        <Flex flexDirection="row" flexWrap="wrap">
          <InfoBox label="NID Selfie">
            <NIDPhoto
              applicationId={applicationId}
              photoField="meta.loaneeNidSelfie"
              statusField="meta.loaneeNidSelfieStatus"
            />
            <UploadNID
              label="Upload NID Selfie"
              type={nidTypes.nidSelfie}
              applicationId={applicationId}
              status={get(application, 'meta.loaneeNidSelfieStatus')}
            />
          </InfoBox>
          <InfoBox label="NID Front">
            <NIDPhoto
              applicationId={applicationId}
              photoField="meta.loaneeNidPhoto"
              statusField="meta.loaneeNidPhotoFrontStatus"
            />
            <UploadNID
              label="Upload NID Front"
              type={nidTypes.nidPhoto}
              side="FRONT"
              applicationId={applicationId}
              status={get(application, 'meta.loaneeNidPhotoFrontStatus')}
            />
          </InfoBox>
          <InfoBox label="NID Back">
            <NIDPhoto
              applicationId={applicationId}
              photoField="meta.loaneeNidPhotoBack"
              statusField="meta.loaneeNidPhotoBackStatus"
            />
            <UploadNID
              label="Upload NID Back"
              type={nidTypes.nidPhoto}
              side="BACK"
              applicationId={applicationId}
              status={get(application, 'meta.loaneeNidPhotoBackStatus')}
            />
          </InfoBox>
        </Flex>
      </Box>
    </Stack>
  )
}

export default LoaneeNID
