import React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
import UnileverCollectionPage from './unilever'

function CollectionSummaryPage() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/unilever`} component={UnileverCollectionPage} />
      <Redirect to={`${path}/unilever`} />
    </Switch>
  )
}

export default CollectionSummaryPage
