import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { api } from 'api'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormTextarea from 'components/Form/Textarea'
import { shopUpFinancialInstitutionId } from 'pages/admin/constants'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { APPLICATION_PAGINATION_PURGE } from 'store/applications'
import * as Yup from 'yup'

const validationSchema = Yup.object({
  note: Yup.string()
    .trim()
    .min(10, 'Minimum 10 characters.')
    .required('Required'),
  file: Yup.mixed().test('file', 'Required.', (value) => !!value?.length),
})

function DisburseLoan({ applicationId, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const dispatch = useDispatch()

  const toast = useToast()

  const history = useHistory()

  const form = useForm({ validationSchema })

  const handleSubmit = useCallback(
    async ({ note, file }) => {
      try {
        const formData = new FormData()
        formData.append('file', file[0])
        formData.append('note', note)

        await api(
          'POST /legacy/v3/eloan/partners/{partnerId}/applications/{applicationId}/offer/action/send-to-financed',
          {
            applicationId,
            partnerId: shopUpFinancialInstitutionId,
            body: formData,
          }
        )

        dispatch({ type: APPLICATION_PAGINATION_PURGE })

        history.push('/dash/finance/awaiting-disbursement')

        onClose()
      } catch (error) {
        handleAPIError(error, { toast })
      }
    },
    [applicationId, dispatch, toast, onClose, history]
  )

  return (
    <Authorize permissions="eloan:ApplicationForPartner:update">
      <Button onClick={onOpen} variantColor="cyan" px={10} {...props}>
        DISBURSE
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <Form form={form} onSubmit={handleSubmit}>
          <ModalContent p={6}>
            <ModalHeader>
              Are you sure, you want to disburse this loan?
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormTextarea
                name="note"
                label={
                  <Text mb={2} fontWeight={500}>
                    Write Note...
                  </Text>
                }
              />
              <FormInput
                name="file"
                type="file"
                border="none"
                paddingLeft={0}
                accept="image/*,application/pdf"
                label={
                  <Text my={4} mb={2} fontWeight={500}>
                    Upload Attachment
                  </Text>
                }
              />
            </ModalBody>
            <ModalFooter>
              <Button
                flex={1}
                variant="outline"
                variantColor="red"
                mr={3}
                onClick={onClose}
              >
                CANCEL
              </Button>
              <FormButton type="submit" flex={1} variantColor="green">
                YES, CONFIRM
              </FormButton>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    </Authorize>
  )
}

export default DisburseLoan
