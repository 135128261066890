import React, { useState, useCallback } from 'react'
import {
  SimpleGrid,
  Box,
  Text,
  Heading,
  Stack,
  Flex,
  Button,
  Divider,
  useToast,
} from '@chakra-ui/core'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import FormButton from 'components/Form/Button'
import * as Yup from 'yup'
import Modifier from './Modifier'
import { toISODate } from 'utils/cast'
import ModifierItem from './ModifierItem'
import { getDate } from '@eloan/shared'
import { useDispatch } from 'react-redux'
import { addLoanModifierConfiguration } from 'store/loan-modifier'
import { handleAPIError } from 'components/Form/helpers'
import { loanModifierId, loanModifierTitle } from '../constants'
import { PaymentHolidayTooltip, InterestWaiverTooltip } from './Tooltips'

const validationSchema = Yup.object({
  name: Yup.string().trim().required('Required.'),
})

function StartCongifuration({ onSubmit }) {
  const form = useForm({ validationSchema })

  return (
    <Form form={form} onSubmit={onSubmit}>
      <FormInput name="name" label="Enter Configuration Name" />
      <FormButton px={10} mt={4} type="submit" variantColor="cyan">
        START
      </FormButton>
    </Form>
  )
}

function ConfigurationDetails({ title, config = [], submitting, onSubmit }) {
  return (
    <Box>
      {!title ? (
        <>
          <Heading size="md">Configuration Details</Heading>
          <Text color="gray.500">No configuration added yet.</Text>
        </>
      ) : (
        <Box>
          <Flex justify="space-between" align="center">
            <Heading size="md">{title}</Heading>
            <Button
              size="sm"
              isDisabled={!config.length || submitting}
              isLoading={submitting}
              variantColor="green"
              px={6}
              onClick={onSubmit}
            >
              SAVE MODIFIER
            </Button>
          </Flex>
          {!config.length && (
            <Text color="gray.500">No configuration added yet.</Text>
          )}
          <Stack spacing={4} mt={6}>
            {config.map((item, index) => (
              <ModifierItem
                key={index}
                title={loanModifierTitle[item.id]}
                startDate={getDate(item.startDate)}
                endDate={getDate(item.endDate)}
              />
            ))}
          </Stack>
        </Box>
      )}
    </Box>
  )
}

function LoanModifierConfiguration() {
  const [title, setTitle] = useState()
  const [config, setConfig] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const addConfig = useCallback(
    ({ id, startDate, endDate }) => {
      setConfig([
        ...config,
        { id, startDate: toISODate(startDate), endDate: toISODate(endDate) },
      ])
    },
    [config]
  )

  const dispatch = useDispatch()
  const toast = useToast()

  const onSubmit = useCallback(async () => {
    try {
      setSubmitting(true)

      await dispatch(
        addLoanModifierConfiguration({ title, config, loanType: 'TERM' })
      )

      setTitle(null)
      setConfig([])
    } catch (error) {
      handleAPIError(error, { toast })
    }
    setSubmitting(false)
  }, [dispatch, toast, title, config])

  return (
    <SimpleGrid columns={2} gridColumnGap={6}>
      <Box>
        {!title ? (
          <StartCongifuration onSubmit={({ name }) => setTitle(name)} />
        ) : (
          <>
            <Modifier
              rightHeader={<PaymentHolidayTooltip />}
              onSubmit={({ date }) =>
                addConfig({
                  id: loanModifierId.TERM_LOAN_PAYMENT_HOLIDAY,
                  startDate: date.start,
                  endDate: date.end,
                })
              }
              title={
                loanModifierTitle[loanModifierId.TERM_LOAN_PAYMENT_HOLIDAY]
              }
            />
            <Divider mt={4} />
            <Modifier
              rightHeader={<InterestWaiverTooltip />}
              onSubmit={({ date }) =>
                addConfig({
                  id: loanModifierId.TERM_LOAN_INTEREST_WAIVER,
                  startDate: date.start,
                  endDate: date.end,
                })
              }
              title={
                loanModifierTitle[loanModifierId.TERM_LOAN_INTEREST_WAIVER]
              }
            />
          </>
        )}
      </Box>
      <Box>
        <ConfigurationDetails
          onSubmit={onSubmit}
          title={title}
          config={config}
          submitting={submitting}
        />
      </Box>
    </SimpleGrid>
  )
}

export default LoanModifierConfiguration
