import React, { useMemo } from 'react'
import { DataTable } from '@eloan/shared'
import { useTable } from 'react-table'
import { useLoanModifierCongfigurations } from 'store/loan-modifier/hooks'
import { Button, Flex } from '@chakra-ui/core'
import { NavLink } from 'react-router-dom'

const getColumns = () => [
  { Header: 'Modifier Name', accessor: 'title' },
  { Header: 'Loans Added', accessor: '' },
  {
    Header: '',
    id: 'action_button',
    accessor: ({ id }) => (
      <Flex justify="flex-end">
        <Button
          as={NavLink}
          to={`/dash/admin/loan-modifier/single/${id}`}
          variantColor="green"
          size="sm"
          px={6}
        >
          ADD & VIEW LOAN
        </Button>
      </Flex>
    ),
  },
]

function LoanModifierConfigurationList() {
  const loanModifiers = useLoanModifierCongfigurations()

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(
    () => loanModifiers.allIds.map((id) => loanModifiers.byId[id]),
    [loanModifiers]
  )

  const table = useTable({ columns, data })

  return <DataTable {...table} />
}

export default LoanModifierConfigurationList
