import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import React, { useMemo } from 'react'
import { useBankBranches, useBanks } from 'store/banks/hooks'

function RetailerBankInfo({ bankInfo }) {
  const banks = useBanks()
  const branches = useBankBranches(get(bankInfo, 'bankId'))

  const bank = useMemo(() => get(banks.byId, get(bankInfo, 'bankId')), [
    bankInfo,
    banks.byId,
  ])
  const branch = useMemo(() => get(branches.byId, get(bankInfo, 'branchId')), [
    bankInfo,
    branches.byId,
  ])

  return (
    <>
      <InfoBox label="Bank Name" content={get(bank, 'bankName')} />
      <InfoBox label="Branch Name" content={get(branch, 'branchName')} />
      <InfoBox label="Routing Number" content={get(branch, 'routingNumber')} />
      <InfoBox
        label="Account Number"
        content={get(bankInfo, 'accountNumber')}
      />
    </>
  )
}

export default RetailerBankInfo
