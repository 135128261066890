import React from 'react'
import { Text, Stack } from '@chakra-ui/core'

export function InfoCard({
  label,
  value,
  isInline = false,
  labelColor = 'gray.600',
  valueColor = '',
  valueFontSize = 'lg',
  labelFontSize,
  spacing = 1,
  mt = 0,
  allowed = true,
  ...props
}) {
  return (
    <Stack mt={mt} spacing={spacing} isInline={isInline} {...props}>
      <Text color={labelColor} fontSize={labelFontSize}>
        {label}
      </Text>
      <Text color={valueColor} fontSize={valueFontSize}>
        {allowed ? value : '***'}
      </Text>
    </Stack>
  )
}
