import React from 'react'
import { animated, useSpring } from 'react-spring'
import { LoadingDots } from './LoadingDots'

const defaultFormatter = (value) => value

export function CountUpNumber({
  loading,
  number,
  prefix,
  suffix,
  formatter = defaultFormatter,
}) {
  const spring = useSpring({
    to: { number: number || 0 },
    from: { number: 0 },
  })

  return loading ? (
    <LoadingDots count={5} period={2500} />
  ) : (
    <>
      {prefix}
      <animated.span>{spring.number.interpolate(formatter)}</animated.span>
      {suffix}
    </>
  )
}
