import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import { FormDatePicker } from 'components/Form/DatePicker'
import ErrorMessage from 'components/Form/ErrorMessage'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import FormSwitch from 'components/Form/Switch'
import { DateTime } from 'luxon'
import { formOptions } from 'pages/admin/constants/options'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { createConfig } from 'store/admin'
import { getBigInstance } from 'utils/big-instance'
import { toDate } from 'utils/cast'
import * as Yup from 'yup'

const getDefaultValues = () => ({
  tenureUnit: 'MONTH',
  minTenure: 6,
  maxTenure: 10,
  installmentPeriodUnit: 'MONTH',
  installmentPeriod: 1,
  interestCalculationMethod: 'DECLINING_BALANCE',
  interestTimePeriodCountCriteria: 'ACTUAL_DAYS_COUNT',
  interestType: 'SIMPLE',
  daysInYear: 360,
  interestChargeFrequency: 'DAY',
  interestRate: 10,
  shouldPartialPaymentAffectRunningEmi: true,
  repaymentStrategy: {
    one: 'PENALTY',
    two: 'INTEREST',
    three: 'PRINCIPAL',
  },
  penaltyCalculationMethod: 'FLAT_FEE_WITH_INTEREST',
  penaltyInterestType: 'SIMPLE',
  penaltyInterestRate: 10,
  penaltyFlatFee: 10,
  penaltyInterestChargeFrequency: 'DAY',
  penaltyGracePeriodUnit: 'DAY',
  penaltyGracePeriod: 1,
  penaltyToleranceType: 'FIXED',
  penaltyToleranceAmount: 100,
  minPeriodBeforeAccountSettlementUnit: 'DAY',
  minPeriodBeforeAccountSettlement: 10,
  repaymentDueDateOffsetUnit: 'DAY',
  repaymentDueDateOffset: 7,
  adjustPenaltyInLastEmi: true,
})

const getValidationSchema = () =>
  Yup.object({
    minTenure: Yup.number()
      .integer()
      .typeError('Must be number.')
      .moreThan(0, 'Must be more than 0')
      .required('Required.'),
    maxTenure: Yup.number()
      .integer()
      .typeError('Must be number.')
      .moreThan(0, 'Must be more than 0')
      .required('Required.'),
    installmentPeriod: Yup.number()
      .integer()
      .moreThan(0, 'Must be more than 0')
      .typeError('Must be number.')
      .required('Required.'),
    installmentPeriodUnit: Yup.string().oneOf(
      [Yup.ref('tenureUnit')],
      'Installment Period Unit and Tenure Unit must be same.'
    ),
    interestRate: Yup.number()
      .typeError('Must be number.')
      .moreThan(0, 'Must be more than 0')
      .max(60, 'Must be within 60')
      .required('Required.'),
    penaltyInterestRate: Yup.number()
      .typeError('Must be number.')
      .max(60, 'Must be within 60')
      .required('Required.'),
    penaltyFlatFee: Yup.number()
      .min(0, 'Minimum 0')
      .typeError('Must be number.')
      .required('Required.'),
    penaltyGracePeriod: Yup.number()
      .min(0, 'Minimum 0')
      .typeError('Must be number.')
      .required('Required.'),
    penaltyToleranceAmount: Yup.number()
      .typeError('Must be number.')
      .required('Required.'),
    repaymentStrategy: Yup.object().test(
      'are-unique',
      'Must be set of different stragies.',
      (obj) => {
        const values = Object.values(obj)
        return values.length === [...new Set(values)].length
      }
    ),
    minPeriodBeforeAccountSettlement: Yup.number()
      .typeError('Must be number.')
      .required('Required.'),
    repaymentDueDateOffset: Yup.number()
      .typeError('Must be number.')
      .required('Required.'),
  })

function ConfigurationForm({ onSubmit }) {
  const defaultValues = useMemo(() => getDefaultValues(), [])

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({ defaultValues, validationSchema })

  const onFormSubmit = useCallback(
    (values) => {
      const {
        repaymentStrategy,
        interestRate,
        penaltyInterestRate,
        dayEndAt,
        ...rest
      } = values

      const big = getBigInstance()

      onSubmit({
        ...rest,
        interestRate: big(interestRate).div(100).toFixed(4),
        penaltyInterestRate: big(penaltyInterestRate).div(100).toFixed(4),
        repaymentStrategy: Object.values(repaymentStrategy),
        dayEndAt: dayEndAt
          ? DateTime.fromJSDate(toDate(dayEndAt)).toFormat('HH:mm:ssZZ')
          : undefined,
      })
    },
    [onSubmit]
  )

  return (
    <Form form={form} mb={10}>
      <Box mb={10}>
        <Heading size="md" mb={4} color="gray.500">
          Configuration
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <Box width="100%">
            <FormSelect
              label="Select tenure unit"
              name="tenureUnit"
              options={formOptions.dayUnits}
            />
          </Box>
          <Box width="100%">
            <FormInput label="Min Tenure" name="minTenure" />
          </Box>
          <Box width="100%">
            <FormInput label="Max Tenure" name="maxTenure" />
          </Box>
        </SimpleGrid>
      </Box>

      <SimpleGrid columns={3} gridGap={4} mb={10}>
        <Box width="100%">
          <FormSelect
            label="Installment period unit"
            name="installmentPeriodUnit"
            options={formOptions.dayUnits}
          />
        </Box>
        <Box width="100%">
          <FormInput
            label="Enter installment period"
            name="installmentPeriod"
          />
        </Box>
      </SimpleGrid>

      <Box mb={10}>
        <Heading size="sm" mb={4} color="gray.500">
          Interest settings
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <Box width="100%">
            <FormSelect
              label="Interest calculation method"
              name="interestCalculationMethod"
              options={formOptions.interestCalculationMethod}
            />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Interest time period count criteria"
              name="interestTimePeriodCountCriteria"
              options={formOptions.interestTimePeriodCountCriteria}
            />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Interest type"
              name="interestType"
              options={formOptions.interestType}
            />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Days in Year"
              name="daysInYear"
              options={formOptions.daysInYear}
            />
          </Box>
          <Box width="100%">
            <FormInput label="Interest rate" name="interestRate" />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Interest charge frequency"
              name="interestChargeFrequency"
              options={formOptions.dayUnits}
            />
          </Box>
        </SimpleGrid>
      </Box>

      <Box mb={10}>
        <Heading size="sm" mb={4} color="gray.500">
          Should partial payment affect running EMI
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <Box width="100%">
            <FormSelect
              name="shouldPartialPaymentAffectRunningEmi"
              options={formOptions.booleanOptions}
            />
          </Box>
        </SimpleGrid>
      </Box>

      <Box mb={10}>
        <Heading size="sm" mb={4} color="gray.500">
          Repayment strategy (select order of strategy)
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <Box width="100%">
            <FormSelect
              label="Repayment strategy 1"
              name="repaymentStrategy.one"
              options={formOptions.repaymentStrategy}
            />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Repayment strategy 2"
              name="repaymentStrategy.two"
              options={formOptions.repaymentStrategy}
            />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Repayment strategy 3"
              name="repaymentStrategy.three"
              options={formOptions.repaymentStrategy}
            />
          </Box>
        </SimpleGrid>
        <ErrorMessage mt={2} name="repaymentStrategy" as={Box} />
      </Box>

      <Box mb={10}>
        <Heading size="sm" mb={4} color="gray.500">
          Penalty and tolerance
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <Box width="100%">
            <FormSelect
              label="Penalty calculation method"
              name="penaltyCalculationMethod"
              options={formOptions.penaltyCalculationMethod}
            />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Penalty interest type"
              name="penaltyInterestType"
              options={formOptions.interestType}
            />
          </Box>
          <Box width="100%">
            <FormInput
              label="Penalty interest rate"
              name="penaltyInterestRate"
            />
          </Box>
          <Box width="100%">
            <FormInput label="Penalty flat fee" name="penaltyFlatFee" />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Penalty interest charge frequency"
              name="penaltyInterestChargeFrequency"
              options={formOptions.dayUnits}
            />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Penalty grace period unit"
              name="penaltyGracePeriodUnit"
              options={formOptions.dayUnits}
            />
          </Box>
          <Box width="100%">
            <FormInput label="Penalty grace period" name="penaltyGracePeriod" />
          </Box>
          <Box width="100%">
            <FormSelect
              label="Tolerance amount type"
              name="penaltyToleranceType"
              options={formOptions.toleranceAmountType}
            />
          </Box>
          <Box width="100%">
            <FormInput
              label="Tolerance amount value"
              name="penaltyToleranceAmount"
            />
          </Box>
        </SimpleGrid>
      </Box>

      <SimpleGrid columns={3} gridGap={4} mb={6}>
        <Box width="100%">
          <FormSelect
            label="Min period before account
              settlement unit"
            name="minPeriodBeforeAccountSettlementUnit"
            options={formOptions.dayUnits}
          />
        </Box>
        <Box width="100%">
          <FormInput
            label="Min period before account
              settlement"
            name="minPeriodBeforeAccountSettlement"
          />
        </Box>
        <Box width="100%">
          <FormSelect
            label="Repayment due date offset unit"
            name="repaymentDueDateOffsetUnit"
            options={formOptions.dayUnits}
          />
        </Box>
        <Box width="100%">
          <FormInput
            label="Repayment due date offset"
            name="repaymentDueDateOffset"
          />
        </Box>
        <Box>
          <FormDatePicker
            label="Day End At"
            name="dayEndAt"
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="hh:mm:ss aa"
          />
        </Box>
        <Box>
          <FormSwitch
            size="lg"
            label="Adjust Penalty In Last EMI"
            name="adjustPenaltyInLastEmi"
          />
        </Box>
      </SimpleGrid>

      <FormButton
        type="submit"
        variantColor="cyan"
        px={10}
        onClick={(event) => {
          event.stopPropagation()
          form.handleSubmit(onFormSubmit)(event)
        }}
      >
        SUBMIT
      </FormButton>
    </Form>
  )
}

function CreateConfiguration({ type, financialInstitutionId }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()

  useEffect(() => {
    setData(null)
  }, [isOpen])

  const onConfirm = useCallback(async () => {
    setLoading(true)
    try {
      await dispatch(createConfig(financialInstitutionId, data))
      onClose()
    } catch (error) {
      handleAPIError(error, { toast })
    }
    setLoading(false)
  }, [dispatch, toast, data, onClose, financialInstitutionId])

  return (
    <Authorize permissions="eloan:TermLoanConfigs:write">
      <Button
        onClick={onOpen}
        variant="link"
        variantColor="cyan"
        size="sm"
        leftIcon="small-add"
        disabled={!financialInstitutionId}
      >
        CREATE CONFIGURATION
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px" bg="gray.50">
            {type === 'fi' ? 'FI' : 'ShopUP'} configuration: New configuration
            creation
          </DrawerHeader>
          <DrawerBody overflowY="auto">
            {!data ? (
              <ConfigurationForm onSubmit={setData} />
            ) : (
              <Box
                p={10}
                m={10}
                borderWidth="1px"
                borderColor="gray.100"
                boxShadow="sm"
              >
                <Heading mb={4} size="md">
                  CONFIRM CONFIG CREATION
                </Heading>
                <Text mb={8}>
                  Are you sure that you want to create this config?
                </Text>
                <Stack isInline width="100%" spacing={4}>
                  <Button
                    flex={1}
                    variant="outline"
                    variantColor="red"
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                  <Button
                    flex={1}
                    variantColor="green"
                    onClick={onConfirm}
                    isLoading={loading}
                  >
                    CONFIRM
                  </Button>
                </Stack>
              </Box>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Authorize>
  )
}

export default CreateConfiguration
