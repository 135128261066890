import { Box, Stack, ButtonGroup, IconButton, Text } from '@chakra-ui/core'
import React, { useCallback, useMemo } from 'react'
import Form from 'components/Form/Form'
import FormButton from 'components/Form/Button'
import { useForm } from 'react-hook-form'
import { FormDateRangePicker } from 'components/Form/DatePicker'
import { toISODate } from 'utils/cast'

function PartnerBeftnListFilter({ onSubmit }) {
  const defaultValues = useMemo(
    () => ({
      date: {
        start: new Date(),
        end: new Date(),
      },
    }),
    []
  )

  const form = useForm({ defaultValues })

  const onFormSubmit = useCallback(
    ({ date }) => {
      const filterObject = {}

      const { start, end } = date

      if (start || end) {
        filterObject.min = toISODate(start || end)
        filterObject.max = toISODate(end || start)
      }

      onSubmit({ ...filterObject })
    },
    [onSubmit]
  )

  const formReset = form.reset

  const onReset = useCallback(() => {
    formReset()
    onSubmit({})
  }, [formReset, onSubmit])

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack isInline spacing={4} marginBottom={6} align="flex-end">
        <Box zIndex={2}>
          <FormDateRangePicker
            label={
              <Text fontSize="xs" color="gray.500">
                Select start and end date
              </Text>
            }
            name="date"
          />
        </Box>
        <Box>
          <ButtonGroup spacing={2}>
            <IconButton
              type="button"
              variantColor="cyan"
              variant="outline"
              icon="small-close"
              onClick={onReset}
            />
            <FormButton type="submit" minWidth="6rem" variantColor="cyan">
              Filter
            </FormButton>
          </ButtonGroup>
        </Box>
      </Stack>
    </Form>
  )
}

export default PartnerBeftnListFilter
