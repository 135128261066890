export const getLinkWithPageQuery = (location, page) => {
  const path = location.pathname
  const query = new URLSearchParams(location.search)

  if (page) {
    query.set('page', page)
  } else {
    query.delete('page')
  }

  return `${path}?${query.toString()}`
}
