export const getPACallStatusOptions = () => ({
  called: 'Called',
  unreachable: 'Unreachable',
  call_later: 'Call Later',
  postponed: 'Postponed',
})

export const getGenderOptions = () => ({
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Others',
})

export const getMaritalOptions = () => ({
  married: 'Married',
  unmarried: 'Unmarried',
})

export const getProfessionOptions = () => ({
  business: 'Business',
  government_employee: 'Government Employee',
  service_holder: 'Service Holder',
})

export const getOwnershipOptions = () => ({
  owned: 'Owned',
  rented: 'Rented',
})

export const getEducationOptions = () => ({
  no_formal_education: 'No Formal Education',
  psc: 'PSC',
  jsc: 'JSC',
  hsc: 'HSC',
  ssc: 'SSC',
  undergraduate: 'Undergraduate',
  postgraduate: 'Postgraduate',
  other: 'Other',
})

export const getRelationshipOptions = () => ({
  'PARENT-MALE': 'Father',
  'PARENT-FEMALE': 'Mother',
  'SPOUSE-MALE': 'Husband',
  'SPOUSE-FEMALE': 'Wife',
  'OFFSPRING-MALE': 'Son',
  'OFFSPRING-FEMALE': 'Daughter',
  'SIBLING-MALE': 'Brother',
  'SIBLING-FEMALE': 'Sister',
  OTHER: 'Other',
})
