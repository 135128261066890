import React, { useMemo } from 'react'
import { Accordion, Text, Image, Link } from '@chakra-ui/core'
import CustomAccordionItem from './CustomAccordionItem'
import { get } from 'lodash-es'
import { getPrivateImageSrc } from 'utils/getPrivateImageSrc'
import InfoCollection from './InfoCollection'
import { getDate, getPrivateSrc, displayBDT } from '@eloan/shared'
import { useApplicationEntities } from 'store/applications/hooks'
import {
  TRADE_LICENSE_KEYS,
  getDocDataByKey,
  LOANEE_BANK_STATEMENT_KEYS,
} from 'pages/eloan-applications/utils/getDocImage'

const loaneePersonalInfoDataMapper = [
  {
    label: 'Name',
    key: 'meta.loaneeName',
  },
  {
    label: 'Age',
    key: 'meta.loaneeAge',
  },
  {
    label: 'Profession',
    key: 'meta.loaneeProfession',
  },
  {
    label: 'Business Type',
    key: 'meta.businessType',
  },
  {
    label: "Father's Name",
    key: 'meta.fatherName',
  },
  {
    label: "Mother's Name",
    key: 'meta.motherName',
  },
  {
    label: 'Spouse Name',
    key: 'meta.spouseName',
  },
  {
    label: 'Passport Image',
    getValue: ({ meta }) => (
      <Link href={getPrivateImageSrc(get(meta, 'loaneePhoto'))} isExternal>
        <Image
          size="50px"
          objectFit="cover"
          src={getPrivateImageSrc(get(meta, 'loaneePhoto'))}
        />
      </Link>
    ),
  },
  {
    label: 'Phone',
    key: 'meta.loaneePhoneNumber',
  },
]

const loaneeNIDDataMapper = [
  {
    label: 'NID Number',
    key: 'meta.loaneeNid',
  },
  {
    label: 'NID Name',
    key: 'meta.loaneeNidName',
  },
  {
    label: 'NID Date of Birth',
    getValue: ({ meta }) => getDate(get(meta, 'loaneeNidDob')),
  },
  {
    label: 'Porichoy Status',
    getValue: ({ meta }) =>
      get(meta, 'loaneeNidPorichoyValid') && (
        <Text color="green.500">PORICHOY VALID</Text>
      ),
  },
  {
    label: 'NID Front Image',
    getValue: ({ meta }) => (
      <Link href={getPrivateImageSrc(get(meta, 'loaneeNidPhoto'))} isExternal>
        <Image
          size="100px"
          objectFit="cover"
          src={getPrivateImageSrc(get(meta, 'loaneeNidPhoto'))}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
      </Link>
    ),
  },
  {
    label: 'NID Back Image',
    getValue: ({ meta }) => (
      <Link
        href={getPrivateImageSrc(get(meta, 'loaneeNidPhotoBack'))}
        isExternal
      >
        <Image
          size="100px"
          objectFit="cover"
          src={getPrivateImageSrc(get(meta, 'loaneeNidPhotoBack'))}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
      </Link>
    ),
  },
]

const loaneeAddressDataMapper = [
  {
    label: 'Premise Ownership Status',
    key: 'meta.loaneePremiseOwnershipStatus',
  },
  {
    label: 'Present Address',
    key: 'meta.loaneePresentAddress',
  },
  {
    label: 'Permanent Address',
    key: 'meta.loaneePermanentAddress',
  },
]

const loaneeBusinessAddressDataMapper = [
  {
    label: 'Business Address',
    key: 'meta.loaneeBusinessAddress',
  },
]

const loaneeTradeLicenseDataMapper = [
  {
    label: 'Proprietor Name',
    key: 'meta.tradeLicenceProprietorName',
  },
  {
    label: 'Type of Business',
    key: 'meta.tradeLicenceBusinessType',
  },
  {
    label: 'Business Name',
    key: 'meta.tradeLicenceBusinessName',
  },
  {
    label: 'Licence No.',
    key: 'meta.tradeLicenceNumber',
  },
  {
    label: 'Issuing Authority',
    key: 'meta.tradeLicenceIssuingAuthority',
  },
  {
    label: 'License Issue Date',
    getValue: ({ meta }) => getDate(get(meta, 'tradeLicenceIssuedAt')),
  },
  {
    label: 'License Expiry Date',
    getValue: ({ meta }) => getDate(get(meta, 'tradeLicenceExpiresAt')),
  },
  {
    label: 'Trade License',
    getValue: ({ docs }) =>
      getDocDataByKey(docs, TRADE_LICENSE_KEYS) && (
        <Link
          href={getPrivateImageSrc(getDocDataByKey(docs, TRADE_LICENSE_KEYS))}
          color="cyan.500"
          isExternal
        >
          View
        </Link>
      ),
  },
]

const guarantorNIDDataMapper = [
  {
    label: 'NID Number',
    key: 'meta.guarantorNid',
  },
  {
    label: 'NID Name',
    key: 'meta.guarantorNidName',
  },
  {
    label: 'NID Date of Birth',
    getValue: ({ meta }) => getDate(get(meta, 'guarantorNidDob')),
  },
  {
    label: 'Porichoy Status',
    getValue: ({ meta }) =>
      get(meta, 'guarantorNidPorichoyValid') && (
        <Text color="green.500">PORICHOY VALID</Text>
      ),
  },
  {
    label: 'Phone Number',
    key: 'meta.guarantorPhoneNumber',
  },
  {
    label: 'NID Front Image',
    getValue: ({ meta }) => (
      <Link href={getPrivateImageSrc(get(meta, 'guarantorPhoto'))} isExternal>
        <Image
          size="100px"
          objectFit="cover"
          src={getPrivateImageSrc(get(meta, 'guarantorNidPhoto'))}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
      </Link>
    ),
  },
  {
    label: 'NID Back Image',
    getValue: ({ meta }) => (
      <Link
        href={getPrivateImageSrc(get(meta, 'guarantorNidPhotoBack'))}
        isExternal
      >
        <Image
          size="100px"
          objectFit="cover"
          src={getPrivateImageSrc(get(meta, 'guarantorNidPhotoBack'))}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
      </Link>
    ),
  },
]

const bankInfoDataMapper = [
  {
    label: 'Bank Name',
    key: 'bankDetails.bankName',
  },
  {
    label: 'Branch Name',
    key: 'bankDetails.bankBranchName',
  },
  {
    label: 'Account Name',
    key: 'bankDetails.bankAccountName',
  },
  {
    label: 'Account Number',
    key: 'bankDetails.bankAccountNumber',
  },
  {
    label: 'Routing Number',
    key: 'bankDetails.routingNumber',
  },
  {
    label: 'Bkash Number',
    key: 'bankDetails.bkashAccountNumber',
  },
  {
    label: 'Statement',
    getValue: ({ docs }) =>
      getDocDataByKey(docs, LOANEE_BANK_STATEMENT_KEYS) && (
        <Link
          color="cyan.500"
          href={getPrivateSrc(
            getDocDataByKey(docs, LOANEE_BANK_STATEMENT_KEYS)
          )}
          isExternal
        >
          Statement 1
        </Link>
      ),
  },
]

const monthlyIncomeDataMapper = [
  {
    label: 'Avg Monthly Sale',
    getValue: ({ meta }) => displayBDT(get(meta, 'avgMonthlySale')),
  },
  {
    label: 'Avg Cost of Goods Sold',
    getValue: ({ meta }) => displayBDT(get(meta, 'monthlyCostOfGoodsSold')),
  },
  {
    label: 'Avg Monthly Profit Margin (c = a - (a * 100) / b)',
    getValue: ({ meta }) =>
      get(meta, 'monthlyCostOfGoodsSold') &&
      displayBDT(
        get(meta, 'avgMonthlySale') -
          (get(meta, 'avgMonthlySale') * 100) /
            get(meta, 'monthlyCostOfGoodsSold')
      ),
  },
  {
    label: 'Highest Sale in a Month',
    getValue: ({ meta }) => displayBDT(get(meta, 'highestSaleInMonth')),
  },
  {
    label: 'Lowest Sale in a Month',
    getValue: ({ meta }) => displayBDT(get(meta, 'lowestSaleInMonth')),
  },
  {
    label: 'EMI of Loan/Debt (if any)',
    getValue: ({ meta }) => displayBDT(get(meta, 'loan')),
  },
]

function ApplicationInfo({ application }) {
  const docsMeta = useApplicationEntities({
    applicationId: get(application, 'id'),
    partnerId: get(application, 'offer.loanPartner'),
  })

  const applicationWithDocs = useMemo(
    () => ({ ...application, docs: docsMeta }),
    [application, docsMeta]
  )

  return (
    <Accordion defaultIndex={[0]} allowMultiple allowToggle>
      <CustomAccordionItem title="Loanee Personal Information">
        <InfoCollection
          application={application}
          dataMapper={loaneePersonalInfoDataMapper}
        />
        <InfoCollection
          title="NID/Smart Card"
          grid={4}
          application={application}
          dataMapper={loaneeNIDDataMapper}
        />
      </CustomAccordionItem>
      <CustomAccordionItem title="Loanee Address">
        <InfoCollection
          grid={3}
          application={application}
          dataMapper={loaneeAddressDataMapper}
        />
      </CustomAccordionItem>
      <CustomAccordionItem title="Loanee Business Info">
        <InfoCollection
          grid={1}
          application={application}
          dataMapper={loaneeBusinessAddressDataMapper}
        />
        <InfoCollection
          title="Trade Licence"
          application={applicationWithDocs}
          dataMapper={loaneeTradeLicenseDataMapper}
        />
      </CustomAccordionItem>
      <CustomAccordionItem title="Guarantor NID/Smart Card">
        <InfoCollection
          application={application}
          dataMapper={guarantorNIDDataMapper}
        />
      </CustomAccordionItem>
      <CustomAccordionItem title="Bank Account">
        <InfoCollection
          application={applicationWithDocs}
          dataMapper={bankInfoDataMapper}
        />
      </CustomAccordionItem>
      <CustomAccordionItem title="Monthly Income in Last 12 Months">
        <InfoCollection
          application={application}
          dataMapper={monthlyIncomeDataMapper}
        />
      </CustomAccordionItem>
    </Accordion>
  )
}

export default ApplicationInfo
