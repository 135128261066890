import { keyBy, map, union } from 'lodash-es'
import { emptyArray, emptyObject } from 'utils/defaults'
import { COLLECTION_LIST_LOADED } from 'store/collections'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
}
const idKey = 'id'

const collectionsReducer = (state = initialState, { type, data, ids }) => {
  switch (type) {
    case COLLECTION_LIST_LOADED:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        loading: false,
      }
    default:
      return state
  }
}

export default collectionsReducer
