import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ApplicationList from './List'
import ApplicationView from './View'

function NobodarApplicationsPage() {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/dash/nobodar-applications"
          component={ApplicationList}
        />
        <Route
          path="/dash/nobodar-applications/:applicationId/:tabId?"
          component={ApplicationView}
        />
      </Switch>
    </>
  )
}

export default NobodarApplicationsPage
