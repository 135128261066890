import FormSelect from 'components/Form/Select'
import React, { useMemo } from 'react'
import { Box } from 'reflexbox'
import { cibApprovalStatusDisplayText } from 'utils/meta'

function ApplicationCibApprovalFilter({ ...props }) {
  const options = useMemo(
    () => ({
      '': 'Any',
      ...cibApprovalStatusDisplayText,
    }),
    []
  )

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="metaExtra.cibApprovalStatus"
        label={`Filter by CIB Status`}
        options={options}
      />
    </Box>
  )
}

export default ApplicationCibApprovalFilter
