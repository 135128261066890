import { Stack } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAreas } from 'store/areas/hooks'
import GuarantorInfo from './sections/GuarantorInfo'
import LegacySection from './sections/Legacy'
import LoaneeInfo from './sections/LoaneeInfo'
import LoanInfo from './sections/LoanInfo'
import TPGuarantorInfo from './sections/TPGuarantorInfo'
import CibInfo from './sections/CibInfo'
import OtherInfo from './sections/OtherInfo'

function LoanDetails({ applicationId }) {
  const areas = useAreas()

  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const shopArea = useMemo(() => {
    return areas.byId[get(application, 'meta.shopAreaId', null)]
  }, [application, areas.byId])

  return (
    <Stack spacing={6} width="100%">
      <LoanInfo application={application} />

      <LoaneeInfo application={application} shopArea={shopArea} />

      <GuarantorInfo title="Family Guarantor Info" application={application} />

      <TPGuarantorInfo
        title="Business Guarantor Info"
        application={application}
      />

      <CibInfo application={application} />

      <OtherInfo application={application} />

      <LegacySection application={application} />
    </Stack>
  )
}

export default LoanDetails
