import { ButtonGroup, useToast } from '@chakra-ui/core'
import { Form, FormButton, FormInput, handleAPIError } from '@eloan/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { initLoginWithPhone, loginWithPhone } from 'store/currentUser'
import { bangladeshMobileNumberPattern } from 'utils/regex'
import * as Yup from 'yup'

function LoginWithPhoneOTP({ phone }) {
  const toast = useToast()

  const defaultValues = useMemo(() => ({ code: '' }), [])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        code: Yup.string()
          .matches(/^\d+$/, 'expected format: XXXX')
          .length(4)
          .required(`required`),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ code }) => {
      try {
        await dispatch(loginWithPhone({ phone, code }))
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, phone, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit} spacing={2}>
      <FormInput name="code" label={`OTP Code`} />

      <ButtonGroup display="flex" mt={2}>
        <FormButton type="submit" variantColor="blue" flexGrow="1">
          Login
        </FormButton>
      </ButtonGroup>
    </Form>
  )
}

function RequestLoginOTP({ setPhone }) {
  const toast = useToast()

  const defaultValues = useMemo(() => ({ phone: '' }), [])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        phone: Yup.string()
          .matches(bangladeshMobileNumberPattern, 'invalid phone number')
          .required(`required`),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ phone }) => {
      try {
        const formattedPhone = `880${phone.slice(-10)}`
        await dispatch(initLoginWithPhone({ phone: formattedPhone }))
        setPhone(formattedPhone)
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, setPhone, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit} spacing={2}>
      <FormInput name="phone" label={`Phone`} />

      <ButtonGroup display="flex" mt={2}>
        <FormButton type="submit" variantColor="blue" flexGrow="1">
          Request OTP Code
        </FormButton>
      </ButtonGroup>
    </Form>
  )
}

function PhoneOTPLogin() {
  const [phone, setPhone] = useState(null)

  return phone ? (
    <LoginWithPhoneOTP phone={phone} />
  ) : (
    <RequestLoginOTP setPhone={setPhone} />
  )
}

export default PhoneOTPLogin
