import { combineReducers } from 'redux'
import { CURRENT_USER_REMOVE } from 'store/currentUser'
import applications from './applications/reducer'
import areas from './areas/reducer'
import banks from './banks/reducer'
import businessMediums from './businessMediums/reducer'
import cca from './cca/reducer'
import user from './currentUser/reducer'
import distributors from './distributors/reducer'
import docMeta from './docMeta/reducer'
import docRequirements from './docRequirements/reducer'
import errorBoundary from './errorBoundary/reducer'
import notes from './notes/reducer'
import paCallLogs from './paCallLogs/reducer'
import pagination from './pagination/reducer'
import partners from './partners/reducer'
import retailers from './retailers/reducer'
import settlements from './settlements/reducer'
import users from './users/reducer'
import nobodar from './nobodar/reducer'
import collections from './collections/reducer'
import admin from './admin/reducer'
import beftn from './beftn/reducer'
import repaymentFollowup from './repaymentFollowup/reducer'
import accounts from './accounts/reducer'
import loanModifier from './loan-modifier/reducer'
import redxShopOffers from './redx-shop-offers/reducer'
import appBkashPayments from './app-bkash-payments/reducer'

const reducer = combineReducers({
  admin,
  applications,
  areas,
  banks,
  businessMediums,
  user,
  distributors,
  docMeta,
  docRequirements,
  errorBoundary,
  notes,
  paCallLogs,
  pagination,
  partners,
  retailers,
  users,
  cca,
  settlements,
  nobodar,
  collections,
  beftn,
  repaymentFollowup,
  accounts,
  loanModifier,
  redxShopOffers,
  appBkashPayments,
})

export default (state, action) => {
  if (action.type === CURRENT_USER_REMOVE) {
    state = { errorBoundary: state.errorBoundary }
  }

  return reducer(state, action)
}
