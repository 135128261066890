import React, { useMemo, memo, useState } from 'react'
import { Box, Text, SimpleGrid } from '@chakra-ui/core'
import { displayBDT, getDate, toRounded2F2PNumber } from '@eloan/shared'
import { usePartnerRepayments } from 'store/admin/hooks'
import { get, map } from 'lodash-es'
import ListTable from 'components/ListTable/ListTable'
import InfoBox from 'components/Box/InfoBox'
import { CountUpNumber } from '@eloan/shared/components/Animated'
import { formOptions } from 'pages/admin/constants/options'
import { preparePartnerRepayment } from 'pages/eloan-applications/utils/repayments'
import { useTable } from 'react-table'
import RepaymentFilter from './RepaymentFilter'
import { DateTime } from 'luxon'

const RepaymentSummary = memo(({ summary }) => {
  const isLoading = useMemo(() => typeof summary === 'undefined', [summary])

  return (
    <SimpleGrid columns={9} spacing={2} mb={4}>
      <InfoBox color="gray.500" label="Disbursed Amount">
        <Text fontSize="md" color="black">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'disbursedAmount')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Loanee Outstanding Amount">
        <Text fontSize="md" color="red.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'loaneeOutstandingAmount')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Total Collection">
        <Text fontSize="md" color="green.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'totalCollection')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Total Paid to Partner">
        <Text fontSize="md" color="green.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'totalPaidToPartner')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Cash in Hand">
        <Text fontWeight={600} fontSize="md" color="black">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'cashInHand')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="ShopUp Tenure">
        <Text fontWeight={600} fontSize="md" color="black">
          {get(summary, 'tenure.value')}{' '}
          {get(formOptions.dayUnits, get(summary, 'tenure.unit'))}
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Partner Outsanding Amount">
        <Text fontSize="md" color="red.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'partnerOutstandingAmount')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Partner Name">
        <Text fontSize="md" color="black">
          {get(summary, 'fiName')}
        </Text>
      </InfoBox>
    </SimpleGrid>
  )
})

function displayBDTAsFloat(value) {
  return value !== undefined && displayBDT(parseFloat(value))
}

const getColumns = () => [
  { Header: 'SL', Cell: ({ row }) => row.index + 1 },
  {
    Header: 'EMI Duration',
    accessor: ({ startDate, endDate }) =>
      startDate &&
      `${getDate(
        DateTime.fromISO(startDate).plus({ day: 1 }).toISO()
      )} - ${getDate(endDate)}`,
  },
  {
    Header: 'Repayment Date',
    accessor: ({ offsetedEndDate }) => getDate(offsetedEndDate),
  },
  { Header: 'EMI no.', accessor: ({ id }) => id },
  {
    Header: 'Target Partner EMI',
    accessor: ({ targetEMI }) => displayBDTAsFloat(targetEMI),
  },
  {
    Header: 'Collection Date',
    accessor: ({ clientDeposits }) => (
      <Box>
        {map(clientDeposits, (deposit, index) => (
          <Text
            key={index}
            {...(index < clientDeposits.length - 1 && {
              mb: 2,
              borderBottomWidth: 1,
              pb: 2,
            })}
          >
            {getDate(deposit.transactionDate)}
          </Text>
        ))}
      </Box>
    ),
  },
  {
    Header: 'Collection Amount',
    accessor: ({ clientDeposits }) => (
      <Box>
        {map(clientDeposits, (deposit, index) => (
          <Text
            key={index}
            {...(index < clientDeposits.length - 1 && {
              mb: 2,
              borderBottomWidth: 1,
              pb: 2,
            })}
            color="green.500"
          >
            {displayBDTAsFloat(deposit.amount)}
          </Text>
        ))}
      </Box>
    ),
  },
  {
    Header: 'Paid to Partner Date',
    accessor: ({ deposits }) => (
      <Box>
        {map(deposits, (deposit, index) => (
          <Text
            key={index}
            {...(index < deposits.length - 1 && {
              mb: 2,
              borderBottomWidth: 1,
              pb: 2,
            })}
          >
            {getDate(deposit.transactionDate)}
          </Text>
        ))}
      </Box>
    ),
  },
  {
    Header: 'Paid to Partner',
    accessor: ({ deposits }) => (
      <Box>
        {map(deposits, (deposit, index) => (
          <Text
            key={index}
            {...(index < deposits.length - 1 && {
              mb: 2,
              borderBottomWidth: 1,
              pb: 2,
            })}
            color="green.500"
          >
            {displayBDTAsFloat(deposit.amount)}
          </Text>
        ))}
      </Box>
    ),
  },
  {
    Header: 'Outstanding Amount',
    accessor: ({ deposits }) => (
      <Box>
        {map(deposits, (deposit, index) => (
          <Text
            key={index}
            {...(index < deposits.length - 1 && {
              mb: 2,
              borderBottomWidth: 1,
              pb: 2,
            })}
            color="green.500"
          >
            {displayBDTAsFloat(get(deposit, 'outstandingAfter'))}
          </Text>
        ))}
      </Box>
    ),
  },
]

const getRowProps = (_, row) => ({
  sx: {
    backgroundColor: get(row, 'original.isHolidayEMI')
      ? 'gray.100'
      : 'transparent',
  },
})

function PartnerRepayment({ applicationId }) {
  const [summaryDate, setSummaryDate] = useState()

  const repayment = usePartnerRepayments(applicationId, { summaryDate })

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => preparePartnerRepayment(repayment), [repayment])

  const table = useTable({ data, columns })

  return (
    <Box>
      <RepaymentFilter onChange={setSummaryDate} />
      <RepaymentSummary summary={get(repayment, 'partner.summary')} />
      <ListTable table={table} getRowProps={getRowProps} />
    </Box>
  )
}

export default PartnerRepayment
