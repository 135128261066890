import { createSlice } from '@reduxjs/toolkit'
import { batch } from 'react-redux'
import { APPLICATION_UPDATE } from '../applications'

const accountStatus = {
  CLOSED: 'CLOSED',
}

const initialState = {
  byApplicationIds: {},
}

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: initialState,
  reducers: {
    add(state, { payload }) {
      const { applicationId, accounts } = payload

      state.byApplicationIds[applicationId] = accounts
    },
    updateStatusAsClosed(state, { payload }) {
      const { applicationId } = payload

      state.byApplicationIds[applicationId] = state.byApplicationIds[
        applicationId
      ].map((account) => ({
        ...account,
        accountStatus: accountStatus.CLOSED,
      }))
    },
  },
})

export const { add, updateStatusAsClosed } = accountsSlice.actions

export default accountsSlice.reducer

export const fetchAccountsByEloanId = (applicationId) => async (
  dispatch,
  getState,
  { api }
) => {
  const { data } = await api('/eloan/v1/accounts?eloanId={applicationId}', {
    applicationId,
  })

  dispatch(add({ applicationId, accounts: data.accounts }))

  return data
}

export const closeAccount = (accountId, { applicationId }) => async (
  dispatch,
  getState,
  { api }
) => {
  const { data } = await api('PATCH /eloan/v1/accounts/{accountId}/close', {
    accountId,
  })

  batch(() => {
    dispatch(updateStatusAsClosed({ applicationId, accountId }))
    dispatch({
      // update sl_loan_offer.status = 'ended'
      type: APPLICATION_UPDATE,
      data: { offer: { status: 'ended' } },
      applicationId,
    })
  })

  return data
}

export const forceCloseAccount = (
  accountId,
  { applicationId },
  fileAttachment
) => async (dispatch, getState, { api }) => {
  const formData = new FormData()
  formData.set('payment-proof', fileAttachment)

  const { data } = await api(
    'POST /eloan/v1/accounts/{accountId}/force-close',
    {
      accountId,
      body: formData,
    }
  )

  batch(() => {
    dispatch(updateStatusAsClosed({ applicationId, accountId }))
    dispatch({
      // update sl_loan_offer.status = 'ended'
      type: APPLICATION_UPDATE,
      data: { offer: { status: 'ended' } },
      applicationId,
    })
  })

  return data
}
