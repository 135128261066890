import React, { useMemo } from 'react'
import { Box, Text, Flex } from '@chakra-ui/core'
import { NavLink } from 'react-router-dom'

const getActiveStyle = (style) => ({
  ...style,
})

const getStyle = (style) => ({
  marginRight: '30px',
  ...style,
})

export function RouterTab({
  children,
  tabs,
  base,
  activeStyle,
  style,
  ...props
}) {
  const linkActiveStyle = useMemo(() => getActiveStyle(activeStyle), [
    activeStyle,
  ])
  const linkStyle = useMemo(() => getStyle(style), [style])
  return (
    <Box {...props}>
      <Flex borderBottom="1px solid #EEE">
        {tabs.map((tab) => (
          <NavLink
            key={tab.id}
            path={base}
            to={tab.id}
            activeStyle={linkActiveStyle}
            style={linkStyle}
          >
            <Box py={2}>
              <Text fontSize="lg">{tab.label}</Text>
            </Box>
          </NavLink>
        ))}
      </Flex>
      <Box>{children}</Box>
    </Box>
  )
}
