import React, { useCallback, useMemo, useEffect } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  useDisclosure,
  Button,
  ModalFooter,
  useToast,
  Stack,
} from '@chakra-ui/core'
import { useDispatch, batch } from 'react-redux'
import { get, zipObject } from 'lodash-es'
import Form from 'components/Form/Form'
import { useForm } from 'react-hook-form'
import FormSelect from 'components/Form/Select'
import FormButton from 'components/Form/Button'
import { useFieldAgentUsers, useCallAgentUsers } from 'store/users/hooks'
import * as Yup from 'yup'
import { handleAPIError } from 'components/Form/helpers'
import { addCCA, removeFromCCA } from 'store/repaymentFollowup/ccaSlice'
import {
  removeFromCallTeam,
  assignCallAgent,
} from 'store/repaymentFollowup/callTeamSlice'
import {
  assignFieldAgent,
  removeFromFieldTeam,
} from 'store/repaymentFollowup/fieldTeamSlice'
import { teams } from './constants'

const getValidationSchema = () =>
  Yup.object({
    repaymentTeam: Yup.string().required('Required.'),
    agentId: Yup.string().when('repaymentTeam', {
      is: 'cca',
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable().required('Required.'),
    }),
  })

function AssignAgent({
  applicationIds,
  teamOptions,
  onSubmitCallback = () => null,
}) {
  // quick fix to handle refreshes
  const { isOpen, onOpen, onClose } = useDisclosure()

  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({ validationSchema })

  const { setValue, watch, reset } = form

  const repaymentTeam = watch('repaymentTeam')

  const fieldAgents = useFieldAgentUsers()
  const callAgents = useCallAgentUsers()

  const fieldAgentsOptions = useMemo(
    () =>
      zipObject(
        fieldAgents.allIds,
        fieldAgents.allIds.map((id) => get(fieldAgents.byId[id], 'name'))
      ),
    [fieldAgents]
  )

  const callAgentsOptions = useMemo(
    () =>
      zipObject(
        callAgents.allIds,
        callAgents.allIds.map((id) => get(callAgents.byId[id], 'name'))
      ),
    [callAgents]
  )

  const agentOptions = useMemo(() => {
    if (repaymentTeam === teams.call_team) {
      return callAgentsOptions
    }

    if (repaymentTeam === teams.field_team) {
      return fieldAgentsOptions
    }

    return []
  }, [callAgentsOptions, fieldAgentsOptions, repaymentTeam])

  useEffect(() => {
    setValue('agentId', null)
  }, [setValue, agentOptions])

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const toast = useToast()
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ repaymentTeam, agentId }) => {
      try {
        if (repaymentTeam === teams.cca) {
          await dispatch(addCCA({ applicationIds }))

          batch(() => {
            dispatch(removeFromCallTeam(applicationIds))
            dispatch(removeFromFieldTeam(applicationIds))
          })
        }

        if (repaymentTeam === teams.call_team) {
          await dispatch(assignCallAgent({ applicationIds, agentId }))
        }

        if (repaymentTeam === teams.field_team) {
          await dispatch(assignFieldAgent({ applicationIds, agentId }))
          await onSubmitCallback()
          dispatch(removeFromCCA(applicationIds))
        }

        onClose()
      } catch (error) {
        handleAPIError(error, { toast, form })
      }
    },
    [applicationIds, toast, form, dispatch, onClose, onSubmitCallback]
  )

  const repaymentTeamOptions = useMemo(() => teamOptions, [teamOptions])

  return (
    <Box>
      <Button
        variant="outline"
        variantColor="cyan"
        onClick={onOpen}
        px={10}
        disabled={!applicationIds.length}
      >
        Assign Agent
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalHeader>Assign Agent</ModalHeader>
          <ModalCloseButton type="button" />
          <Form form={form} onSubmit={onSubmit}>
            <ModalBody>
              <Stack spacing={6}>
                <Box>
                  <FormSelect
                    name="repaymentTeam"
                    label="Select Repayment Team"
                    options={repaymentTeamOptions}
                  />
                </Box>
                {repaymentTeam !== teams.cca && (
                  <Box>
                    <FormSelect
                      name="agentId"
                      label="Select Agent Name"
                      options={agentOptions}
                    />
                  </Box>
                )}
              </Stack>
            </ModalBody>
            <ModalFooter justifyContent="flex-start">
              <Button variantColor="red" onClick={onClose} px={10} mr={6}>
                CANCEL
              </Button>
              <FormButton type="submit" variantColor="green" px={10}>
                ASSIGN
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default AssignAgent
