import { Box, Stack, useToast } from '@chakra-ui/core'
import { handleAPIError } from '@eloan/shared'
import React, { useCallback } from 'react'
import { GoogleLogin } from 'react-google-login'
import { useDispatch } from 'react-redux'
import { loginWithGoogle } from 'store/currentUser'

function LoginWithGoogle() {
  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID

  const toast = useToast()

  const dispatch = useDispatch()
  const onSuccess = useCallback(
    async (res) => {
      try {
        await dispatch(loginWithGoogle({ clientId, idToken: res.tokenId }))
      } catch (err) {
        handleAPIError(err, { toast })
      }
    },
    [clientId, dispatch, toast]
  )

  const onFailure = useCallback(
    (res) => {
      console.error(res)

      toast({
        title: 'Failed to login with Google',
        status: 'error',
        duration: 3000,
      })
    },
    [toast]
  )

  return (
    <Box p={1}>
      <GoogleLogin
        clientId={clientId}
        onSuccess={onSuccess}
        onFailure={onFailure}
        scope="profile email openid"
        buttonText="Login with Google"
      />
    </Box>
  )
}

function SocialLogin() {
  return (
    <Stack spacing={2} justifyContent="center" alignItems="center">
      <LoginWithGoogle />
    </Stack>
  )
}

export default SocialLogin
