import React, { memo } from 'react'
import { useLoanProductById } from 'store/admin/hooks'
import { useParams } from 'react-router-dom'
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
  Stack,
  Flex,
} from '@chakra-ui/core'
import { get, map } from 'lodash-es'
import { LoanProductFiClientConfig } from 'pages/admin/components/Configuration/ConfigItem'
import InfoBox from 'components/Box/InfoBox'
import Authorize from 'components/Authorize'

const ProductConfig = memo(({ configs }) => {
  return (
    <Accordion allowMultiple>
      {configs.map((config, index) => (
        <AccordionItem key={index} mb={4} borderBottomWidth="1px">
          <AccordionHeader
            bg="gray.50"
            borderLeftWidth="1px"
            borderRightWidth="1px"
          >
            <Box flex="1" textAlign="left" color="blue.500">
              #{index + 1} {get(config, 'financialInstitution.name')}
            </Box>
            <AccordionIcon />
          </AccordionHeader>
          <AccordionPanel
            p={4}
            bordrBottomWidth="1px"
            borderLeftWidth="1px"
            borderRightWidth="1px"
          >
            <Stack isInline spacing={10}>
              <InfoBox label="Min Credit Limit">
                {get(config, 'minCreditLimit')}
              </InfoBox>
              <InfoBox label="Max Credit Limit">
                {get(config, 'maxCreditLimit')}
              </InfoBox>
            </Stack>
            {map(
              get(config, 'loanProductFiClientConfigs', []),
              (item, innerIndex) => (
                <LoanProductFiClientConfig
                  key={innerIndex}
                  fiConfigType={get(
                    item,
                    'loanProductFiClientConfig.fiLoanConfigType'
                  )}
                  clientConfigType={get(
                    item,
                    'loanProductFiClientConfig.clientLoanConfigType'
                  )}
                  fiConfig={item.fiConfig}
                  clientConfig={item.clientConfig}
                  fiConfigSerial={index + 1}
                  clientConfigSerial={index + 2}
                />
              )
            )}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
})

function ProductView() {
  const { loanProductId } = useParams()

  const loanProduct = useLoanProductById(loanProductId)

  return (
    <Authorize permissions="eloan:LoanProduct:read">
      <Box>
        <Heading fontSize={5} mb={10}>
          Product Name: {get(loanProduct, 'productName')}
        </Heading>
        <Box>
          <Flex mb={4}>
            <InfoBox label="User Product Type">
              {get(loanProduct, 'productType')?.split('_')?.[0]}
            </InfoBox>
            <InfoBox label="FI Product Type">
              {get(loanProduct, 'productType')?.split('_')?.[1]}
            </InfoBox>
          </Flex>
          <ProductConfig configs={get(loanProduct, 'loanProductConfigs', [])} />
        </Box>
      </Box>
    </Authorize>
  )
}

export default ProductView
