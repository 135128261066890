import {
  Box,
  ButtonGroup,
  Icon,
  IconButton,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import FormSelect from 'components/Form/Select'

function RedXShopOfferListFilter({ onSubmit }) {
  const defaultValues = useMemo(
    () => ({
      shopId: '',
      shopName: '',
      status: '',
    }),
    []
  )

  const form = useForm({ defaultValues })

  const handleSubmit = useCallback(
    ({ shopId, shopName, status }) => {
      const filterObject = {}

      filterObject.shopId = shopId || undefined
      filterObject.shopName = shopName || undefined
      filterObject.status = status || undefined
      onSubmit({ ...filterObject })
    },
    [onSubmit]
  )

  const formReset = form.reset

  const onReset = useCallback(() => {
    formReset()
    onSubmit({})
  }, [formReset, onSubmit])

  const StatusOption = {
    eligible: 'Eligible',
    rejected: 'Rejected',
    'repeat-pending': 'Repeat Pending',
  }

  return (
    <Form form={form} onSubmit={handleSubmit} flex={1}>
      <Stack isInline spacing={4} align="flex-end" flexWrap="wrap">
        <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                Shop ID
              </Text>
            }
            placeholder="Search"
            name="shopId"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
          />
        </Box>

        <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                Shop Name
              </Text>
            }
            placeholder="Search"
            name="shopName"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
          />
        </Box>
        <Box marginBottom={6} width={200}>
          <FormSelect
            name="status"
            label="Select Status"
            options={StatusOption}
          />
        </Box>
        <Box marginBottom={6}>
          <ButtonGroup spacing={2}>
            <IconButton
              type="button"
              variantColor="cyan"
              variant="outline"
              icon="small-close"
              onClick={onReset}
            />
            <FormButton type="submit" minWidth="6rem" variantColor="cyan">
              Filter
            </FormButton>
          </ButtonGroup>
        </Box>
      </Stack>
    </Form>
  )
}

export default RedXShopOfferListFilter
