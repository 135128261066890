import { ThemeProvider } from 'emotion-theming'
import { location, shop, taka, user } from 'icons'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import B2BForm from './B2BForm'

const scopedTheme = (theme) => ({
  ...theme,
  icons: { ...theme.icons, location, shop, taka, user },
})

function LeadGeneration() {
  return (
    <ThemeProvider theme={scopedTheme}>
      <Switch>
        <Route path="/lead-generation/b2b" component={B2BForm} />
      </Switch>
    </ThemeProvider>
  )
}

export default LeadGeneration
