import { Button } from '@chakra-ui/core'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box } from 'reflexbox'
import { setErrorBoundaryRootError } from 'store/errorBoundary'

const defaultErrorMessage = `Something went wrong!`

class RootErrorBoundary extends Component {
  componentDidCatch(error, info) {
    console.error(error, info)
    this.props.setError(defaultErrorMessage)
  }

  tryRemount = () => {
    this.props.setError(null)
  }

  render() {
    return this.props.error ? (
      <Box textAlign="center">
        {this.props.error}
        <Button type="button" icon="repeat" onClick={this.tryRemount} />
      </Box>
    ) : (
      this.props.children
    )
  }
}

const mapStateToProps = ({ errorBoundary }) => ({
  error: errorBoundary.rootError,
})

const mapDispatchToProps = {
  setError: setErrorBoundaryRootError,
}

export default connect(mapStateToProps, mapDispatchToProps)(RootErrorBoundary)
