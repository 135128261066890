import { Badge, Button, Spinner, Stack, Checkbox, Box } from '@chakra-ui/core'
import { DataTable } from '@eloan/shared'
import ApplicationListHeader from 'components/ApplicationList/Header'
import Authorize from 'components/Authorize'
import NavLink from 'components/Link/NavLink'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import usePagination from 'hooks/usePagination'
import { capitalize, get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useRowSelect, useTable } from 'react-table'
import { getNobodarApplications } from 'store/nobodar'
import { emptyArray } from 'utils/defaults'
import { getDate } from 'utils/getDate'
import { getLinkWithPageQuery } from 'utils/getLinkWithPageQuery'
import {
  callStatusMapper,
  feeDepositStatusColorMap,
  paymentStatusMapper,
} from '../constants'
import NobodarListFilter from './Filter/Filter'
import AddBKashTransaction from './Modals/AddBKashTransaction'
import VerifyPayment from './Modals/VerifyPayment'
import SetPAAssignment from './Modals/SetPAAssignment'

const getColumns = () =>
  [
    {
      Header: ({ getToggleAllRowsSelectedProps }) => {
        const { checked, ...props } = getToggleAllRowsSelectedProps()
        return <Checkbox isChecked={checked} {...props} />
      },
      accessor: 'id',
      id: 'id-selector',
      Cell: ({ row }) => {
        const { checked, ...props } = row.getToggleRowSelectedProps()
        return <Checkbox isChecked={checked} {...props} />
      },
    },
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'fullName' },
    { Header: 'Phone Number', accessor: 'phone' },
    {
      Header: 'Application date',
      accessor: ({ createdAt }) => getDate(createdAt),
    },
    {
      Header: 'Payment Status',
      accessor: ({ paymentStatus }) =>
        capitalize(paymentStatusMapper[paymentStatus]),
    },
    {
      Header: 'Eligibility Status',
      accessor: ({ status }) =>
        status === 'rejected' ? 'Not Eligible' : 'Eligible',
    },
    {
      Header: 'Call Status',
      accessor: ({ paCallLastStatus, paCallLastStatusStreak }) =>
        `${callStatusMapper[paCallLastStatus] ?? callStatusMapper.pending}${
          paCallLastStatusStreak ? ` [${paCallLastStatusStreak}]` : ''
        }`,
    },
    {
      Header: 'Trx ID',
      accessor: 'feeDeposits',
      Cell: ({ cell: { value: feeDeposits } }) => (
        <Stack isInline spacing={1} flexWrap="wrap" maxWidth="8rem">
          {feeDeposits.map((feeDeposit) => (
            <Badge
              key={feeDeposit.id}
              my={1}
              fontSize="0.8em"
              variantColor={
                feeDepositStatusColorMap[
                  get(feeDeposit, 'bkashTransaction.status')
                ]
              }
            >
              {feeDeposit.transactionId}
            </Badge>
          ))}
        </Stack>
      ),
    },
    {
      Header: '',
      id: 'action-button',
      accessor: 'id',
      Cell: ({ cell: { value } }) => (
        <Stack isInline spacing={2} justify="flex-end">
          <VerifyPayment applicationId={value} />
          <AddBKashTransaction applicationId={value} />
          <Button
            as={NavLink}
            to={`/dash/nobodar-applications/${value}`}
            variantColor="cyan"
            size="sm"
            target="_blank"
          >
            Open
          </Button>
        </Stack>
      ),
    },
  ].filter(Boolean)

const getQueryObject = ({
  phone,
  paymentStatus,
  callStatus,
  callStatusStreak,
  startDate,
  endDate,
  paId,
  feeDepositLastCreatedAt,
}) => {
  let queryObject = {
    limit: 20,
    filter: {},
  }

  const query = []

  if (phone) {
    query.push(`phone like %${phone}%`)
  }

  if (paymentStatus) {
    query.push(`paymentStatus = ${paymentStatus}`)
  }

  if (callStatus) {
    query.push(
      `paCallLastStatus = ${callStatus === 'pending' ? null : callStatus}`
    )
  }

  if (callStatusStreak) {
    query.push(`paCallLastStatusStreak >= ${callStatusStreak}`)
  }

  if (paId) {
    query.push(`paId = ${paId}`)
  }

  const toSQLDateOptions = {
    includeOffset: false,
  }

  if (startDate || endDate) {
    const formatStartDate = DateTime.fromJSDate(startDate || endDate, {
      zone: 'utc',
    })
    const formatEndDate = DateTime.fromJSDate(endDate || startDate, {
      zone: 'utc',
    })

    const startSqlDate = formatStartDate.toSQL(toSQLDateOptions)
    const endSqlDate = formatEndDate.plus({ day: 1 }).toSQL(toSQLDateOptions)

    query.push(`createdAt >= ${startSqlDate}`, `createdAt < ${endSqlDate}`)
  }

  if (feeDepositLastCreatedAt) {
    const date = DateTime.fromJSDate(feeDepositLastCreatedAt, {
      zone: 'utc',
    })

    query.push(
      `feeDepositLastCreatedAt >= ${date.toSQL(toSQLDateOptions)}`,
      `feeDepositLastCreatedAt < ${date
        .plus({ day: 1 })
        .toSQL(toSQLDateOptions)}`
    )
  }

  queryObject.filter.application = query.join(';') || undefined

  return queryObject
}

const getRowId = (row) => row.id

function NobodarApplicationList() {
  const [filter, setFilter] = useState()

  const dispatch = useDispatch()
  const fetchPage = useCallback(
    (...args) => {
      dispatch(getNobodarApplications(...args))
    },
    [dispatch]
  )

  const history = useHistory()

  useEffect(() => {
    history.push(getLinkWithPageQuery(window.location, null))
  }, [filter, history])

  const queryObject = useMemo(() => getQueryObject({ ...filter }), [filter])

  const pagination = useSelector((state) => state.pagination.nobodar)
  const [page] = usePagination(pagination, fetchPage, queryObject)

  const applications = useSelector((state) => state.nobodar.applications)

  const [selectedApplicationIds, setSelectedApplicationIds] = useState(
    emptyArray
  )

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => {
    return get(pagination.pages[page], 'itemIds', emptyArray).map(
      (id) => applications.byId[id]
    )
  }, [pagination.pages, page, applications.byId])

  const table = useTable(
    {
      data,
      columns,
      getRowId,
    },
    useRowSelect
  )

  useEffect(() => {
    setSelectedApplicationIds(
      Object.keys(table.state.selectedRowIds).filter(
        (id) => table.state.selectedRowIds[id]
      )
    )
  }, [table.state.selectedRowIds])

  return (
    <Authorize permissions="eloan:AnyNobodarApplication:read,eloan:OwnNobodarApplication:read">
      <Stack isInline justifyContent="space-between" alignItems="center">
        <ApplicationListHeader
          title={`Nobodar Applications`}
          subtitle={`List of Nobodar Applications`}
        />

        <Box>
          <SetPAAssignment applicationIds={selectedApplicationIds} />
        </Box>
      </Stack>

      <NobodarListFilter onChange={setFilter} />

      {pagination.loading ? (
        <Spinner
          display="block"
          mx="auto"
          my={20}
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : (
        <DataTable {...table} />
      )}

      <PageBasedSwitcher
        pageIndex={page}
        totalPages={pagination.totalPages}
        totalItems={pagination.totalItems}
      />
    </Authorize>
  )
}

export default NobodarApplicationList
