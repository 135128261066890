import { Box, Button, Heading, Stack, Text, useToast } from '@chakra-ui/core'
import { Table, toRounded2F2PNumber } from '@eloan/shared'
import { downloadCSVFile } from '@eloan/shared/utils/csv'
import LoanScorecardComponentsModal from 'components/ApplicationModals/LoanScorecardComponents'
import Authorize from 'components/Authorize'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateFinalScorecardForApplication } from 'store/applications'
import { camelCaseToSentence } from 'utils/string'
import { mapScorecardComponentValues } from 'utils/application-scorecard'

const downloadFinalScorecardCsv = (applicationId, application, scorecard) => {
  const salesDataItems = Object.entries(
    get(application, 'darazMerchantData.monthlySalesData') ?? {}
  ).map(([key, value]) => [`MonthlySales(${key})`, value])

  const scorecardComponents = mapScorecardComponentValues(
    scorecard.scoreComponents
  ).map((component) => {
    return [
      component.fieldName,
      component.value,
      component.score,
      component.weight,
      component.weightedScore,
    ]
  })

  Object.keys(scorecard.scoreComponents).map((fieldName) => {
    const component = scorecard.scoreComponents[fieldName]
    let fieldNameDisplay = camelCaseToSentence(fieldName)
    if (fieldNameDisplay === 'Has Previous Loan History') {
      fieldNameDisplay = 'Previous Loan experience from formal channel'
    }

    let valueDisplay = component.value
    if (['postgraduate', 'undergraduate'].includes(valueDisplay)) {
      valueDisplay = 'graduation'
    }

    return [
      fieldNameDisplay,
      valueDisplay,
      toRounded2F2PNumber(component.score),
      component.weight,
      toRounded2F2PNumber(component.weightedScore),
    ]
  })

  const componentHeaders = [
    'Component',
    'Value',
    'Score',
    'Weight',
    'Weighted Score',
  ]

  downloadCSVFile({
    filename: `eLoan-${applicationId}-${scorecard.type}-scorecard.csv`,
    data: [
      ['eLoan ID', applicationId],
      ['Daraz Shop Name', get(application, 'darazMerchantData.shopName')],
      [
        'Daraz Age (Months)',
        get(application, 'darazMerchantData.data.timeSellingOnDarazInMonths'),
      ],
      [
        'Monthly Sales Volume (Average of one year)',
        scorecard.monthlyAverageSales,
      ],
      ['Current Stock Value', scorecard.totalStockValue],
      ['Days Stock in Hand', scorecard.daysStockInHand],
      ['Days of Credit to Buyer', scorecard.daysOfCreditToBuyer],
      ['Days of Credit from Supplier', scorecard.daysOfCreditFromSupplier],
      ['Working Capital Cycle', scorecard.workingCapitalCycle],
      ['Liquidity Gap', scorecard.liquidityGap],
      ['Average Monthly Sales of last one yar', scorecard.monthlyAverageSales],
      [
        'Working Capital Financed (Cycle 1 for Pilot)',
        scorecard.workingCapitalCycleFinanced,
      ],
      ['Working Capital Cycle', scorecard.workingCapitalCycle],
      ['Days Financed', scorecard.daysFinanced],
      ['Amount Required', scorecard.amountRequired],
      ['Final Score', scorecard.score],
      [
        'Maximum Loan Limit (based on credit scoring)',
        scorecard.maxLoanLimitBasedOnScore,
      ],
      [
        'Maximum Loan Limit (based on 70% liquidity gap)',
        scorecard.maxLoanLimitBasedOnLiquidityGap,
      ],
      ['Final Offer', scorecard.maximumOffer],
      ['Interest Rate', scorecard.interestRate],
      ['Tenure in Days', scorecard.tenureInDays],
      ['Tenure in Months (rounded-up)', scorecard.tenureInMonths],
      ['-'],
      ...salesDataItems,
      ['-'],
      componentHeaders,
      ...scorecardComponents,
    ],
  })
}

const downloadScorecardCsv = (applicationId, application, scorecard) => {
  const salesDataItems = Object.entries(
    get(application, 'darazMerchantData.monthlySalesData') ?? {}
  ).map(([key, value]) => [`MonthlySales(${key})`, value])

  downloadCSVFile({
    filename: `eLoan-${applicationId}-${scorecard.type}-scorecard.csv`,
    data: [
      ['eLoan ID', applicationId],
      ['Daraz Shop Name', get(application, 'darazMerchantData.shopName')],
      [
        'Daraz Age (Months)',
        get(application, 'darazMerchantData.data.timeSellingOnDarazInMonths'),
      ],
      [
        'Monthly Sales Volume (Average of one year)',
        scorecard.monthlyAverageSales,
      ],
      ['Current Stock Value', scorecard.totalStockValue],
      ['Days Stock in Hand', scorecard.daysStockInHand],
      ['Days of Credit to Buyer', scorecard.daysOfCreditToBuyer],
      ['Days of Credit from Supplier', scorecard.daysOfCreditFromSupplier],
      ['Working Capital Cycle', scorecard.workingCapitalCycle],
      ['Liquidity Gap', scorecard.liquidityGap],
      ['Average Monthly Sales of last one yar', scorecard.monthlyAverageSales],
      [
        'Working Capital Financed (Cycle 1 for Pilot)',
        scorecard.workingCapitalCycleFinanced,
      ],
      ['Working Capital Cycle', scorecard.workingCapitalCycle],
      ['Days Financed', scorecard.daysFinanced],
      ['Amount Required', scorecard.amountRequired],
      ['Minimum Offer', scorecard.minimumOffer],
      ['Maximum Offer', scorecard.maximumOffer],
      ['Interest Rate', scorecard.interestRate],
      // [
      //   'Maximum Loan Limit (based on credit scoring)',
      //   scorecard.maxLoanLimitBasedOnScore,
      // ],
      // [
      //   'Maximum Loan Limit (based on 70% liquidity gap)',
      //   scorecard.maxLoanLimitBasedOnLiquidityGap,
      // ],
      ['Tenure in Days', scorecard.tenureInDays],
      ['Tenure in Months (rounded-up)', scorecard.tenureInMonths],
      ['-'],
      ...salesDataItems,
    ],
  })
}

function ScorecardTable({ data, applicationId }) {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Item</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Monthly Sales Volume (Average of one year)</Table.Cell>
          <Table.Cell>
            {toRounded2F2PNumber(data.monthlyAverageSales)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Current Stock Value </Table.Cell>
          <Table.Cell>{toRounded2F2PNumber(data.totalStockValue)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Days Stock in Hand</Table.Cell>
          <Table.Cell>{data.daysStockInHand}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Days of Credit to Buyer</Table.Cell>
          <Table.Cell>{data.daysOfCreditToBuyer}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Days of Credit from Supplier</Table.Cell>
          <Table.Cell>{data.daysOfCreditFromSupplier}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Working Capital Cycle</Table.Cell>
          <Table.Cell>{data.workingCapitalCycle}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Liquidity Gap</Table.Cell>
          <Table.Cell>{data.liquidityGap}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell colSpan="2">
            <Text color="blue.500" fontWeight="bold" as="span">
              Loan Determination
            </Text>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Average Monthly Sales of last one year</Table.Cell>
          <Table.Cell>{data.monthlyAverageSales}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Working Capital Financed (Cycle 1 for Pilot)</Table.Cell>
          <Table.Cell>{data.workingCapitalCycleFinanced}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Working Capital Cycle</Table.Cell>
          <Table.Cell>{data.workingCapitalCycle}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Days Financed</Table.Cell>
          <Table.Cell>{data.daysFinanced}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Amount Required</Table.Cell>
          <Table.Cell>{toRounded2F2PNumber(data.amountRequired)}</Table.Cell>
        </Table.Row>
        {data.type === 'final' && (
          <>
            <Table.Row>
              <Table.Cell>
                <Text as="span" fontWeight="bold">
                  Final Score
                </Text>
              </Table.Cell>
              <Table.Cell>
                <LoanScorecardComponentsModal
                  buttonTitle={toRounded2F2PNumber(data.score)}
                  applicationId={applicationId}
                  scorecard={data}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Maximum Loan Limit (based on credit scoring)
              </Table.Cell>
              <Table.Cell>
                <Text as="span" fontWeight="bold" display="block">
                  {toRounded2F2PNumber(data.maxLoanLimitBasedOnScore)}
                </Text>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                Maximum Loan Limit (based on 70% of liquidity gap)
              </Table.Cell>
              <Table.Cell>
                <Text as="span" fontWeight="bold" display="block">
                  {toRounded2F2PNumber(data.maxLoanLimitBasedOnLiquidityGap)}
                </Text>
              </Table.Cell>
            </Table.Row>
          </>
        )}
        <Table.Row>
          {data.type === 'initial' && (
            <>
              <Table.Cell>
                <Text as="span" fontWeight="bold">
                  Minimum Offer
                </Text>
              </Table.Cell>

              <Table.Cell>
                <Text as="span" fontWeight="bold" display="block">
                  {toRounded2F2PNumber(data.minimumOffer)}
                </Text>
                <Text as="span" color="cyan.500">
                  or 50,000.00 whichever is lower
                </Text>
              </Table.Cell>
            </>
          )}
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <Text as="span" fontWeight="bold">
              Maximum Offer
            </Text>
          </Table.Cell>
          <Table.Cell>
            <Text as="span" fontWeight="bold" display="block">
              {toRounded2F2PNumber(data.maximumOffer)}
            </Text>
            <Text as="span" color="cyan.500">
              or 100,000.00 whichever is lower
            </Text>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell></Table.Cell>
          <Table.Cell color="blue.500" fontWeight="bold">
            Considering 6 Working Capital Cycle
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Interest Rate</Table.Cell>
          <Table.Cell>{data.interestRate}%</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Tenure in days</Table.Cell>
          <Table.Cell>{data.tenureInDays}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Tenure in months (rounded-up)</Table.Cell>
          <Table.Cell>{data.tenureInMonths}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}

function GenerateFinalScorecardButton({ applicationId }) {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const dispatch = useDispatch()

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      await dispatch(generateFinalScorecardForApplication(applicationId))
      toast({
        status: 'info',
        title: 'Final Scorecard generated!',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      handleAPIError(err, { toast })
    } finally {
      setLoading(false)
    }
  }, [applicationId, dispatch, toast])

  return (
    <Button
      isDisabled={loading}
      isLoading={loading}
      variantColor="orange"
      onClick={onSubmit}
    >
      Generate Final Scorecard
    </Button>
  )
}

function Scorecard({ applicationId }) {
  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const initialScorecard = useMemo(
    () =>
      get(application, 'scorecards', []).find(
        (scorecard) => scorecard.type === 'initial'
      ),
    [application]
  )

  const finalScorecard = useMemo(
    () =>
      get(application, 'scorecards', []).find(
        (scorecard) => scorecard.type === 'final'
      ),
    [application]
  )

  const onDownloadInitialScorecard = useCallback(() => {
    if (!initialScorecard) {
      return
    }

    downloadScorecardCsv(applicationId, application, initialScorecard)
  }, [application, applicationId, initialScorecard])

  const onDownloadFinalScorecard = useCallback(() => {
    if (!finalScorecard) {
      return
    }

    downloadFinalScorecardCsv(applicationId, application, finalScorecard)
  }, [application, applicationId, finalScorecard])

  return (
    <Authorize permissions="eloan:AnyApplication:read,eloan:Application:read">
      <Stack spacing={8} width="100%" mb={10}>
        {finalScorecard ? (
          <>
            <Heading>Final Scorecard</Heading>
            <Box>
              <Button
                size="sm"
                variant="ghost"
                leftIcon="download"
                variantColor="cyan"
                onClick={onDownloadFinalScorecard}
              >
                Download CSV of this Scorecard
              </Button>
            </Box>
            <ScorecardTable
              data={finalScorecard}
              applicationId={applicationId}
            />
          </>
        ) : (
          <Box textAlign="right" mt={4}>
            <GenerateFinalScorecardButton applicationId={applicationId} />
          </Box>
        )}
      </Stack>

      {initialScorecard && (
        <Stack spacing={8} width="100%">
          <Heading>Initial Scorecard</Heading>
          <Box>
            <Button
              size="sm"
              variant="ghost"
              leftIcon="download"
              variantColor="cyan"
              isDisabled={!initialScorecard}
              onClick={onDownloadInitialScorecard}
            >
              Download CSV of this Scorecard
            </Button>
          </Box>
          <ScorecardTable
            data={initialScorecard}
            applicationId={applicationId}
          />
        </Stack>
      )}
    </Authorize>
  )
}

export default Scorecard
