import { Heading, Text } from '@chakra-ui/core'
import React from 'react'
import { Box, Flex } from 'reflexbox'

function ApplicationListHeader({ title, subtitle, Right }) {
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      mb={10}
    >
      <Box>
        <Heading fontSize={5}>{title}</Heading>
        <Text>{subtitle}</Text>
      </Box>

      <Box>{Right}</Box>
    </Flex>
  )
}

export default ApplicationListHeader
