import { ButtonGroup, IconButton } from '@chakra-ui/core'
import AreaFilter from 'components/ApplicationFilters/AreaFilter'
import OfferCreationDateFilter from 'components/ApplicationFilters/OfferCreationDateFilter'
import OfferStatusFilter from 'components/ApplicationFilters/OfferStatusFilter'
import PAAssignmentFilter from 'components/ApplicationFilters/PAAssignmentFilter'
import PartnerFilter from 'components/ApplicationFilters/PartnerFilter'
import ApplicationStatusFilter from 'components/ApplicationFilters/StatusFilter'
import ApplicationSubStatusFilter from 'components/ApplicationFilters/SubStatusFilter'
import ApplicationTypeFilter from 'components/ApplicationFilters/TypeFilter'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Flex } from 'reflexbox'

const initialState = {
  type: '',
  status: '',
  subStatus: '',

  meta: {
    shopAreaId: '',
  },

  offer: {
    status: '',

    createdAt: {
      start: '',
      end: '',
    },
  },

  paAssignment: {
    paId: '',
  },
}

function OfferListFilters({ defaultValues = initialState, onChange }) {
  const form = useForm({
    defaultValues,
  })

  const formReset = form.reset

  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const onReset = useCallback(() => {
    formReset(initialState)
    onChange(initialState)
  }, [formReset, onChange])

  return (
    <Form form={form} onSubmit={onChange}>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
        mb={8}
      >
        <ApplicationTypeFilter m={1} />

        <PartnerFilter m={1} />

        <OfferStatusFilter m={1} />

        <ApplicationStatusFilter m={1} />

        <ApplicationSubStatusFilter m={1} />

        <OfferCreationDateFilter m={1} />

        <AreaFilter m={1} />

        <PAAssignmentFilter m={1} />

        <ButtonGroup spacing={0} m={1}>
          <IconButton type="button" icon="small-close" onClick={onReset} />
          <FormButton type="submit" minWidth="6rem" variantColor="green">
            Filter
          </FormButton>
        </ButtonGroup>
      </Flex>
    </Form>
  )
}

export default OfferListFilters
