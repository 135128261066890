import { Button, Heading } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormTextarea from 'components/Form/Textarea'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box, Flex } from 'reflexbox'
import { sendApplicationToDigitization } from 'store/applications'
import * as Yup from 'yup'

const getValidationSchemaForNote = () => {
  return Yup.object({
    note: Yup.string().min(4, `seriously?`).required(`required`),
  })
}

function SendToDigitization({
  applicationId,
  currentStatus,
  toast,
  onDone,
  ...props
}) {
  const isInValidStatus = useMemo(() => {
    return ['pending', 'preparing', 'troubleshooting'].includes(currentStatus)
  }, [currentStatus])

  const validationSchema = useMemo(() => getValidationSchemaForNote(), [])

  const form = useForm({
    validationSchema,
  })

  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ note }) => {
      try {
        await dispatch(sendApplicationToDigitization(applicationId, { note }))
        toast({
          title: `Application(${applicationId}) sent for Digitization`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        onDone()
      } catch (err) {
        handleAPIError(err, { toast })
      }
    },
    [dispatch, applicationId, onDone, toast]
  )

  return (
    <Authorize permissions="eloan:Application:requestDigitization">
      <Box {...props}>
        <Heading fontSize={3} mb={2}>
          Send to Digitization
        </Heading>

        <Form form={form} onSubmit={onSubmit}>
          <FormTextarea name="note" label={`Note`} />
          <Flex p={2} mb={2}>
            <Button
              flexGrow="1"
              type="submit"
              variantColor={isInValidStatus ? 'green' : 'gray'}
              isLoading={form.formState.isSubmitting}
              isDisabled={!isInValidStatus || form.formState.isSubmitting}
            >
              Proceed
            </Button>
          </Flex>
        </Form>
      </Box>
    </Authorize>
  )
}

export default SendToDigitization
