import NavLink from 'components/Link/NavLink'
import React, { memo } from 'react'
import { Box, Flex } from 'reflexbox'
import logo from '../../logo.svg'
import CurrentUserPopover from './CurrentUserPopover'

function Topbar() {
  return (
    <Flex
      as="header"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 'sticky',
        bg: 'white',
        left: 0,
        right: 0,
        borderBottomWidth: '1px',
        width: '100%',
        height: 'navbar',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.135571)',
      }}
      px={8}
    >
      <Box>
        <NavLink to="/" display="block">
          <Box as="img" src={logo} alt="ShopUp Logo" />
        </NavLink>
      </Box>
      <Box>
        <CurrentUserPopover />
      </Box>
    </Flex>
  )
}

export default memo(Topbar)
