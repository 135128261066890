import { batch } from 'react-redux'

export const NOBODAR_APPLICATION_ADD_BULK = 'NOBODAR_APPLICATION_ADD_BULK'
export const NOBODAR_APPLICATION_ADD = 'NOBODAR_APPLICATION_ADD'
export const NOBODAR_APPLICATION_UPDATE = 'NOBODAR_APPLICATION_UPDATE'
export const NOBODAR_APPLICATION_UPDATE_BULK = 'NOBODAR_APPLICATION_UPDATE_BULK'

export const NOBODAR_PA_CALL_LOG_ADD = 'NOBODAR_PA_CALL_LOG_ADD'
export const NOBODAR_PA_CALL_LOG_ADD_BULK = 'NOBODAR_PA_CALL_LOG_ADD_BULK'

export const NOBODAR_PAGE_REQUEST = 'NOBODAR_PAGE_REQUEST'
export const NOBODAR_PAGE_ADD = 'NOBODAR_PAGE_ADD'
export const NOBODAR_PAGE_REMOVE = 'NOBODAR_PAGE_REMOVE'
export const NOBODAR_PAGINATION_PURGE = 'NOBODAR_PAGINATION_PURGE'

export const NOBODAR_MANUALLY_VERIFY_BKASH_SUCCESS =
  'NOBODAR_MANUALLY_VERIFY_BKASH_SUCCESS'

export const getNobodarApplications = (
  { page = 1, limit, filter },
  queryHash
) => async (dispatch, _getState, { api }) => {
  dispatch({ type: NOBODAR_PAGE_REQUEST, page, queryHash })

  const { data } = await api(
    `/legacy/v3/eloan/nobodar/applications{?page,limit,filter*}`,
    {
      page,
      limit,
      filter,
    }
  )

  batch(() => {
    dispatch({ type: NOBODAR_APPLICATION_ADD_BULK, data })
    dispatch({ type: NOBODAR_PAGE_ADD, page, data, queryHash })
  })
}

export const getNobodarApplication = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `/legacy/v3/eloan/nobodar/applications/{applicationId}`,
    {
      applicationId,
    }
  )

  dispatch({ type: NOBODAR_APPLICATION_ADD, data, applicationId })

  return data
}

export const setNobodarApplicationStatus = (
  applicationId,
  { status, note }
) => async (dispatch, _getState, { api }) => {
  const {
    data,
  } = await api(
    `POST /legacy/v3/eloan/nobodar/applications/{applicationId}/action/set-status`,
    { applicationId, status, note }
  )

  dispatch({ type: NOBODAR_APPLICATION_UPDATE, data, applicationId })

  return data
}

export const setPaAssignmentsForNobodarApplications = ({
  applicationIds,
  paId,
}) => async (dispatch, _getState, { api }) => {
  const {
    data,
  } = await api(
    `POST /legacy/v3/eloan/nobodar/applications/action/set-pa-assignments`,
    { applicationIds, paId }
  )

  dispatch({ type: NOBODAR_APPLICATION_UPDATE_BULK, data })

  return data
}

export const setNobodarApplicationShortlist = (
  applicationId,
  { shortlist, note }
) => async (dispatch, _getState, { api }) => {
  const {
    data,
  } = await api(
    `POST /legacy/v3/eloan/nobodar/applications/{applicationId}/action/set-shortlist`,
    { applicationId, shortlist, note }
  )

  dispatch({ type: NOBODAR_APPLICATION_UPDATE, data, applicationId })

  return data
}

export const addBkashTransactionAsFeeDeposit = (
  applicationId,
  { transactionId, note }
) => async (dispatch, _getState, { api }) => {
  const {
    data,
  } = await api(
    `POST /legacy/v3/eloan/nobodar/applications/{applicationId}/fee-deposits/action/add-bkash-transaction`,
    { applicationId, transactionId, note }
  )

  dispatch({ type: NOBODAR_APPLICATION_UPDATE, data, applicationId })

  return data
}

export const verifyBkashTransaction = ({
  applicationId,
  feeDepositId,
  amount,
  correctTransactionId,
  note,
}) => async (dispatch, _getState, { api }) => {
  const {
    data,
  } = await api(
    `POST /legacy/v3/eloan/nobodar/applications/{applicationId}/fee-deposits/{feeDepositId}/action/manually-verify-bkash-transaction`,
    { applicationId, feeDepositId, amount, correctTransactionId, note }
  )

  dispatch({ type: NOBODAR_APPLICATION_UPDATE, data, applicationId })

  return data
}

export const getNobodarPACallLogsForApplication = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `/legacy/v3/eloan/nobodar/applications/{applicationId}/pa-call-logs`,
    {
      applicationId,
    }
  )

  dispatch({ type: NOBODAR_PA_CALL_LOG_ADD_BULK, data, applicationId })

  return data
}

export const addNobodarCallLog = ({
  applicationId,
  status,
  followupDate,
  note,
}) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/nobodar/applications/{applicationId}/pa-call-logs`,
    {
      applicationId,
      status,
      note,
      followupDate,
    }
  )

  dispatch({ type: NOBODAR_PA_CALL_LOG_ADD, data, applicationId })

  return data
}
