import Authorize from 'components/Authorize'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CreateRetailer from './Create'
import EditRetailer from './Edit'
import RetailerList from './List'
import ViewRetailer from './View'

function RetailersPage() {
  return (
    <Authorize permissions="eloan:AnyRetailer:read">
      <Switch>
        <Route exact path="/dash/retailers" component={RetailerList} />
        <Route path="/dash/retailers/create" component={CreateRetailer} />
        <Route
          path="/dash/retailers/:retailerId/edit"
          component={EditRetailer}
        />
        <Route path="/dash/retailers/:retailerId" component={ViewRetailer} />
      </Switch>
    </Authorize>
  )
}

export default RetailersPage
