import { api } from 'api'

export const FI_SET_ALL = 'FI_SET_ALL'
export const FI_ADD = 'FI_ADD'

export const LOAN_CONFIG_SET_ALL = 'LOAN_CONFIG_SET_ALL'
export const LOAN_CONFIG_ADD = 'LOAN_CONFIG_ADD'
export const LOAN_CONFIG_REMOVE_ALL = 'LOAN_CONFIG_REMOVE_ALL'

export const LOAN_PRODUCT_SET_ALL = 'LOAN_PRODUCT_SET_ALL'
export const LOAN_PRODUCT_ADD = 'LOAN_PRODUCT_ADD'

export const getFinancialInstitutions = () => async (dispatch, getState) => {
  const { data } = await api(`/eloan/v1/loan-products/financial-institutions`)

  dispatch({
    type: FI_SET_ALL,
    data,
  })
}

export const createFinancialInstitution = ({
  name,
  shopupLoanPartnerId,
}) => async (dispatch, getState) => {
  const { data } = await api(
    `POST /eloan/v1/loan-products/financial-institutions`,
    {
      name,
      shopupLoanPartnerId,
    }
  )

  dispatch({
    type: FI_ADD,
    data,
  })

  return data
}

export const getLoanConfigs = () => async (dispatch, getState) => {
  const { data } = await api(`/eloan/v1/loan-products/term-loan-configs`)

  dispatch({
    type: LOAN_CONFIG_SET_ALL,
    data,
  })

  return data
}

export const createConfig = (id, payload) => async (dispatch, getState) => {
  const { data } = await api(
    `POST /eloan/v1/loan-products/financial-institutions/{id}/term-loan-configs`,
    {
      id,
      ...payload,
    }
  )

  dispatch({
    type: LOAN_CONFIG_ADD,
    data,
  })

  return data
}

export const createLoanProduct = ({
  productType,
  productName,
  configs,
}) => async (dispatch, getState) => {
  const { data } = await api(`POST /eloan/v1/loan-products`, {
    productType,
    productName,
    configs,
  })

  dispatch({
    type: LOAN_PRODUCT_ADD,
    data,
  })

  return data
}

export const getLoanProducts = () => async (dispatch, getState) => {
  const { data } = await api(`/eloan/v1/loan-products`)

  dispatch({
    type: LOAN_PRODUCT_SET_ALL,
    data,
  })
}
