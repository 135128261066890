import { batch } from 'react-redux'

export const CCA_DATA_LOADING = 'CCA_DATA_LOADING'
export const CCA_DATA_LOADED = 'CCA_DATA_LOADED'

export const CCA_PAGE_REQUEST = 'CCA_PAGE_REQUEST'
export const CCA_ADD_BULK = 'CCA_ADD_BULK'

export const CCA_PAGE_ADD = 'CCA_PAGE_ADD'
export const CCA_PAGE_REMOVE = 'CCA_PAGE_REMOVE'
export const CCA_PAGINATION_PURGE = 'CCA_PAGINATION_PURGE'

export const CCA_REMOVE_PENDING = 'CCA_REMOVE_PENDING'
export const CCA_REMOVE_SUCCESS = 'CCA_REMOVE_SUCCESS'

export const CCA_ADD_PENDING = 'CCA_ADD_PENDING'
export const CCA_ADD_SUCCESS = 'CCA_ADD_SUCCESS'

export const getCCAData = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch({ type: CCA_DATA_LOADING, applicationId })

  const { data } = await api(
    `/legacy/v3/eloan/cca/applications/{applicationId}`,
    {
      applicationId,
    }
  )

  dispatch({ type: CCA_DATA_LOADED, data, applicationId })

  return data
}

export const addCCA = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch({ type: CCA_ADD_PENDING, applicationId })

  const { data } = await api(`POST /legacy/v3/eloan/cca/applications`, {
    applicationID: applicationId,
  })
  batch(() => {
    dispatch({ type: CCA_ADD_SUCCESS, data, applicationId })
    dispatch({ type: CCA_PAGINATION_PURGE })
  })

  return data
}

export const removeCCA = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch({ type: CCA_REMOVE_PENDING, applicationId })

  const { data } = await api(
    `DELETE /legacy/v3/eloan/cca/applications/{applicationId}`,
    {
      applicationId,
    }
  )
  batch(() => {
    dispatch({ type: CCA_REMOVE_SUCCESS, applicationId })
    dispatch({ type: CCA_PAGINATION_PURGE })
  })

  return data
}

export const getCCAPage = ({ page = 1, limit, filter }, queryHash) => async (
  dispatch,
  _getState,
  { api }
) => {
  dispatch({ type: CCA_PAGE_REQUEST, page, queryHash })

  try {
    const { data } = await api(
      `/legacy/v3/eloan/cca/applications{?limit,page,filter}`,
      {
        limit,
        page,
        filter,
      }
    )

    batch(() => {
      dispatch({ type: CCA_ADD_BULK, data })
      dispatch({ type: CCA_PAGE_ADD, page, data, queryHash })
    })

    return data
  } catch (err) {
    if (err.error && err.error.status >= 500) {
      dispatch({ type: CCA_PAGE_REMOVE, page, queryHash })
    }
  }
}
