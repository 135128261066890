import {
  Box,
  ButtonGroup,
  Icon,
  IconButton,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import { FormDateRangePicker } from 'components/Form/DatePicker'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toISODate } from 'utils/cast'

function AppBkashPaymentsPageFilter({ onDateRangeChange, onFilterChange }) {
  const defaultValues = useMemo(
    () => ({
      date: {
        start: new Date(),
        end: new Date(),
      },
    }),
    []
  )

  const form = useForm({ defaultValues })

  const handleSubmit = useCallback(
    ({ date }) => {
      const { start, end } = date

      if (start && end) {
        onDateRangeChange({
          start: toISODate(start),
          end: toISODate(end),
        })
      }
    },
    [onDateRangeChange]
  )

  const formReset = form.reset
  const onReset = useCallback(() => {
    formReset()
  }, [formReset])

  return (
    <Form form={form} onSubmit={handleSubmit} flex={1}>
      <Stack isInline spacing={4} align="flex-end" flexWrap="wrap">
        <Box zIndex={2} marginBottom={6}>
          <FormDateRangePicker
            label={
              <Text fontSize="xs" color="gray.500">
                Select Date Range
              </Text>
            }
            startProps={{ placeholderText: 'Start Date' }}
            endProps={{ placeholderText: 'End Date' }}
            name="date"
          />
        </Box>
        <Box marginBottom={6}>
          <ButtonGroup spacing={2}>
            <IconButton
              type="button"
              variantColor="cyan"
              variant="outline"
              icon="small-close"
              onClick={onReset}
            />
            <FormButton type="submit" minWidth="6rem" variantColor="cyan">
              Submit
            </FormButton>
          </ButtonGroup>
        </Box>

        {/* <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                eLoan ID
              </Text>
            }
            placeholder="Search"
            name="eloanId"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
            onChange={(e) =>
              onFilterChange({
                eloanId: e.target.value,
              })
            }
          />
        </Box>
        <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                Shop ID or Name
              </Text>
            }
            placeholder="Search"
            name="shopIdOrName"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
            onChange={(e) =>
              onFilterChange({
                shopId: e.target.value,
                shopName: e.target.value,
              })
            }
          />
        </Box>
        <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                Transaction ID
              </Text>
            }
            placeholder="Search"
            name="transactionId"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
            onChange={(e) =>
              onFilterChange({
                transactionId: e.target.value,
              })
            }
          />
        </Box>
        <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                Loanee Name or Phone
              </Text>
            }
            placeholder="Search"
            name="loaneeNameOrPhone"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
            onChange={(e) =>
              onFilterChange({
                createdByUserName: e.target.value,
                createdByUserPhoneNumber: e.target.value,
              })
            }
          />
        </Box> */}
      </Stack>
    </Form>
  )
}

export default AppBkashPaymentsPageFilter
