import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import {
  Form,
  FormButton,
  FormCheckbox,
  FormInput,
  FormSelect,
  FormTextarea,
  handleAPIError,
  InfoCard,
} from '@eloan/shared'
import { get } from 'lodash-es'
import React, { useCallback, useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { verifyBkashTransaction } from 'store/nobodar'
import { useNobodarApplication } from 'store/nobodar/hooks'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    feeDepositId: Yup.string().required('Required.'),
    amount: Yup.number()
      .integer()
      .typeError('Must be a number.')
      .moreThan(0, 'More than 0.')
      .required('Required.'),
    trxIdIncorrect: Yup.boolean(),
    correctTransactionId: Yup.string().when('trxIdIncorrect', {
      is: true,
      then: Yup.string().required(`Required.`),
      otherwise: Yup.string().oneOf(
        [''],
        'Only required when the given Transaction ID is incorrect.'
      ),
    }),
    note: Yup.string().when('trxIdIncorrect', {
      is: true,
      then: Yup.string().required(`Required.`),
      otherwise: Yup.string().notRequired(),
    }),
  })
}

const getDefaultValues = (unverfiedDeposits) => {
  const defaultValues = {
    feeDepositId: get(unverfiedDeposits[0], 'id', ''),
    amount: '',
    trxIdIncorrect: false,
    correctTransactionId: '',
  }
  return defaultValues
}

const customSelectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.isDisabled
        ? 'hsl(0, 0%, 100%)'
        : provided.backgroundColor,
    }
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
      color: state.isDisabled ? 'hsl(0,0%,20%)' : provided.color,
    }
  },
}

function VerifyPayment({ applicationId, ...props }) {
  const application = useNobodarApplication(applicationId)

  const { isOpen, onClose, onOpen } = useDisclosure()

  const toast = useToast()

  const unverfiedDeposits = useMemo(
    () =>
      get(application, 'feeDeposits', []).filter(
        (deposit) => get(deposit, 'bkashTransaction.status') === 'unverified'
      ),
    [application]
  )

  const defaultValues = useMemo(() => getDefaultValues(unverfiedDeposits), [
    unverfiedDeposits,
  ])
  const validationSchema = useMemo(getValidationSchema, [])

  const form = useForm({ defaultValues, validationSchema })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const confirm = useCallback(
    async ({ feeDepositId, amount, correctTransactionId, note }) => {
      try {
        await dispatch(
          verifyBkashTransaction({
            applicationId,
            feeDepositId,
            amount,
            correctTransactionId: correctTransactionId?.trim(),
            note,
          })
        )
        onClose()
      } catch (error) {
        handleAPIError(error, { form, toast })
      }
    },
    [applicationId, dispatch, form, onClose, toast]
  )

  const transactionOptions = useMemo(
    () =>
      unverfiedDeposits.reduce(
        (previous, { id, transactionId }) => ({
          ...previous,
          [id]: transactionId,
        }),
        {}
      ),
    [unverfiedDeposits]
  )

  const trxIdIncorrect = form.watch('trxIdIncorrect')

  return (
    <>
      {unverfiedDeposits.length > 0 && (
        <Button size="sm" variantColor="green" {...props} onClick={onOpen}>
          Verify Payment
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={confirm}>
            <ModalHeader>Verify Payment Manually</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack isInline spacing={6} mb={6}>
                <InfoCard
                  label="Name"
                  value={get(application, 'fullName')}
                  valueFontSize="md"
                />
                <InfoCard
                  label="Phone number"
                  value={get(application, 'phone')}
                  valueFontSize="md"
                />
              </Stack>

              <Stack spacing={4}>
                <Box>
                  <FormSelect
                    name="feeDepositId"
                    label={
                      <Stack isInline spacing={4} alignItems="center">
                        <Text>Transaction ID</Text>
                        <Box>
                          <FormCheckbox
                            name="trxIdIncorrect"
                            variantColor="red"
                          >
                            Incorrect
                          </FormCheckbox>
                        </Box>
                      </Stack>
                    }
                    placeholder="Select Transaction ID"
                    options={transactionOptions}
                    isDisabled={unverfiedDeposits.length === 1}
                    isSearchable={false}
                    styles={customSelectStyles}
                  />
                </Box>
                {trxIdIncorrect && (
                  <Box>
                    <FormInput
                      name="correctTransactionId"
                      label="Correct Transaction ID *"
                    />
                  </Box>
                )}
                <Box>
                  <FormInput
                    name="amount"
                    label={`Amount *`}
                    placeholder="Enter amount"
                  />
                </Box>
                <Box>
                  <FormTextarea
                    name="note"
                    label={`Note${trxIdIncorrect ? ' *' : ''}`}
                  />
                </Box>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button mr={4} variantColor="green" onClick={onClose}>
                CANCEL
              </Button>
              <FormButton type="submit" variantColor="cyan">
                VERIFY
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default VerifyPayment
