import React, { useMemo, useCallback } from 'react'
import {
  Box,
  Stack,
  SimpleGrid,
  Flex,
  FormLabel,
  Input,
  Radio,
} from '@chakra-ui/core'
import Form from 'components/Form/Form'
import { useForm, Controller } from 'react-hook-form'
import FormSelect from 'components/Form/Select'
import FormInput from 'components/Form/Input'
import FormButton from 'components/Form/Button'
import * as Yup from 'yup'
import { useLoanProducts, useLoanProductById } from 'store/admin/hooks'
import { zipObject, map, get, reduce, find, keyBy } from 'lodash-es'
import { LoanProductFiClientConfig } from 'pages/admin/components/Configuration/ConfigItem'
import InfoBox from 'components/Box/InfoBox'
import { findInstallmentCount, convertToDays } from 'utils/installments'
import { calculateEMI } from '@shopuptech/credit-calc'

const getValidationSchema = () =>
  Yup.object({
    loanProductId: Yup.string().required('Required.'),
    offerAmount: Yup.number()
      .typeError('Must be number.')
      .required('Required.'),
    clientTenure: Yup.number()
      .typeError('Must be number.')
      .required('Required.'),
    fiTenure: Yup.number().typeError('Must be number.').required('Required.'),
    loanProductFiClientConfigId: Yup.string().required('Required.'),
    financialInstitutionId: Yup.string().required('Required.'),
  })

const getDefaultValues = (data) => ({
  financialInstitutionId: get(
    data,
    'loanProductFiClientConfig.fiConfig.financialInstitution.id'
  ),
  loanProductId: get(
    data,
    'loanProductFiClientConfig.loanProductConfig.loanProduct.id'
  ),
  loanProductFiClientConfigId: get(data, 'loanProductFiClientConfig.id'),
  offerAmount: get(data, 'offerAmount'),
  fiTenure: get(data, 'fiTenure'),
  clientTenure: get(data, 'clientTenure'),
})

function OfferForm({ applicationId, draftAccount, onConfirmOpen }) {
  const defaultValues = useMemo(() => getDefaultValues(draftAccount), [
    draftAccount,
  ])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({ validationSchema, defaultValues })

  const { watch, setError, clearError, control, errors } = form

  const loanProducts = useLoanProducts()

  const loanProductOptions = useMemo(
    () =>
      zipObject(
        loanProducts.allIds,
        loanProducts.allIds.map((id) => loanProducts.byId[id].productName)
      ),
    [loanProducts]
  )

  const loanProductId = watch('loanProductId')
  const financialInstitutionId = watch('financialInstitutionId')
  const loanProductFiClientConfigId = watch('loanProductFiClientConfigId')
  const clientTenure = watch('clientTenure')
  const offerAmount = watch('offerAmount')

  const loanProduct = useLoanProductById(loanProductId)

  const fiOptions = useMemo(
    () =>
      reduce(
        loanProduct.loanProductConfigs,
        (obj, { financialInstitution }) => ({
          ...obj,
          [financialInstitution.id]: financialInstitution.name,
        }),
        {}
      ),
    [loanProduct]
  )

  const loanProductConfigByFi = useMemo(() => {
    const loanProductConfig = find(
      loanProduct.loanProductConfigs,
      ({ financialInstitution }) =>
        financialInstitution.id === parseInt(financialInstitutionId)
    )
    if (loanProductConfig) {
      return {
        ...loanProductConfig,
        loanProductFiClientConfigsById: keyBy(
          loanProductConfig.loanProductFiClientConfigs,
          'id'
        ),
      }
    }
  }, [loanProduct, financialInstitutionId])

  const loanProductFiClientConfig = useMemo(() => {
    if (loanProductFiClientConfigId) {
      return get(
        loanProductConfigByFi,
        `loanProductFiClientConfigsById.${loanProductFiClientConfigId}`
      )
    }
  }, [loanProductConfigByFi, loanProductFiClientConfigId])

  const numberOfInstallments = useMemo(() => {
    if (loanProductFiClientConfigId && parseInt(clientTenure)) {
      return findInstallmentCount(
        {
          unit: get(
            loanProductFiClientConfig,
            'clientConfig.installmentPeriodUnit'
          ),
          value: get(
            loanProductFiClientConfig,
            'clientConfig.installmentPeriod'
          ),
        },
        {
          unit: get(loanProductFiClientConfig, 'clientConfig.tenureUnit'),
          value: parseInt(clientTenure),
        }
      )
    }
  }, [loanProductFiClientConfig, clientTenure, loanProductFiClientConfigId])

  const installmentAmountWithInterest = useMemo(() => {
    if (
      loanProductFiClientConfig &&
      parseFloat(offerAmount) &&
      numberOfInstallments
    ) {
      return calculateEMI({
        repaymentMethod: get(
          loanProductFiClientConfig,
          'clientConfig.interestCalculationMethod'
        ),
        numberOfInstallments,
        principal: offerAmount,
        compoundFactor:
          get(loanProductFiClientConfig, 'clientConfig.interestType') ===
          'SIMPLE'
            ? 'NONE'
            : get(
                loanProductFiClientConfig,
                'clientConfig.interestChargeFrequency'
              ),
        installmentLength: convertToDays({
          unit: get(
            loanProductFiClientConfig,
            'clientConfig.installmentPeriodUnit'
          ),
          value: get(
            loanProductFiClientConfig,
            'clientConfig.installmentPeriod'
          ),
        })?.value,
        yearlyInterestRate: get(
          loanProductFiClientConfig,
          'clientConfig.interestRate'
        ),
        daysInYear: get(loanProductFiClientConfig, 'clientConfig.daysInYear'),
      })
    }
  }, [loanProductFiClientConfig, numberOfInstallments, offerAmount])

  const onSubmit = useCallback(
    async ({
      offerAmount,
      clientTenure,
      fiTenure,
      loanProductFiClientConfigId,
    }) => {
      if (
        offerAmount < get(loanProductConfigByFi, 'minCreditLimit') ||
        offerAmount > get(loanProductConfigByFi, 'maxCreditLimit')
      ) {
        setError(
          'offerAmount',
          'rangeError',
          'Must be within min/max range of offer amount (from config).'
        )
        return
      }

      if (
        clientTenure <
          get(loanProductFiClientConfig, 'clientConfig.minTenure') ||
        clientTenure > get(loanProductFiClientConfig, 'clientConfig.maxTenure')
      ) {
        setError(
          'clientTenure',
          'rangeError',
          'Must be within min/max tenure of client config.'
        )
        return
      }

      if (
        fiTenure < get(loanProductFiClientConfig, 'fiConfig.minTenure') ||
        fiTenure > get(loanProductFiClientConfig, 'fiConfig.maxTenure')
      ) {
        setError(
          'fiTenure',
          'rangeError',
          'Must be within min/max tenure of fi config.'
        )
        return
      }

      clearError()

      onConfirmOpen({
        data: {
          eloanId: applicationId,
          offerAmount,
          clientTenure,
          fiTenure,
          loanProductFiClientConfigId,
        },
        form,
      })
    },
    [
      onConfirmOpen,
      applicationId,
      loanProductConfigByFi,
      setError,
      clearError,
      loanProductFiClientConfig,
      form,
    ]
  )

  return (
    <>
      <Form form={form} width="80%" onSubmit={onSubmit}>
        <Stack spacing={4}>
          <Box>
            <FormSelect
              label="Select Product Name"
              name="loanProductId"
              options={loanProductOptions}
            />
          </Box>
          <Box>
            <FormSelect
              label="Select Bank or FI's"
              name="financialInstitutionId"
              options={fiOptions}
            />
          </Box>
          <Box color="red.500">
            {get(errors, 'loanProductFiClientConfigId') &&
              'Select a loan config.'}
          </Box>
          <Box p={4} borderWidth="1px">
            <Stack isInline spacing={10}>
              <InfoBox label="Min Credit Limit">
                {get(loanProductConfigByFi, 'minCreditLimit')}
              </InfoBox>
              <InfoBox label="Max Credit Limit">
                {get(loanProductConfigByFi, 'maxCreditLimit')}
              </InfoBox>
            </Stack>
            {map(
              get(loanProductConfigByFi, 'loanProductFiClientConfigsById', {}),
              (item, index) => (
                <Flex justify="space-between" key={get(item, 'id')}>
                  <Controller
                    as={Radio}
                    control={control}
                    name={`loanProductFiClientConfigId`}
                    value={get(item, 'id')}
                    valueName="checked"
                    defaultIsChecked={
                      get(draftAccount, 'loanProductFiClientConfig.id') ===
                      get(item, 'id')
                    }
                  >
                    <LoanProductFiClientConfig
                      key={index}
                      fiConfigType={get(item, 'fiLoanConfigType')}
                      clientConfigType={get(item, 'clientLoanConfigType')}
                      fiConfig={item.fiConfig}
                      clientConfig={item.clientConfig}
                      fiConfigSerial={index + 1}
                      clientConfigSerial={index + 2}
                    />
                  </Controller>
                </Flex>
              )
            )}
          </Box>
          <SimpleGrid columns={2} gridGap={4}>
            <Box>
              <FormInput label="Offer Amount" name="offerAmount" />
            </Box>
            <Box>
              <FormInput
                label={`Loanee Tenure (${get(
                  loanProductFiClientConfig,
                  'clientConfig.tenureUnit',
                  '-'
                )})`}
                name="clientTenure"
              />
            </Box>
            <Box>
              <FormInput
                type="number"
                label={`FI Tenure (${get(
                  loanProductFiClientConfig,
                  'fiConfig.tenureUnit',
                  '-'
                )})`}
                name="fiTenure"
              />
            </Box>
            <Box>
              <FormLabel>Installment Amount With Interest</FormLabel>
              <Input readOnly value={installmentAmountWithInterest || ''} />
            </Box>
            <Box>
              <FormLabel>Number of Installments</FormLabel>
              <Input readOnly value={numberOfInstallments || ''} />
            </Box>
          </SimpleGrid>
          <Box>
            <FormButton type="submit" variantColor="cyan" px={10}>
              SAVE OFFER
            </FormButton>
          </Box>
        </Stack>
      </Form>
    </>
  )
}

export default OfferForm
