import { Badge, Box, SimpleGrid, Stack, Text } from '@chakra-ui/core'
import { ceilToDecimal, displayBDT } from '@eloan/shared'
import { CountUpNumber } from '@eloan/shared/components/Animated'
import { DataTable } from '@eloan/shared/components/Table'
import { toCeiled2F0PNumber, toRounded2F2PNumber } from '@eloan/shared/utils'
import StatusBadge from 'components/Application/StatusBadge'
import InfoBox from 'components/Box/InfoBox'
import { get, map, sum } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { useApplication } from 'store/applications/hooks'
import { emptyArray } from 'utils/defaults'

const getColumns = () => [
  {
    Header: 'Withdrawal Date',
    accessor: ({ withdrawlDate }) =>
      DateTime.fromISO(withdrawlDate).toLocaleString(DateTime.DATE_MED),
  },
  {
    Header: 'Due Date',
    accessor: ({ repaymentDeadline }) => (
      <Text minW="100px">
        {DateTime.fromISO(repaymentDeadline).toLocaleString(DateTime.DATE_MED)}
      </Text>
    ),
  },
  {
    Header: 'Withdrawal Amount',
    accessor: ({ amount }) => displayBDT(amount),
  },
  {
    Header: 'Total Repayable',
    accessor: ({ repayable }) =>
      displayBDT(ceilToDecimal(get(repayable, 'primary.total'), 0)),
  },
  {
    Header: 'Repayable Interest',
    accessor: ({ repayable }) => displayBDT(get(repayable, 'primary.interest')),
  },
  {
    Header: 'Repayable Penalty',
    accessor: ({ repayable }) => displayBDT(get(repayable, 'primary.penalty')),
  },
  {
    Header: 'Repaid Amount',
    accessor: ({ repaymentBreakdown }) =>
      displayBDT(sum(Object.values(repaymentBreakdown))),
  },
  {
    Header: 'Remaining Repayable',
    accessor: ({ repayable }) =>
      displayBDT(ceilToDecimal(get(repayable, 'outstanding.total'), 0)),
  },
  {
    Header: 'Repayment Status',
    accessor: ({ repaymentStatus, isOverdue }) => (
      <Stack isInline spacing={4}>
        <Badge>{repaymentStatus}</Badge>
        {isOverdue && <StatusBadge text="OVERDUE" variantColor="red" p={0} />}
      </Stack>
    ),
  },
]

function RLAccount({ applicationId }) {
  const application = useApplication(applicationId)

  const withdrawls = useMemo(() => {
    return get(application, 'rlAccount.loans', emptyArray).sort(
      (a, b) =>
        new Date(get(a, 'withdrawlDate')) - new Date(get(b, 'withdrawlDate'))
    )
  }, [application])

  const columns = useMemo(() => getColumns(), [])

  const table = useTable({
    data: withdrawls,
    columns: columns,
  })

  return (
    <Box>
      <SimpleGrid columns={4} mb={6}>
        <InfoBox
          label="Base Limit"
          content={
            <CountUpNumber
              loading={!application || !application.rlAccount}
              number={get(application, 'rlAccount.principalCredit')}
              prefix="BDT "
              formatter={toRounded2F2PNumber}
            />
          }
        />
        <InfoBox
          label="Current Limit"
          content={
            <CountUpNumber
              loading={!application || !application.rlAccount}
              number={get(application, 'rlAccount.remainingCredit')}
              prefix="BDT "
              formatter={toRounded2F2PNumber}
            />
          }
        />
        <InfoBox
          label="Total Collected"
          content={
            <CountUpNumber
              loading={!application || !application.rlAccount}
              number={sum(
                map(
                  get(application, 'rlAccount.loans', emptyArray),
                  'amountRepaid'
                )
              )}
              prefix="BDT "
              formatter={toRounded2F2PNumber}
            />
          }
        />
        <InfoBox
          label="Total Due"
          content={
            <CountUpNumber
              loading={!application || !application.rlAccount}
              number={get(application, 'rlAccount.outstanding.total')}
              prefix="BDT "
              formatter={toCeiled2F0PNumber}
            />
          }
        />
      </SimpleGrid>

      <DataTable {...table} />
    </Box>
  )
}

export default RLAccount
