import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Text,
  SimpleGrid,
} from '@chakra-ui/core'
import { get } from 'lodash-es'

export function ConfirmOffer({
  title,
  subtitle,
  onConfirm,
  isOpen,
  onClose,
  isLoading,
  data,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="lg" mb={4}>
            {subtitle}
          </Text>
          <SimpleGrid spacing={2} columns={2} borderTopWidth="1px" pt={4}>
            <Text color="gray.500">eloan ID</Text>
            <Text>{get(data, 'eloanId')}</Text>
            <Text color="gray.500">Offer Amount</Text>
            <Text>{get(data, 'offerAmount')}</Text>
            <Text color="gray.500">Client Tenure</Text>
            <Text>{get(data, 'clientTenure')}</Text>
            <Text color="gray.500">FI Tenure</Text>
            <Text>{get(data, 'fiTenure')}</Text>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Button flex={1} variantColor="red" mr={3} onClick={onClose}>
            CANCEL
          </Button>
          <Button
            flex={1}
            variantColor="cyan"
            onClick={onConfirm}
            isLoading={isLoading}
          >
            CONFIRM
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
