import React from 'react'

export const user = {
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.927 13.03c-.095.562-.562.97-1.113.97H1.186c-.55 0-1.018-.408-1.114-.97a4.094 4.094 0 0 1 2.4-4.57c.818-.36 2.08-.578 3.447-.594.02-.003.037-.003.056-.003h.05a.33.33 0 0 1 .055.003c1.386.016 2.62.231 3.478.606 1.008.405 2.827 1.89 2.37 4.559M6 0C7.79 0 9.248 1.511 9.248 3.368c0 1.858-1.457 3.369-3.248 3.369-1.792 0-3.249-1.511-3.249-3.369C2.751 1.511 4.208 0 6 0"
    />
  ),
  viewBox: '0 0 14 14',
}

export const shop = {
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.1111 0H.888889v1.75H15.1111V0zM16 8.75V7l-.8889-4.375H.888889L0 7v1.75h.888889V14H9.77778V8.75h3.55552V14h1.7778V8.75H16zm-13.33333 3.5H8v-3.5H2.66667v3.5z"
      clipRule="evenodd"
    />
  ),
  viewBox: '0 0 16 16',
}

export const taka = {
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.37671 14c-.426 0-.79148-.0531-1.08703-.1581-.30731-.1081-.55821-.2556-.748-.4391-.18862-.1827-.3455-.4248-.46537-.7206-.12163-.2989-.18392-.7867-.18979-1.4926l-.04407-5.20019c-.00118-.13709-.10812-.24639-.23797-.24516l-1.20985.01112c-.13044.00124-.23679-.10868-.23797-.24516l-.00822-1.0183c-.00118-.1371.10282-.24825.23327-.2501l1.20984-.01112c.13044-.00123.23503-.11301.23327-.24948l-.00529-.60888c-.0047-.58974-.07815-1.04733-.21917-1.36104-.0517-.11733-.21153-.47426-.9748-.47426-.22799.00185-.43834.03273-.645762.09139-.198017.05558-.37488.11486-.525302.17662C.304073 1.86679.0190936.73486.0190936.73486-.0337892.605796.0273198.453266.151301.403864.440393.288386.720085.197609.986262.132151 1.32941.0481673 1.70606.00370517 2.10796 0c.71099 0 1.24334.195139 1.61587.57986.22563.232808.40132.55639.52178.96211.12104.41004.18626.99484.19214 1.73896l.00646.68422c.00118.13648.10753.2464.23739.24516l4.65474-.05249c.12986-.00123.2368.10869.23797.24516l.00823 1.01831c.00117.13709-.10342.24886-.23327.2501l-4.65475.0531c-.12985.00062-.23444.11239-.23327.24949l.04407 5.16312c.00411.5237.07286.8775.20977 1.0819.13456.2014.40426.3033.80205.3033.46596-.0038.91076-.1322 1.34499-.3817.43481-.2501.79559-.578 1.07235-.9751.28204-.4063.4166-.8268.41307-1.28627-.00235-.24331-.07521-.44092-.21682-.58789-.14043-.14388-.37723-.21613-.70393-.21613-.17627.00123-.32023.00926-.45127.02284-.12515.01359-.24384.03767-.35431.07226l-.18391-1.18442c-.01998-.1303.0564-.25566.17921-.28283.13632-.02964.28087-.05682.43246-.08213.24561-.03953.49416-.06114.7386-.06299.66632 0 1.18986.16426 1.5906.48784.40661.32853.61579.85775.62166 1.57347.00526.60705-.12456 1.18005-.38487 1.70255-.26147.5249-.6199.9905-1.0647 1.3839-.44363.3952-.94484.712-1.48836.9411-.54175.2285-1.09115.347-1.63408.3526L5.37671 14z"
    />
  ),
  viewBox: '0 0 14 14',
}

export const location = {
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3908 8.41888H9.10461c.95839-1.55485 1.44379-2.96583 1.44379-4.20217C10.5484 1.89163 8.69994 0 6.42784 0 4.15573 0 2.30729 1.89163 2.30729 4.21671c0 1.23634.48537 2.64743 1.44378 4.20217h-2.2862L0 14h12.8557l-1.4649-5.58112zM6.42784 2.25522c1.1787 0 2.13763.98128 2.13763 2.1875 0 1.20611-.95893 2.1875-2.13763 2.1875S4.2902 5.64883 4.2902 4.44272c0-1.20622.95894-2.1875 2.13764-2.1875zM4.28989 9.2392h-2.2103l-1.03431 3.9405H11.8104l-1.0342-3.9405H8.56589c-.73207 1.0457-1.38385 1.7832-2.13805 2.546-.7755-.7841-1.4467-1.5585-2.13795-2.546z"
    />
  ),
  viewBox: '0 0 14 14',
}
