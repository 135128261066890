import { get } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicationPACallLogs } from 'store/paCallLogs'
import { emptyArray } from 'utils/defaults'

export function useApplicationPACallLogs(applicationId) {
  const byId = useSelector((state) => state.paCallLogs.byId)
  const allIds = useSelector((state) =>
    get(state.paCallLogs.idsByApplication, applicationId, emptyArray)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApplicationPACallLogs(applicationId))
  }, [applicationId, dispatch])

  const paCallLogs = useMemo(() => ({ byId, allIds }), [allIds, byId])

  return paCallLogs
}
