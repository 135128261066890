import React, { useMemo } from 'react'
import Authorize from 'components/Authorize'
import {
  Heading,
  Box,
  Button,
  Link,
  Text,
  Badge,
  List,
  ListItem,
  ListIcon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/core'
import { useParams } from 'react-router-dom'
import { getDate, DataTable } from '@eloan/shared'
import { useTable } from 'react-table'
import { get } from 'lodash-es'
import { useApplication } from 'store/applications/hooks'
import LoanDetails from 'pages/repayment-followup/LoanDetails'
import {
  useCallTeamFollowupHistory,
  useRepaymentOfflineFollowupHistory,
} from 'store/repaymentFollowup/hooks'

const fields = [
  '*',
  'shop.id',
  'offer.id',
  'offer.loanAmount',
  'offer.shopupSelectedTenure',
  'meta.loaneeName',
  'meta.loaneeNidName',
  'meta.shopAreaId',
  'paAssignment.paId',
].join(',')

const getColumns = () => [
  { Header: 'Serial', Cell: (cell) => cell.row.index + 1 },
  { Header: 'Agent', accessor: 'followupByAgent.fullName' },
  { Header: 'Status', accessor: 'followupStatus' },
  {
    Header: 'Call / Visit Time',
    accessor: ({ followupTime }) => getDate(followupTime),
  },
  {
    Header: 'Commitment Date',
    accessor: ({ committedDate }) => getDate(committedDate),
  },
  { Header: 'Commitment Message', accessor: 'note' },
]

function History({ applicationId }) {
  const history = useRepaymentOfflineFollowupHistory(applicationId)
  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => history, [history])

  const table = useTable({ columns, data })

  return <DataTable {...table} />
}

const callHistoryColumns = [
  { Header: 'Serial', Cell: ({ row }) => row.index + 1 },
  {
    Header: 'Call Date',
    accessor: 'callTime',
    Cell: ({ cell: { value } }) => (
      <Text whiteSpace="nowrap">{getDate(value)}</Text>
    ),
  },
  {
    Header: 'Call Status',
    accessor: ({ callStatus }) => <Badge variant="solid">{callStatus}</Badge>,
  },
  {
    Header: 'Commitment Date',
    accessor: 'committedDate',
    Cell: ({ cell: { value } }) => (
      <Text whiteSpace="nowrap">{getDate(value)}</Text>
    ),
  },
  {
    Header: 'Messages',
    accessor: ({ note }) => {
      const notes = (note || '')
        .split('\n\n\n')
        .filter(Boolean)
        .map((note) => note.replace(/\[User:\d+:/, '['))

      return (
        <List spacing={3}>
          {notes.map((note, index) => (
            <ListItem key={index}>
              <ListIcon icon="chat" />
              {note}
            </ListItem>
          ))}
        </List>
      )
    },
  },
]

function CallTeamHistory({ applicationId }) {
  const history = useCallTeamFollowupHistory(applicationId)
  const data = useMemo(() => history, [history])

  const columns = useMemo(() => callHistoryColumns, [])
  const table = useTable({
    data,
    columns,
  })

  return <DataTable {...table} />
}

function RepaymentFollowupFieldTeamView() {
  const { applicationId } = useParams()

  const application = useApplication(applicationId, fields)

  const repaymentDetailsLink = useMemo(() => {
    const isNewEloan = get(application, 'isNewEloan')

    if (isNewEloan) {
      return `/dash/eloan-applications/${get(
        application,
        'id'
      )}/loanee-repayment`
    }

    return `${window.location.origin.replace(
      '//eloan.',
      '//sap.'
    )}/merchant-advance/loan-offers/loan-pay?shopId=${get(
      application,
      'shop.id'
    )}&loanOfferId=${get(application, 'offer.id')}`
  }, [application])

  return (
    <Authorize permissions="eloan:AnyApplication:read">
      <Box mb={10}>
        <Heading size="lg" mb={2}>
          {`${
            get(application, 'meta.loaneeName', '...') ??
            get(application, 'meta.loaneeNidName') ??
            'N/A'
          } | eLoan ID: ${get(application, 'id', '...')} | ${get(
            application,
            'phoneNumber',
            '...'
          )}`}
        </Heading>
        <Button
          size="sm"
          variant="outline"
          variantColor="blue"
          as={Link}
          href={repaymentDetailsLink}
          target="_blank"
          _hover={{
            textDecoration: 'none',
          }}
        >
          View Repayment Details
        </Button>
      </Box>
      <Box>
        <LoanDetails application={application} />
        <Tabs mt={6}>
          <TabList>
            <Tab>Field Team History</Tab>
            <Tab>Call History</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <History applicationId={applicationId} />
            </TabPanel>
            <TabPanel>
              <CallTeamHistory applicationId={applicationId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Authorize>
  )
}

export default RepaymentFollowupFieldTeamView
