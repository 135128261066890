import React, { useCallback, useMemo } from 'react'
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast,
  Stack,
  Text,
} from '@chakra-ui/core'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { Form, FormTextarea, FormButton, handleAPIError } from '@eloan/shared'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { api } from 'api'
import { shopUpFinancialInstitutionId } from 'pages/admin/constants'
import { APPLICATION_PAGINATION_PURGE } from 'store/applications'
import Authorize from 'components/Authorize'

function RevertLoan({ applicationId, ...props }) {
  const dispatch = useDispatch()

  const toast = useToast()

  const history = useHistory()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const defaultValues = useMemo(
    () => ({
      note: '',
    }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        note: Yup.string()
          .trim()
          .min(10, 'Minimum 10 characters.')
          .required('Required.'),
      }),
    []
  )

  const form = useForm({ defaultValues, validationSchema })

  const handleSubmit = useCallback(
    async ({ note }) => {
      try {
        await api(
          'POST /legacy/v3/eloan/partners/{partnerId}/applications/{applicationId}/offer/action/send-to-need-revision',
          {
            applicationId,
            partnerId: shopUpFinancialInstitutionId,
            note,
          }
        )
        dispatch({ type: APPLICATION_PAGINATION_PURGE })
        toast({
          title: 'Successfully action performed.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        onClose()
        history.push('/dash/finance/awaiting-disbursement')
      } catch (error) {
        handleAPIError(error, { toast })
      }
    },
    [dispatch, applicationId, toast, onClose, history]
  )

  return (
    <Authorize permissions="eloan:ApplicationForPartner:update">
      <Button px={10} onClick={onOpen} variantColor="orange" {...props}>
        REVERT
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Revert Application</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb={4}>
              Please enter the reason for reverting this application
            </Text>
            <Form form={form} onSubmit={handleSubmit}>
              <FormTextarea
                label={`Write note...`}
                name="note"
                placeholder="Note..."
              />
              <Stack isInline mt={4}>
                <Button flex={1} variantColor="green" onClick={onClose}>
                  CANCEL
                </Button>
                <FormButton type="submit" flex={1} variantColor="red" ml={4}>
                  SUBMIT
                </FormButton>
              </Stack>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default RevertLoan
