import { Box } from '@chakra-ui/core'
import React from 'react'
import { FormContext } from 'react-hook-form'

function Form({ onSubmit, form, ...props }) {
  return (
    <FormContext {...form}>
      <Box as="form" onSubmit={form.handleSubmit(onSubmit)} {...props} />
    </FormContext>
  )
}

export default Form
