export const PA_CALL_LOG_ADD = 'PA_CALL_LOG_ADD'
export const PA_CALL_LOG_ADD_BULK = 'PA_CALL_LOG_ADD_BULK'

export const getApplicationPACallLogs = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `/legacy/v4/eloan/applications/{applicationId}/pa-call-logs`,
    {
      applicationId,
    }
  )

  dispatch({ type: PA_CALL_LOG_ADD_BULK, data, applicationId })

  return data
}

export const createApplicationPACallLog = (
  applicationId,
  { note, status }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `POST /legacy/v4/eloan/applications/{applicationId}/pa-call-logs`,
    {
      applicationId,
      note,
      status,
    }
  )

  dispatch({ type: PA_CALL_LOG_ADD, data, applicationId })

  return data
}
