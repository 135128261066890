import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDistributorRoutes } from 'store/distributors/hooks'
import { updateRetailer } from 'store/retailers'
import { useRetailer } from 'store/retailers/hooks'
import { bangladeshMobileNumberPattern } from 'utils/regex'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    externalId: Yup.string().required(`required`),
    ownerName: Yup.string().required(`required`),
    phoneNumber: Yup.string()
      .matches(bangladeshMobileNumberPattern)
      .required(`required`),
    shopName: Yup.string().required(`required`),

    routeId: Yup.number().integer().required(`required`),
  })
}

const getDefaultValues = (retailer) => {
  return {
    externalId: get(retailer, 'externalId', ''),
    ownerName: get(retailer, 'ownerName', ''),
    phoneNumber: get(retailer, 'phoneNumber', ''),
    shopName: get(retailer, 'shopName', ''),

    routeId: get(retailer, 'routeId', ''),
  }
}

function EditRetailer() {
  const toast = useToast()

  const { retailerId } = useParams()

  const retailer = useRetailer(retailerId)

  const defaultValues = useMemo(() => getDefaultValues(retailer), [retailer])
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const history = useHistory()
  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ externalId, ownerName, phoneNumber, shopName, routeId }) => {
      try {
        await dispatch(
          updateRetailer(retailerId, {
            externalId,
            ownerName,
            phoneNumber,
            shopName,
            routeId,
          })
        )
        history.push('/dash/retailers')
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, history, retailerId, toast]
  )

  const routes = useDistributorRoutes(get(retailer, 'distributor.id'))

  const routeOptions = useMemo(() => {
    return zipObject(
      routes.allIds,
      routes.allIds.map(
        (id) =>
          `${get(routes.byId[id], 'code')} - ${get(routes.byId[id], 'name')}`
      )
    )
  }, [routes.allIds, routes.byId])

  return (
    <Authorize permissions="eloan:Retailer:update">
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>
            Edit Retailer: {get(retailer, 'externalId')}
          </Heading>
        </Box>

        <Box>
          <Button as={Link} to={'/dash/retailers'}>
            Go Back
          </Button>
        </Box>
      </Stack>

      <Form form={form} onSubmit={onSubmit}>
        <Stack spacing={4}>
          <FormInput name="externalId" label={`Retailer ID`} />

          <FormInput name="shopName" label={`Shop Name`} />

          <FormInput name="ownerName" label={`Owner Name`} />

          <FormInput name="phoneNumber" label={`Phone Number`} />

          <FormSelect name="routeId" label={`Route`} options={routeOptions} />

          <ButtonGroup>
            <FormButton type="button" onClick={() => formReset(defaultValues)}>
              Reset
            </FormButton>
            <FormButton type="submit" variantColor="green">
              Save
            </FormButton>
          </ButtonGroup>
        </Stack>
      </Form>
    </Authorize>
  )
}

export default EditRetailer
