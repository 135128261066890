import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { DataTable } from '@eloan/shared/components/Table'
import { FormDatePicker } from 'components/Form/DatePicker'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import { get, groupBy } from 'lodash-es'
import React, { useCallback, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useTable } from 'react-table'
import { settle } from 'store/settlements'
import { downloadAsCSV } from 'utils/downloadAsCSV'
import { getDate } from 'utils/getDate'
import * as Yup from 'yup'

const getColumns = () => [
  {
    Header: 'Sl.',
    Cell: ({ row }) => row.index + 1,
  },
  {
    Header: 'Date',
    accessor: ({ createdAt }) => getDate(createdAt),
  },
  {
    Header: 'Retailer Name',
    accessor: 'ownerName',
  },
  {
    Header: 'Phone',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Settlement Amount',
    accessor: 'principalRepaidAmount',
  },
  {
    Header: 'Bank Name',
    accessor: 'bankName',
  },
  {
    Header: 'Bank Branch',
    accessor: 'branchName',
  },
  {
    Header: 'Account No.',
    accessor: 'accountNumber',
  },
  {
    Header: 'Routing No.',
    accessor: 'routingNumber',
  },
]

function generateCSV(items) {
  const groupedItems = groupBy(items, 'retailerId')

  const itemsForCSV = []

  let counter = 0

  for (const index in groupedItems) {
    const uniqueItemData = get(groupedItems[index], '0')

    const totalAmount = groupedItems[index].reduce(
      (total, item) => total + item.principalRepaidAmount,
      0
    )

    counter++

    itemsForCSV.push({
      Serial: counter,
      'Payment Date': getDate(uniqueItemData.createdAt),
      'Retailer Name': uniqueItemData.ownerName,
      Phone: uniqueItemData.phoneNumber,
      'Bank Name': uniqueItemData.bankName,
      'Bank Branch': uniqueItemData.branchName,
      'Account Number': uniqueItemData.accountNumber,
      'Routing Number': uniqueItemData.routingNumber,
      'Settlement Amount': totalAmount,
    })
  }

  downloadAsCSV({
    data: itemsForCSV,
  })
}

const getDefaultValues = () => ({
  settlementDate: new Date(),
})

const getValidationSchema = () => {
  return Yup.object({
    settlementDate: Yup.date().notRequired(),
  })
}

function Settle({ items }) {
  const cancelRef = useRef()

  const { isOpen, onClose, onOpen } = useDisclosure()

  const itemsArray = useMemo(() => Object.values(items), [items])

  const columns = useMemo(() => getColumns(), [])

  const toast = useToast()

  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ settlementDate }) => {
      try {
        await dispatch(
          settle({ ids: itemsArray.map(({ id }) => id), settlementDate })
        )

        toast({
          title: 'Settled Successfully.',
          status: 'success',
          duration: 5000,
        })

        onClose()

        generateCSV(itemsArray)

        // window.location.reload()
      } catch (error) {
        handleAPIError(error, { toast })
      }
    },
    [toast, onClose, itemsArray, dispatch]
  )

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const table = useTable({
    data: itemsArray,
    columns: columns,
  })

  return (
    <>
      <Button
        px={6}
        leftIcon="download"
        variantColor="green"
        onClick={onOpen}
        disabled={!Object.keys(items).length}
      >
        SETTLE
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size="5xl"
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <Form form={form} onSubmit={onSubmit}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Settlements
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>Are you sure that you want to Settle these?</Text>

              <Stack mt={4} spacing={4}>
                <Box>
                  <FormDatePicker
                    name="settlementDate"
                    label={`Settlement Date`}
                    maxDate={new Date()}
                  />
                </Box>

                <Box>
                  <DataTable {...table} />
                </Box>
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" variantColor="green" ml={3}>
                Confirm &amp; Download BEFTN
              </Button>
            </AlertDialogFooter>
          </Form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default Settle
