import React from 'react'
import { Route, useRouteMatch, Redirect, Switch } from 'react-router-dom'
import PartnerBeftnList from './List'

function EloanPartnerBeftnPage() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}`} component={PartnerBeftnList} />
      <Redirect to={`${path}`} />
    </Switch>
  )
}

export default EloanPartnerBeftnPage
