import { Heading, Stack } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import LocationPhoto from 'components/Image/LocationPhoto'
import { get } from 'lodash-es'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Flex } from 'reflexbox'
import { useAreas } from 'store/areas/hooks'
import LoaneeInfo from './sections/LoaneeInfo'
import LoanInfo from './sections/LoanInfo'

function Locations({ applicationId }) {
  const areas = useAreas()

  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const shopArea = useMemo(() => {
    return areas.byId[get(application, 'meta.shopAreaId', null)]
  }, [application, areas.byId])

  return (
    <Stack spacing={8} width="100%">
      <LoanInfo application={application} />

      <LoaneeInfo application={application} shopArea={shopArea} />

      <Box>
        <Heading fontSize={5} mb={4}>
          Location Photos
        </Heading>

        <Flex flexDirection="row" flexWrap="wrap">
          <InfoBox label="Home Photo">
            <LocationPhoto
              applicationId={applicationId}
              photoField="meta.initialHomePhoto"
              statusField="meta.homePhotoStatus"
              latField="meta.initialHomeLat"
              lonField="meta.initialHomeLon"
              metaField="meta.initialHomeMeta"
            />
          </InfoBox>
          <InfoBox label="Shop Photo">
            <LocationPhoto
              applicationId={applicationId}
              photoField="meta.initialShopPhoto"
              statusField="meta.shopPhotoStatus"
              latField="meta.initialShopLat"
              lonField="meta.initialShopLon"
              metaField="meta.initialShopMeta"
            />
          </InfoBox>
          <InfoBox label="Marketplace Photo">
            <LocationPhoto
              applicationId={applicationId}
              photoField="meta.initialMarketplacePhoto"
              statusField="meta.marketplacePhotoStatus"
              latField="meta.initialMarketplaceLat"
              lonField="meta.initialMarketplaceLon"
              metaField="meta.initialMarketplaceMeta"
            />
          </InfoBox>
        </Flex>
      </Box>
    </Stack>
  )
}

export default Locations
