import { Heading, Icon, Link } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import React from 'react'
import { Box, Flex } from 'reflexbox'

function LegacySection({ application, ...props }) {
  return (
    <>
      <Box {...props}>
        <Heading fontSize={5} mb={4}>
          Link to SAP
        </Heading>

        <Flex flexDirection="row" flexWrap="wrap">
          <InfoBox>
            <Link
              fontSize={2}
              href={`${window.location.origin.replace(
                '//eloan.',
                '//sap.'
              )}/merchant-advance/loan-offers/loan-info?shopId=${get(
                application,
                'shop.id'
              )}&loanOfferId=${get(application, 'offer.id')}`}
              isExternal
            >
              Open Loan Info <Icon name="external-link" mx="2px" />
            </Link>
          </InfoBox>
        </Flex>
      </Box>
    </>
  )
}

export default LegacySection
