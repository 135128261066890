import camelcaseKeys from 'camelcase-keys'
import { get } from 'lodash-es'

export const BANK_LOADING_ALL = 'BANK_LOADING_ALL'
export const BANK_SET_ALL = 'BANK_SET_ALL'
export const BANK_BRANCH_LOADING_ALL_FOR_BANK =
  'BANK_BRANCH_LOADING_ALL_FOR_BANK'
export const BANK_BRANCH_SET_ALL_FOR_BANK = 'BANK_BRANCH_SET_ALL_FOR_BANK'

export const getAllBanks = () => async (dispatch, getState, { api }) => {
  const { banks } = getState()

  if (!banks.loading) {
    dispatch({ type: BANK_LOADING_ALL })

    const { data } = await api(`/legacy/v1/banks`)

    dispatch({
      type: BANK_SET_ALL,
      data: {
        items: camelcaseKeys(data.banks, { deep: true }),
        totalItems: data.banks.length,
      },
    })
  }
}

export const getAllBankBranches = (bankId) => async (
  dispatch,
  getState,
  { api }
) => {
  const { banks } = getState()

  if (!get(banks.byId[banks], 'branches.loading')) {
    dispatch({ type: BANK_BRANCH_LOADING_ALL_FOR_BANK, bankId })

    const { data } = await api(`/legacy/v1/banks/{bankId}/branches`, { bankId })

    dispatch({
      type: BANK_BRANCH_SET_ALL_FOR_BANK,
      data: {
        items: camelcaseKeys(data.branches, { deep: true }),
        totalItems: data.branches.length,
      },
      bankId,
    })
  }
}
