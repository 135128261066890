import {
  Button,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  SimpleGrid,
  Box,
  Stack,
} from '@chakra-ui/core'
import { handleAPIError } from 'components/Form/helpers'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { createBeftn } from 'store/beftn'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import * as Yup from 'yup'
import ErrorMessage from 'components/Form/ErrorMessage'
import { emptyArray } from 'utils/defaults'
import { useTable } from 'react-table'
import { DataTable } from '@eloan/shared'

function ConfirmSettlement({ selectedEloans }) {
  const [submitData, setSubmitData] = useState(emptyArray)

  const { isOpen, onClose, onOpen } = useDisclosure()

  const toast = useToast()

  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const history = useHistory()

  const onConfirm = useCallback(async () => {
    try {
      setIsSubmitting(true)

      await dispatch(
        createBeftn({
          beftnRequests: submitData,
        })
      )

      toast({
        title: 'Settled Successfully.',
        status: 'success',
        duration: 5000,
      })

      setIsSubmitting(false)

      history.push('/dash/admin/partner-beftn')
    } catch (error) {
      setIsSubmitting(false)
      handleAPIError(error, { toast })
    }
  }, [toast, history, dispatch, submitData])

  const handleSubmit = useCallback(({ eloans }) => {
    setSubmitData(eloans)
  }, [])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        eloans: Yup.array().of(
          Yup.object({
            amount: Yup.number()
              .typeError('Must be a number')
              .moreThan(0, 'Must be more than 0')
              .required('Required.'),
          })
        ),
      }),
    []
  )

  const form = useForm({ validationSchema })

  const { register, reset } = form

  const columns = useMemo(
    () => [
      { Header: 'eLoan Id', accessor: 'eloanId' },
      { Header: 'Amount', accessor: 'amount' },
    ],
    []
  )

  const table = useTable({
    data: submitData,
    columns,
  })

  useEffect(() => {
    if (isOpen) {
      reset()
      setSubmitData(emptyArray)
    }
  }, [isOpen, reset])

  return (
    <>
      <Button
        px={6}
        size="sm"
        variantColor="cyan"
        onClick={onOpen}
        disabled={!selectedEloans.length}
        isLoading={isSubmitting}
      >
        CONFIRM SETTLEMENT
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent px={4} py={6}>
          <ModalHeader fontSize="lg" fontWeight="bold">
            Are you sure you want to confirm this settlement?
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!!submitData.length ? (
              <Box>
                <Text>
                  Please click confirm to start the next process of BEFTN
                  download.
                </Text>
                <DataTable {...table} />
                <Stack isInline spacing={4} mt={6}>
                  <Button
                    px={10}
                    size="sm"
                    onClick={onClose}
                    variantColor="red"
                    variant="outline"
                  >
                    Cancel
                  </Button>
                  <Button
                    px={10}
                    size="sm"
                    variantColor="green"
                    ml={3}
                    onClick={onConfirm}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                  >
                    CONFIRM
                  </Button>
                </Stack>
              </Box>
            ) : (
              <Form form={form} onSubmit={handleSubmit}>
                <SimpleGrid
                  pb={2}
                  mb={2}
                  fontWeight={600}
                  alignItems="center"
                  columns={2}
                  gridGap={4}
                  borderBottomWidth="1px"
                >
                  <Text>eLoan ID</Text>
                  <Text>Amount</Text>
                </SimpleGrid>
                <SimpleGrid alignItems="center" columns={2} gridGap={4}>
                  {selectedEloans.map((loan, index) => (
                    <React.Fragment key={loan.eloanId}>
                      <Input
                        defaultValue={loan.eloanId}
                        name={`eloans[${index}].eloanId`}
                        isReadOnly
                        variant="unstyled"
                        ref={register()}
                      />
                      <Box>
                        <Input
                          defaultValue={loan.payableAmount}
                          name={`eloans[${index}].amount`}
                          ref={register()}
                        />
                        <ErrorMessage
                          as={Box}
                          name={`eloans[${index}].amount`}
                        />
                      </Box>
                    </React.Fragment>
                  ))}
                </SimpleGrid>
                <Stack isInline spacing={4} mt={6}>
                  <Button
                    px={10}
                    size="sm"
                    onClick={onClose}
                    variantColor="red"
                    variant="outline"
                  >
                    Cancel
                  </Button>
                  <Button
                    px={10}
                    type="submit"
                    size="sm"
                    variantColor="green"
                    ml={3}
                  >
                    CONTINUE
                  </Button>
                </Stack>
              </Form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmSettlement
