import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import RepaymentFollowupCallTeam from './call-team'
import RepaymentFollowupCCA from './cca'
import RepaymentFollowupFieldTeam from './field-team'

function RepaymentFollowupPage() {
  return (
    <Switch>
      <Route
        path="/dash/repayment-followup/call-team"
        component={RepaymentFollowupCallTeam}
      />
      <Route
        path="/dash/repayment-followup/field-team"
        component={RepaymentFollowupFieldTeam}
      />
      <Route
        path="/dash/repayment-followup/cca"
        component={RepaymentFollowupCCA}
      />
      <Redirect to="/dash/repayment-followup/field-team" />
    </Switch>
  )
}

export default RepaymentFollowupPage
