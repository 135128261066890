import { get, keyBy, map, union } from 'lodash-es'
import {
  BANK_BRANCH_LOADING_ALL_FOR_BANK,
  BANK_BRANCH_SET_ALL_FOR_BANK,
  BANK_LOADING_ALL,
  BANK_SET_ALL,
} from 'store/banks'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

export const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: false,
}

const banksReducer = (state = initialState, { type, data, bankId }) => {
  switch (type) {
    case BANK_LOADING_ALL:
      return {
        ...state,
        loading: true,
      }
    case BANK_SET_ALL:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        loading: false,
      }
    case BANK_BRANCH_LOADING_ALL_FOR_BANK:
      return {
        ...state,
        byId: {
          ...state.byId,
          [bankId]: {
            ...get(state.byId, bankId),
            branches: initialState,
          },
        },
      }
    case BANK_BRANCH_SET_ALL_FOR_BANK:
      return {
        ...state,
        byId: {
          ...state.byId,
          [bankId]: {
            ...get(state.byId, bankId),
            branches: {
              byId: {
                ...get(state.byId[bankId], 'branches.byId', emptyObject),
                ...keyBy(data.items, idKey),
              },
              allIds: union(
                get(state.byId[bankId], 'branches.allIds', emptyArray),
                map(data.items, idKey)
              ),
              loading: false,
            },
          },
        },
      }
    default:
      return state
  }
}

export default banksReducer
