export const NOTE_ADD = 'NOTE_ADD'
export const NOTE_ADD_BULK = 'NOTE_ADD_BULK'

export const getApplicationNotes = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `/legacy/v4/eloan/applications/{applicationId}/notes`,
    {
      applicationId,
    }
  )

  dispatch({ type: NOTE_ADD_BULK, data, applicationId })

  return data
}

export const createApplicationNote = (applicationId, { note, type }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `POST /legacy/v4/eloan/applications/{applicationId}/notes`,
    {
      applicationId,
      note,
      type,
    }
  )

  dispatch({ type: NOTE_ADD, data, applicationId })

  return data
}
