import {
  Text,
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import {
  Form,
  FormButton,
  FormSelect,
  FormTextarea,
  handleAPIError,
  Table,
} from '@eloan/shared'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  setNobodarApplicationShortlist,
  setNobodarApplicationStatus,
} from 'store/nobodar'
import { useNobodarApplication } from 'store/nobodar/hooks'
import * as Yup from 'yup'
import {
  nobodarApplicationShortlistOptions,
  nobodarApplicationStatusOptions,
} from '../../constants'
import {
  NobodarAssessmentQuestionnaireProvider,
  useNobodarAssessmentQuestionnaire,
  evaluateHidden,
} from '../Assessment/Provider'

const checklists = [
  {
    name: 'Document Checklist',
    stepName: 'doc_checklist',
    items: [
      {
        name: 'NID - Self',
        checks: [
          [
            {
              source: 'application',
              field: 'availableIdDocs',
              op: 'includes',
              value: 'nid',
            },
          ],
        ],
      },
      {
        name: 'NID - Guarantors',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 63,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
          [
            {
              source: 'assessmentQuestion',
              sourceId: 65,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Valid T/L',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 6,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: "Bank Statement - recent 1 year's",
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 88,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Copy of BIN',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 13,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name:
          'Copy of MOA, AOA, Certificate of Incorporation, Form X, updated Form XII',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 10,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Stock report',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 55,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Net worth statement - self',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 90,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
          // [
          //   {
          //     source: 'assessmentQuestion',
          //     sourceId: 91,
          //     field: 'answer.data.value',
          //     op: '=',
          //     value: true,
          //   },
          // ],
        ],
      },
      {
        name: 'Business specific valid license',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 17,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'All existing loans statement/sanction copy',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 40,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'All credit card recent bill copies',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 40,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Salary statement and certificate from HR',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 92,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'LOI (Letter of Introduction) from HR',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 93,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'EOSB assignment',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 94,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
    ],
  },
  {
    name: 'Eligibility Checklist',
    stepName: 'eligibility_checklist',
    items: [
      {
        name: 'What is the age of this trade license?',
        render: (isPassed, data) =>
          isPassed ? `${data} (> 3 Years)` : `${data} (< 3 Years)`,
        data: {
          source: 'assessmentQuestion',
          sourceId: 7,
          field: 'answer.data.value',
        },
        checks: [
          [
            {
              source: 'data',
              op: 'diffNow:months:>',
              value: 12 * 3,
            },
          ],
        ],
      },
      {
        name: 'Do you have regisered partnership deed?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 9,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name:
          'Do you have incoporation certificate, MOA, AOA, Form X and Form XII (updated) from RJSC?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 10,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Do you have eTIN',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 12,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Do you have any bank account?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 19,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name:
          'Is there any reflection of your business transactions in your accounts?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 24,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Is the status of the loan you guaranteed for regular',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 49,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: '(Business) Calculated DBR',
        render: (isPassed, data) => {
          const percentage = `${Number(data * 100).toFixed(2)}%`
          return isPassed ? `${percentage} (<= 30%)` : `${percentage} (> 30%)`
        },
        data: {
          source: 'assessmentQuestion',
          sourceId: 72,
          field: 'answer.data.value',
        },
        checks: [
          [
            {
              source: 'data',
              op: '<=',
              value: 0.3,
            },
          ],
        ],
      },
      {
        name:
          'Are you regularly repaying your loan EMIs and credit card minimum amount due?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 51,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name:
          'Have you ever been delayed on any repayment of your existing loans?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 53,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name:
          'Do you maintain formal/informal accounting statements, e.g.; stock position, supplier data like credit obtained, amount purchased, amount repaid, on-credit sales data like client name, address/contact no etc)',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 55,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Do you have audited financials?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 56,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Do you have credit ratings',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 57,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name:
          'Can you provide an immediate family member as guarantor with ager over 21 having NID?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 63,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name:
          'Can you provide a business acquiantance / office colleague as guarantor with ager over 21 having NID?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 65,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Is this additional earning have bank transaction reflection?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 70,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'Are you a regular full time staff?',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 74,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: 'How long (in years) is your employer in business?',
        render: (isPassed, data) =>
          isPassed ? `${data} (> 3 Years)` : `${data} (< 3 Years)`,
        data: {
          source: 'assessmentQuestion',
          sourceId: 77,
          field: 'answer.data.value',
        },
        checks: [
          [
            {
              source: 'data',
              op: '>',
              value: 3,
            },
          ],
        ],
      },
      {
        name: 'How long (in months) are your engaged with current employer',
        render: (isPassed, data) =>
          isPassed ? `${data} (> 6 Months)` : `${data} (< 6 Months)`,
        data: {
          source: 'assessmentQuestion',
          sourceId: 79,
          field: 'answer.data.value',
        },
        checks: [
          [
            {
              source: 'data',
              op: '>',
              value: 6,
            },
          ],
        ],
      },
      {
        name: 'How long (in months) have you been engaged on this earnings?',
        render: (isPassed, data) =>
          isPassed ? `${data} (< 6 Months)` : `${data} (> 6 Months)`,
        data: {
          source: 'assessmentQuestion',
          sourceId: 81,
          field: 'answer.data.value',
        },
        checks: [
          [
            {
              source: 'data',
              op: '<',
              value: 6,
            },
          ],
        ],
      },
      {
        name: 'What is your gross salary or revenue?',
        render: (isPassed, data) =>
          isPassed ? `${data} (>= 25,000)` : `${data} (< 25,000)`,
        data: {
          source: 'assessmentQuestion',
          sourceId: 83,
          field: 'answer.data.value',
        },
        checks: [
          [
            {
              source: 'data',
              op: '>=',
              value: 25000,
            },
          ],
        ],
      },
      {
        name:
          'What is your net salary or revenue after deducting tax, PF, other deductible?',
        render: (isPassed, data) =>
          isPassed ? `${data} (>= 23,000)` : `${data} (< 23,000)`,
        data: {
          source: 'assessmentQuestion',
          sourceId: 84,
          field: 'answer.data.value',
        },
        checks: [
          [
            {
              source: 'data',
              op: '>=',
              value: 23000,
            },
          ],
        ],
      },
      {
        name: 'Is your salary credited to bank',
        checks: [
          [
            {
              source: 'assessmentQuestion',
              sourceId: 85,
              field: 'answer.data.value',
              op: '=',
              value: true,
            },
          ],
        ],
      },
      {
        name: '(Personal) Calculated DBR',
        render: (isPassed, data) => {
          const percentage = `${Number(data * 100).toFixed(2)}%`
          return isPassed ? `${percentage} (<= 30%)` : `${percentage} (> 30%)`
        },
        data: {
          source: 'assessmentQuestion',
          sourceId: 87,
          field: 'answer.data.value',
        },
        checks: [
          [
            {
              source: 'data',
              op: '<=',
              value: 0.3,
            },
          ],
        ],
      },
    ],
  },
]

const getDefaultValues = (application) => ({
  status: get(application, 'status') || '',
  shortlist: get(application, 'shortlist') || '',
  note: '',
})

const validationSchema = Yup.object({
  status: Yup.string()
    .oneOf(Object.keys(nobodarApplicationStatusOptions))
    .required(`required`),
  shortlist: Yup.string().when('status', {
    is: 'shortlisted',
    then: Yup.string()
      .oneOf(Object.keys(nobodarApplicationShortlistOptions))
      .required(`required`),
    otherwise: Yup.string().notRequired(),
    note: Yup.string().min(5, 'too short!').required(`required`),
  }),
})

const prepareChecklistItem = (checklistItem, state, application) => {
  const { render, data, checks, ...otherProps } = checklistItem

  let content = ''
  let missing = false
  let hidden = false

  let dataValue = null

  if (data) {
    const dataSource =
      data.source === 'application'
        ? application
        : data.source === 'assessmentQuestion'
        ? state.questions.byId[data.sourceId]
        : null

    dataValue = get(dataSource, data.field, null)
  }

  const passed = checks.every((andPart) => {
    return andPart.some((orPart) => {
      let { source, sourceId, field, op, value } = orPart

      if (source === 'data') {
        source = data.source
        sourceId = data.sourceId
        field = data.field
      }

      if (source === 'assessmentQuestion') {
        hidden = evaluateHidden(state, sourceId)
      }

      const dataSource =
        source === 'application'
          ? application
          : source === 'assessmentQuestion'
          ? state.questions.byId[sourceId]
          : null

      if (!dataSource) {
        missing = true
        return false
      }

      const fieldValue = get(dataSource, field, null)

      if (fieldValue === null) {
        missing = true
        return false
      }

      switch (op) {
        case '=':
          return fieldValue === value
        case '!=':
          return fieldValue !== value
        case '<':
          return fieldValue < value
        case '<=':
          return fieldValue <= value
        case '>':
          return fieldValue > value
        case '>=':
          return fieldValue >= value
        case 'includes':
          return fieldValue.includes(value)
        case 'diffNow:months:<':
        case 'diffNow:months:<=':
        case 'diffNow:months:>':
        case 'diffNow:months:>=': {
          const datetime = DateTime.fromISO(fieldValue)

          const [, unit, compOp] = op.split(':')

          const diffNowValue = -datetime.diffNow(unit).values[unit]

          if (compOp === '<') {
            return diffNowValue < value
          } else if (compOp === '<=') {
            return diffNowValue <= value
          } else if (compOp === '>') {
            return diffNowValue > value
          } else if (compOp === '>=') {
            return diffNowValue >= value
          }

          missing = true
          return false
        }
        default:
          missing = true
          return false
      }
    })
  })

  if (render) {
    content = render(passed, dataValue)
  } else {
    content = passed ? 'Yes' : 'No'
  }

  if (missing) {
    content = '...'
  }

  return {
    ...otherProps,
    isMissing: missing,
    isPassed: passed,
    isHidden: hidden,
    content,
  }
}

function ChecklistItem({ name, content, isHidden, isMissing, isPassed }) {
  if (isHidden) {
    return null
  }

  return (
    <Table.Row sx={{ textAlign: 'right' }}>
      <Table.Cell sx={{ textAlign: 'left' }}>{name}</Table.Cell>
      <Table.Cell>
        <Text color={isMissing ? 'yellow.500' : 'red.500'}>
          {!isPassed && content}
        </Text>
      </Table.Cell>
      <Table.Cell>
        <Text color={isMissing ? 'yellow.500' : 'green.500'}>
          {isPassed && content}
        </Text>
      </Table.Cell>
    </Table.Row>
  )
}

function Checklist({ application, name, items }) {
  const { state } = useNobodarAssessmentQuestionnaire()

  const checklistItems = useMemo(() => {
    return items
      .map((item) => prepareChecklistItem(item, state, application))
      .sort((a, b) => {
        if (a.isMissing) {
          return 1
        }

        if (b.isMissing) {
          return -1
        }

        if (a.isPassed && !b.isPassed) {
          return -1
        }

        if (!a.isPassed && b.isPassed) {
          return 1
        }

        return 0
      })
  }, [application, items, state])

  return (
    <Box>
      <Heading fontSize={3}>{name}</Heading>

      <Table sx={{ fontSize: 2 }}>
        <Table.Body>
          {checklistItems.map((item) => (
            <ChecklistItem key={item.name} {...item} />
          ))}
        </Table.Body>
      </Table>
    </Box>
  )
}

const steps = ['doc_checklist', 'eligibility_checklist', 'final_selection']

function ChangeApplicationStatus({ applicationId, ...props }) {
  const [stepIndex, setStepIndex] = useState(0)
  const goToNextStep = useCallback(() => {
    setStepIndex((currIndex) => {
      let nextIndex = currIndex + 1
      if (nextIndex === steps.length) {
        nextIndex = 0
      }
      return nextIndex
    })
  }, [])
  const goToPrevStep = useCallback(() => {
    setStepIndex((currIndex) => {
      let prevIndex = currIndex - 1
      if (prevIndex === -1) {
        prevIndex = steps.length - 1
      }
      return prevIndex
    })
  }, [])

  const application = useNobodarApplication(applicationId)

  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const selectedStatus = form.watch('status')

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ status, shortlist, note }) => {
      try {
        if (status === 'shortlisted') {
          await dispatch(
            setNobodarApplicationShortlist(applicationId, { shortlist, note })
          )
        } else {
          await dispatch(
            setNobodarApplicationStatus(applicationId, { status, note })
          )
        }
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, onClose, toast]
  )

  /**
   * I know this is not the proper way of doing this. This should be handled in the backend too.
   * But for now, with everything being hazy, I'm gonna go with illusion of sanity with frontend policing.
   */
  if (application.status !== '') {
    return null
  }

  return (
    <NobodarAssessmentQuestionnaireProvider applicationId={applicationId}>
      <Button variantColor="blue" onClick={onOpen} {...props}>
        Checklist &amp; Final Selection
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="920px">
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Update Nobodar Application Status</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                {checklists.map((checklist) => (
                  <Box
                    key={checklist.name}
                    display={
                      steps[stepIndex] !== checklist.stepName ? 'none' : 'block'
                    }
                  >
                    <Checklist {...checklist} application={application} />
                  </Box>
                ))}

                <Box
                  display={
                    steps[stepIndex] !== 'final_selection' ? 'none' : 'block'
                  }
                >
                  <Box>
                    <FormSelect
                      name="status"
                      label="Status"
                      options={nobodarApplicationStatusOptions}
                    />
                  </Box>

                  {selectedStatus === 'shortlisted' && (
                    <Box>
                      <FormSelect
                        name="shortlist"
                        label="Shortlisted for"
                        options={nobodarApplicationShortlistOptions}
                      />
                    </Box>
                  )}

                  <Box>
                    <FormTextarea name="note" label={`Note`} />
                  </Box>
                </Box>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack
                w="100%"
                isInline
                spacing={4}
                justifyContent="space-between"
              >
                <Stack isInline spacing={4}>
                  <Button
                    mr={4}
                    variant="outline"
                    onClick={goToPrevStep}
                    isDisabled={stepIndex === 0}
                  >
                    Previous
                  </Button>

                  <Button
                    variant="outline"
                    onClick={goToNextStep}
                    isDisabled={stepIndex === steps.length - 1}
                  >
                    Next
                  </Button>
                </Stack>

                <Stack isInline spacing={4}>
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>

                  {stepIndex === steps.length - 1 ? (
                    <FormButton type="submit" variantColor="blue">
                      Submit
                    </FormButton>
                  ) : null}
                </Stack>
              </Stack>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </NobodarAssessmentQuestionnaireProvider>
  )
}

export default ChangeApplicationStatus
