import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import { zipObject, get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { generateApplication } from 'store/applications'
import { useDistributors } from 'store/distributors/hooks'
import * as Yup from 'yup'
import FormInput from 'components/Form/Input'

const getDefaultValues = () => ({
  distributorId: '',
})

const getValidationSchema = () => {
  return Yup.object({
    distributorId: Yup.number().integer().required(`required`),
  })
}

function GenerateUnileverApplication({ ...props }) {
  const distributors = useDistributors()

  const distributorOptions = useMemo(() => {
    return zipObject(
      distributors.allIds,
      distributors.allIds.map((id) => get(distributors.byId[id], 'name'))
    )
  }, [distributors.allIds, distributors.byId])

  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ distributorId, file }) => {
      try {
        const body = new FormData()
        body.set('type', 'unilever')
        body.set('distributorId', distributorId)
        if (file && file[0]) {
          body.set('file', file[0], file[0].name)
          await dispatch(generateApplication(body))
          onClose()
        }
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, toast, onClose]
  )

  return (
    <Authorize permissions="eloan:AnyApplication:update">
      <Box {...props}>
        <Button variantColor="green" onClick={onOpen}>
          Generate Application
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader>Generate Unilever eLoan Application</ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <FormSelect
                  name="distributorId"
                  label={`Distributor`}
                  options={distributorOptions}
                />

                <FormInput
                  type="file"
                  name="file"
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  label="SpreadSheet"
                />
              </ModalBody>

              <ModalFooter>
                <Button type="button" mr={3} onClick={onClose}>
                  Close
                </Button>
                <FormButton type="submit" mr={3} variantColor="green">
                  Generate
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default GenerateUnileverApplication
