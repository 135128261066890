import {
  Box,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
} from '@chakra-ui/core'
import { debounce, map } from 'lodash-es'
import {
  callStatusMapper,
  paymentStatusMapper,
} from 'pages/nobodar-applications/constants'
import React, { useEffect, useMemo, useReducer } from 'react'
import ReactDatePicker from 'react-datepicker'
import ReactSelect from 'react-select'
import { useNobodarPAUsers } from 'store/users/hooks'
import { emptyObject } from 'utils/defaults'

const initialState = {
  filter: emptyObject,
}

const reducer = (state = initialState, { key, value }) => {
  const newState = {
    ...state,
    filter: {
      ...state.filter,
      [key]: value,
    },
  }

  return newState
}

function NobodarListFilter({ onChange }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const paymentOptions = useMemo(
    () =>
      map(paymentStatusMapper, (payment, key) => ({
        label: payment,
        value: key,
      })),
    []
  )
  const callStatusOptions = useMemo(
    () =>
      map(callStatusMapper, (status, key) => ({
        label: status,
        value: key,
      })),
    []
  )

  const paUsers = useNobodarPAUsers()

  const paOptions = useMemo(
    () =>
      paUsers.allIds.map((id) => ({
        value: id,
        label: paUsers.byId[id].name,
      })),
    [paUsers]
  )

  useEffect(() => {
    onChange(state.filter)
  }, [onChange, state.filter])

  const onPhoneChange = debounce((value) => {
    dispatch({
      key: 'phone',
      value,
    })
  }, 500)

  return (
    <Stack isInline spacing={4} flexWrap="wrap" mb={4}>
      <Box maxW="200px">
        <FormLabel fontSize="sm">Search by Phone</FormLabel>
        <InputGroup>
          <InputLeftElement>
            <Icon name="search" color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search"
            h="2.4rem"
            onChange={(event) => onPhoneChange(event.target.value)}
          />
        </InputGroup>
      </Box>

      <Box>
        <FormLabel fontSize="sm">Application Date Range</FormLabel>
        <Stack isInline spacing={0}>
          <Box w="140px">
            <ReactDatePicker
              placeholderText="Start date"
              selected={state.filter.startDate}
              selectsStart
              startDate={state.filter.startDate}
              endDate={state.filter.endDate}
              dateFormat="MMM dd, yyyy"
              onChange={(value) =>
                dispatch({
                  key: 'startDate',
                  value: value,
                })
              }
              customInput={<Input h="2.4rem" />}
            />
          </Box>
          <Box w="140px">
            <ReactDatePicker
              placeholderText="End date"
              selected={state.filter.endDate}
              selectsEnd
              startDate={state.filter.startDate}
              endDate={state.filter.endDate}
              minDate={state.filter.startDate}
              dateFormat="MMM dd, yyyy"
              onChange={(value) =>
                dispatch({
                  key: 'endDate',
                  value: value,
                })
              }
              customInput={<Input h="2.4rem" />}
            />
          </Box>
        </Stack>
      </Box>

      <Box minW="160px">
        <FormLabel fontSize="sm">Payment Status</FormLabel>
        <ReactSelect
          options={paymentOptions}
          onChange={(data) =>
            dispatch({
              key: 'paymentStatus',
              value: data ? data.value : '',
            })
          }
          isClearable
        />
      </Box>

      <Box minW="240px">
        <FormLabel fontSize="sm">Last Call Status (min streak)</FormLabel>
        <Stack isInline spacing={0}>
          <Box flexGrow="1">
            <ReactSelect
              options={callStatusOptions}
              onChange={(data) =>
                dispatch({
                  key: 'callStatus',
                  value: data ? data.value : '',
                })
              }
              isClearable
            />
          </Box>
          <NumberInput
            maxW="70px"
            min={0}
            step={1}
            onChange={(value) => {
              dispatch({
                key: 'callStatusStreak',
                value,
              })
            }}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Stack>
      </Box>

      <Box minW="180px">
        <FormLabel fontSize="sm">Filter by Nobodar PA</FormLabel>
        <ReactSelect
          options={paOptions}
          onChange={(data) =>
            dispatch({
              key: 'paId',
              value: data ? data.value : '',
            })
          }
          isClearable
        />
      </Box>

      <Box minW="180px">
        <FormLabel fontSize="sm">Last Fee Deposit Date</FormLabel>
        <Box>
          <ReactDatePicker
            placeholderText="Select Date"
            selected={state.filter.feeDepositLastCreatedAt}
            dateFormat="MMM dd, yyyy"
            onChange={(value) =>
              dispatch({
                key: 'feeDepositLastCreatedAt',
                value: value,
              })
            }
            customInput={<Input h="2.4rem" />}
          />
        </Box>
      </Box>
    </Stack>
  )
}

export default NobodarListFilter
