import { Box, Heading, Text } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import ListTable from 'components/ListTable/ListTable'
import { defaults } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTable } from 'react-table'
import { getAppBkashPayments } from 'store/app-bkash-payments'
import { toISODate } from 'utils/cast'
import { emptyObject } from 'utils/defaults'
import AppBkashPaymentsPageFilter from './Filter'

const getColumns = () => [
  {
    Header: 'eLoan ID',
    accessor: 'eloanId',
    Cell: ({ cell: { value } }) => {
      return (
        <Link
          target="_blank"
          isExternal={true}
          to={`/dash/applications/${value}`}
        >
          {value}
        </Link>
      )
    },
  },
  {
    Header: 'Offer ID',
    accessor: 'offerId',
  },
  {
    Header: 'Shop ID',
    accessor: 'shopId',
  },
  {
    Header: 'Shop Name',
    accessor: 'shopName',
  },
  {
    Header: 'Payment Amount',
    accessor: 'amount',
  },
  {
    Header: 'Payment Date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) => {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED)
    },
  },
  {
    Header: 'Transaction ID',
    accessor: 'transactionId',
  },
  {
    Header: 'Paid by user',
    accessor: ({ createdByUserName, createdByUserPhoneNumber }) =>
      `${createdByUserName} (${createdByUserPhoneNumber})`,
  },
]

const defaultQueryObject = {
  dateFrom: toISODate(),
  dateTo: toISODate(),
}

const getQueryObject = ({ start, end }) => {
  return defaults(
    {
      dateFrom: start,
      dateTo: end,
    },
    defaultQueryObject
  )
}

function applyListFilters(filteredData, listFilters) {
  return (filteredData || []).filter((props) => {
    let bool = true
    Object.keys(listFilters).forEach((key) => {
      if (listFilters[key]) {
        const query = listFilters[key].toString().toLowerCase()
        const propValue = props[key] || ''
        bool = bool || propValue.toString().toLowerCase().includes(query)
      }
    })
    return bool
  })
}

function AppBkashPaymentsPage() {
  const [dateFilterValues, setDateFilterValues] = useState(emptyObject)
  const [listFilters, setListFilters] = useState({
    eloanId: '',
    loaneeNameOrPhone: '',
    shopIdOrName: '',
    transactionId: '',
  })

  const setListQueryFilterValues = (newFilterMap) => {
    const newFilters = {
      ...listFilters,
    }
    Object.keys(newFilterMap).forEach((key) => {
      if (newFilterMap[key]) {
        newFilters[key] = newFilterMap[key]
      }
    })
    setListFilters(newFilters)
  }

  const dispatch = useDispatch()

  const queryObject = useMemo(() => getQueryObject({ ...dateFilterValues }), [
    dateFilterValues,
  ])

  useEffect(() => {
    dispatch(
      getAppBkashPayments({
        fromDate: queryObject.dateFrom,
        toDate: queryObject.dateTo,
      })
    )
  }, [dispatch, queryObject])

  const paymentsList = useSelector((state) => state.appBkashPayments)

  const data = useMemo(() => {
    return applyListFilters(paymentsList.payments, listFilters)
  }, [paymentsList, listFilters])

  const columns = useMemo(() => getColumns(), [])

  const table = useTable({
    data,
    columns,
  })

  return (
    <Authorize permissions="eloan:AnyApplication:read">
      <Box mb={10}>
        <Heading fontSize={5} mb={4}>
          Eloan App bKash Payments
        </Heading>
        <Text>
          Bkash Payments made by loanee directly from Eloan mobile App
        </Text>
        <AppBkashPaymentsPageFilter
          onDateRangeChange={setDateFilterValues}
          onFilterChange={setListQueryFilterValues}
        />
      </Box>
      <ListTable table={table} />
    </Authorize>
  )
}

export default AppBkashPaymentsPage
