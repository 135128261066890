import { Button, ButtonGroup, IconButton } from '@chakra-ui/core'
import AreaFilter from 'components/ApplicationFilters/AreaFilter'
import CreationDateFilter from 'components/ApplicationFilters/CreationDateFilter'
import DocSubmissionMethodFilter from 'components/ApplicationFilters/DocSubmissionMethodFilter'
import ApplicationStatusFilter from 'components/ApplicationFilters/StatusFilter'
import Form from 'components/Form/Form'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Flex } from 'reflexbox'
import OfferStatusFilter from 'components/ApplicationFilters/OfferStatusFilter'

const getDefaultValues = () => ({
  createdAt: {
    start: '',
    end: '',
  },
  status: '',
  meta: {
    shopAreaId: '',
    docSubmissionMethod: '',
  },
  offer: {
    status: '',
  },
})

function UnassignedApplicationListFilters({ onChange }) {
  const defaultValues = useMemo(() => getDefaultValues(), [])

  const form = useForm({
    defaultValues,
  })

  const onReset = useCallback(() => {
    form.reset(defaultValues)
  }, [defaultValues, form])

  return (
    <Form form={form} onSubmit={onChange}>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
        mb={8}
      >
        <ApplicationStatusFilter m={1} />

        <OfferStatusFilter m={1} />

        <DocSubmissionMethodFilter m={1} />

        <CreationDateFilter m={1} />

        <AreaFilter m={1} />

        <ButtonGroup spacing={0} m={1}>
          <IconButton type="button" icon="small-close" onClick={onReset} />
          <Button type="submit" minWidth="6rem" variantColor="green">
            Filter
          </Button>
        </ButtonGroup>
      </Flex>
    </Form>
  )
}

export default UnassignedApplicationListFilters
