import { Stack } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAreas } from 'store/areas/hooks'
import GuarantorInfo from './sections/GuarantorInfo'
import LegacySection from './sections/Legacy'
import LoaneeInfo from './sections/LoaneeInfo'
import LoanInfo from './sections/LoanInfo'
import OfferInfo from './sections/OfferInfo'

function LoanDetails({ applicationId, ...props }) {
  const areas = useAreas()

  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const shopArea = useMemo(() => {
    return areas.byId[get(application, 'meta.shopAreaId', null)]
  }, [application, areas.byId])

  return (
    <Stack spacing={6} {...props}>
      <OfferInfo application={application} />
      <LoanInfo application={application} />
      <LoaneeInfo application={application} shopArea={shopArea} />
      <GuarantorInfo application={application} />
      <LegacySection application={application} />
    </Stack>
  )
}

export default LoanDetails
