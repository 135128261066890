import { keyBy, map, pullAll } from 'lodash-es'
import { emptyArray, emptyObject } from 'utils/defaults'
import { FETCH_SETTLEMENTS_DATA_RESOLVED, SETTLE_DONE } from 'store/settlements'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: true,
}
const idKey = 'id'

const settlementsReducer = (state = initialState, { type, data, ids }) => {
  switch (type) {
    case FETCH_SETTLEMENTS_DATA_RESOLVED:
      return {
        ...state,
        byId: keyBy(data, idKey),
        allIds: map(data, idKey),
        loading: false,
      }
    case SETTLE_DONE:
      return {
        ...state,
        allIds: pullAll([...state.allIds], ids),
      }
    default:
      return state
  }
}

export default settlementsReducer
