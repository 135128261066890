import { get, keyBy, map } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllDistributors,
  getDistributor,
  getDistributorRoutes,
  getDistributorRoutesForLoanProduct,
} from 'store/distributors'
import { emptyArray, emptyObject } from 'utils/defaults'

export function useDistributors({ include } = {}, forceRefresh = false) {
  const distributors = useSelector((state) => state.distributors)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!distributors.allIds.length || forceRefresh) {
      dispatch(getAllDistributors({ include }))
    }
  }, [dispatch, forceRefresh, distributors.allIds.length, include])

  return distributors
}

export function useDistributor(distributorId, { include } = {}) {
  const distributor = useSelector((state) =>
    get(state.distributors.byId, distributorId)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (distributorId && !distributor) {
      dispatch(getDistributor(distributorId, { include }))
    }
  }, [dispatch, distributor, distributorId, include])

  return distributor
}

export function useDistributorRoutes(distributorId) {
  const [routes, setRoutes] = useState({
    byId: emptyObject,
    allIds: emptyArray,
  })

  const dispatch = useDispatch()
  useEffect(() => {
    if (distributorId) {
      dispatch(getDistributorRoutes(distributorId)).then((data) => {
        const byId = keyBy(data.items, 'id')
        const allIds = map(data.items, 'id')
        setRoutes({
          byId,
          allIds,
        })
      })
    }
  }, [dispatch, distributorId])

  return routes
}

export function useDistributorRoutesForLoanProduct(loanProductId) {
  const [routes, setRoutes] = useState({
    byId: emptyObject,
    allIds: emptyArray,
  })

  const dispatch = useDispatch()
  useEffect(() => {
    if (loanProductId) {
      dispatch(getDistributorRoutesForLoanProduct(loanProductId)).then(
        (data) => {
          const byId = keyBy(data.items, 'id')
          const allIds = map(data.items, 'id')
          setRoutes({
            byId,
            allIds,
          })
        }
      )
    }
  }, [dispatch, loanProductId])

  return routes
}
