export const nobodarApplicationStatusOptions = {
  on_hold: 'Will Consider Later',
  shortlisted: 'Shortlisted',
  rejected: 'Rejected',
}

export const nobodarApplicationShortlistOptions = {
  bank: 'Bank',
  nbfi: 'NBFI',
  mfi_ngo: 'MFI/NGO',
  shopup: 'ShopUp',
}

export const nobodarPreferredInstitutionMap = nobodarApplicationShortlistOptions

export const callStatusMapper = {
  pending: 'Pending',
  called: 'Called',
  informed: 'Informed',
  need_followup: 'Need Follow-up',
  not_interested: 'Not Interested',
  unreachable: 'Unreachable',
  consultancy_provided: 'Consultancy Provided',
}

export const paymentStatusMapper = {
  unverified: 'Unverified',
  partially_paid: 'Partially Paid',
  paid: 'Paid',
  unpaid: 'Unpaid',
}

export const feeDepositStatusColorMap = {
  invalid: 'red',
  unverified: 'yellow',
  verified: 'green',
}

export const nobodarIdDocMap = {
  nid: 'NID',
  digital_birth_certificate: 'Digital Birth Certificate',
  passport: 'Passport',
  driving_license: 'Driving License',
}
