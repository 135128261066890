import { Heading, SimpleGrid, useToast } from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import { FormDatePicker } from 'components/Form/DatePicker'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import FormTextarea from 'components/Form/Textarea'
import { get, set } from 'lodash-es'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { updateApplicationRCAInfo } from 'store/applications'
import { useApplication } from 'store/applications/hooks'
import {
  getGenderOptions,
  getMaritalOptions,
  getProfessionOptions,
  getEducationOptions,
  getOwnershipOptions,
} from 'utils/selectOptions'
import DecoratedFormInput from '../components/DecoratedFormInput'

const relationshipMap = {
  'PARENT-MALE': 'Father',
  'PARENT-FEMALE': 'Mother',
  'SPOUSE-MALE': 'Husband',
  'SPOUSE-FEMALE': 'Wife',
  'OFFSPRING-MALE': 'Son',
  'OFFSPRING-FEMALE': 'Daughter',
  'SIBLING-MALE': 'Brother',
  'SIBLING-FEMALE': 'Sister',
  OTHER: 'Other',
}

const maritalStatusOptions = getMaritalOptions()
const genderOptions = getGenderOptions()
const professionOptions = getProfessionOptions()
const educationOptions = getEducationOptions()
const ownershipOptions = getOwnershipOptions()

const fields = [
  'meta.guarantorName',
  'meta.guarantorNid',
  'meta.guarantorNidName',
  'meta.guarantorNidDob',
  'meta.guarantorGender',
  'meta.guarantorPhoneNumber',
  'meta.guarantorProfession',
  'meta.guarantorEducation',
  'meta.guarantorDesignation',
  'meta.guarantorRelationship',
  'meta.guarantorSpouseName',
  'meta.guarantorFatherName',
  'meta.guarantorMotherName',
  'meta.guarantorPresentAddress',
  'meta.guarantorPermanentAddress',
  'meta.guarantorMaritalStatus',
  'meta.guarantorPremiseOwnershipStatus',
  'meta.guarantorPersonalNetWorth',
  'meta.guarantorMonthlyNonBusinessIncome',
].join(',')

const getDefaultValues = (application) => {
  return fields.split(',').reduce(
    (values, field) => {
      set(values, field, get(application, field) || '')
      return values
    },
    { meta: {} }
  )
}

function GuarantorOneRCA({ applicationId, ...props }) {
  const toast = useToast()

  const application = useApplication(applicationId, fields)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])

  const form = useForm({
    defaultValues,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ meta, shop }) => {
      try {
        await dispatch(updateApplicationRCAInfo(applicationId, { meta, shop }))
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, toast]
  )

  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Guarantor 1 (Family) Information
      </Heading>

      <Form form={form} onSubmit={onSubmit}>
        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="meta.guarantorName"
            label={`Full Name`}
            value={get(application, 'meta.guarantorName')}
          />
          <DecoratedFormInput
            name="meta.guarantorNid"
            label={`NID Number`}
            value={get(application, 'meta.guarantorNid')}
          />
          <DecoratedFormInput
            name="meta.guarantorNidName"
            label={`NID Full Name`}
            value={get(application, 'meta.guarantorNidName')}
          />
          <FormDatePicker name="meta.guarantorNidDob" label={`Date of Birth`} />
          <FormSelect
            name="meta.guarantorGender"
            label={`Gender`}
            options={genderOptions}
          />
          <DecoratedFormInput
            name="meta.guarantorPhoneNumber"
            label={`Phone Number`}
            value={get(application, 'meta.guarantorPhoneNumber')}
          />

          <FormSelect
            name="meta.guarantorProfession"
            label={`Profession`}
            options={professionOptions}
          />

          <FormSelect
            name="meta.guarantorEducation"
            label={`Education`}
            options={educationOptions}
          />

          <DecoratedFormInput
            name="meta.guarantorDesignation"
            label={`Designation`}
            value={get(application, 'meta.guarantorDesignation')}
          />
          <FormSelect
            name="meta.guarantorRelationship"
            label={`Relationship with Loanee`}
            options={relationshipMap}
          />
          <FormSelect
            name="meta.guarantorMaritalStatus"
            label={`Marital Status`}
            options={maritalStatusOptions}
          />
          <DecoratedFormInput
            name="meta.guarantorSpouseName"
            label={`Spouse Name`}
            value={get(application, 'meta.guarantorSpouseName')}
          />
          <DecoratedFormInput
            name="meta.guarantorFatherName"
            label={`Father Name`}
            value={get(application, 'meta.guarantorFatherName')}
          />
          <DecoratedFormInput
            name="meta.guarantorMotherName"
            label={`Mother Name`}
            value={get(application, 'meta.guarantorMotherName')}
          />
          <FormSelect
            name="meta.guarantorPremiseOwnershipStatus"
            label={`Ownership Status of Residence`}
            options={ownershipOptions}
          />
          <DecoratedFormInput
            name="meta.guarantorPersonalNetWorth"
            label={`Personal Net Worth`}
            value={get(application, 'meta.guarantorPersonalNetWorth')}
          />
          <DecoratedFormInput
            name="meta.guarantorMonthlyNonBusinessIncome"
            label={`Non Business Income`}
            value={get(application, 'meta.guarantorMonthlyNonBusinessIncome')}
          />
          <FormTextarea
            name="meta.guarantorPresentAddress"
            label={`Present Address`}
          />
          <FormTextarea
            name="meta.guarantorPermanentAddress"
            label={`Permanent Address`}
          />
        </SimpleGrid>

        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default memo(GuarantorOneRCA)
