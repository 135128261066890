import {
  Heading,
  SimpleGrid,
  useToast,
  Button,
  IconButton,
} from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { updateApplicationMetaJson } from 'store/applications'
import FormInput from 'components/Form/Input'

const getDefaultValues = ({ meta }, fieldName) =>
  get(meta, `${fieldName}.items`, [''])

function FIExperienceForm({ application, fieldName }) {
  const toast = useToast()

  const defaultValues = useMemo(
    () => ({
      fiExperience: getDefaultValues(application, fieldName),
    }),
    [application, fieldName]
  )

  const form = useForm({
    defaultValues,
  })

  const { control } = form

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'fiExperience',
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ fiExperience }) => {
      try {
        await dispatch(
          updateApplicationMetaJson(get(application, 'id'), {
            fieldName,
            items: fiExperience,
          })
        )
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [application, dispatch, form, toast, fieldName]
  )

  return (
    <Box mb={10}>
      <Heading fontSize={4} color="gray.600">
        Previous Experience with other FIs
      </Heading>
      <Form form={form} onSubmit={onSubmit}>
        {fields.map((field, index) => (
          <SimpleGrid
            key={field.id}
            columns={6}
            spacing={4}
            borderBottomWidth={1}
            borderBottomColor="gray.200"
            py={4}
          >
            <FormInput
              defaultValue={field.nameOfFi}
              placeholder="Name of Bank"
              name={`fiExperience[${index}].fiName`}
            />
            <FormInput
              defaultValue={field.acName}
              placeholder="A/C Name"
              name={`fiExperience[${index}].acName`}
            />
            <FormInput
              defaultValue={field.facilityType}
              placeholder="Facility Type"
              name={`fiExperience[${index}].facilityType`}
            />
            <FormInput
              defaultValue={field.facilityAmount}
              placeholder="Facility Amount"
              name={`fiExperience[${index}].facilityAmount`}
            />

            <IconButton
              justifySelf="center"
              onClick={() => remove(index)}
              variantColor="red"
              icon="delete"
              variant="outline"
            />
          </SimpleGrid>
        ))}
        <Button
          leftIcon="small-add"
          mt={4}
          onClick={() => append()}
          variantColor="blue"
          variant="outline"
          size="sm"
        >
          Add more
        </Button>
        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default FIExperienceForm
