import pdfMake from 'pdfmake/build/pdfmake'
// require('./pdfmakeUnicode');
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
const fonts = {
  balooda2: {
    normal:
      'https://d118vismjsp9sl.cloudfront.net/web2.0/fonts/balooda2/BalooDa2-Regular.ttf',
    bold:
      'https://d118vismjsp9sl.cloudfront.net/web2.0/fonts/balooda2/BalooDa2-Bold.ttf',
    italics:
      'https://d118vismjsp9sl.cloudfront.net/web2.0/fonts/balooda2/BalooDa2-Bold.ttf',
    bolditalics:
      'https://d118vismjsp9sl.cloudfront.net/web2.0/fonts/balooda2/BalooDa2-Bold.ttf',
  },
  // download default Roboto font from cdnjs.com
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
}

export function generateAndDownloadPdf(template, filename = 'file') {
  pdfMake.createPdf(template, null, fonts).download(filename)
}

export function generateAndOpenPdf(template) {
  pdfMake.createPdf(template, null, fonts).open()
}

export function getDefaultTemplateConfigs() {
  return {
    styles: {
      header: {
        fontSize: 14,
        bold: true,
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 10,
      font: 'balooda2',
    },
    pageMargins: [40, 40, 40, 40],
  }
}
