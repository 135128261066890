export const DOC_REQUIREMENT_SET = 'DOC_REQUIREMENT_SET'
export const DOC_REQUIREMENT_SET_ALL = 'DOC_REQUIREMENT_SET_ALL'

export const getApplicationDocRequirements = (applicationId) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `/legacy/v4/eloan/applications/{applicationId}/docs/requirements`,
    {
      applicationId,
    }
  )

  dispatch({ type: DOC_REQUIREMENT_SET_ALL, data, applicationId })

  return data
}

export const setApplicationDocRequirement = (
  applicationId,
  { entityId, staticEntityId, required, collected }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `PATCH /legacy/v4/eloan/applications/{applicationId}/docs/requirements`,
    {
      applicationId,
      entityId,
      staticEntityId,
      required,
      collected,
    }
  )

  dispatch({ type: DOC_REQUIREMENT_SET, data, applicationId })

  return data
}
