import { useState, useEffect } from 'react'
import { emptyArray } from 'utils/defaults'
import { api } from 'api'

export function useCloseableAccounts({ limit, outstandingThreshold }) {
  const [data, setData] = useState(emptyArray)

  useEffect(() => {
    async function fetchData() {
      const { data } = await api(
        `/eloan/v1/accounts/closeable-accounts{?limit,outstandingThreshold}`,
        {
          limit,
          outstandingThreshold,
        }
      )

      setData(data.closeableAccounts)
    }

    fetchData()
  }, [limit, outstandingThreshold])

  return data
}
