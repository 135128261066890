import { Text } from '@chakra-ui/core'
import React from 'react'
import { Box } from 'reflexbox'

function DefaultInfoBoxLabel(props) {
  return <Text fontSize={1} {...props} />
}

function DefaultInfoBoxContent(props) {
  return <Box fontSize={4} {...props} />
}

function InfoBox({
  children,
  label,
  LabelComponent = DefaultInfoBoxLabel,
  content = children,
  ContentComponent = DefaultInfoBoxContent,
  ...props
}) {
  return (
    <Box mr={10} mb={4} {...props}>
      {label && <LabelComponent>{label}</LabelComponent>}
      {content && <ContentComponent>{content}</ContentComponent>}
    </Box>
  )
}

export default InfoBox
