import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  Stack,
} from '@chakra-ui/core'
import Topbar from 'components/Topbar'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import EmailPassLogin from './EmailPassLogin'
import ForgotPassword from './ForgotPassword'
import PhoneOTPLogin from './PhoneOTPLogin'
import SocialLogin from './SocialLogin'

function Login({ location }) {
  const [loginMethod, setLoginMethod] = useState('email-pass')

  const status = useSelector((state) => state.user.status)

  if (status.authed) {
    return <Redirect to={location.state ? location.state.from || '/' : '/'} />
  }

  return (
    <>
      <Topbar />

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        {status.authed === null ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        ) : (
          <Stack
            spacing={2}
            borderWidth="1px"
            rounded="lg"
            width="100%"
            maxWidth="480px"
            p={4}
          >
            <Heading as="h2" fontSize={6} textAlign="center">
              Log In
            </Heading>

            <Box>
              {loginMethod === 'email-pass' ? (
                <EmailPassLogin isLoading={status.loading} />
              ) : loginMethod === 'phone-otp' ? (
                <PhoneOTPLogin isLoading={status.loading} />
              ) : loginMethod === 'social' ? (
                <SocialLogin isLoading={status.loading} />
              ) : null}
            </Box>

            <Divider my={4} />

            <Stack justifyContent="center" alignItems="center">
              {loginMethod !== 'phone-otp' && (
                <Button
                  type="button"
                  variant="outline"
                  leftIcon="phone"
                  onClick={() => setLoginMethod('phone-otp')}
                >
                  Login with Phone + OTP
                </Button>
              )}
              {loginMethod !== 'email-pass' && (
                <Button
                  type="button"
                  variant="outline"
                  leftIcon="email"
                  onClick={() => setLoginMethod('email-pass')}
                >
                  Login with Email + Password
                </Button>
              )}
              {loginMethod !== 'social' && (
                <Button
                  type="button"
                  variant="outline"
                  leftIcon="link"
                  onClick={() => setLoginMethod('social')}
                >
                  Social Login
                </Button>
              )}
            </Stack>
          </Stack>
        )}
      </Flex>
    </>
  )
}

function LoginPage() {
  return (
    <Switch>
      <Route path="/login/forgot-password/:token?" component={ForgotPassword} />
      <Route path="/login" component={Login} />
    </Switch>
  )
}

export default LoginPage
