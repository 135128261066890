import {
  Box,
  Button,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { Form, FormButton, FormInput, handleAPIError } from '@eloan/shared'
import Authorize from 'components/Authorize'
import { get } from 'lodash-es'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { MdFileUpload } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { uploadCibPaymentBankSlip } from 'store/applications'
import { getPrivateImageSrc } from 'utils/getPrivateImageSrc'

function CibPaymentBankSlip({ application, ...props }) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const form = useForm()

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ file }) => {
      try {
        await dispatch(
          uploadCibPaymentBankSlip(get(application, 'id'), {
            file: file[0],
          })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { toast, form })
      }
    },
    [application, dispatch, form, onClose, toast]
  )

  const photo = get(application, 'metaExtra.cibPaymentBankSlip')

  return (
    <Authorize permissions="eloan:AnyApplication:read,eloan:Application:read">
      <Box {...props}>
        <Heading fontSize={3} my={4}>
          CIB Payment Bank Slip
        </Heading>

        <Box
          mb={4}
          as={Link}
          display="block"
          href={getPrivateImageSrc(
            get(application, 'metaExtra.cibPaymentBankSlip')
          )}
          target="_blank"
          size={64}
          position="relative"
        >
          <Image
            size="100%"
            objectFit="cover"
            src={getPrivateImageSrc(
              get(application, 'metaExtra.cibPaymentBankSlip')
            )}
            fallbackSrc="https://via.placeholder.com/128?text=N/A"
          />
        </Box>

        <Stack isInline justifyContent="space-between">
          {!photo && (
            <Authorize permissions="eloan:Application:update">
              <Box>
                <Button
                  onClick={onOpen}
                  title="Upload Photo"
                  variantColor="blue"
                >
                  <Box as={MdFileUpload} />
                </Button>

                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                  <ModalOverlay />
                  <ModalContent>
                    <Form form={form} onSubmit={onSubmit}>
                      <ModalHeader>CIB Payment Bank Slip</ModalHeader>

                      <ModalCloseButton type="button" />

                      <ModalBody>
                        <FormInput name="file" type="file" label="Bank Slip" />
                      </ModalBody>

                      <ModalFooter>
                        <Button type="button" mr={3} onClick={onClose}>
                          Close
                        </Button>
                        <FormButton type="submit">Upload</FormButton>
                      </ModalFooter>
                    </Form>
                  </ModalContent>
                </Modal>
              </Box>
            </Authorize>
          )}
        </Stack>
      </Box>
    </Authorize>
  )
}

export default CibPaymentBankSlip
