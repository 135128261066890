import { get } from 'lodash-es'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRetailers, getRetailerData } from 'store/retailers'

export function useRetailers() {
  const retailers = useSelector((state) => state.retailers)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllRetailers())
  }, [dispatch])

  return retailers
}

export function useRetailer(retailerId) {
  const retailer = useSelector((state) => state.retailers.byId[retailerId])

  const loading = useSelector((state) =>
    get(state.retailers.loadingById, retailerId, null)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!loading) {
      dispatch(getRetailerData(retailerId))
    }
  }, [dispatch, loading, retailerId])

  return retailer
}
