import { get } from 'lodash-es'

export const RETAILER_LOADING_ALL = 'RETAILER_LOADING_ALL'
export const RETAILER_SET_ALL = 'RETAILER_SET_ALL'
export const RETAILER_ADD = 'RETAILER_ADD'
export const RETAILER_UPDATE = 'RETAILER_UPDATE'

export const getAllRetailers = () => async (dispatch, getState, { api }) => {
  const { retailers } = getState()

  if (!retailers.loading) {
    dispatch({ type: RETAILER_LOADING_ALL })

    try {
      const { data } = await api(`/legacy/v3/eloan/retailers`)

      dispatch({ type: RETAILER_SET_ALL, data })
    } catch (err) {
      if (get(err.error, 'code') === 403) {
        console.error(err)
      }
    }
  }
}

export const getRetailerData = (retailerId) => async (
  dispatch,
  _getState,
  { api }
) => {
  try {
    const { data } = await api(`/legacy/v3/eloan/retailers/{retailerId}`, {
      retailerId,
    })

    dispatch({ type: RETAILER_UPDATE, data, retailerId })

    return data
  } catch (err) {
    if (get(err.error, 'code') === 403) {
      console.error(err)
    }
  }
}

export const createRetailer = ({
  externalId,
  ownerName,
  phoneNumber,
  shopName,
  distributorId,
  routeId,
}) => async (dispatch, _getState, { api }) => {
  const { data } = await api(`POST /legacy/v3/eloan/retailers`, {
    externalId,
    ownerName,
    phoneNumber,
    shopName,
    distributorId,
    routeId,
  })

  dispatch({ type: RETAILER_ADD, data })

  return data
}

export const updateRetailer = (
  retailerId,
  { externalId, ownerName, phoneNumber, shopName, routeId }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(`PUT /legacy/v3/eloan/retailers/{retailerId}`, {
    retailerId,
    externalId,
    ownerName,
    phoneNumber,
    shopName,
    routeId,
  })

  dispatch({ type: RETAILER_UPDATE, retailerId, data })

  return data
}

export const addRetailerBankInfo = (
  retailerId,
  { bankId, branchId, accountName, accountNumber }
) => async (dispatch, _getState, { api }) => {
  const { data } = await api(
    `POST /legacy/v3/eloan/retailers/{retailerId}/bank-info`,
    {
      retailerId,
      bankId,
      branchId,
      accountName,
      accountNumber,
    }
  )

  dispatch({ type: RETAILER_UPDATE, retailerId, data })

  return data
}
