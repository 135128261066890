import { Box, Heading, SimpleGrid, Stack, Tooltip } from '@chakra-ui/core'
import { ceilToDecimal, displayBDT } from '@eloan/shared'
import InfoBox from 'components/Box/InfoBox'
import { get, map, sum } from 'lodash-es'
import React from 'react'
import { useApplication } from 'store/applications/hooks'
import { emptyArray } from 'utils/defaults'
import RetailerBankInfo from './BankInfo'

function RetailerInfo({ applicationId }) {
  const application = useApplication(applicationId)

  const offer = get(application, 'offer', null)

  return (
    <Stack spacing={6} width="100%">
      <Box>
        <SimpleGrid columns={5}>
          <InfoBox label="eLoan ID" content={get(application, 'id')} />
          <InfoBox
            label="Retailer ID"
            content={get(application, 'retailer.externalId')}
          />
          <InfoBox
            label="Retailer Name"
            content={get(application, 'retailer.ownerName')}
          />
          <InfoBox
            label="Shop Name"
            content={get(application, 'retailer.shopName')}
          />
          <InfoBox
            label="Phone Number"
            content={get(application, 'retailer.phoneNumber')}
          />
          <InfoBox
            label="Route Code"
            content={get(application, 'retailer.route.code')}
          />
          <InfoBox
            label="Route Name"
            content={get(application, 'retailer.route.name')}
          />
        </SimpleGrid>
      </Box>

      {offer && (
        <Stack spacing={12}>
          <Box>
            <Heading size="lg" mb={2}>
              Retailer Bank Account Details
            </Heading>

            <SimpleGrid columns={5}>
              <RetailerBankInfo
                bankInfo={get(application, 'retailerBankInfo')}
              />
            </SimpleGrid>
          </Box>

          <Box>
            <Heading size="lg" mb={2}>
              Revolving Loan Summary
            </Heading>

            <SimpleGrid columns={4}>
              <InfoBox
                label="Base Limit"
                content={displayBDT(
                  get(application, 'rlAccount.principalCredit')
                )}
              />
              <InfoBox
                label="Current Limit"
                content={displayBDT(
                  get(application, 'rlAccount.remainingCredit')
                )}
              />
              <InfoBox
                label="Total Due"
                content={displayBDT(
                  ceilToDecimal(
                    get(application, 'rlAccount.outstanding.total'),
                    0
                  )
                )}
              />
              <InfoBox label="" content={null} />
              <InfoBox
                label="Total Disbursed"
                content={displayBDT(
                  sum(
                    map(
                      get(application, 'rlAccount.loans', emptyArray),
                      'amount'
                    )
                  )
                )}
              />
              <InfoBox
                label="Total Collected"
                content={displayBDT(
                  sum(
                    map(
                      get(application, 'rlAccount.repayments', emptyArray),
                      'amount'
                    )
                  )
                )}
              />
              <InfoBox
                label="Total Principal Collected"
                content={displayBDT(
                  sum(
                    map(
                      get(application, 'rlAccount.loans', emptyArray),
                      'repaymentBreakdown.principalRepaid'
                    )
                  )
                )}
              />
              <InfoBox
                label="Total Interest Collected"
                content={displayBDT(
                  sum(
                    map(
                      get(application, 'rlAccount.loans', emptyArray),
                      'repaymentBreakdown.interestRepaid'
                    )
                  )
                )}
              />
            </SimpleGrid>
          </Box>

          <Box>
            <Heading size="lg" mb={2}>
              OverDraft Loan Summary
            </Heading>

            <SimpleGrid columns={5}>
              <InfoBox
                label={
                  <Tooltip label="Available Credit">Current Limit</Tooltip>
                }
                content={displayBDT(
                  get(application, 'odAccount.availableCredit')
                )}
              />
              <InfoBox
                label="Outstanding Principal Balance"
                content={displayBDT(
                  get(application, 'odAccount.currentBalance')
                )}
              />
              <InfoBox
                label="Accumulated Interest Payable"
                content={displayBDT(
                  get(application, 'odAccount.totalAccruedInterest')
                )}
              />
            </SimpleGrid>
          </Box>
        </Stack>
      )}
    </Stack>
  )
}

export default RetailerInfo
