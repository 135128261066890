import {
  Heading,
  SimpleGrid,
  useToast,
  Button,
  IconButton,
} from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { updateApplicationMetaJson } from 'store/applications'
import FormInput from 'components/Form/Input'

const getDefaultValues = ({ meta }, fieldName) =>
  get(meta, `${fieldName}.items`, [''])

function ShortTermLoanForm({ application, fieldName }) {
  const toast = useToast()

  const defaultValues = useMemo(
    () => ({
      shortTermLoan: getDefaultValues(application, fieldName),
    }),
    [application, fieldName]
  )

  const form = useForm({
    defaultValues,
  })

  const { control } = form

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'shortTermLoan',
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ shortTermLoan }) => {
      try {
        await dispatch(
          updateApplicationMetaJson(get(application, 'id'), {
            fieldName,
            items: shortTermLoan,
          })
        )
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [application, dispatch, form, toast, fieldName]
  )

  return (
    <Box mb={10}>
      <Heading fontSize={4} color="gray.600">
        Short Term Loan (Active)
      </Heading>
      <Form form={form} onSubmit={onSubmit}>
        {fields.map((field, index) => (
          <SimpleGrid
            key={field.id}
            columns={6}
            spacing={4}
            borderBottomWidth={1}
            borderBottomColor="gray.200"
            py={4}
            alignItems="flex-end"
          >
            <FormInput
              defaultValue={field.nameOfConcern}
              placeholder="Name of Concern"
              label="Name of Concern"
              name={`shortTermLoan[${index}].nameOfConcern`}
              required
            />
            <FormInput
              defaultValue={field.fiName}
              placeholder="Name of Bank"
              label="Name of Bank"
              name={`shortTermLoan[${index}].fiName`}
              required
            />
            <FormInput
              defaultValue={field.facility}
              placeholder="Facility"
              label="Facility"
              name={`shortTermLoan[${index}].facility`}
              required
            />
            <FormInput
              defaultValue={field.liabilityType}
              placeholder="Liability Type"
              label="Liability Type"
              name={`shortTermLoan[${index}].liabilityType`}
            />
            <FormInput
              defaultValue={field.sanctionAmount}
              placeholder="Sanction Amount"
              label="Sanction Amount"
              name={`shortTermLoan[${index}].sanctionAmount`}
              required
            />
            <FormInput
              defaultValue={field.outstanding}
              placeholder="Outstanding"
              label="Outstanding"
              name={`shortTermLoan[${index}].outstanding`}
              required
            />
            <FormInput
              defaultValue={field.highestBalance}
              placeholder="Highest Balance"
              label="Highest Balance"
              name={`shortTermLoan[${index}].highestBalance`}
            />
            <FormInput
              defaultValue={field.lowestBalance}
              placeholder="Lowest Balance"
              label="Lowest Balance"
              name={`shortTermLoan[${index}].lowestBalance`}
            />
            <FormInput
              defaultValue={field.rate}
              placeholder="Rate"
              label="Rate"
              name={`shortTermLoan[${index}].rate`}
            />
            <FormInput
              defaultValue={field.securityType}
              placeholder="Security Type"
              label="Security Type"
              name={`shortTermLoan[${index}].securityType`}
            />

            <IconButton
              justifySelf="center"
              onClick={() => remove(index)}
              variantColor="red"
              icon="delete"
              variant="outline"
            />
          </SimpleGrid>
        ))}
        <Button
          leftIcon="small-add"
          mt={4}
          onClick={() => append()}
          variantColor="blue"
          variant="outline"
          size="sm"
        >
          Add more
        </Button>
        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default ShortTermLoanForm
