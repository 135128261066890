import { Badge, Divider, Heading, Stack, useToast } from '@chakra-ui/core'
import { ExperimentalDataTable } from '@eloan/shared/components/Table'
import StatusTransition from 'components/Application/StatusTransition'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormTextarea from 'components/Form/Textarea'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useTable } from 'react-table'
import { Box } from 'reflexbox'
import { createApplicationNote } from 'store/notes'
import { useApplicationNotes, useParterNotes } from 'store/notes/hooks'
import { applicationStatusDisplayText } from 'utils/application-status'
import {
  cibApprovalStatusDisplayText,
  cibApprovalStatusVariantColor,
} from 'utils/meta'
import { offerStatusDisplayText } from 'utils/offer-status'
import * as Yup from 'yup'

const getDefaultValues = () => ({
  note: '',
  type: '',
})

const getValidationSchema = () => {
  return Yup.object({
    note: Yup.string().required(`required`),
    type: Yup.string(),
  })
}

function CreateNote({ applicationId }) {
  const toast = useToast()

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({ defaultValues, validationSchema })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ note, type }) => {
      try {
        await dispatch(createApplicationNote(applicationId, { note, type }))
        form.reset(defaultValues)
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, defaultValues, dispatch, form, toast]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <FormTextarea
        name="note"
        label="New Note"
        placeholder="Write your note here..."
      />

      <FormButton type="submit" mt={2}>
        Add Note
      </FormButton>
    </Form>
  )
}

const applicationNoteColumns = [
  {
    Header: 'Time',
    id: 'date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) =>
      DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
  },
  { Header: 'By', id: 'by', accessor: 'user.fullName' },
  { Header: 'Note', accessor: 'note' },
  {
    id: 'meta',
    accessor: (row) => ({
      type: row.type,
      from: row.fromStatus,
      to: row.toStatus,
    }),
    Cell: ({ cell: { value } }) => {
      const displayText =
        value.type === 'status_change'
          ? applicationStatusDisplayText
          : value.type === 'offer_status_change'
          ? offerStatusDisplayText
          : null

      const prefix =
        value.type === 'status_change'
          ? ''
          : value.type === 'offer_status_change'
          ? 'offer:'
          : ''

      return displayText ? (
        <StatusTransition
          from={value.from}
          to={value.to}
          displayText={displayText}
          prefix={prefix}
        />
      ) : null
    },
  },
]

const partnerNoteColumns = [
  {
    Header: 'Time',
    id: 'date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) =>
      DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
  },
  { Header: 'Partner', id: 'partner', accessor: 'partner.name' },
  { Header: 'By', id: 'by', accessor: 'user.fullName' },
  { Header: 'Note', accessor: 'note' },
  {
    id: 'meta',
    accessor: (row) => ({
      type: row.type,
      from: row.from,
      to: row.to,
    }),
    Cell: ({ cell: { value } }) => {
      if (value.type === 'step_change') {
        return (
          <>
            <Badge p={1}>{value.from ?? 'NULL'}</Badge>
            {` ⇒ `}
            <Badge p={1}>{value.to}</Badge>
          </>
        )
      }

      if (value.type === 'cib_approval_status_change') {
        return (
          <>
            <Badge
              p={1}
              variantColor={cibApprovalStatusVariantColor[value.from]}
            >
              CIB:{cibApprovalStatusDisplayText[value.from]}
            </Badge>
            {` ⇒ `}
            <Badge p={1} variantColor={cibApprovalStatusVariantColor[value.to]}>
              CIB:{cibApprovalStatusDisplayText[value.to]}
            </Badge>
          </>
        )
      }

      return null
    },
  },
]

function Notes({ applicationId }) {
  const notes = useApplicationNotes(applicationId)
  const partnerNotes = useParterNotes(applicationId, {
    fields: '*,partner.name',
  })

  const applicationNotesData = useMemo(() => {
    return notes.allIds.map((id) => notes.byId[id])
  }, [notes.allIds, notes.byId])

  const applicationNoteTable = useTable({
    data: applicationNotesData,
    columns: applicationNoteColumns,
  })

  const partnerNotesData = useMemo(() => {
    return partnerNotes.data
      ? partnerNotes.data.allIds.map((id) => partnerNotes.data.byId[id])
      : []
  }, [partnerNotes.data])

  const partnerNoteTable = useTable({
    data: partnerNotesData,
    columns: partnerNoteColumns,
  })

  return (
    <Stack spacing={8} width="100%">
      <Box>
        <Box mb={4}>
          <Heading as="h4" fontSize={5}>
            Application Notes
          </Heading>
        </Box>
        <ExperimentalDataTable {...applicationNoteTable} />
      </Box>

      <Box>
        <CreateNote applicationId={applicationId} />
      </Box>

      <Box>
        <Box mb={4}>
          <Heading as="h4" fontSize={5}>
            Partner Notes
          </Heading>
        </Box>
        <ExperimentalDataTable {...partnerNoteTable} />
      </Box>

      <Divider />
    </Stack>
  )
}

export default Notes
