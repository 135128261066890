import { Heading, Text } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { DataTable } from '@eloan/shared/components/Table'
import React, { useMemo } from 'react'
import { Box, Flex } from 'reflexbox'
import { useFieldAgentUsers } from 'store/users/hooks'
import FieldAgentAreas from './FieldAgentAreas'
import { useTable } from 'react-table'

const getColumns = () => [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Name',
    accessor: 'name',
  },
  { Header: 'Phone', accessor: 'phone' },
  {
    Header: '',
    id: 'areas',
    accessor: 'id',
    Cell: ({ cell: { value } }) => <FieldAgentAreas userId={value} />,
  },
]

function FieldAgentsPage() {
  const fieldAgentUsers = useFieldAgentUsers()

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => {
    return fieldAgentUsers.allIds.map((id) => fieldAgentUsers.byId[id])
  }, [fieldAgentUsers.allIds, fieldAgentUsers.byId])

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <Authorize permissions="eloan:AnyApplication:read">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Field Agents</Heading>
          <Text>eLoan Field Agent Users</Text>
        </Box>
      </Flex>

      <DataTable {...table} />
    </Authorize>
  )
}

export default FieldAgentsPage
