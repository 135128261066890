import { Link } from '@chakra-ui/core'
import React, { memo } from 'react'

function CoordinateLink({ lat, lon, ...props }) {
  return (
    <Link
      fontSize={1}
      color="primary"
      {...props}
      isExternal
      _hover={{ textDecoration: 'none' }}
      href={`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`}
    >
      Coordinates: {lat}, {lon}
    </Link>
  )
}

export default memo(CoordinateLink)
