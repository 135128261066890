import {
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from '@chakra-ui/core'
import ApplicaitonStatuses from 'components/Application/Statuses'
import Authorize from 'components/Authorize'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import { getApplicationData } from 'store/applications'
import BusinessInfoRCA from './sections/BusinessInfoRCA'
import GuarantorOneRCA from './sections/GuarantorOneRCA'
import GuarantorTwoRCA from './sections/GuarantorTwoRCA'
import StatementsRCA from './sections/StatementsRCA'
import LoaneeRCA from './sections/LoaneeRCA'
import ExperienceRCA from './sections/ExperienceRca'
import CibRCA from './sections/CibRCA'

const fields = [
  'id',
  'type',
  'status',
  'subStatus',

  'phoneNumber',
  'loanAmount',
  'tenure',

  'meta.*',
  'metaExtra.*',

  'offer.id',
  'offer.status',

  'paAssignment.paId',

  'shop.id',
  'shop.shopName',
  'shop.businessMediums',
  'shop.businessNote',
].join(',')

const tabs = [
  { id: 'loanee', name: 'Loanee' },
  { id: 'guarantor-one', name: 'Guarantor One' },
  { id: 'guarantor-two', name: 'Guarantor Two' },
  { id: 'business-info', name: 'Business Info' },
  { id: 'income-statement', name: 'Statements' },
  { id: 'experience', name: 'Experience' },
  { id: 'cib', name: 'CIB Undertaking' },
]

function ApplicationRCAPage() {
  const [unauthorized, setUnauthorized] = useState(false)

  const history = useHistory()

  const { applicationId, tabId } = useParams()

  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApplicationData(applicationId, { fields })).catch((err) => {
      if (get(err.error, 'code') === 403) {
        setUnauthorized(true)
      }
    })
  }, [applicationId, dispatch])

  const onTabChange = useCallback(
    (index) => {
      history.replace(
        `/dash/applications/${applicationId}/rca/${tabs[index].id}`
      )
    },
    [applicationId, history]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === tabId)
    return index < 0 ? 0 : index
  }, [tabId])

  useEffect(() => {
    onTabChange(tabIndex)
  }, [onTabChange, tabIndex])

  const type = get(application, 'type')

  return (
    <Authorize permissions="eloan:AnyApplication:update,eloan:Application:update">
      {unauthorized ? (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Text fontSize={6} color="red.500">
            You are not authorized to access this eLoan Application (
            {applicationId})
          </Text>
        </Flex>
      ) : application ? (
        <>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            mb={10}
          >
            <Stack spacing={1} mb={2}>
              <Heading fontSize={5}>
                RCA | Application ID: {applicationId} |{' '}
                {get(application, 'phoneNumber')}
              </Heading>
              <Text as="div">
                {type ? (
                  <Tag size="md" variantColor="blue" mr={4}>
                    {type}
                  </Tag>
                ) : null}
                RCA for eLoan Application
              </Text>
              <ApplicaitonStatuses application={application} />s
            </Stack>

            <Stack isInline spacing={2} flexWrap="wrap"></Stack>
          </Flex>

          <Tabs index={tabIndex} onChange={onTabChange}>
            <Box>
              <TabList mb={4}>
                {tabs.map((tab) => (
                  <Tab id={tab.id} key={tab.id}>
                    {tab.name}
                  </Tab>
                ))}
              </TabList>
            </Box>

            <TabPanels p={4}>
              <TabPanel>
                <LoaneeRCA applicationId={applicationId} />
              </TabPanel>
              <TabPanel>
                <GuarantorOneRCA applicationId={applicationId} />
              </TabPanel>
              <TabPanel>
                <GuarantorTwoRCA applicationId={applicationId} />
              </TabPanel>
              <TabPanel>
                <BusinessInfoRCA applicationId={applicationId} />
              </TabPanel>
              <TabPanel>
                <StatementsRCA applicationId={applicationId} />
              </TabPanel>
              <TabPanel>
                <ExperienceRCA applicationId={applicationId} />
              </TabPanel>
              <TabPanel>
                <CibRCA applicationId={applicationId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : null}
    </Authorize>
  )
}

export default ApplicationRCAPage
