import { combineReducers } from 'redux'
import {
  APPLICATION_PAGE_ADD,
  APPLICATION_PAGE_REMOVE,
  APPLICATION_PAGE_REQUEST,
  APPLICATION_PAGINATION_PURGE,
} from 'store/applications'
import {
  CCA_PAGE_ADD,
  CCA_PAGE_REMOVE,
  CCA_PAGE_REQUEST,
  CCA_PAGINATION_PURGE,
} from 'store/cca'
import {
  COLLECTION_PAGE_ADD,
  COLLECTION_PAGE_REMOVE,
  COLLECTION_PAGE_REQUEST,
  COLLECTION_PAGINATION_PURGE,
} from 'store/collections'
import { getPaginationReducer } from 'store/helpers'
import {
  NOBODAR_PAGE_ADD,
  NOBODAR_PAGE_REMOVE,
  NOBODAR_PAGE_REQUEST,
  NOBODAR_PAGINATION_PURGE,
} from 'store/nobodar'
import {
  REDX_SHOP_OFFER_PAGE_ADD,
  REDX_SHOP_OFFER_PAGE_REMOVE,
  REDX_SHOP_OFFER_PAGE_REQUEST,
  REDX_SHOP_OFFER_PAGINATION_PURGE,
} from 'store/redx-shop-offers'
import {
  USER_PAGE_ADD,
  USER_PAGE_REMOVE,
  USER_PAGE_REQUEST,
  USER_PAGINATION_PURGE,
} from 'store/users'

const applications = getPaginationReducer({
  idKey: 'id',
  ADD: APPLICATION_PAGE_ADD,
  REMOVE: APPLICATION_PAGE_REMOVE,
  REQUEST: APPLICATION_PAGE_REQUEST,
  PURGE: APPLICATION_PAGINATION_PURGE,
})

const users = getPaginationReducer({
  idKey: 'id',
  ADD: USER_PAGE_ADD,
  REMOVE: USER_PAGE_REMOVE,
  REQUEST: USER_PAGE_REQUEST,
  PURGE: USER_PAGINATION_PURGE,
})

const cca = getPaginationReducer({
  idKey: 'applicationId',
  ADD: CCA_PAGE_ADD,
  REMOVE: CCA_PAGE_REMOVE,
  REQUEST: CCA_PAGE_REQUEST,
  PURGE: CCA_PAGINATION_PURGE,
})

const nobodar = getPaginationReducer({
  idKey: 'id',
  ADD: NOBODAR_PAGE_ADD,
  REMOVE: NOBODAR_PAGE_REMOVE,
  REQUEST: NOBODAR_PAGE_REQUEST,
  PURGE: NOBODAR_PAGINATION_PURGE,
})

const collections = getPaginationReducer({
  idKey: 'id',
  ADD: COLLECTION_PAGE_ADD,
  AREMOVEDD: COLLECTION_PAGE_REMOVE,
  REQUEST: COLLECTION_PAGE_REQUEST,
  PURGE: COLLECTION_PAGINATION_PURGE,
})

const redxShopOffers = getPaginationReducer({
  idKey: 'id',
  ADD: REDX_SHOP_OFFER_PAGE_ADD,
  AREMOVEDD: REDX_SHOP_OFFER_PAGE_REMOVE,
  REQUEST: REDX_SHOP_OFFER_PAGE_REQUEST,
  PURGE: REDX_SHOP_OFFER_PAGINATION_PURGE,
})

const paginationReducer = combineReducers({
  applications,
  users,
  cca,
  nobodar,
  collections,
  redxShopOffers,
})

export default paginationReducer
