import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import LoanModifierPage from './pages/loan-modifier'
import LoanModifierConfigViewPage from './pages/loan-modifier/View'
import AdminLoanProductList from './pages/loan-product'
import AdminProductView from './pages/loan-product/View/Product/VIew'
import AdminOffer from './pages/offer'
import PALeaveManagementPage from './pages/pa-leave-management'
import EloanPartnerBeftnPage from './pages/partner-beftn'
import EloanPartnerSettlementPage from './pages/partner-settlement'
import RedxShopOfferPage from './pages/redx-shop-offer'
import AppBkashPaymentsPage from './pages/app-bkash-payments'

function AdminPage() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route
        exact
        path={`${path}/loan-product/:tabId?`}
        component={AdminLoanProductList}
      />
      <Route
        path={`${path}/loan-product/single/:loanProductId`}
        component={AdminProductView}
      />
      <Route
        path={`${path}/offer/:applicationId/:tabId?`}
        component={AdminOffer}
      />
      <Route
        path={`${path}/partner-settlement`}
        component={EloanPartnerSettlementPage}
      />
      <Route path={`${path}/partner-beftn`} component={EloanPartnerBeftnPage} />
      <Route path={`${path}/redx-shop-offers`} component={RedxShopOfferPage} />
      <Route
        path={`${path}/app-bkash-payments`}
        component={AppBkashPaymentsPage}
      />
      <Route
        path={`${path}/pa-leave-management`}
        component={PALeaveManagementPage}
      />

      <Route
        path={`${path}/loan-modifier/single/:loanModifierId/:tabId?`}
        component={LoanModifierConfigViewPage}
      />
      <Route
        exact
        path={`${path}/loan-modifier/:tabId?`}
        component={LoanModifierPage}
      />

      <Redirect to={`${path}/loan-product`} />
    </Switch>
  )
}

export default AdminPage
