import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import camelcaseKeys from 'camelcase-keys'

const adapter = createEntityAdapter({
  selectId: (item) => item.applicationId,
})

const fieldTeamSlice = createSlice({
  name: 'fieldTeam',
  initialState: adapter.getInitialState(),
  reducers: {
    add(state, action) {
      adapter.setAll(state, action.payload)
    },
    assign(state, action) {
      adapter.upsertMany(
        state,
        action.payload.applicationIds.map((id) => ({
          applicationId: id,
          agentId: action.payload.agentId,
        }))
      )
    },
    remove(state, action) {
      adapter.removeMany(state, action.payload)
    },
  },
})

export const {
  add,
  assign,
  remove: removeFromFieldTeam,
} = fieldTeamSlice.actions

export const { selectAll: selectAllFieldTeam } = adapter.getSelectors(
  (state) => state.repaymentFollowup.fieldTeam
)

export default fieldTeamSlice.reducer

export const fetchFieldTeamLoanList = ({
  limit,
  agentId,
  minDpd,
  maxDpd,
}) => async (dispatch, getState, { api }) => {
  const { data } = await api(
    '/legacy/v3/repayment/offline-followups/loans{?limit,agentID,minDpd,maxDpd}',
    {
      limit,
      agentID: agentId,
      minDpd,
      maxDpd,
    }
  )

  dispatch(add(camelcaseKeys(data, { deep: true })))

  return data
}

export const assignFieldAgent = ({ applicationIds, agentId }) => async (
  dispatch,
  getState,
  { api }
) => {
  const {
    data,
  } = await api(
    `POST /legacy/v3/repayment/offline-followups/applications/actions/assign-agent`,
    { applicationIds, agentId }
  )

  dispatch(assign({ applicationIds, agentId }))

  return data
}
