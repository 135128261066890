import React from 'react'
import { Flex, Box, Text } from '@chakra-ui/core'

function ModifierItem({ title, startDate, endDate, ...props }) {
  return (
    <Flex mb={4} pb={4} borderBottomWidth="1px" {...props}>
      <Box>
        <Text fontWeight={600}>{title}</Text>
        <Text color="gray.700">
          {startDate} - {endDate}
        </Text>
      </Box>
    </Flex>
  )
}

export default ModifierItem
