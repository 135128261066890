import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  List,
  ListIcon,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/core'
import { DataTable, emptyArray, getDate } from '@eloan/shared'
import { api } from 'api'
import Authorize from 'components/Authorize'
import InfoBox from 'components/Box/InfoBox'
import FormButton from 'components/Form/Button'
import { FormDatePicker } from 'components/Form/DatePicker'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import FormTextarea from 'components/Form/Textarea'
import Loader from 'components/Loader/Loader'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import { callStatusMapper } from 'pages/repayment-followup/constants'
import LoanDetails from 'pages/repayment-followup/LoanDetails'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTable } from 'react-table'
import { useApplication } from 'store/applications/hooks'
import {
  fetchCallTeamLoan,
  selectAllCallTeamLoan,
  updateFollowupCall,
} from 'store/repaymentFollowup/callTeamSlice'
import { useRepaymentOfflineFollowupHistory } from 'store/repaymentFollowup/hooks'
import * as Yup from 'yup'

const fields = [
  '*',
  'offer.id',
  'offer.loanAmount',
  'offer.shopupSelectedTenure',
  'meta.loaneeName',
  'meta.shopAreaId',
  'paAssignment.paId',
  'shop.id',
  'repayments.*',
].join(',')

function UpdateCall({ callListId, offerId }) {
  const validationSchema = useMemo(
    () =>
      Yup.object({
        callStatus: Yup.string().required('Required.'),
        committedDate: Yup.string().when('callStatus', {
          is: 'committed',
          then: Yup.string().required('Required.'),
          otherwise: Yup.string().nullable(),
        }),
        note: Yup.string().required('Required.'),
      }),
    []
  )

  const form = useForm({ validationSchema })

  const { reset } = form

  const dispatch = useDispatch()

  const toast = useToast()

  const onSubmit = useCallback(
    async ({ callStatus, committedDate, note }) => {
      try {
        await dispatch(
          updateFollowupCall(callListId, {
            offerId,
            callStatus,
            committedDate: committedDate
              ? new Date(committedDate).toISOString()
              : undefined,
            note,
          })
        )

        await dispatch(fetchCallTeamLoan(callListId))

        reset()
      } catch (error) {
        handleAPIError(error, { toast, form })
      }
    },
    [callListId, offerId, dispatch, form, toast, reset]
  )

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Stack spacing={4}>
        <Box>
          <FormSelect
            name="callStatus"
            label={
              <Text color="gray.700" fontSize={14}>
                Select Call Status
              </Text>
            }
            options={callStatusMapper}
          />
        </Box>
        <Box>
          <FormDatePicker
            name="committedDate"
            label={
              <Text fontSize={14} color="gray.700">
                Select Committed Date
              </Text>
            }
          />
        </Box>
        <Box>
          <FormTextarea
            name="note"
            label={
              <Text fontSize={14} color="gray.700">
                Enter Your Message
              </Text>
            }
          />
        </Box>
        <FormButton px={10} variantColor="cyan" type="submit">
          SUBMIT
        </FormButton>
      </Stack>
    </Form>
  )
}

const callHistoryColumns = [
  { Header: 'Serial', Cell: ({ row }) => row.index + 1 },
  {
    Header: 'Call Date',
    accessor: 'callTime',
    Cell: ({ cell: { value } }) => (
      <Text whiteSpace="nowrap">{getDate(value)}</Text>
    ),
  },
  {
    Header: 'Call Status',
    accessor: ({ callStatus }) => <Badge variant="solid">{callStatus}</Badge>,
  },
  {
    Header: 'Commitment Date',
    accessor: 'committedDate',
    Cell: ({ cell: { value } }) => (
      <Text whiteSpace="nowrap">{getDate(value)}</Text>
    ),
  },
  {
    Header: 'Messages',
    accessor: ({ note }) => {
      const notes = (note || '')
        .split('\n\n\n')
        .filter(Boolean)
        .map((note) => note.replace(/\[User:\d+:/, '['))

      return (
        <List spacing={3}>
          {notes.map((note, index) => (
            <ListItem key={index}>
              <ListIcon icon="chat" />
              {note}
            </ListItem>
          ))}
        </List>
      )
    },
  },
]

const repaymentHistoryColumns = [
  {
    Header: 'Month',
    accessor: 'monthId',
  },
  {
    Header: 'Repayment Date',
    accessor: 'endTime',
    Cell: ({ cell: { value } }) =>
      DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED),
  },
  {
    Header: 'Monthly EMI',
    accessor: 'shopupPayment',
  },
  {
    Header: 'Collection',
    accessor: 'totalCollection',
  },
  {
    Header: 'Due/Excess',
    accessor: 'shopupDifferenceInBalance',
    Cell: ({ cell: { value } }) => (
      <Text color={value < 0 ? 'red.500' : 'green.500'}>{value}</Text>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ cell: { value } }) => (
      <Badge variantColor={value === 'paid' ? 'green' : 'red'}>{value}</Badge>
    ),
  },
]

const fieldTeamHistoryColumns = [
  { Header: '#', Cell: (cell) => cell.row.index + 1 },
  { Header: 'Agent', accessor: 'followupByAgent.fullName' },
  {
    Header: 'Status',
    accessor: ({ followupStatus }) => (
      <Text fontSize="xs">{followupStatus}</Text>
    ),
  },
  {
    Header: 'Visit Time',
    accessor: ({ followupTime }) => getDate(followupTime),
  },
  {
    Header: 'Commitment Date',
    accessor: ({ committedDate }) => getDate(committedDate),
  },
  { Header: 'Message', accessor: 'note' },
]

function SendRepaymentMethodDetails({ applicationId }) {
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  const sendSms = useCallback(async () => {
    setLoading(true)
    try {
      await api(
        `POST /legacy/v3/repayment/action/send-repayment-method-details-sms`,
        {
          eloanId: applicationId,
        }
      )

      setSent(true)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [applicationId])

  return (
    <Button
      size="sm"
      variant="outline"
      variantColor="orange"
      onClick={sendSms}
      isDisabled={!applicationId || loading || sent}
      isLoading={loading}
    >
      {sent ? 'Repayment Method Details Sent' : 'Send Repayment Method Details'}
    </Button>
  )
}

function RepaymentFollowupCallTeamView() {
  const { callListId } = useParams()

  const dispatch = useDispatch()

  const allCallTeamLoans = useSelector(selectAllCallTeamLoan)

  const callTeamLoan = useMemo(
    () => allCallTeamLoans.find((loan) => loan.id === parseInt(callListId)),
    [callListId, allCallTeamLoans]
  )

  const application = useApplication(get(callTeamLoan, 'applicationId'), fields)

  const history = useRepaymentOfflineFollowupHistory(
    get(callTeamLoan, 'applicationId')
  )

  useEffect(() => {
    dispatch(fetchCallTeamLoan(callListId))
  }, [dispatch, callListId])

  const repaymentDetailsLink = useMemo(() => {
    const isNewEloan = get(application, 'isNewEloan')

    if (isNewEloan) {
      return `/dash/eloan-applications/${get(
        application,
        'id'
      )}/loanee-repayment`
    }

    return `${window.location.origin.replace(
      '//eloan.',
      '//sap.'
    )}/merchant-advance/loan-offers/loan-pay?shopId=${get(
      application,
      'shop.id'
    )}&loanOfferId=${get(application, 'offer.id')}`
  }, [application])

  const callHistoryData = useMemo(() => {
    return get(callTeamLoan, 'repaymentCallFollowup', emptyArray).filter(
      (item) => item.note
    )
  }, [callTeamLoan])

  const callHistoryTable = useTable({
    data: callHistoryData,
    columns: callHistoryColumns,
  })

  const fieldTeamHistorydata = useMemo(() => history, [history])

  const fieldTeamHistoryTable = useTable({
    data: fieldTeamHistorydata,
    columns: fieldTeamHistoryColumns,
  })

  const repaymentHistoryTable = useTable({
    data: get(application, 'repayments', emptyArray),
    columns: repaymentHistoryColumns,
  })

  if (!callTeamLoan) return <Loader />

  return (
    <Authorize permissions="eloan:AnyApplication:read, eloan:Application:read">
      <Stack mb={10}>
        <Heading size="lg">
          {`${get(application, 'meta.loaneeName', '...')} | eLoan ID: ${get(
            application,
            'id',
            '...'
          )} | ${get(application, 'phoneNumber', '...')}`}
        </Heading>

        <Stack isInline>
          <Button
            size="sm"
            variant="outline"
            variantColor="blue"
            as={Link}
            href={repaymentDetailsLink}
            target="_blank"
            _hover={{
              textDecoration: 'none',
            }}
          >
            View Repayment Details
          </Button>
          <SendRepaymentMethodDetails applicationId={get(application, 'id')} />
        </Stack>
      </Stack>
      <Box>
        <LoanDetails application={application} />

        <Divider />

        <Flex mt={6} spacing={6}>
          <Box flex={2}>
            <Stack isInline spacing={8}>
              <InfoBox label="Due Amount">
                {get(callTeamLoan, 'dueAmount')}
              </InfoBox>
              <InfoBox label="Due Date">
                {DateTime.fromISO(
                  get(callTeamLoan, 'paymentDueDate')
                ).toLocaleString(DateTime.DATE_MED)}
              </InfoBox>
            </Stack>

            <Divider my={4} />

            <Heading size="md" mb={4}>
              Update Your Call
            </Heading>
            <UpdateCall
              callListId={callListId}
              offerId={get(callTeamLoan, 'offerId')}
            />
          </Box>

          <Box flex={3} ml={6}>
            <Tabs>
              <TabList>
                <Tab>Call History</Tab>
                <Tab>Field Team History</Tab>
                <Tab>Repayment History</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <DataTable {...callHistoryTable} />
                </TabPanel>
                <TabPanel>
                  <DataTable {...fieldTeamHistoryTable} />
                </TabPanel>

                <TabPanel>
                  <DataTable
                    {...repaymentHistoryTable}
                    headerProps={{ sx: { textAlign: 'right' } }}
                    cellProps={{ sx: { textAlign: 'right' } }}
                    rowProps={{
                      sx: { '&:nth-of-type(2n-1)': { bg: 'gray.100' } },
                    }}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Box>
    </Authorize>
  )
}

export default RepaymentFollowupCallTeamView
