import { Heading, SimpleGrid, useToast } from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import { FormDatePicker } from 'components/Form/DatePicker'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import FormTextarea from 'components/Form/Textarea'
import { get, set } from 'lodash-es'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { updateApplicationRCAInfo } from 'store/applications'
import { useApplication } from 'store/applications/hooks'
import {
  getGenderOptions,
  getMaritalOptions,
  getProfessionOptions,
  getEducationOptions,
  getOwnershipOptions,
} from 'utils/selectOptions'
import DecoratedFormInput from '../components/DecoratedFormInput'

const relationshipMap = {
  'PARENT-MALE': 'Father',
  'PARENT-FEMALE': 'Mother',
  'SPOUSE-MALE': 'Husband',
  'SPOUSE-FEMALE': 'Wife',
  'OFFSPRING-MALE': 'Son',
  'OFFSPRING-FEMALE': 'Daughter',
  'SIBLING-MALE': 'Brother',
  'SIBLING-FEMALE': 'Sister',
  OTHER: 'Other',
}

const genderOptions = getGenderOptions()
const maritalStatusOptions = getMaritalOptions()
const professionOptions = getProfessionOptions()
const educationOptions = getEducationOptions()
const ownershipOptions = getOwnershipOptions()

const fields = [
  'meta.tpGuarantorNid',
  'meta.tpGuarantorNidName',
  'meta.tpGuarantorNidDob',
  'meta.tpGuarantorGender',
  'meta.tpGuarantorPhoneNumber',
  'meta.tpGuarantorProfession',
  'meta.tpGuarantorEducation',
  'meta.tpGuarantorDesignation',
  'meta.tpGuarantorRelationship',
  'meta.tpGuarantorSpouseName',
  'meta.tpGuarantorFatherName',
  'meta.tpGuarantorMotherName',
  'meta.tpGuarantorPresentAddress',
  'meta.tpGuarantorPermanentAddress',
  'meta.tpGuarantorMaritalStatus',
  'meta.tpGuarantorPremiseOwnershipStatus',
  'meta.tpGuarantorPersonalNetWorth',
  'meta.tpGuarantorMonthlyNonBusinessIncome',
].join(',')

const getDefaultValues = (application) => {
  return fields.split(',').reduce(
    (values, field) => {
      set(values, field, get(application, field) || '')
      return values
    },
    { meta: {} }
  )
}

function GuarantorTwoRCA({ applicationId, ...props }) {
  const toast = useToast()

  const application = useApplication(applicationId, fields)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])

  const form = useForm({
    defaultValues,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ meta, shop }) => {
      try {
        await dispatch(updateApplicationRCAInfo(applicationId, { meta, shop }))
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, toast]
  )

  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Guarantor 2 (3rd Party) Information
      </Heading>

      <Form form={form} onSubmit={onSubmit}>
        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="meta.tpGuarantorNid"
            label={`NID Number`}
            value={get(application, 'meta.tpGuarantorNid')}
          />
          <DecoratedFormInput
            name="meta.tpGuarantorNidName"
            label={`Full Name`}
            value={get(application, 'meta.tpGuarantorNidName')}
          />
          <FormDatePicker
            name="meta.tpGuarantorNidDob"
            label={`Date of Birth`}
          />
          <FormSelect
            name="meta.tpGuarantorGender"
            label={`Gender`}
            options={genderOptions}
          />
          <DecoratedFormInput
            name="meta.tpGuarantorPhoneNumber"
            label={`Phone Number`}
            value={get(application, 'meta.tpGuarantorPhoneNumber')}
          />
          <FormSelect
            name="meta.tpGuarantorProfession"
            label={`Profession`}
            options={professionOptions}
          />

          <FormSelect
            name="meta.tpGuarantorEducation"
            label={`Education`}
            options={educationOptions}
          />
          <DecoratedFormInput
            name="meta.tpGuarantorDesignation"
            label={`Designation`}
            value={get(application, 'meta.tpGuarantorDesignation')}
          />
          <FormSelect
            name="meta.tpGuarantorRelationship"
            label={`Relationship with Loanee`}
            options={relationshipMap}
          />
          <FormSelect
            name="meta.tpGuarantorMaritalStatus"
            label={`Marital Status`}
            options={maritalStatusOptions}
          />
          <DecoratedFormInput
            name="meta.tpGuarantorSpouseName"
            label={`Spouse Name`}
            value={get(application, 'meta.tpGuarantorSpouseName')}
          />
          <DecoratedFormInput
            name="meta.tpGuarantorFatherName"
            label={`Father Name`}
            value={get(application, 'meta.tpGuarantorFatherName')}
          />
          <DecoratedFormInput
            name="meta.tpGuarantorMotherName"
            label={`Mother Name`}
            value={get(application, 'meta.tpGuarantorMotherName')}
          />
          <FormSelect
            name="meta.tpGuarantorPremiseOwnershipStatus"
            label={`Ownership Status of Residence`}
            options={ownershipOptions}
          />
          <DecoratedFormInput
            name="meta.tpGuarantorPersonalNetWorth"
            label={`Personal Net Worth`}
            value={get(application, 'meta.tpGuarantorPersonalNetWorth')}
          />
          <DecoratedFormInput
            name="meta.tpGuarantorMonthlyNonBusinessIncome"
            label={`Non Business Income`}
            value={get(application, 'meta.tpGuarantorMonthlyNonBusinessIncome')}
          />
          <FormTextarea
            name="meta.tpGuarantorPresentAddress"
            label={`Present Address`}
          />
          <FormTextarea
            name="meta.tpGuarantorPermanentAddress"
            label={`Permanent Address`}
          />
        </SimpleGrid>

        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default memo(GuarantorTwoRCA)
