const manipulator = {
  ceil: Math.ceil,
  floor: Math.floor,
  round: Math.round,
}

/**
 * Manipulate a number with specific precision.
 *
 * @param {number} number
 * @param {string} operation ceil / floor / round
 * @param {number} precision
 */
export const manipulateNumber = (number, operation, precision) => {
  const factor = 10 ** precision
  return manipulator[operation](number * factor) / factor
}

/**
 * Returns a formatter function that accepts a single number as parameter.
 */
export const getNumberFormatter = ({
  manipulation = 'round',
  precision = 2,
  fractionDigits = precision,
  locale = 'en-US',
  style,
  currency,
  useGrouping,
}) => {
  const formatter = Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
    style,
    currency,
    useGrouping,
  })

  return (number) => {
    if (typeof number === 'number') {
      return formatter.format(manipulateNumber(number, manipulation, precision))
    }
    return number
  }
}

/**
 * Rounded with Precision 2 and displays 2 Fraction digits.
 * e.g.: `42.4242` becomes `42.42`
 */
export const toRounded2F2PNumber = getNumberFormatter({
  manipulation: 'round',
  precision: 2,
  fractionDigits: 2,
})

/**
 * Ceiled with Precision 2 and displays 2 Fraction digits.
 * e.g.: `42.4242` becomes `42.43`
 */
export const toCeiled2F2PNumber = getNumberFormatter({
  manipulation: 'ceil',
  precision: 2,
  fractionDigits: 2,
})

/**
 * Ceiled with Precision 0 and displays 2 Fraction digits.
 * e.g.: `42.4242` becomes `43.00`
 */
export const toCeiled2F0PNumber = getNumberFormatter({
  manipulation: 'ceil',
  precision: 0,
  fractionDigits: 2,
})

/**
 * Rounded with Precision 2 and displays 2 Fraction digits with BDT prefix.
 * e.g.: `42.4242` becomes `BDT 42.42`
 */
export const displayBDT = getNumberFormatter({
  manipulation: 'round',
  precision: 2,
  fractionDigits: 2,
  style: 'currency',
  currency: 'BDT',
})

export const ceilToDecimal = (number, precision = 2) => {
  return manipulateNumber(number, 'ceil', precision)
}
