import { useState, useEffect } from 'react'
import { api } from 'api'

function useOverdueApplications({ loanProductId }) {
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetchApplications() {
      try {
        setLoading(true)

        const {
          data,
        } = await api(
          `/legacy/v4/eloan/loan-products/{loanProductId}/overdue-applications`,
          { loanProductId }
        )

        setApplications(data)
      } catch (error) {}

      setLoading(false)
    }

    fetchApplications()
  }, [loanProductId])

  return { applications, loading }
}

export default useOverdueApplications
