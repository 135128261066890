import { DateTime } from 'luxon'

export const getDate = (dateToFormat, format = DateTime.DATE_MED) => {
  if (dateToFormat) return DateTime.fromISO(dateToFormat).toLocaleString(format)
  return null
}

export const getDateDiff = (dateToDiff) => {
  return DateTime.fromJSDate(new Date()).diff(DateTime.fromISO(dateToDiff), [
    'days',
    'hours',
    'minutes',
  ])
}
