import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Text,
} from '@chakra-ui/core'

export function ConfirmLoanProduct({ onConfirm, isOpen, onClose, isLoading }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Loan Product Creation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="lg">
            Are you sure that you want to create a loan product?
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button flex={1} variantColor="red" mr={3} onClick={onClose}>
            CANCEL
          </Button>
          <Button
            flex={1}
            variantColor="cyan"
            onClick={onConfirm}
            isLoading={isLoading}
          >
            CONFIRM
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
