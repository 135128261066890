import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import ApplicationListHeader from 'components/ApplicationList/Header'
import ApplicationListTable from 'components/ApplicationList/Table'
import Authorize from 'components/Authorize'
import FormCheckbox from 'components/Form/Checkbox'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import useApplicationListQuery from 'hooks/useApplicationListQuery'
import usePagination from 'hooks/usePagination'
import { get, zipObject } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from 'reflexbox'
import { assignPAsToApplications, getApplicationPage } from 'store/applications'
import { useAreas } from 'store/areas/hooks'
import { usePermit } from 'store/currentUser/hooks'
import { usePAUsers } from 'store/users/hooks'
import { emptyArray } from 'utils/defaults'
import UnassignedApplicationListFilters from './Filters'

const fields = [
  'id',
  'type',
  'status',

  'phoneNumber',
  'loanAmount',
  'tenure',
  'createdAt',

  'meta.loaneeName',
  'meta.loaneeNidName',
  'meta.shopAreaId',
  'meta.docSubmissionMethod',

  'offer.id',
  'offer.status',

  'paAssignment.paId',
].join(',')

const getColumns = (areasById, canSetPA) =>
  [
    canSetPA && {
      Header: '',
      accessor: 'id',
      id: 'checkbox-field',
      Cell: ({ cell: { value } }) => (
        <FormCheckbox name={`applicationIdsSelection._${value}`} />
      ),
    },
    { Header: 'ID', accessor: 'id' },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ cell: { value } }) =>
        value ? <Tag size="sm">{value}</Tag> : null,
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Offer Status',
      accessor: 'offer.status',
    },
    { Header: 'Name', accessor: 'meta.loaneeName' },
    { Header: 'Phone', accessor: 'phoneNumber' },
    {
      Header: 'Application Date',
      id: 'createdAt',
      accessor: (row) =>
        DateTime.fromISO(get(row, 'createdAt')).toLocaleString(
          DateTime.DATE_MED
        ),
    },
    {
      Header: 'Area',
      id: 'meta.shopAreaId',
      accessor: (row) =>
        get(areasById, [get(row, 'meta.shopAreaId'), 'name'], 'N/A'),
    },
    {
      Header: 'Doc Submission Method',
      accessor: 'meta.docSubmissionMethod',
    },
  ].filter(Boolean)

const parseApplicationIdsSelection = (applicationIdsSelection) => {
  return Object.keys(applicationIdsSelection)
    .filter((key) => applicationIdsSelection[key])
    .map((id) => Number(id.replace(/^_/, '')))
}

const getDefaultValues = () => ({
  applicationIdsSelection: {},
  paId: '',
})

const defaultQueryObject = {
  limit: 50,
  fields,
  metaFilter: '',
  paAssignmentFilter: 'paId==null',
}

const fixedFilterQueryObject = {
  paAssignment: {
    paId: 'null',
  },
}

const filterQueryOperationTypes = {
  createdAt: 'date-range',
}

function PAAssignModal({ form }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false)

  const paUsers = usePAUsers()
  const paUserOptions = useMemo(() => {
    return zipObject(
      paUsers.allIds,
      paUsers.allIds.map(
        (id) =>
          `[${get(paUsers.byId[id], 'type') === 'online' ? 'O' : 'F'}] ${get(
            paUsers.byId[id],
            'name'
          )}`
      )
    )
  }, [paUsers.allIds, paUsers.byId])

  const applicationIdsSelection = form.watch('applicationIdsSelection')
  const selectedApplicationIds = useMemo(
    () => parseApplicationIdsSelection(applicationIdsSelection),
    [applicationIdsSelection]
  )

  const dispatch = useDispatch()
  const toast = useToast()

  const onSubmit = useCallback(
    async ({ applicationIdsSelection, paId }) => {
      const applicationIds = parseApplicationIdsSelection(
        applicationIdsSelection
      )

      try {
        await dispatch(assignPAsToApplications({ applicationIds, paId }))
        toast({
          title: 'Done!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, toast]
  )

  return (
    <Box>
      <Button
        type="button"
        isDisabled={selectedApplicationIds.length === 0}
        onClick={onOpen}
      >
        Assign PA
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Assign PA for Applications:</ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <Text as="span" fontSize={2} fontWeight="bold">
                Application IDs:
              </Text>
              <Text>{selectedApplicationIds.join(', ')}</Text>

              <FormSelect
                name="paId"
                options={paUserOptions}
                label={`Portfolio Associate:`}
              />
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button
                type="submit"
                variantColor="green"
                isDisabled={
                  form.formState.isSubmitting ||
                  !form.formState.dirty ||
                  selectedApplicationIds.length === 0
                }
              >
                Assign
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Box>
  )
}

function UnassignedApplicationsPage() {
  const canSetPA = usePermit('eloan:AnyApplicationPAAssignment:set')

  const queryOptions = useMemo(
    () => ({
      default: defaultQueryObject,
      fixedFilter: fixedFilterQueryObject,
      filterTypes: filterQueryOperationTypes,
    }),
    []
  )

  const [queryObject, onFilterObjectChange] = useApplicationListQuery(
    queryOptions.default,
    queryOptions.fixedFilter,
    queryOptions.filterTypes
  )

  const dispatch = useDispatch()
  const fetchPage = useCallback(
    (...args) => {
      dispatch(getApplicationPage(...args))
    },
    [dispatch]
  )

  const pagination = useSelector((state) => state.pagination.applications)
  const [page] = usePagination(pagination, fetchPage, queryObject)

  const areas = useAreas()
  const columns = useMemo(() => getColumns(areas.byId, canSetPA), [
    areas.byId,
    canSetPA,
  ])

  const applications = useSelector((state) => state.applications)
  const data = useMemo(() => {
    return get(pagination.pages[page], 'itemIds', emptyArray).map(
      (id) => applications.byId[id]
    )
  }, [pagination.pages, page, applications.byId])

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const form = useForm({
    defaultValues,
  })

  return (
    <Authorize permissions="eloan:AnyApplication:read">
      <ApplicationListHeader
        title={`Unassigned eLoan Applications`}
        subtitle={`List of eLoan Applications without assigned Portfolio Associates`}
        Right={canSetPA ? <PAAssignModal form={form} /> : null}
      />

      <UnassignedApplicationListFilters onChange={onFilterObjectChange} />

      <Form form={form}>
        <ApplicationListTable
          loading={pagination.loading}
          data={data}
          columns={columns}
        />
      </Form>

      <PageBasedSwitcher
        pageIndex={page}
        totalPages={pagination.totalPages}
        totalItems={pagination.totalItems}
      />
    </Authorize>
  )
}

export default UnassignedApplicationsPage
