import { Button, InputRightElement, useToast } from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { getApplicationData } from 'store/applications'
import { useDispatch } from 'react-redux'
import { handleAPIError } from 'components/Form/helpers'

const newEloanApplicationPath = '/dash/eloan-applications'

// WARNING: temporary handled isNewEloan check
function ApplicationIdOpenerFetch({
  basePath = '/dash/applications',
  ...props
}) {
  const form = useForm()

  const dispatch = useDispatch()

  const toast = useToast()

  const onSubmit = useCallback(
    async ({ applicationId }) => {
      if (!Number.isInteger(+applicationId)) {
        return
      }
      try {
        const data = await dispatch(
          getApplicationData(applicationId, { fields: 'status,isNewEloan' })
        )

        const path =
          data.isNewEloan && data.status === 'accepted'
            ? newEloanApplicationPath
            : basePath

        window.open(`${path}/${applicationId}`)
      } catch (error) {
        handleAPIError(error, { toast })
      }
    },
    [basePath, dispatch, toast]
  )

  return (
    <Form {...props} form={form} onSubmit={onSubmit}>
      <FormInput
        name="applicationId"
        label={`Open Application`}
        pr="3.5rem"
        width="14rem"
        placeholder="Application ID"
        InputRight={
          <InputRightElement width="3.5rem">
            <Button size="sm" type="submit">
              Go
            </Button>
          </InputRightElement>
        }
      />
    </Form>
  )
}

export default ApplicationIdOpenerFetch
