import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useLoanModifierCongfiguration } from 'store/loan-modifier/hooks'
import LoanModifierConfigAddLoans from './AddLoans'
import LoanModifierConfigLoanList from './LoanList'

const tabs = [
  {
    id: '',
    name: 'Add Loans',
  },
  {
    id: 'list',
    name: 'View Loans',
  },
]

function LoanModifierConfigViewPage() {
  const { tabId, loanModifierId } = useParams()

  const loanModifier = useLoanModifierCongfiguration(loanModifierId)
  const history = useHistory()

  const onTabChange = useCallback(
    (index) => {
      history.replace(
        `/dash/admin/loan-modifier/single/${loanModifierId}/${tabs[index].id}`
      )
    },
    [history, loanModifierId]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === tabId)
    return index < 0 ? 0 : index
  }, [tabId])

  return (
    <Authorize>
      <Heading size="md" mb={10}>
        {get(loanModifier, 'title')}
      </Heading>
      <Tabs index={tabIndex} onChange={onTabChange} variantColor="cyan">
        <TabList mb={4}>
          {tabs.map((tab) => (
            <Tab id={tab.id} key={tab.id} px={0} pt={0} mr={4}>
              {tab.name}
            </Tab>
          ))}
        </TabList>

        <TabPanels py={4}>
          <TabPanel>
            <LoanModifierConfigAddLoans loanModifier={loanModifier} />
          </TabPanel>
          <TabPanel>
            <LoanModifierConfigLoanList loans={get(loanModifier, 'accounts')} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Authorize>
  )
}

export default LoanModifierConfigViewPage
