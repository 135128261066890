import { Button, Stack } from '@chakra-ui/core'
import ApplicationIdOpener from 'components/Application/IdOpener'
import ApplicationListHeader from 'components/ApplicationList/Header'
import ApplicationListTable from 'components/ApplicationList/Table'
import Authorize from 'components/Authorize'
import NavLink from 'components/Link/NavLink'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import usePagination from 'hooks/usePagination'
import { get } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicationPageForLoanProduct } from 'store/applications'
import { emptyArray, emptyObject } from 'utils/defaults'
import UnileverApplicationListFilter from './Filter'
import GenerateUnileverApplication from './modals/GenerateUnileverApplication'

const getColumns = () =>
  [
    { Header: 'eLoan ID', accessor: 'id' },
    { Header: 'Retailer ID', accessor: 'retailer.externalId' },
    { Header: 'Retailer Name', accessor: 'retailer.ownerName' },
    { Header: 'Base Limit', accessor: 'rlAccount.principalCredit' },
    { Header: 'Route Name', accessor: 'retailer.route.name' },
    { Header: 'Route Code', accessor: 'retailer.route.code' },

    {
      Header: '',
      accessor: 'id',
      id: 'open-button',
      Cell: ({ cell: { value } }) => (
        <Button as={NavLink} to={`/dash/unilever-applications/${value}`}>
          Open
        </Button>
      ),
    },
  ].filter(Boolean)

const getQueryObject = (filter) => {
  return {
    limit: 20,
    loanProductId: 2,
    filter,
  }
}

function UnileverApplicationList() {
  const [filter, setFilter] = useState(emptyObject)
  const queryObject = useMemo(() => getQueryObject(filter), [filter])

  const dispatch = useDispatch()
  const fetchPage = useCallback(
    (...args) => {
      dispatch(getApplicationPageForLoanProduct(...args))
    },
    [dispatch]
  )

  const pagination = useSelector((state) => state.pagination.applications)
  const [page] = usePagination(pagination, fetchPage, queryObject)

  const columns = useMemo(() => getColumns(), [])

  const applications = useSelector((state) => state.applications)
  const data = useMemo(() => {
    return get(pagination.pages[page], 'itemIds', emptyArray).map(
      (id) => applications.byId[id]
    )
  }, [pagination.pages, page, applications.byId])

  return (
    <Authorize permissions="">
      <ApplicationListHeader
        title={`eLoan Unilever Applications`}
        subtitle={`List of eLoan Unilever Applications`}
        Right={
          <Stack isInline spacing={4} alignItems="flex-end">
            <GenerateUnileverApplication />
            <ApplicationIdOpener basePath="/dash/unilever-applications" />
          </Stack>
        }
      />

      <UnileverApplicationListFilter onChange={setFilter} />

      <ApplicationListTable
        loading={pagination.loading}
        data={data}
        columns={columns}
      />

      <PageBasedSwitcher
        pageIndex={page}
        totalPages={pagination.totalPages}
        totalItems={pagination.totalItems}
      />
    </Authorize>
  )
}

export default UnileverApplicationList
