import { emptyArray } from 'utils/defaults'
import { APP_BKASH_PAYMENTS_SET_ALL } from '.'

const initialState = {
  payments: emptyArray,
  loading: true,
}

const appBkashPaymentsReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case APP_BKASH_PAYMENTS_SET_ALL:
      return {
        ...state,
        payments: data,
        loading: false,
      }
    default:
      return state
  }
}

export default appBkashPaymentsReducer
