import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/core'
import { displayBDT } from '@eloan/shared'
import { DataTable } from '@eloan/shared/components/Table'
import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { getDate } from 'utils/getDate'

const getColumns = () => [
  {
    Header: 'Deposit Date',
    accessor: ({ depositDate }) => getDate(depositDate),
  },
  {
    Header: 'Amount',
    accessor: ({ amount }) => displayBDT(amount),
  },
]

function CollectionSummary({ repayments, children, ...props }) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => repayments, [repayments])

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <>
      <Button px={6} onClick={onOpen} variant="link" {...props}>
        {children}
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay zIndex={1400} />
        <ModalContent zIndex={1400} p={4}>
          <ModalHeader>Collection Summary</ModalHeader>
          <ModalCloseButton type="button" />
          <ModalBody>
            <Box>
              <DataTable {...table} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CollectionSummary
