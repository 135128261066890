import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBusinessMediums } from '.'

export function useBusinessMediums(forceRefresh = false) {
  const businessMediums = useSelector((state) => state.businessMediums)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!businessMediums.allIds.length || forceRefresh) {
      dispatch(getAllBusinessMediums())
    }
  }, [businessMediums.allIds.length, dispatch, forceRefresh])

  return businessMediums
}
