import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/core'
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Authorize from 'components/Authorize'
import ApplicationListHeader from 'components/ApplicationList/Header'
import { useMemo } from 'react'
import { useCallback } from 'react'
import CreateTweakOffer from './View/CreateTweak'
import { useApplication } from 'store/applications/hooks'
import Loader from 'components/Loader/Loader'
import { get } from 'lodash-es'

const fields = ['*', 'meta.loaneeName', 'offer.*'].join(',')

const tabs = [{ id: '', name: 'Offer' }]

function AdminOffer() {
  const { tabId, applicationId } = useParams()

  const application = useApplication(applicationId, fields)

  const history = useHistory()

  const onTabChange = useCallback(
    (index) => {
      history.replace(`/dash/admin/offer/${applicationId}/${tabs[index].id}`)
    },
    [history, applicationId]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === tabId)
    return index < 0 ? 0 : index
  }, [tabId])

  return (
    <Authorize permissions="eloan:DraftAccount:write">
      {application ? (
        <>
          <ApplicationListHeader
            title={`${get(application, 'meta.loaneeName')} | eLOAN ID: ${get(
              application,
              'id'
            )} | ${get(application, 'phoneNumber')}`}
          />
          <Tabs index={tabIndex} onChange={onTabChange} variantColor="cyan">
            <TabList mb={4}>
              {tabs.map((tab) => (
                <Tab id={tab.id} key={tab.id} px={0} pt={0} mr={4}>
                  {tab.name}
                </Tab>
              ))}
            </TabList>

            <TabPanels py={4}>
              <TabPanel>
                <CreateTweakOffer application={application} />
              </TabPanel>
              <TabPanel></TabPanel>
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <Loader />
      )}
    </Authorize>
  )
}

export default AdminOffer
