import { api } from 'api'
import { useEffect, useState } from 'react'
import { emptyArray } from 'utils/defaults'

export function useCollectionByApplication({
  applicationId,
  isSettled,
  loanProductId,
}) {
  const [items, setItems] = useState(emptyArray)

  useEffect(() => {
    api(
      'GET /legacy/v4/eloan/loan-products/{loanProductId}/applications/{applicationId}/collections{?isSettled}',
      {
        applicationId,
        isSettled,
        loanProductId,
      }
    ).then(({ data }) => setItems(data))
  }, [applicationId, isSettled, loanProductId])

  return items
}
