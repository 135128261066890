import FormSelect from 'components/Form/Select'
import React, { useMemo } from 'react'
import { Box } from 'reflexbox'
import { getPACallStatusOptions } from 'utils/selectOptions'

function PACallStatusFilter({ ...props }) {
  const paCallStatusOptions = useMemo(
    () => ({
      null: 'Uncalled',
      ...getPACallStatusOptions(),
    }),
    []
  )

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="meta.paCallStatus"
        label={`Filter by PA Call Status`}
        options={paCallStatusOptions}
      />
    </Box>
  )
}

export default PACallStatusFilter
