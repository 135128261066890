import React, { useMemo } from 'react'
import {
  Box,
  Flex,
  Button,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/core'
import { union } from 'lodash-es'
import { useLocation, Link } from 'react-router-dom'

const getLinkWithLimitQuery = (location, limit) => {
  const path = location.pathname
  const query = new URLSearchParams(location.search)

  if (limit) {
    query.set('limit', limit)
  } else {
    query.delete('limit')
  }

  return `${path}?${query.toString()}`
}

const MAX_LIMIT = 500

const defaultLimits = [20, 60, 100, 200, 300, MAX_LIMIT]

const clipMaxLimit = (limit) =>
  parseInt(limit) > MAX_LIMIT ? MAX_LIMIT : parseInt(limit)

function LimitBasedFilter({
  count,
  total,
  limit = 20,
  limitList = defaultLimits,
}) {
  const items = useMemo(() => union(limitList, [clipMaxLimit(limit)]), [
    limit,
    limitList,
  ])

  const location = useLocation()

  return (
    <Flex
      position="relative"
      justify="space-between"
      mt={10}
      borderTopWidth="1px"
      bg="white"
      py={4}
    >
      <Text fontSize="lg">
        Total in page: <Text as="strong">{count}</Text>
        {!!total && <Text as="strong">/{total}</Text>}
      </Text>
      <Box>
        <Menu>
          <MenuButton
            as={Button}
            variant="link"
            color="gray.700"
            rightIcon="triangle-down"
          >
            Number of Items per Page
          </MenuButton>
          <MenuList minWidth="240px">
            <MenuOptionGroup defaultValue={clipMaxLimit(limit)} type="radio">
              {items.map((count) => (
                <MenuItemOption
                  key={count}
                  as={Link}
                  value={count}
                  to={getLinkWithLimitQuery(location, count)}
                >
                  {count} items
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  )
}

export default LimitBasedFilter
