import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAreas, getAllAreasAndDistricts } from 'store/areas'

export function useAreas(forceRefresh = false) {
  const areas = useSelector((state) => state.areas)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!areas.allIds.length || forceRefresh) {
      dispatch(getAllAreas())
    }
  }, [areas.allIds.length, dispatch, forceRefresh])

  return areas
}

export function useDistricts(forceRefresh = false) {
  const districts = useSelector((state) => state.areas.districts)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!districts.allIds.length || forceRefresh) {
      dispatch(getAllAreasAndDistricts())
    }
  }, [districts.allIds.length, dispatch, forceRefresh])

  return districts
}
