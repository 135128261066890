import React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
import FinanceAwaitingDisbursementList from './awaiting-disbursement/List'
import FinanceAwaitingDisbursementView from './awaiting-disbursement/View'
import FinanceCloseLoanPage from './close-loan'
import FinanceCollectionPage from './collection'
import FinanceRedxSummaryPage from './redx-summary'

function FinancePage() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route
        exact
        path={`${path}/close-loan`}
        component={FinanceCloseLoanPage}
      />
      <Route
        exact
        path={`${path}/awaiting-disbursement`}
        component={FinanceAwaitingDisbursementList}
      />
      <Route
        exact
        path={`${path}/awaiting-disbursement/details/:applicationId`}
        component={FinanceAwaitingDisbursementView}
      />
      <Route
        path={`${path}/awaiting-disbursement/:tabId?`}
        component={FinanceAwaitingDisbursementList}
      />
      <Route path={`${path}/collection`} component={FinanceCollectionPage} />
      <Route path={`${path}/redx-summary`} component={FinanceRedxSummaryPage} />
      <Redirect to={`${path}/close-loan`} />
    </Switch>
  )
}

export default FinancePage
