import { Box } from '@chakra-ui/core'
import { FormDateRangePicker } from 'components/Form/DatePicker'
import React from 'react'

function CreationDateFilter({ ...props }) {
  return (
    <Box {...props}>
      <FormDateRangePicker
        name="createdAt"
        label="Filter by Date"
        inputProps={{ width: '8rem' }}
        startProps={{ placeholderText: 'Start Date' }}
        endProps={{ placeholderText: 'End Date' }}
      />
    </Box>
  )
}

export default CreationDateFilter
