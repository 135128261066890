import { Box, Heading, SimpleGrid, Button, Text } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import React from 'react'

function FinanceRedxSummaryPage() {
  return (
    <Authorize permissions="eloan:RedXTransaction:read">
      <Box>
        <Heading size="lg" mb={10}>
          RedX Finance Summary
        </Heading>
        <SimpleGrid mt={2} pt={6} borderTopWidth={1} columns={{ xs: 1, md: 2 }}>
          <Button
            onClick={() =>
              window.open(
                'https://mb.shopup.com.bd/question/5832-redx-rap-finance-summary'
              )
            }
            variantColor="green"
            mr={3}
          >
            Click Here to See the Total Finance Summary
          </Button>

          <Button
            onClick={() =>
              window.open(
                'https://mb.shopup.com.bd/question/5899-redx-rap-disbursements-and-collections-in-date-range'
              )
            }
            variantColor="green"
            mr={3}
          >
            Click here to See the Collection and Disbursement With Date Range
          </Button>
        </SimpleGrid>
      </Box>
    </Authorize>
  )
}

export default FinanceRedxSummaryPage
