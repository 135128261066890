import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

const adapter = createEntityAdapter({
  selectId: (item) => item.applicationId,
})

const ccaSlice = createSlice({
  name: 'cca',
  initialState: adapter.getInitialState({ paginationData: {} }),
  reducers: {
    add(state, { payload }) {
      const { items, ...paginationData } = payload
      adapter.setAll(state, items)
      state.paginationData = paginationData
    },
    removeOne(state, action) {
      adapter.removeOne(state, action.payload.applicationId)
    },
    remove(state, action) {
      adapter.removeMany(state, action.payload)
    },
  },
})

export const { selectAll: selectAllCCA } = adapter.getSelectors(
  (state) => state.repaymentFollowup.cca
)

export const { add, removeOne, remove: removeFromCCA } = ccaSlice.actions

export default ccaSlice.reducer

export const fetchCCAList = ({ eloanId, limit, minDpd, maxDpd }) => async (
  dispatch,
  getState,
  { api }
) => {
  const { data } = await api(
    `/legacy/v3/eloan/cca/applications{?limit,minDpd,maxDpd,filter}`,
    {
      limit,
      minDpd,
      maxDpd,
      filter: eloanId,
    }
  )

  dispatch(add(data))

  return data
}

export const addCCA = ({ applicationIds }) => async (
  dispatch,
  getState,
  { api }
) => {
  const { data } = await api(`POST /legacy/v3/eloan/cca/applications`, {
    applicationIds,
  })

  return data
}

export const removeCCA = (applicationId) => async (
  dispatch,
  getState,
  { api }
) => {
  const { data } = await api(
    `DELETE /legacy/v3/eloan/cca/applications/{applicationId}`,
    {
      applicationId,
    }
  )

  dispatch(removeOne({ applicationId }))

  return data
}
