import React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
import UnileverSettlementsPage from './unilever'

function SettlementsPage() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/unilever`} component={UnileverSettlementsPage} />
      <Redirect to={`${path}/unilever`} />
    </Switch>
  )
}

export default SettlementsPage
