import camelcaseKeys from 'camelcase-keys'
import { batch } from 'react-redux'

export const USER_ADD = 'USER_ADD'
export const USER_ADD_BULK = 'USER_ADD_BULK'

export const USER_LOADING_INIT = 'USER_LOADING_INIT'
export const USER_LOADING_DONE = 'USER_LOADING_DONE'

export const USER_PAGE_ADD = 'USER_PAGE_ADD'
export const USER_PAGE_REMOVE = 'USER_PAGE_REMOVE'
export const USER_PAGE_REQUEST = 'USER_PAGE_REQUEST'
export const USER_PAGINATION_PURGE = 'USER_PAGINATION_PURGE'

export const USER_FIELD_AGENT_AREAS_SET = 'USER_FIELD_AGENT_AREAS_SET'

export const getUserById = (userId) => async (dispatch, _getState, { api }) => {
  const { data } = await api(`/auth/v0/users/{userId}`, {
    userId,
  })

  dispatch({ type: USER_ADD, data, userId })
}

export const getUserByPhone = (userPhone) => async (
  dispatch,
  _getState,
  { api }
) => {
  const url = `/legacy/v3/users?filter=PHONE_NUMBER==${userPhone}`

  const { data } = await api(url)

  const user = data.items[0] || null

  if (user) {
    dispatch({
      type: USER_ADD,
      data: camelcaseKeys(user, { deep: true }),
      userId: user.id || user.ID,
    })
  }

  return user
}

export const getUserPage = (
  { page = 1, limit, fields, filter, rolesFilter },
  queryHash
) => async (dispatch, _getState, { api }) => {
  dispatch({ type: USER_PAGE_REQUEST, page, queryHash })

  try {
    const { data } = await api(
      `/legacy/v4/users{?limit,page,fields,filter,rolesFilter}`,
      {
        limit,
        page,
        fields,
        filter,
        rolesFilter,
      }
    )

    batch(() => {
      dispatch({ type: USER_ADD_BULK, data, groupName: queryHash })
      dispatch({ type: USER_PAGE_ADD, page, data, queryHash })
    })

    return data
  } catch (err) {
    if (err.error && err.error.status >= 500) {
      dispatch({ type: USER_PAGE_REMOVE, page, queryHash })
    }

    throw err
  }
}

export const getAllUserByRole = (roleId, { fields } = {}) => async (
  dispatch,
  _getState,
  { api }
) => {
  const groupName = `byRole:${roleId}`

  dispatch({ type: USER_LOADING_INIT, groupName })

  const { data } = await api(`/auth/v0/roles/{roleId}/users{?fields*}`, {
    roleId,
    fields,
  })

  dispatch({ type: USER_ADD_BULK, data, groupName })

  dispatch({ type: USER_LOADING_DONE, groupName })
}

export const getFieldAgentAreas = (userId) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api('/legacy/v3/users/{userId}/field-agent-areas', {
    userId,
  })

  dispatch({
    type: USER_FIELD_AGENT_AREAS_SET,
    data: camelcaseKeys(data, { deep: true }),
    userId,
  })
}

export const setFieldAgentAreas = (userId, { areaIds }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    'POST /legacy/v3/users/{userId}/field-agent-areas',
    {
      userId,
      areaIds,
    }
  )

  dispatch({
    type: USER_FIELD_AGENT_AREAS_SET,
    data: camelcaseKeys(data, { deep: true }),
    userId,
  })
}
