import FormSelect from 'components/Form/Select'
import React from 'react'
import { Box } from 'reflexbox'
import { cibPaymentStatusDisplayText } from 'utils/meta'

const options = {
  '': 'Any',
  ...cibPaymentStatusDisplayText,
}

function ApplicationCibPaymentStatusFilter({ ...props }) {
  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="metaExtra.cibPaymentStatus"
        label={`CIB Payment`}
        options={options}
      />
    </Box>
  )
}

export default ApplicationCibPaymentStatusFilter
