export const offerStatusDisplayText = {
  offer_negotiating: 'Offer Negotiate',
  offer_tweaking: 'Offer Tweak',
  loanee_agreement_pending: 'Agreement Pending',
  loanee_agreement_signed: 'Agreement Signed',
  'shopup-approved': 'ShopUp Approved',
  'partner-approved': 'Partner Approved',
  financed: 'Financed',
  active: 'Active',
  ended: 'Ended',
  'shopup-rejected': 'ShopUp Rejected',
  'partner-rejected': 'Partner Rejected',
  need_revision: 'Need Revision',
}
