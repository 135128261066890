import { Box, Heading, Image, Link, SimpleGrid, Text } from '@chakra-ui/core'
import { getDate, getPrivateSrc } from '@eloan/shared'
import InfoBox from 'components/Box/InfoBox'
import { get, map } from 'lodash-es'
import {
  getDocDataByKey,
  LOANEE_BANK_CHEQUE_KEYS,
  SIGNED_AGREEMENT_KEYS,
} from 'pages/eloan-applications/utils/getDocImage'
import React, { useMemo } from 'react'
import { useApplicationEntities } from 'store/applications/hooks'
import { getPrivateImageSrc } from 'utils/getPrivateImageSrc'
import { getRelationshipOptions } from 'utils/selectOptions'

const dataMapper = [
  {
    label: 'Full Name',
    key: 'meta.loaneeName',
  },
  {
    label: 'DOB',
    getValue: ({ meta }) => getDate(meta?.loaneeDob),
  },
  {
    label: 'Bank A/C No',
    key: 'bankDetails.bankAccountNumber',
  },
  {
    label: 'Routing Number',
    key: 'bankDetails.routingNumber',
  },
  {
    label: 'NID Number',
    key: 'meta.loaneeNid',
  },
  {
    label: 'Guarantor Name',
    key: 'meta.guarantorName',
  },
  {
    label: 'Trade License Number',
    key: 'meta.tradeLicenceNumber',
  },
  {
    label: 'Bank Name',
    key: 'bankDetails.bankName',
  },
  {
    label: 'Existing Loan',
    key: 'meta.loan',
  },
  {
    label: 'Business Type',
    key: 'meta.businessType',
  },
  {
    label: 'Relationship with Guarantor',
    getValue: ({ meta }) =>
      getRelationshipOptions()[meta?.guarantorRelationship],
  },
  {
    label: 'Trade License Expiry Date',
    getValue: ({ meta }) => getDate(meta?.tradeLicenceExpiresAt),
  },
  {
    label: 'Branch Name',
    key: 'bankDetails.bankBranchName',
  },
  {
    label: 'Existing Loan Repayment',
    key: 'meta.loanInstallment',
  },
]

const attachmentsMapper = [
  {
    label: 'NID Front Image',
    getValue: ({ meta }) => (
      <Link href={getPrivateImageSrc(get(meta, 'loaneeNidPhoto'))} isExternal>
        <Image
          size="100px"
          objectFit="cover"
          src={getPrivateImageSrc(get(meta, 'loaneeNidPhoto'))}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
      </Link>
    ),
  },
  {
    label: 'NID Back Image',
    getValue: ({ meta }) => (
      <Link
        href={getPrivateImageSrc(get(meta, 'loaneeNidPhotoBack'))}
        isExternal
      >
        <Image
          size="100px"
          objectFit="cover"
          src={getPrivateImageSrc(get(meta, 'loaneeNidPhotoBack'))}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
      </Link>
    ),
  },
  {
    label: 'Trade License',
    getValue: ({ metaExtra }) => (
      <Link
        href={getPrivateImageSrc(get(metaExtra, 'tradeLicensePhoto'))}
        isExternal
      >
        <Image
          size="100px"
          objectFit="cover"
          src={getPrivateImageSrc(get(metaExtra, 'tradeLicensePhoto'))}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
      </Link>
    ),
  },
  {
    label: 'Bank Cheque',
    getValue: ({ docs }) =>
      getDocDataByKey(docs, LOANEE_BANK_CHEQUE_KEYS) && (
        <Link
          color="cyan.500"
          href={getPrivateSrc(getDocDataByKey(docs, LOANEE_BANK_CHEQUE_KEYS))}
          isExternal
        >
          VIEW
        </Link>
      ),
  },
  {
    label: 'Signed Agreement',
    getValue: ({ docs }) =>
      getDocDataByKey(docs, SIGNED_AGREEMENT_KEYS) && (
        <Link
          color="cyan.500"
          href={getPrivateSrc(getDocDataByKey(docs, SIGNED_AGREEMENT_KEYS))}
          isExternal
        >
          VIEW
        </Link>
      ),
  },
]

function FinanceLoanInfo({ application = {} }) {
  const docsMeta = useApplicationEntities({
    applicationId: get(application, 'id'),
    partnerId: get(application, 'offer.loanPartner'),
  })

  const applicationWithDocs = useMemo(
    () => ({ ...application, docs: docsMeta }),
    [application, docsMeta]
  )
  return (
    <Box>
      <SimpleGrid
        columns={5}
        gridColumnGap={2}
        borderBottomWidth="1px"
        pb={4}
        mb={4}
      >
        {map(dataMapper, ({ label, key, getValue }) => (
          <InfoBox key={label} label={label} color="gray.500">
            <Text color="black" fontSize="md" wordBreak="break-word">
              {getValue
                ? getValue(applicationWithDocs)
                : get(applicationWithDocs, key)}
            </Text>
          </InfoBox>
        ))}
      </SimpleGrid>
      <Heading size="md" mb={4}>
        Attachments
      </Heading>
      <SimpleGrid
        columns={5}
        gridColumnGap={2}
        borderBottomWidth="1px"
        pb={4}
        mb={4}
      >
        {map(attachmentsMapper, ({ label, key, getValue }) => (
          <InfoBox key={label} label={label} color="gray.500">
            <Text color="black" fontSize="md" wordBreak="break-word">
              {getValue
                ? getValue(applicationWithDocs)
                : get(applicationWithDocs, key)}
            </Text>
          </InfoBox>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default FinanceLoanInfo
