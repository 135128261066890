import { emptyArray } from '@eloan/shared/utils/defaults'
import { get } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNobodarApplication,
  getNobodarPACallLogsForApplication,
} from 'store/nobodar'

export function useNobodarApplication(applicationId) {
  const application = useSelector((state) =>
    get(state.nobodar.applications.byId, applicationId)
  )

  const dispatch = useDispatch()
  useEffect(() => {
    if (applicationId && !application) {
      dispatch(getNobodarApplication(applicationId))
    }
  }, [application, applicationId, dispatch])

  return application
}

export function useNobodarApplicationPACallLogs(applicationId) {
  const byId = useSelector((state) => state.nobodar.paCallLogs.byId)
  const allIds = useSelector((state) =>
    get(state.nobodar.paCallLogs.idsByApplication, applicationId, emptyArray)
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getNobodarPACallLogsForApplication(applicationId))
  }, [applicationId, dispatch])

  const paCallLogs = useMemo(() => ({ byId, allIds }), [allIds, byId])

  return paCallLogs
}
