import React from 'react'
import { SimpleGrid, Box, Heading } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { map, get } from 'lodash-es'

function InfoCollection({
  title,
  dataMapper,
  application,
  grid = 5,
  ...props
}) {
  return (
    <Box py={4} {...props}>
      {title && (
        <Heading size="md" mb={4}>
          {title}
        </Heading>
      )}
      <SimpleGrid columns={grid} gridColumnGap={2}>
        {map(dataMapper, ({ label, key, getValue }, index) => (
          <InfoBox key={index} label={label} color="gray.500">
            <Box
              color="black"
              fontSize="md"
              textTransform="capitalize"
              wordBreak="break-word"
            >
              {getValue ? getValue(application) : get(application, key)}
            </Box>
          </InfoBox>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default InfoCollection
