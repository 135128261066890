import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { displayBDT } from '@eloan/shared'
import Authorize from 'components/Authorize'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { closeAccount, forceCloseAccount } from 'store/accounts/reducer'
import { usePartnerRepayments } from 'store/admin/hooks'

function isAccountCloseable({ partner }) {
  return (
    +get(partner, 'summary.loaneeOutstandingAmount') <= 0 &&
    +get(partner, 'summary.partnerOutstandingAmount') <= 0
  )
}

function isAccountForceCloseable({ partner }) {
  return get(partner, 'summary.isAccountForceCloseable')
}

function showAccountClosingButton(partnerSummary) {
  return (
    isAccountCloseable(partnerSummary) ||
    isAccountForceCloseable(partnerSummary)
  )
}

function CloseLoan({ application, fiAccountId }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const repayment = usePartnerRepayments(get(application, 'id'))

  const [isSubmitting, setIsSubmitting] = useState(false)

  const dispatch = useDispatch()

  const toast = useToast()
  const beftnProofFileInput = useRef(null)

  const onConfirm = useCallback(async () => {
    try {
      setIsSubmitting(true)
      if (isAccountCloseable(repayment)) {
        await dispatch(
          closeAccount(fiAccountId, {
            applicationId: get(application, 'id'),
          })
        )
      } else if (isAccountForceCloseable(repayment)) {
        if (!beftnProofFileInput.current.files.length) {
          toast({
            title: 'Beftn Proof Must Be Submitted!',
            description:
              'Beftn proof must be submitted to force close an account',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        } else {
          await dispatch(
            forceCloseAccount(
              fiAccountId,
              {
                applicationId: get(application, 'id'),
              },
              beftnProofFileInput.current.files[0]
            )
          )
        }
      }

      onClose()
    } catch (error) {
      handleAPIError(error, { toast })
    }
    setIsSubmitting(false)
  }, [
    fiAccountId,
    application,
    dispatch,
    toast,
    onClose,
    repayment,
    beftnProofFileInput,
  ])

  return (
    <Authorize permissions="eloan:AnyApplication:close">
      {showAccountClosingButton(repayment) && (
        <Button onClick={onOpen} variantColor="cyan" px={10}>
          CLOSE LOAN
        </Button>
      )}
      <Modal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalHeader>Are you sure, you want to close this loan?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Stack isInline spacing={4}>
                <Text>Loanee Name:</Text>
                <Text>{get(application, 'meta.loaneeName')}</Text>
              </Stack>
              <Stack isInline spacing={4}>
                <Text>eLoan ID:</Text>
                <Text>{get(application, 'id')}</Text>
              </Stack>
              {get(repayment, 'partner.summary') && (
                <>
                  <Stack isInline spacing={4} mt={4}>
                    <Text>Loanee Outstanding Amount:</Text>
                    <Text>
                      {displayBDT(
                        +get(
                          repayment,
                          'partner.summary.loaneeOutstandingAmount'
                        )
                      )}
                    </Text>
                  </Stack>
                  <Stack isInline spacing={4}>
                    <Text>Partner Outstanding Amount:</Text>
                    <Text>
                      {displayBDT(
                        +get(
                          repayment,
                          'partner.summary.partnerOutstandingAmount'
                        )
                      )}
                    </Text>
                  </Stack>
                  {isAccountCloseable(repayment) === false &&
                    isAccountForceCloseable(repayment) === true && (
                      <Stack spacing={1} mt={4}>
                        <Text>Attach BEFTN Proof:</Text>
                        <Input
                          ref={beftnProofFileInput}
                          border="none"
                          paddingLeft={0}
                          name="attachment"
                          type="file"
                          label="Attachment"
                          placeholder="Attach BEFTN Proof"
                        />
                      </Stack>
                    )}
                </>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              flex={1}
              variant="outline"
              variantColor="red"
              mr={3}
              onClick={onClose}
            >
              CANCEL
            </Button>
            <Button
              flex={1}
              variantColor="green"
              onClick={onConfirm}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              YES, CONFIRM
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default CloseLoan
