import { defaultsDeep, get, keyBy, map, union } from 'lodash-es'
import {
  DOC_REQUIREMENT_SET,
  DOC_REQUIREMENT_SET_ALL,
} from 'store/docRequirements'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  idsByApplication: emptyObject,
}

const docRequirementsReducer = (
  state = initialState,
  { type, data, applicationId }
) => {
  switch (type) {
    case DOC_REQUIREMENT_SET:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: defaultsDeep(data, state.byId[data[idKey]]),
        },
        allIds: union(state.allIds, [data[idKey]]),
        idsByApplication: {
          ...state.idsByApplication,
          [applicationId]: union(
            get(state.idsByApplication, applicationId, emptyArray),
            [data[idKey]]
          ),
        },
      }

    case DOC_REQUIREMENT_SET_ALL:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        idsByApplication: {
          ...state.idsByApplication,
          [applicationId]: union(
            get(state.idsByApplication, applicationId, emptyArray),
            map(data.items, idKey)
          ),
        },
      }

    default:
      return state
  }
}

export default docRequirementsReducer
