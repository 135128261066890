import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ApplicationList from './List'

function ShwanirbhorApplicationsPage() {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/dash/shwanirbhor-applications"
          component={ApplicationList}
        />
      </Switch>
    </>
  )
}

export default ShwanirbhorApplicationsPage
