import FormSelect from 'components/Form/Select'
import React, { useMemo } from 'react'
import { Box } from 'reflexbox'

function DocSubmissionMethodFilter({ ...props }) {
  const docSubmissionMethodOptions = useMemo(
    () => ({
      '': 'Any',
      null: 'N/A',
      online: 'Online',
      offline: 'Offline',
    }),
    []
  )

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="meta.docSubmissionMethod"
        label={`Filter by DocSubmissionMethod`}
        options={docSubmissionMethodOptions}
      />
    </Box>
  )
}

export default DocSubmissionMethodFilter
