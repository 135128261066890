import { api } from 'api'
import { batch } from 'react-redux'

export const BEFTN_SET_ALL = 'BEFTN_SET_ALL'
export const BEFTN_ADD = 'BEFTN_ADD'
export const BEFTN_UPDATE = 'BEFTN_UPDATE'

export const getBankDetailsForEloanId = async (eloanIds) => {
  const { data } = await api(
    `/legacy/v4/eloan/applications?fields=id,bankDetails.*&filter=${eloanIds
      .map((id) => `id==${id}`)
      .join(',')}`
  )
  const bankDetails = {}
  for (const item of data?.items) {
    bankDetails[item.id] = item.bankDetails
  }
  return bankDetails
}

export const getBeftns = ({ limit = 20, dateTo, dateFrom }) => async (
  dispatch,
  _getState
) => {
  const { data } = await api(`/eloan/v1/beftns{?limit,dateTo,dateFrom}`, {
    limit,
    dateTo,
    dateFrom,
  })

  dispatch({ type: BEFTN_SET_ALL, data })

  return data
}

export const createBeftn = ({ beftnRequests }) => async (
  dispatch,
  _getState
) => {
  const { data } = await api(`POST /eloan/v1/beftns`, { beftnRequests })

  dispatch({ type: BEFTN_ADD })

  return data
}

export const updateBeftn = (id, formData) => async (dispatch, _getState) => {
  const { data } = await api(`PUT /eloan/v1/beftns/{id}`, {
    id,
    body: formData,
  })

  batch(() => {
    dispatch({ type: BEFTN_UPDATE, data, id })
  })

  return data
}
