import { Spinner } from '@chakra-ui/core'
import { DataTable } from '@eloan/shared/components/Table'
import React from 'react'
import { useTable } from 'react-table'

function ApplicationListTable({ loading, data, columns }) {
  const table = useTable({
    data: data,
    columns: columns,
  })

  return loading ? (
    <Spinner
      display="block"
      mx="auto"
      my={20}
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  ) : (
    <DataTable {...table} />
  )
}

export default ApplicationListTable
