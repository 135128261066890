import React, { useMemo, useState, useEffect } from 'react'
import { debounce, get } from 'lodash-es'
import { emptyArray } from 'utils/defaults'
import Authorize from 'components/Authorize'
import {
  Checkbox,
  Box,
  Heading,
  Flex,
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
} from '@chakra-ui/core'
import ListTable from 'components/ListTable/ListTable'
import { useTable, useRowSelect } from 'react-table'
import ConfirmSettlement from './Settle'
import { useFiPayableData } from 'store/settlements/hooks'
import { useQuery } from 'hooks/useQuery'
import { displayBDT, getDate } from '@eloan/shared'
import { useFinancialInstitutions } from 'store/admin/hooks'

const getColumns = (currentFi) => [
  {
    Header: ({ getToggleAllRowsSelectedProps }) => {
      const { checked, ...props } = getToggleAllRowsSelectedProps()
      return <Checkbox isChecked={checked} {...props} mt={1} />
    },
    accessor: 'id',
    Cell: ({ row }) => {
      const { checked, ...props } = row.getToggleRowSelectedProps()
      return <Checkbox isChecked={checked} {...props} mt={1} />
    },
  },
  { Header: 'eloan ID', accessor: 'eloanId' },
  { Header: `${get(currentFi, 'name')} ID`, accessor: 'fiMemberId' },
  { Header: 'Applicant Name', accessor: 'loaneeName' },
  { Header: 'Phone Number', accessor: 'loaneePhoneNumber' },
  {
    Header: 'Loanee Overdue',
    accessor: ({ currentOverdueAmount }) => displayBDT(currentOverdueAmount),
  },
  // {
  //   Header: 'Cash ShopUp Holds',
  //   accessor: ({ cashShopupHolds }) => displayBDT(cashShopupHolds),
  // },
  { Header: 'Partner EMI', accessor: ({ emiAmount }) => displayBDT(emiAmount) },
  {
    Header: 'Payable Amount',
    accessor: ({ payableAmount }) => displayBDT(payableAmount),
  },
  { Header: 'Partner Due Date', accessor: ({ emiDate }) => getDate(emiDate) },
]

function HeaderActionBar({ selectedEloans, onSearch }) {
  const onChange = debounce((value) => {
    onSearch(value)
  }, 500)

  return (
    <Flex justify="space-between" mt={6} align="center">
      <Box>
        <InputGroup size="sm">
          <InputLeftElement
            children={<Icon name="search" color="blue.500" />}
          />
          <Input
            placeholder="Search by eLOAN ID"
            onChange={(event) => onChange(event.target.value)}
          />
        </InputGroup>
      </Box>
      <Box>
        <Authorize permissions="eloan:PartnerBeftn:write">
          <ConfirmSettlement selectedEloans={selectedEloans} />
        </Authorize>
      </Box>
    </Flex>
  )
}

function PartnerSettlementList() {
  const query = useQuery()

  const [eloanId, setEloanId] = useState('')
  const [selectedEloans, setSelectedEloans] = useState(emptyArray)

  const queryObject = useMemo(
    () => ({
      loanProductId: query.get('loanProductId'),
      fiId: query.get('fiId'),
      eloanId: eloanId || undefined,
    }),
    [query, eloanId]
  )

  const finanancialInstituions = useFinancialInstitutions()

  const currentFi = useMemo(() => {
    return finanancialInstituions.byId[query.get('fiId')]
  }, [finanancialInstituions.byId, query])

  const paymentsData = useFiPayableData(queryObject)

  const data = useMemo(
    () => (paymentsData.allIds || []).map((id) => paymentsData.byId[id]),
    [paymentsData]
  )

  const columns = useMemo(() => getColumns(currentFi), [currentFi])

  const table = useTable(
    {
      data,
      columns: columns,
      getRowId: (row) => row.eloanId,
    },
    useRowSelect
  )

  useEffect(() => {
    setSelectedEloans(
      Object.keys(table.state.selectedRowIds)
        .filter((id) => table.state.selectedRowIds[id])
        .map((selectedId) => paymentsData.byId[selectedId])
    )
  }, [table.state.selectedRowIds, paymentsData])

  return (
    <Authorize permissions="eloan:PartnerSettlement:read">
      <Box mb={10}>
        <Heading fontSize={5}>Partner Settlement</Heading>
        <HeaderActionBar
          selectedEloans={selectedEloans}
          onSearch={setEloanId}
        />
      </Box>
      <ListTable table={table} />
    </Authorize>
  )
}

export default PartnerSettlementList
