import { toRounded2F2PNumber } from '@eloan/shared'
import { camelCaseToSentence } from './string'

export const mapScorecardComponentValues = (scoreComponents) => {
  return Object.keys(scoreComponents).map((fieldName) => {
    const component = scoreComponents[fieldName]
    let fieldNameDisplay = camelCaseToSentence(fieldName)
    if (fieldNameDisplay === 'Has Previous Loan History') {
      fieldNameDisplay = 'Previous Loan experience from formal channel'
    }

    let valueDisplay = component.value
    if (['postgraduate', 'undergraduate'].includes(valueDisplay)) {
      valueDisplay = 'graduation'
    }

    return {
      fieldName: fieldNameDisplay,
      value: valueDisplay,
      score: toRounded2F2PNumber(component.score),
      weight: component.weight,
      weightedScore: toRounded2F2PNumber(component.weightedScore),
    }
  })
}
