import React, { useCallback, useMemo, useState } from 'react'
import {
  Button,
  Box,
  Heading,
  Text,
  Stack,
  Link,
  Flex,
  Icon,
  Tooltip,
  IconButton,
  useTheme,
} from '@chakra-ui/core'
import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import { useTable } from 'react-table'
import ListTable from 'components/ListTable/ListTable'
import { emptyArray, emptyObject } from 'utils/defaults'
import { get } from 'lodash-es'
import Authorize from 'components/Authorize'
import { getPrivateSrc } from '@eloan/shared'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicationPage } from 'store/applications'
import usePagination from 'hooks/usePagination'
import { getDate } from 'utils/getDate'
import { shopUpFinancialInstitutionId } from 'pages/admin/constants'
import { useApplicationEntities } from 'store/applications/hooks'
import {
  getDocDataByKey,
  LOANEE_BANK_CHEQUE_KEYS,
} from 'pages/eloan-applications/utils/getDocImage'
import FinanceAwaitingDisbursementListFilter from './Filter'
import { downloadCSVFile } from '@eloan/shared/utils/csv'
import { RouterTab } from 'components/RouterTab/RouterTab'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import { useCounts } from './useCounts'

function ActionButtons({ application }) {
  const docs = useApplicationEntities({
    applicationId: application?.id,
    partnerId: shopUpFinancialInstitutionId,
  })

  const detailsRoutePath = useMemo(() => {
    if (['active', 'financed'].includes(application?.offer?.status)) {
      return `/dash/eloan-applications/${application?.id}`
    }
    return `/dash/finance/awaiting-disbursement/details/${application?.id}`
  }, [application])

  return (
    <Stack isInline spacing={4} alignItems="center">
      <Box>
        {getDocDataByKey(docs, LOANEE_BANK_CHEQUE_KEYS) && (
          <Flex align="center">
            <Icon name="download" color="cyan.500" mr={1} />
            <Link
              color="cyan.500"
              href={getPrivateSrc(
                getDocDataByKey(docs, LOANEE_BANK_CHEQUE_KEYS)
              )}
              isExternal
              fontWeight={600}
            >
              BANK CHEQUE
            </Link>
          </Flex>
        )}
      </Box>
      <Button
        as={RouterLink}
        to={detailsRoutePath}
        variantColor="cyan"
        variant="outline"
        rightIcon="chevron-right"
        px={4}
        size="sm"
      >
        VIEW
      </Button>
    </Stack>
  )
}

const getColumns = () => [
  {
    Header: 'STORE INFO',
    accessor: ({ shop, meta, createdAt, id, phoneNumber }) => (
      <Box>
        <Heading size="md" mb={2}>
          {shop?.shopName}
        </Heading>
        <Stack isInline spacing={2}>
          <Text>Name:</Text>
          <Text>{meta?.loaneeName}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Created On:</Text>
          <Text>{getDate(createdAt)}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>eLoan ID:</Text>
          <Text>{id}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Phone:</Text>
          <Text>{phoneNumber}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Email:</Text>
          <Text>{meta?.loaneeEmail}</Text>
        </Stack>
      </Box>
    ),
  },
  {
    Header: 'PAYMENT INFO',
    accessor: ({ bankDetails }) => (
      <Box>
        <Stack isInline spacing={2}>
          <Text>Bkash:</Text>
          <Text>{bankDetails?.bkashAccountNumber}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Bank: </Text>
          <Text>{bankDetails?.bankName}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Branch: </Text>
          <Text>{bankDetails?.bankBranchName}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Bank Account:</Text>
          <Text>
            {bankDetails?.bankAccountName}
            {bankDetails?.bankAccountNumber && ' , '}{' '}
            {bankDetails?.bankAccountNumber}
          </Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Routing Number:</Text>
          <Text>{bankDetails?.routingNumber}</Text>
        </Stack>
      </Box>
    ),
  },
  {
    Header: 'LOAN INFO',
    accessor: ({ loanAmount, tenure, onboardingFee }) => (
      <Box>
        <Stack isInline spacing={2}>
          <Text>Disbursement Amount:</Text>
          <Text>{loanAmount}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Onboarding Fee:</Text>
          <Text>{onboardingFee || 'N/A'}</Text>
        </Stack>
        <Stack isInline spacing={2}>
          <Text>Applied Tenure:</Text>
          <Text>{tenure} month(s)</Text>
        </Stack>
      </Box>
    ),
  },
  {
    Header: '',
    id: 'action',
    accessor: (row) => {
      return <ActionButtons application={row} />
    },
  },
]

function DownloadCSV({ applications }) {
  const handleClick = useCallback(() => {
    downloadCSVFile({
      data: [
        [
          'eLoan Id',
          'Name',
          'Shop ID',
          'Loan Amount',
          'Tenure',
          '% of COD',
          'Bank Account Name',
          'Bank Account Number',
          'Bank Name',
          'Branch Name',
          'Routing Number',
          'Account Type',
        ],
        ...applications.map((item) => [
          item.id,
          item.meta?.loaneeName,
          item.shopId,
          item.loanAmount,
          item.tenure,
          item.redxShopData?.paymentDeductionPercentage,
          item.bankDetails?.bankAccountName,
          `'${item.bankDetails?.bankAccountNumber}`,
          item.bankDetails?.bankName,
          item.bankDetails?.bankBranchName,
          `'${item.bankDetails?.routingNumber}`,
          item.bankDetails?.accountType,
        ]),
      ],
      filename: `awaiting-disbursement-applications-${Date.now()}.csv`,
    })
  }, [applications])

  if (!applications?.length) return null
  return (
    <Tooltip hasArrow label="Download CSV Data" placement="top" bg="blue.500">
      <IconButton
        onClick={handleClick}
        variantColor="blue"
        variant="outline"
        ml={4}
        icon="download"
      />
    </Tooltip>
  )
}

function FinanceDataList({
  getQueryObject,
  offerStatus,
  includeOnboardingFees = false,
}) {
  const [filterValues, setFilterValues] = useState(emptyObject)

  const dispatch = useDispatch()
  const fetchPage = useCallback(
    (params, queryHash) => {
      dispatch(
        getApplicationPage(
          {
            ...params,
            includeOnboardingFees,
          },
          queryHash
        )
      )
    },
    [dispatch, includeOnboardingFees]
  )

  const queryObject = useMemo(() => {
    return getQueryObject({ ...filterValues, offerStatus })
  }, [filterValues, getQueryObject, offerStatus])

  const pagination = useSelector((state) => state.pagination.applications)
  const [page] = usePagination(pagination, fetchPage, queryObject)

  const applications = useSelector((state) => state.applications)
  const data = useMemo(() => {
    return get(pagination.pages[page], 'itemIds', emptyArray).map(
      (id) => applications.byId[id]
    )
  }, [pagination.pages, page, applications.byId])

  const columns = useMemo(() => getColumns(), [])

  const table = useTable({ data, columns })

  return (
    <>
      <Flex alignItems="center" mt={4}>
        <FinanceAwaitingDisbursementListFilter onSubmit={setFilterValues} />
        <DownloadCSV applications={data} />
      </Flex>
      <ListTable table={table} />
      <PageBasedSwitcher
        pageIndex={page}
        totalPages={pagination.totalPages}
        totalItems={pagination.totalItems}
      />
    </>
  )
}

const getTabs = ([awaiting, disbursed, reverted, ended]) => [
  {
    label: `AWAITING(${awaiting})`,
    id: 'awaiting',
  },
  {
    label: `DISBURSED(${disbursed})`,
    id: 'disbursed',
  },
  {
    label: `REVERTED(${reverted})`,
    id: 'reverted',
  },
  {
    label: `ENDED(${ended})`,
    id: 'ended',
  },
]

const getQueryObject = ({
  min: minDate,
  max: maxDate,
  offerStatus = 'status==shopup-approved',
}) => ({
  limit: 80,
  fields: `id,type,phoneNumber,loanAmount,tenure,createdAt,shopId,meta.loaneeName,meta.loaneeEmail,offer.*,shop.shopName,bankDetails.*,redxShopData.*`,
  filter: `type==redx-shopup${
    minDate ? `;createdAt>=${minDate};createdAt<${maxDate}}` : ''
  }`,
  offerFilter: `${offerStatus};loanPartner==${shopUpFinancialInstitutionId}`,
})

function FinanceAwaitingDisbursementList() {
  const { path } = useRouteMatch()

  const { colors } = useTheme()

  const counts = useCounts()

  const tabs = useMemo(() => getTabs(counts), [counts])

  return (
    <Authorize permissions="eloan:AnyApplication:read">
      <Box>
        <Heading size="lg" mb={10}>
          Awaiting Disbursement
        </Heading>
      </Box>
      <RouterTab
        tabs={tabs}
        base={path}
        activeStyle={{ borderBottom: `1px solid ${colors.cyan[500]}` }}
        style={{ color: colors.cyan[500] }}
      >
        <Switch>
          <Route path={`${path}/awaiting`}>
            <FinanceDataList
              getQueryObject={getQueryObject}
              key={`${path}/awaiting`}
              includeOnboardingFees={true}
            />
          </Route>
          <Route path={`${path}/disbursed`}>
            <FinanceDataList
              getQueryObject={getQueryObject}
              offerStatus={`status~={financed|active}`}
              key={`${path}/disbursed`}
            />
          </Route>
          <Route path={`${path}/reverted`}>
            <FinanceDataList
              getQueryObject={getQueryObject}
              offerStatus={`status==need_revision`}
              key={`${path}/disbursed`}
            />
          </Route>
          <Route path={`${path}/ended`}>
            <FinanceDataList
              getQueryObject={getQueryObject}
              offerStatus={`status==ended`}
              key={`${path}/ended`}
            />
          </Route>
          <Redirect to={`${path}/awaiting`} />
        </Switch>
      </RouterTab>
    </Authorize>
  )
}

export default FinanceAwaitingDisbursementList
