import { DataTable } from '@eloan/shared'
import React, { useMemo } from 'react'
import { useTable } from 'react-table'

const getColumns = () => [
  { Header: 'Account ID', accessor: 'accountId' },
  { Header: 'Eloan ID', accessor: 'eloanId' },
  { Header: 'Name', accessor: 'name' },
  { Header: 'Phone Number', accessor: 'phoneNumber' },
  { Header: 'Loan Amount', accessor: 'loanAmount' },
  { Header: 'Account Status', accessor: 'accountStatus' },
  { Header: 'Account Type', accessor: 'accountType' },
  { Header: 'Tenure', accessor: 'tenure.value' },
]

function LoanModifierConfigLoanList({ loans = [] }) {
  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => loans, [loans])

  const table = useTable({ columns, data })

  return <DataTable {...table} />
}

export default LoanModifierConfigLoanList
