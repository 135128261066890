import React, { useCallback, useMemo } from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Box,
  useDisclosure,
  Button,
  useToast,
  Stack,
} from '@chakra-ui/core'
import { useDispatch } from 'react-redux'
import { createFinancialInstitution } from 'store/admin'
import { handleAPIError } from 'components/Form/helpers'
import Authorize from 'components/Authorize'
import Form from 'components/Form/Form'
import { useForm } from 'react-hook-form'
import FormInput from 'components/Form/Input'
import FormButton from 'components/Form/Button'
import * as Yup from 'yup'
import { usePartners } from 'store/partners/hooks'
import { zipObject } from 'lodash-es'
import FormSelect from 'components/Form/Select'
import { shopUpFinancialInstitutionId } from 'pages/admin/constants'

function CreateFinancialInstitution() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const dispatch = useDispatch()
  const toast = useToast()

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().trim().required('Required.'),
        partnerId: Yup.string().required('Required.'),
      }),
    []
  )

  const form = useForm({ validationSchema })

  const partners = usePartners()

  const partnerOptions = useMemo(() => {
    const partnersIdsWithoutShopUp = partners.allIds.filter(
      (id) => id !== shopUpFinancialInstitutionId
    )

    return zipObject(
      partnersIdsWithoutShopUp,
      partnersIdsWithoutShopUp.map((id) => partners.byId[id].name)
    )
  }, [partners])

  const onFormSubmit = useCallback(
    async ({ name, partnerId }) => {
      try {
        await dispatch(
          createFinancialInstitution({ name, shopupLoanPartnerId: partnerId })
        )
        onClose()
      } catch (error) {
        handleAPIError(error, { toast })
      }
    },
    [dispatch, onClose, toast]
  )

  return (
    <Authorize permissions="eloan:FinancialInstitution:write">
      <Button variant="link" variantColor="cyan" size="sm" onClick={onOpen}>
        CREATE BANK or FI's
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalHeader>Bank or, FI creation</ModalHeader>
          <ModalCloseButton type="button" />
          <ModalBody>
            <Form form={form}>
              <Stack spacing={6}>
                <Box>
                  <FormInput name="name" label="Name" />
                </Box>
                <Box>
                  <FormSelect
                    name="partnerId"
                    label="Select Partner"
                    options={partnerOptions}
                  />
                </Box>

                <Stack isInline spacing={4}>
                  <Button flex={1} onClick={onClose} variantColor="red">
                    Cancel
                  </Button>
                  <FormButton
                    flex={1}
                    variantColor="cyan"
                    onClick={(event) => {
                      event.stopPropagation()
                      form.handleSubmit(onFormSubmit)(event)
                    }}
                  >
                    SUBMIT
                  </FormButton>
                </Stack>
              </Stack>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default CreateFinancialInstitution
