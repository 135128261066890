import {
  Box,
  Button,
  Checkbox,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/core'
import { handleAPIError } from '@eloan/shared'
import { toDate } from '@eloan/shared/utils'
import { isNull } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import ReactSelect from 'react-select'
import { Box as ReflexBox } from 'reflexbox'
import { emptyObject } from 'utils/defaults'
import {
  NobodarAssessmentQuestionnaireProvider,
  useNobodarAssessmentQuestionnaire,
  evaluateHidden,
} from './Provider'

const parseDateMeta = (meta) => {
  if (!meta) {
    return {}
  }

  const minDate = meta.min ? DateTime.fromISO(meta.min).toJSDate() : null
  const maxDate = meta.max ? DateTime.fromISO(meta.max).toJSDate() : null
  const pick = meta.pick ?? {}

  const showYearPicker =
    pick.y && ['m', 'd', 'hr', 'min', 'sec'].every((key) => !pick[key])
  const showMonthYearPicker =
    pick.m && ['d', 'hr', 'min', 'sec'].every((key) => !pick[key])

  const hideYear = showMonthYearPicker && !pick.y

  const sx = {}

  if (hideYear) {
    sx['& .react-datepicker-year-header'] = { display: 'none' }
    sx['& .react-datepicker__navigation'] = { display: 'none' }
  }

  return {
    sx,
    dateFormat: meta.format,
    minDate,
    maxDate,
    showYearPicker,
    showMonthYearPicker,
  }
}

function QuestionInput({
  questionId,
  meta = emptyObject,
  answer,
  recordAnswer,
  isNumber,
  isMulti,
}) {
  const options = useMemo(() => {
    if (!meta.options) {
      return null
    }

    return meta.options.values.reduce((options, value, index) => {
      return options.concat({ value, label: meta.options.labels[index] })
    }, [])
  }, [meta.options])

  const value = useMemo(() => {
    if (meta.options) {
      if (
        isNull(answer) ||
        (isMulti && !Array.isArray(answer.data.value)) ||
        typeof answer.data !== 'object'
      ) {
        return isMulti ? [] : null
      }

      return isMulti
        ? answer.data.value.map((value) => ({
            value,
            label: meta.options.labels[meta.options.values.indexOf(value)],
          }))
        : {
            value: answer.data.value,
            label:
              meta.options.labels[
                meta.options.values.indexOf(answer.data.value)
              ],
          }
    }
    return isNull(answer)
      ? null
      : isNumber
      ? Number(answer.data.value)
      : answer.data.value
  }, [answer, isMulti, isNumber, meta.options])

  const onChange = useCallback(
    async (res) => {
      if (!meta.options) {
        await recordAnswer({
          questionId,
          data: {
            value: isNumber ? Number(res.target.value) : res.target.value,
          },
          meta: {},
        })
        return
      } else {
        if (isMulti) {
          if (res)
            await recordAnswer({
              questionId,
              data: { value: res.map(({ value }) => value) },
              meta: {},
            })
        } else {
          if (res) {
            await recordAnswer({
              questionId,
              data: { value: res.value },
              meta: {},
            })
          }
        }
      }
    },
    [isMulti, isNumber, meta.options, questionId, recordAnswer]
  )

  return options ? (
    <ReactSelect
      defaultValue={value}
      options={options}
      isMulti={isMulti}
      onChange={onChange}
    />
  ) : isNumber ? (
    <NumberInput defaultValue={value} onBlur={onChange} step={meta.step}>
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  ) : (
    <Input defaultValue={value} onBlur={onChange} />
  )
}

function QuestionCheckbox({ questionId, answer, recordAnswer }) {
  const isChecked = useMemo(() => {
    return typeof answer?.data?.value === 'boolean' ? answer.data.value : false
  }, [answer])

  return (
    <Checkbox
      size="lg"
      isChecked={isChecked}
      onChange={async () => {
        await recordAnswer({
          questionId,
          data: { value: !isChecked },
          meta: {},
        })
      }}
    />
  )
}

function QuestionDateTime({ questionId, meta, answer, recordAnswer }) {
  const value = useMemo(() => {
    const datetime =
      typeof answer?.data?.value === 'string'
        ? DateTime.fromISO(answer.data.value)
        : null
    return datetime && datetime.isValid ? datetime.toJSDate() : null
  }, [answer])

  const onChange = useCallback(
    async (value = null) => {
      if (value) {
        await recordAnswer({ questionId, data: { value }, meta: {} })
      }
    },
    [questionId, recordAnswer]
  )

  const {
    sx,
    dateFormat,
    minDate,
    maxDate,
    showYearPicker,
    showMonthYearPicker,
  } = useMemo(() => parseDateMeta(meta), [meta])

  return (
    <ReflexBox sx={sx}>
      <ReactDatePicker
        dateFormat={dateFormat}
        minDate={minDate}
        maxDate={maxDate}
        showYearPicker={showYearPicker}
        showMonthYearPicker={showMonthYearPicker}
        selected={toDate(value)}
        onChange={onChange}
        customInput={<Input />}
      />
    </ReflexBox>
  )
}

function QuestionRange({ questionId, range, meta, answer, recordAnswer }) {
  const initialState = useMemo(() => {
    if (typeof answer?.data?.value !== 'object') {
      return { start: null, end: null }
    }
    const startDatetime = DateTime.fromISO(answer.data.value.start)
    const endDatetime = DateTime.fromISO(answer.data.value.end)
    return {
      start: startDatetime.isValid ? startDatetime.toJSDate() : null,
      end: endDatetime.isValid ? endDatetime.toJSDate() : null,
    }
  }, [answer])

  const [start, setStart] = useState(initialState.start)
  const [end, setEnd] = useState(initialState.end)

  const onStartChange = useCallback((value = null) => {
    setStart(value)
  }, [])
  const onEndChange = useCallback((value = null) => {
    setEnd(value)
  }, [])

  useEffect(() => {
    recordAnswer({ questionId, data: { value: { start, end } }, meta: {} })
  }, [end, questionId, recordAnswer, start])

  const {
    sx,
    dateFormat,
    minDate,
    maxDate,
    showYearPicker,
    showMonthYearPicker,
  } = useMemo(() => parseDateMeta(meta), [meta])

  return (
    <>
      {range.type === 'datetime' ? (
        <ReflexBox sx={sx}>
          <Stack isInline>
            <ReactDatePicker
              dateFormat={dateFormat}
              minDate={minDate}
              maxDate={maxDate}
              showYearPicker={showYearPicker}
              showMonthYearPicker={showMonthYearPicker}
              selectsStart
              startDate={start}
              endDate={end}
              selected={toDate(start)}
              onChange={onStartChange}
              customInput={<Input data-type="start" />}
            />
            <ReactDatePicker
              dateFormat={dateFormat}
              maxDate={maxDate}
              showYearPicker={showYearPicker}
              showMonthYearPicker={showMonthYearPicker}
              selectsEnd
              startDate={start}
              endDate={end}
              minDate={start}
              selected={toDate(end)}
              onChange={onEndChange}
              customInput={<Input />}
            />
          </Stack>
        </ReflexBox>
      ) : (
        <Text color="red.500">NOT IMPLEMENTED</Text>
      )}
    </>
  )
}

function QuestionVirtual({ questionId, meta, answer, refreshVirtualAnswer }) {
  return (
    <Stack
      isInline
      spacing={4}
      justifyContent="space-between"
      alignItems="center"
    >
      <Text
        fontSize={2}
        color={
          answer
            ? answer.meta.isResolved
              ? 'blue.500'
              : 'red.500'
            : 'yellow.500'
        }
      >
        {meta.type === 'math' ? (
          <Tooltip zIndex="tooltip" label={answer?.meta?.expression ?? '...'}>
            <Text as="span">{answer?.data?.value ?? 'Not Evaluated Yet'}</Text>
          </Tooltip>
        ) : (
          <Text as="span">{answer?.data?.value ?? '...'}</Text>
        )}
      </Text>
      <Button
        size="sm"
        variantColor="blue"
        onClick={() => refreshVirtualAnswer({ questionId })}
      >
        Refresh
      </Button>
    </Stack>
  )
}

function Question({ questionId, ...props }) {
  const {
    state,
    recordAnswer: _recordAnswer,
    refreshVirtualAnswer: _refreshVirtualAnswer,
  } = useNobodarAssessmentQuestionnaire()

  const toast = useToast()

  const recordAnswer = useCallback(
    async (...params) => {
      try {
        await _recordAnswer(...params)
      } catch (err) {
        handleAPIError(err, { toast })
      }
    },
    [_recordAnswer, toast]
  )

  const refreshVirtualAnswer = useCallback(
    async (...params) => {
      try {
        await _refreshVirtualAnswer(...params)
      } catch (err) {
        handleAPIError(err, { toast })
      }
    },
    [_refreshVirtualAnswer, toast]
  )

  const { id, type, text, meta, inactive, answer } = state.questions.byId[
    questionId
  ]

  const isHidden = useMemo(() => {
    return evaluateHidden(state, questionId)
  }, [questionId, state])

  if (inactive || isHidden) {
    return null
  }

  return (
    <Box id={id} {...props}>
      <Text fontSize={2}>{text}</Text>
      {type === 'bool' && (
        <QuestionCheckbox
          questionId={questionId}
          meta={meta[type]}
          metaState={meta.state}
          answer={answer}
          recordAnswer={recordAnswer}
        />
      )}
      {type === 'datetime' && (
        <QuestionDateTime
          questionId={questionId}
          meta={meta[type]}
          metaState={meta.state}
          answer={answer}
          recordAnswer={recordAnswer}
        />
      )}
      {type === 'text' && (
        <QuestionInput
          questionId={questionId}
          meta={meta[type]}
          metaState={meta.state}
          answer={answer}
          recordAnswer={recordAnswer}
        />
      )}
      {type === 'text[]' && (
        <QuestionInput
          questionId={questionId}
          meta={meta[type]}
          metaState={meta.state}
          answer={answer}
          recordAnswer={recordAnswer}
          isMulti
        />
      )}
      {type === 'number' && (
        <QuestionInput
          questionId={questionId}
          meta={meta[type]}
          metaState={meta.state}
          answer={answer}
          recordAnswer={recordAnswer}
          isNumber
        />
      )}
      {type === 'number[]' && (
        <>
          <Text color="red.500">NOT FUNCTIONAL</Text>
          <QuestionInput
            questionId={questionId}
            meta={meta[type]}
            metaState={meta.state}
            answer={answer}
            recordAnswer={recordAnswer}
            isNumber
            isMulti
          />
        </>
      )}
      {type === 'range' && (
        <QuestionRange
          questionId={questionId}
          range={meta[type]}
          meta={meta[meta[type].type]}
          metaState={meta.state}
          answer={answer}
          recordAnswer={recordAnswer}
        />
      )}
      {type === 'virtual' && (
        <QuestionVirtual
          questionId={questionId}
          meta={meta[type]}
          metaState={meta.state}
          answer={answer}
          refreshVirtualAnswer={refreshVirtualAnswer}
        />
      )}
    </Box>
  )
}

function NobodarAssessment() {
  const { state } = useNobodarAssessmentQuestionnaire()

  return (
    <Box maxWidth="720px">
      {state.loading ? (
        <Spinner size="xl" />
      ) : (
        <Stack spacing={4}>
          {state.questions.allIds.map((questionId) => (
            <Question key={questionId} questionId={questionId} />
          ))}
        </Stack>
      )}
    </Box>
  )
}
function NobodarAssessmentWrapper({ applicationId }) {
  return (
    <NobodarAssessmentQuestionnaireProvider applicationId={applicationId}>
      <NobodarAssessment />
    </NobodarAssessmentQuestionnaireProvider>
  )
}

export default NobodarAssessmentWrapper
