import { Box, Button, Flex, Heading, SimpleGrid, Stack } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { get } from 'lodash-es'
import React from 'react'
import { Link } from 'react-router-dom'
import { useRetailers } from 'store/retailers/hooks'

function RetailerList() {
  const retailers = useRetailers()

  return (
    <Authorize permissions="eloan:AnyRetailer:read">
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Retailers</Heading>
        </Box>

        <Box>
          <Authorize permissions="eloan:Retailer:create">
            <Button as={Link} to={'retailers/create'}>
              Create
            </Button>
          </Authorize>
        </Box>
      </Stack>

      <Stack spacing={2}>
        {retailers.allIds.map((id) => (
          <Box key={id} borderWidth={1} borderRadius={2} shadow="md" p={4}>
            <Heading size="md" as="h4" mb={2}>
              Retailer: {get(retailers.byId[id], 'externalId')}
            </Heading>

            <Flex flexDirection="row" justifyContent="space-between">
              <SimpleGrid flexGrow={1} columns={2}>
                <Box>Shop Name: {get(retailers.byId[id], 'shopName')}</Box>
                <Box>Owner Name: {get(retailers.byId[id], 'ownerName')}</Box>
                <Box>Route Code: {get(retailers.byId[id], 'route.code')}</Box>
                <Box>Route Name: {get(retailers.byId[id], 'route.name')}</Box>
                <Box>
                  Phone Number: {get(retailers.byId[id], 'phoneNumber')}
                </Box>
              </SimpleGrid>
              <Stack isInline flexShrink={1}>
                <Button as={Link} to={`/dash/retailers/${id}`}>
                  Open
                </Button>
                <Authorize permissions="eloan:Retailer:update">
                  <Button as={Link} to={`/dash/retailers/${id}/edit`}>
                    Edit
                  </Button>
                </Authorize>
              </Stack>
            </Flex>
          </Box>
        ))}
      </Stack>
    </Authorize>
  )
}

export default RetailerList
