import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RedxShopOfferCreate } from './Create'
import { RedxShopOfferList } from './List'

function RedxShopOfferPage() {
  return (
    <Switch>
      <Route
        exact
        path="/dash/admin/redx-shop-offers"
        component={RedxShopOfferList}
      />
      <Route
        path="/dash/admin/redx-shop-offers/create"
        component={RedxShopOfferCreate}
      />
    </Switch>
  )
}

export default RedxShopOfferPage
