import { defaultsDeep, get, keyBy, map, union } from 'lodash-es'
import { combineReducers } from 'redux'
import {
  NOBODAR_APPLICATION_ADD,
  NOBODAR_APPLICATION_ADD_BULK,
  NOBODAR_APPLICATION_UPDATE,
  NOBODAR_APPLICATION_UPDATE_BULK,
  NOBODAR_PA_CALL_LOG_ADD,
  NOBODAR_PA_CALL_LOG_ADD_BULK,
} from 'store/nobodar'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialApplicationsState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: false,
}

const applicationsReducer = (
  state = initialApplicationsState,
  { type, data, applicationId }
) => {
  switch (type) {
    case NOBODAR_APPLICATION_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        loading: false,
      }
    case NOBODAR_APPLICATION_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [applicationId]: { ...data },
        },
        allIds: union(state.allIds, [applicationId]),
      }
    case NOBODAR_APPLICATION_UPDATE_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...data.items.reduce((byId, applicationData) => {
            byId[applicationData.id] = defaultsDeep(
              applicationData,
              state.byId[applicationData.id]
            )
            return byId
          }, {}),
        },
      }
    case NOBODAR_APPLICATION_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [applicationId]: defaultsDeep(data, state.byId[applicationId]),
        },
        allIds: union(state.allIds, [applicationId]),
      }
    default:
      return state
  }
}

const initialPACallLogsState = {
  byId: emptyObject,
  allIds: emptyArray,
  idsByApplication: emptyObject,
}

const paCallLogsReducer = (
  state = initialPACallLogsState,
  { type, data, applicationId }
) => {
  switch (type) {
    case NOBODAR_PA_CALL_LOG_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data[idKey]]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [data[idKey]]),
        idsByApplication: {
          ...state.idsByApplication,
          [applicationId]: union(
            get(state.idsByApplication, applicationId, emptyArray),
            [data[idKey]]
          ),
        },
      }

    case NOBODAR_PA_CALL_LOG_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        idsByApplication: {
          ...state.idsByApplication,
          [applicationId]: union(
            get(state.idsByApplication, applicationId, emptyArray),
            map(data.items, idKey)
          ),
        },
      }

    default:
      return state
  }
}

const nobodarReducer = combineReducers({
  applications: applicationsReducer,
  paCallLogs: paCallLogsReducer,
})

export default nobodarReducer
