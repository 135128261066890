import { Box, Button, Flex, Heading, Text } from '@chakra-ui/core'
import { displayBDT } from '@eloan/shared'
import { DataTable } from '@eloan/shared/components/Table'
import Authorize from 'components/Authorize'
import NavLink from 'components/Link/NavLink'
import Loader from 'components/Loader/Loader'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import usePagination from 'hooks/usePagination'
import { get } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTable } from 'react-table'
import { getCollections } from 'store/collections'
import { useAllCollectionSummary } from 'store/collections/hooks'
import { emptyArray } from 'utils/defaults'
import CollectionsListFilter from './Filter/Filter'
import CollectionSummary from './Modals/CollectionSummary'
import Summary from './Summary/Summary'
import { getCollection } from './utils/getCollection'

const getColumns = () => [
  {
    Header: 'eLoan ID',
    accessor: ({ id }) => (
      <Button
        as={NavLink}
        variant="link"
        to={`/dash/unilever-applications/${id}`}
      >
        {id}
      </Button>
    ),
  },
  { Header: 'Name', accessor: 'retailer.ownerName' },
  { Header: 'Phone', accessor: 'retailer.phoneNumber' },
  { Header: 'Route', accessor: 'retailer.route.name' },
  { Header: 'Route Code', accessor: 'retailer.route.code' },
  {
    Header: 'Collection',
    accessor: ({ rlAccount, collection }) => (
      <CollectionSummary repayments={get(rlAccount, 'repayments', [])}>
        {displayBDT(collection.totalCollectedAmount)}
      </CollectionSummary>
    ),
  },
  {
    Header: 'Principal',
    accessor: ({ collection }) =>
      displayBDT(collection.totalPrincipalCollected),
  },
  {
    Header: 'Interest',
    accessor: ({ collection }) => displayBDT(collection.totalInterestCollected),
  },
  {
    Header: 'OD Interest',
    accessor: ({ collection }) => displayBDT(collection.totalOdInterest),
  },
  {
    Header: 'ShopUp Fee',
    accessor: ({ collection }) => displayBDT(collection.totalShopupFee),
  },
]

const loanProductId = 2

const getQueryObject = (filter) => {
  return {
    loanProductId: loanProductId,
    include: 'odAccount',
    filter,
  }
}

function UnileverCollectionPage() {
  const [filter, setFilter] = useState()
  const queryObject = useMemo(() => getQueryObject(filter), [filter])

  const summary = useAllCollectionSummary({ loanProductId })

  const dispatch = useDispatch()
  const fetchPage = useCallback(
    (...args) => {
      dispatch(getCollections(...args))
    },
    [dispatch]
  )

  const pagination = useSelector((state) => state.pagination.collections)
  const [page] = usePagination(pagination, fetchPage, queryObject)

  const applications = useSelector((state) => state.collections)
  const data = useMemo(() => {
    return get(pagination.pages[page], 'itemIds', emptyArray).map((id) => {
      const {
        totalCollectedAmount,
        totalInterestCollected,
        totalPrincipalCollected,
        totalOdInterest,
        totalShopupFee,
      } = getCollection(applications.byId[id])

      return {
        ...applications.byId[id],
        collection: {
          totalCollectedAmount,
          totalInterestCollected,
          totalPrincipalCollected,
          totalOdInterest,
          totalShopupFee,
        },
      }
    })
  }, [pagination.pages, page, applications.byId])

  const columns = useMemo(() => getColumns(), [])

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <Authorize permissions="">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Unilever Collection Summary</Heading>
          <Text>Collection for unilever</Text>
        </Box>
      </Flex>

      <Summary summary={summary} />

      <CollectionsListFilter onChange={setFilter} />

      {pagination.loading ? <Loader /> : <DataTable {...table} />}

      <PageBasedSwitcher
        pageIndex={page}
        totalPages={pagination.totalPages}
        totalItems={pagination.totalItems}
      />
    </Authorize>
  )
}

export default UnileverCollectionPage
