import { keyBy, map, union } from 'lodash-es'
import {
  FI_SET_ALL,
  FI_ADD,
  LOAN_CONFIG_SET_ALL,
  LOAN_CONFIG_REMOVE_ALL,
  LOAN_CONFIG_ADD,
  LOAN_PRODUCT_SET_ALL,
  LOAN_PRODUCT_ADD,
} from 'store/admin'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  financialInstitutions: { byId: emptyObject, allIds: emptyArray },
  loanConfigs: { byId: emptyObject, allIds: emptyArray },
  loanProducts: { byId: emptyObject, allIds: emptyArray },
}

const adminReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FI_SET_ALL:
      return {
        ...state,
        financialInstitutions: {
          ...state.financialInstitutions,
          byId: keyBy(data.items, idKey),
          allIds: map(data.items, (item) => item.id),
        },
      }
    case FI_ADD:
      return {
        ...state,
        financialInstitutions: {
          ...state.financialInstitutions,
          byId: {
            ...state.financialInstitutions.byId,
            [data.id]: data,
          },
          allIds: union([...state.financialInstitutions.allIds, data.id]),
        },
      }
    case LOAN_CONFIG_REMOVE_ALL:
      return {
        ...state,
        loanConfigs: initialState.loanConfigs,
      }
    case LOAN_CONFIG_SET_ALL:
      return {
        ...state,
        loanConfigs: {
          ...state.loanConfigs,
          byId: keyBy(data.items, idKey),
          allIds: map(data.items, (item) => item.id),
        },
      }
    case LOAN_CONFIG_ADD:
      return {
        ...state,
        loanConfigs: {
          ...state.loanConfigs,
          byId: {
            ...state.loanConfigs.byId,
            [data.id]: data,
          },
          allIds: union([...state.loanConfigs.allIds, data.id]),
        },
      }
    case LOAN_PRODUCT_SET_ALL:
      return {
        ...state,
        loanProducts: {
          ...state.loanProducts,
          byId: keyBy(data.items, idKey),
          allIds: map(data.items, (item) => item.id),
        },
      }
    case LOAN_PRODUCT_ADD:
      return {
        ...state,
        loanProducts: {
          ...state.loanProducts,
          byId: {
            ...state.loanProducts.byId,
            [data.id]: data,
          },
          allIds: union([...state.loanProducts.allIds, data.id]),
        },
      }
    default:
      return state
  }
}

export default adminReducer
