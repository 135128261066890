import { batch } from 'react-redux'

export const COLLECTION_LIST_LOADED = 'COLLECTION_LIST_LOADED'

export const COLLECTION_PAGE_ADD = 'COLLECTION_PAGE_ADD'
export const COLLECTION_PAGE_REMOVE = 'COLLECTION_PAGE_REMOVE'
export const COLLECTION_PAGE_REQUEST = 'COLLECTION_PAGE_REQUEST'
export const COLLECTION_PAGINATION_PURGE = 'COLLECTION_PAGINATION_PURGE'

export const getCollections = (
  { loanProductId, limit = 20, page = 1, include, filter },
  queryHash
) => async (dispatch, _getState, { api }) => {
  dispatch({ type: COLLECTION_PAGE_REQUEST, page, queryHash })

  const { data } = await api(
    `/legacy/v4/eloan/loan-products/{loanProductId}/applications{?limit,page,include,filter*}`,
    {
      loanProductId,
      limit,
      page,
      include,
      filter,
    }
  )

  batch(() => {
    dispatch({ type: COLLECTION_LIST_LOADED, data })
    dispatch({ type: COLLECTION_PAGE_ADD, page, data, queryHash })
  })

  return data
}
