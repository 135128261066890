import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { updateDistributor } from 'store/distributors'
import { useDistributor } from 'store/distributors/hooks'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    name: Yup.string().required(`required`),
    areaName: Yup.string().required(`required`),
  })
}

const getDefaultValues = (distributor) => {
  return {
    name: get(distributor, 'name', ''),
    areaName: get(distributor, 'areaName', ''),
  }
}

function EditDistributor() {
  const toast = useToast()

  const { distributorId } = useParams()

  const distributor = useDistributor(distributorId)

  const defaultValues = useMemo(() => getDefaultValues(distributor), [
    distributor,
  ])
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const history = useHistory()
  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ name, areaName }) => {
      try {
        await dispatch(updateDistributor(distributorId, { name, areaName }))
        history.push('/dash/distributors')
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, distributorId, form, history, toast]
  )

  return (
    <Authorize permissions="eloan:Distributor:update">
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Edit Distributor: {distributorId}</Heading>
        </Box>

        <Box>
          <Button as={Link} to={'/dash/distributors'}>
            Go Back
          </Button>
        </Box>
      </Stack>

      <Form form={form} onSubmit={onSubmit}>
        <Stack spacing={4}>
          <FormInput name="name" label={`Distributor Name`} />

          <FormInput name="areaName" label={`Area Name`} />

          <ButtonGroup>
            <FormButton type="button" onClick={() => formReset(defaultValues)}>
              Reset
            </FormButton>
            <FormButton type="submit" variantColor="green">
              Save
            </FormButton>
          </ButtonGroup>
        </Stack>
      </Form>
    </Authorize>
  )
}

export default EditDistributor
