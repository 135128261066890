import { Heading, Stack } from '@chakra-ui/core'
import React, { memo } from 'react'
import { Box } from 'reflexbox'
import TradeLicensePhoto from '../modals/TradeLicensePhoto'

function OtherInfo({ application, ...props }) {
  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Other Info
      </Heading>

      <Stack isInline flexWrap="wrap" spacing={8}>
        <TradeLicensePhoto application={application} />
      </Stack>
    </Box>
  )
}

export default memo(OtherInfo)
