import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useBankBranches, useBanks } from 'store/banks/hooks'
import { addRetailerBankInfo } from 'store/retailers'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    bankId: Yup.number().integer().required(),
    branchId: Yup.number().integer().required(),
    accountName: Yup.string().required(),
    accountNumber: Yup.string().required(),
  })
}

const getDefaultValues = () => ({
  bankId: '',
  branchId: '',
  accountName: '',
  accountNumber: '',
})

function AddRetailerBankInfoModal({ retailerId, ...props }) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ bankId, branchId, accountName, accountNumber }) => {
      try {
        await dispatch(
          addRetailerBankInfo(retailerId, {
            bankId,
            branchId,
            accountName,
            accountNumber,
          })
        )
        form.reset()
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, onClose, retailerId, toast]
  )

  const banks = useBanks()

  const bankOptions = useMemo(() => {
    return zipObject(
      banks.allIds,
      banks.allIds.map((id) => get(banks.byId[id], 'bankName'))
    )
  }, [banks])

  const bankId = form.watch('bankId')
  const bankBranches = useBankBranches(bankId)

  const bankBranchOptions = useMemo(() => {
    return zipObject(
      bankBranches.allIds,
      bankBranches.allIds.map((id) => get(bankBranches.byId[id], 'branchName'))
    )
  }, [bankBranches])

  return (
    <Authorize permissions="eloan:Retailer:update">
      <Box {...props}>
        <Button variantColor="yellow" onClick={onOpen}>
          Add New
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader>Add new Bank Info for Retailer</ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <Stack spacing={2}>
                  <FormSelect
                    name="bankId"
                    label={`Bank`}
                    options={bankOptions}
                  />

                  <FormSelect
                    name="branchId"
                    label={`Branch`}
                    options={bankBranchOptions}
                  />

                  <FormInput name="accountName" label={`Account Name`} />

                  <FormInput name="accountNumber" label={`Account Number`} />
                </Stack>
              </ModalBody>

              <ModalFooter>
                <Button type="button" mr={3} onClick={onClose}>
                  Close
                </Button>

                <FormButton type="submit" variantColor="yellow">
                  Add
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default AddRetailerBankInfoModal
