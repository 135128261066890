export const sidebarItems = [
  {
    title: 'Admin',
    permissions:
      'eloan:AnyApplication:read,eloan:AnyDistributor:read,eloan:AnyRetailer:read',
    items: [
      {
        title: 'Field Agents',
        link: '/dash/users/field-agents',
        permissions: 'eloan:AnyApplication:read',
      },
      {
        title: 'Distributors',
        link: '/dash/distributors',
        permissions: 'eloan:AnyDistributor:read',
      },
      {
        title: 'Retailers',
        link: '/dash/retailers',
        permissions: 'eloan:AnyRetailer:read',
      },
      {
        title: 'PA Leave Management',
        link: '/dash/admin/pa-leave-management',
        permissions: 'eloan:AnyApplicationPAAssignment:set',
      },
    ],
  },
  {
    title: 'Product Configuration & Modifier',
    permissions: 'eloan:LoanProduct:read',
    items: [
      {
        title: 'eLoan Loan Product',
        link: '/dash/admin/loan-product',
        permissions: 'eloan:LoanProduct:read',
      },
      {
        title: 'Loan Modifier',
        link: '/dash/admin/loan-modifier',
      },
    ],
  },
  {
    title: 'Finance',
    permissions:
      'eloan:PartnerSettlement:read, eloan:AnyApplication:close, eloan:AnyApplication:read, eloan:MaterializedTermLoans:write, eloan:PartnerBeftn:read',
    items: [
      {
        title: 'Partner Settlement',
        link: '/dash/admin/partner-settlement/generate',
        permissions:
          'eloan:PartnerSettlement:read, eloan:MaterializedTermLoans:write',
      },
      {
        title: 'Partner BEFTN',
        link: '/dash/admin/partner-beftn',
        permissions: 'eloan:PartnerBeftn:read',
      },
      {
        title: 'Awaiting Disbursement',
        link: '/dash/finance/awaiting-disbursement',
        permissions: 'eloan:AnyApplication:read',
      },
      {
        title: 'Close Loan',
        link: '/dash/finance/close-loan',
        permissions: 'eloan:AnyApplication:close',
      },
      {
        title: 'Collection',
        link: '/dash/finance/collection',
        permissions: 'eloan:RedXTransaction:read',
      },
      {
        title: 'Unilever Settlement',
        link: '/dash/settlements/unilever',
        permissions: '',
      },
      {
        title: 'RedX Shop Offers',
        link: '/dash/admin/redx-shop-offers',
        permissions: 'eloan:RedxShopOffer:manage',
      },
      {
        title: 'App bKash Payments',
        link: '/dash/admin/app-bkash-payments',
        permissions: 'eloan:AnyApplication:read',
      },
      {
        title: 'RedX Finance',
        link: '/dash/finance/redx-summary',
        permissions: 'eloan:RedXTransaction:read',
      },
    ],
  },
  {
    title: 'Applications',
    permissions: 'eloan:AnyApplication:read, eloan:Application:read',
    items: [
      {
        title: 'General',
        link: '/dash/applications',
        permissions: 'eloan:AnyApplication:read, eloan:Application:read',
      },
      {
        title: 'Shwanirbhor Applications',
        link: '/dash/shwanirbhor-applications',
        permissions: 'eloan:AnyApplication:read, eloan:Application:read',
      },
      {
        title: 'Unassigned Applications',
        link: '/dash/unassigned-applications',
        permissions:
          'eloan:AnyApplication:read, eloan:AnyApplicationPAAssignment:set',
      },
      {
        title: 'Nobodar Applications',
        link: '/dash/nobodar-applications',
        permissions: '',
      },
      {
        title: 'Offers',
        link: '/dash/offers',
        permissions: 'eloan:Offer:read',
      },
      {
        title: 'Unilever Applications',
        link: '/dash/unilever-applications',
      },
    ],
  },
  {
    title: 'Repayment',
    link: '/dash/repayment-followup',
    permissions: 'eloan:AnyApplication:read',
    items: [
      {
        title: 'Call Team',
        link: '/dash/repayment-followup/call-team',
        permissions: 'eloan:AnyApplication:read, eloan:Application:read',
      },
      {
        title: 'Field Team',
        link: '/dash/repayment-followup/field-team',
        permissions: '',
      },
      {
        title: 'CCA',
        link: '/dash/repayment-followup/cca',
        permissions: 'eloan:CCA:any',
      },
    ],
  },
  {
    title: 'Loan Summary',
    permissions: 'eloan:AnyApplication:read',
    items: [
      {
        title: 'RL Summary',
        link: '/dash/unilever-rl-summary',
        permissions: '',
      },
      {
        title: 'Unilever Overdue Application',
        link: '/dash/overdue-applications/unilever',
        permissions: '',
      },
      {
        title: 'Unilever Collection Summary',
        link: '/dash/collection-summary/unilever',
        permissions: '',
      },
    ],
  },
]
