import { api } from 'api'
// import { throttle } from 'lodash-es'
import { applyMiddleware, compose, createStore } from 'redux'
import ReduxThunkMiddleware from 'redux-thunk'
import rootReducer from 'store/reducer'
// import { loadState, saveState } from 'utils/localStorage'

// const persistedState = loadState('state')

const composeEnhancers =
  process.env.NODE_ENV === 'development'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose

const middlewares = [ReduxThunkMiddleware.withExtraArgument({ api })]

export const store = createStore(
  rootReducer,
  // persistedState,
  composeEnhancers(applyMiddleware(...middlewares))
)

export const dispatchToStore = store.dispatch

// store.subscribe(
//   throttle(() => {
//     const { errorBoundary, user, pagination, ...state } = store.getState()

//     saveState('state', state)
//   }, 1500)
// )
