import { Button, InputRightElement } from '@chakra-ui/core'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

function ApplicationIdOpener({ basePath = '/dash/applications', ...props }) {
  const form = useForm()

  const onSubmit = useCallback(
    ({ applicationId }) => {
      if (Number.isInteger(+applicationId)) {
        window.open(`${basePath}/${applicationId}`)
      }
    },
    [basePath]
  )

  return (
    <Form {...props} form={form} onSubmit={onSubmit}>
      <FormInput
        name="applicationId"
        label={`Open Application`}
        pr="3.5rem"
        width="14rem"
        placeholder="Application ID"
        InputRight={
          <InputRightElement width="3.5rem">
            <Button size="sm" type="submit">
              Go
            </Button>
          </InputRightElement>
        }
      />
    </Form>
  )
}

export default ApplicationIdOpener
