import {
  Box,
  Flex,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { get, isEmpty } from 'lodash-es'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useApplication } from 'store/applications/hooks'
import DisburseLoan from './DisburseLoan'
import FinanceLoanInfo from './LoanInfo'
import RevertLoan from './RevertLoan'

const fields = [
  '*',
  'offer.*',
  'meta.*',
  'metaExtra.*',
  'shop.shopName',
  'bankDetails.*',
].join(',')

function FinanceAwaitingDisbursementView() {
  const { applicationId } = useParams()

  const application = useApplication(applicationId, fields)

  return (
    <Authorize permissions="eloan:AnyApplication:read">
      <Flex mb={10} justify="space-between">
        <Box>
          <Heading fontSize={5}>
            {get(application, 'meta.loaneeName')}
            {' | '}eloan ID: {applicationId}
            {' | '}
            {get(application, 'meta.loaneePhoneNumber')}
          </Heading>
        </Box>
        {!isEmpty(application) &&
          application?.offer?.status === 'shopup-approved' &&
          application?.type === 'redx-shopup' && (
            <Stack isInline spacing={4}>
              <DisburseLoan applicationId={application?.id} />
              <RevertLoan applicationId={application?.id} />
            </Stack>
          )}
      </Flex>
      <Tabs variantColor="cyan">
        <TabList mb={4}>
          <Tab px={0} pt={0} mr={4}>
            Loan Info
          </Tab>
        </TabList>
        <TabPanels py={4}>
          <TabPanel>
            <FinanceLoanInfo application={application} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Authorize>
  )
}

export default FinanceAwaitingDisbursementView
