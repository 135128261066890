import { Heading } from '@chakra-ui/core'
import { DataTable } from '@eloan/shared/components/Table'
import StatusTransition from 'components/Application/StatusTransition'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { Box } from 'reflexbox'
import { useApplicationNotes } from 'store/notes/hooks'
import { applicationStatusDisplayText } from 'utils/application-status'
import { offerStatusDisplayText } from 'utils/offer-status'

const columns = [
  {
    Header: 'Time',
    id: 'date',
    accessor: 'createdAt',
    Cell: ({ cell: { value } }) =>
      DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
  },
  { Header: 'By', id: 'by', accessor: 'user.fullName' },
  { Header: 'Note', accessor: 'note' },
  {
    id: 'meta',
    accessor: (row) => ({
      type: row.type,
      from: row.fromStatus,
      to: row.toStatus,
    }),
    Cell: ({ cell: { value } }) => {
      const displayText =
        value.type === 'status_change'
          ? applicationStatusDisplayText
          : value.type === 'offer_status_change'
          ? offerStatusDisplayText
          : null

      const prefix =
        value.type === 'status_change'
          ? ''
          : value.type === 'offer_status_change'
          ? 'offer:'
          : ''

      return displayText ? (
        <StatusTransition
          from={value.from}
          to={value.to}
          displayText={displayText}
          prefix={prefix}
        />
      ) : null
    },
  },
]

function Notes({ applicationId, ...props }) {
  const notes = useApplicationNotes(applicationId)

  const notesData = useMemo(() => {
    return notes.allIds.map((id) => notes.byId[id])
  }, [notes.allIds, notes.byId])

  const table = useTable({
    data: notesData,
    columns: columns,
  })

  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Notes
      </Heading>

      <DataTable {...table} />
    </Box>
  )
}

export default Notes
