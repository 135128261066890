import { Button, Tag, Badge } from '@chakra-ui/core'
import ApplicationListHeader from 'components/ApplicationList/Header'
import ApplicationListTable from 'components/ApplicationList/Table'
import Authorize from 'components/Authorize'
import NavLink from 'components/Link/NavLink'
import PageBasedSwitcher from 'components/Pagination/PageBasedSwitcher'
import useApplicationListQuery from 'hooks/useApplicationListQuery'
import usePagination from 'hooks/usePagination'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicationPage } from 'store/applications'
import { useAreas } from 'store/areas/hooks'
import { usePermit } from 'store/currentUser/hooks'
import { usePAUsers } from 'store/users/hooks'
import { emptyArray } from 'utils/defaults'
import ApplicationListFilters from './Filters'
import ApplicationIdOpenerFetch from 'components/Application/IdOpenerFetch'
import {
  cibPaymentStatusDisplayText,
  cibPaymentStatusVariantColor,
} from 'utils/meta'

const getColumns = (areasById, canSeeAnyApplication, paUsers) =>
  [
    { Header: 'ID', accessor: 'id' },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ cell: { value } }) =>
        value ? <Tag size="sm">{value}</Tag> : null,
    },
    { Header: 'Name', accessor: 'meta.loaneeName' },
    {
      Header: 'Phone',
      accessor: 'phoneNumber',
      Cell: ({ cell: { value } }) => value?.slice(-11) ?? null,
    },
    {
      Header: 'Application Date',
      id: 'createdAt',
      accessor: (row) =>
        DateTime.fromISO(get(row, 'createdAt')).toLocaleString(
          DateTime.DATE_MED
        ),
    },
    {
      Header: 'Applied Amount',
      accessor: 'loanAmount',
    },
    {
      Header: 'Area',
      id: 'meta.shopAreaId',
      accessor: (row) =>
        get(areasById, [get(row, 'meta.shopAreaId'), 'name'], 'N/A'),
    },
    {
      Header: 'CIB Payment',
      accessor: 'metaExtra.cibPaymentStatus',
      Cell: ({ cell: { value } }) => (
        <Badge variantColor={get(cibPaymentStatusVariantColor, value)}>
          {cibPaymentStatusDisplayText[value]}
        </Badge>
      ),
    },
    // { Header: 'Loan Amount', accessor: 'loanAmount' },
    // { Header: 'Tenure', accessor: 'tenure' },
    canSeeAnyApplication && {
      Header: 'PA',
      accessor: (row) =>
        get(paUsers.byId[get(row, 'paAssignment.paId')], 'name', 'N/A'),
    },
    {
      Header: '',
      accessor: 'id',
      id: 'open-button',
      Cell: ({ cell: { value, row } }) => (
        <Button
          as={NavLink}
          to={
            get(row, 'original.isNewEloan') &&
            get(row, 'original.status') === 'accepted'
              ? `/dash/eloan-applications/${value}`
              : `/dash/applications/${value}`
          }
        >
          Open
        </Button>
      ),
    },
  ].filter(Boolean)

const defaultQueryObject = {
  limit: 20,
  fields: `id,type,phoneNumber,loanAmount,tenure,createdAt,isNewEloan,status,meta.loaneeName,meta.loaneeNidName,meta.shopAreaId,metaExtra.cibPaymentStatus,paAssignment.paId`,
}

const filterQueryOperationTypes = {
  createdAt: 'date-range',
  meta: {
    loaneeNidSelfie: '!=',
    loaneeNidPhoto: '!=',
    loaneeNidPhotoBack: '!=',
    guarantorPhoneNumber: '!=',
    guarantorNidSelfie: '!=',
    guarantorNidPhoto: '!=',
    guarantorNidPhotoBack: '!=',
    guarantorAgreedAt: '!=',
    initialHomePhoto: '!=',
    initialShopPhoto: '!=',
    initialMarketplacePhoto: '!=',
  },
}

const filterCacheKey = 'applications:filters'

function AllApplicationList() {
  const paUsers = usePAUsers()

  const canSeeAnyApplication = usePermit('eloan:AnyApplication:read')

  const queryOptions = useMemo(
    () => ({
      default: defaultQueryObject,
      fixedFilter: undefined,
      filterTypes: filterQueryOperationTypes,
      filterCacheKey,
    }),
    []
  )

  const [
    queryObject,
    onFilterObjectChange,
    filterObject,
  ] = useApplicationListQuery(
    queryOptions.default,
    queryOptions.fixedFilter,
    queryOptions.filterTypes,
    queryOptions.filterCacheKey
  )

  const dispatch = useDispatch()
  const fetchPage = useCallback(
    (...args) => {
      dispatch(getApplicationPage(...args))
    },
    [dispatch]
  )

  const pagination = useSelector((state) => state.pagination.applications)
  const [page] = usePagination(pagination, fetchPage, queryObject)

  const areas = useAreas()
  const columns = useMemo(
    () => getColumns(areas.byId, canSeeAnyApplication, paUsers),
    [areas.byId, canSeeAnyApplication, paUsers]
  )

  const applications = useSelector((state) => state.applications)
  const data = useMemo(() => {
    return get(pagination.pages[page], 'itemIds', emptyArray).map(
      (id) => applications.byId[id]
    )
  }, [pagination.pages, page, applications.byId])

  return (
    <Authorize permissions="eloan:AnyApplication:read, eloan:Application:read">
      <ApplicationListHeader
        title={`eLoan Applications`}
        subtitle={`List of eLoan Applications`}
        Right={<ApplicationIdOpenerFetch />}
      />

      <ApplicationListFilters
        defaultValues={filterObject}
        onChange={onFilterObjectChange}
      />

      <ApplicationListTable
        loading={pagination.loading}
        data={data}
        columns={columns}
      />

      <PageBasedSwitcher
        pageIndex={page}
        totalPages={pagination.totalPages}
        totalItems={pagination.totalItems}
      />
    </Authorize>
  )
}

export default AllApplicationList
