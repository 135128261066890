import { Heading, SimpleGrid, useToast } from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import { FormDatePicker } from 'components/Form/DatePicker'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import { get, set, zipObject } from 'lodash-es'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { updateApplicationRCAInfo } from 'store/applications'
import { useApplication } from 'store/applications/hooks'
import DecoratedFormInput from '../components/DecoratedFormInput'
import { useDistricts } from 'store/areas/hooks'

const fields = [
  `type`,
  `meta.loaneeName`,

  `meta.loaneeDob`,
  `metaExtra.loaneeBirthDistrict`,
  `metaExtra.loaneeBirthCountry`,

  `meta.fatherName`,
  `meta.motherName`,
  `meta.spouseName`,

  `meta.loaneePermanentAddress`,
  `metaExtra.loaneePermanentAddressLine1`,
  `metaExtra.loaneePermanentAddressLine2`,
  `metaExtra.loaneePermanentAddressSubDistrict`,
  `metaExtra.loaneePermanentAddressDistrict`,
  `metaExtra.loaneePermanentAddressPostcode`,
  `metaExtra.loaneePermanentAddressCountry`,

  `meta.loaneePresentAddress`,
  `metaExtra.loaneePresentAddressLine1`,
  `metaExtra.loaneePresentAddressLine2`,
  `metaExtra.loaneePresentAddressSubDistrict`,
  `metaExtra.loaneePresentAddressDistrict`,
  `metaExtra.loaneePresentAddressPostcode`,
  `metaExtra.loaneePresentAddressCountry`,

  `meta.businessPresentAddress`,
  `metaExtra.businessPresentAddressLine1`,
  `metaExtra.businessPresentAddressLine2`,
  `metaExtra.businessPresentAddressSubDistrict`,
  `metaExtra.businessPresentAddressDistrict`,
  `metaExtra.businessPresentAddressPostcode`,
  `metaExtra.businessPresentAddressCountry`,

  `meta.taxIdentificationNumber`,
  `meta.loaneeNid`,

  `metaExtra.loaneeOtherIdDocType`,
  `metaExtra.loaneeOtherIdDocId`,
  `metaExtra.loaneeOtherIdDocIssueDate`,
  `metaExtra.loaneeOtherIdDocIssuingCountry`,

  `shop.shopName`,
].join(',')

const fieldDefaultValue = {
  'metaExtra.loaneePresentAddressCountry': 'Bangladesh',
  'metaExtra.loaneePermanentAddressCountry': 'Bangladesh',
  'metaExtra.businessPresentAddressCountry': 'Bangladesh',
}

const getDefaultValues = (application) => {
  return fields.split(',').reduce(
    (values, field) => {
      set(
        values,
        field,
        get(application, field) || get(fieldDefaultValue, field, '')
      )
      return values
    },
    { meta: {}, metaExtra: {}, shop: {} }
  )
}

function CibRCA({ applicationId, ...props }) {
  const districts = useDistricts()

  const districtOptions = useMemo(() => {
    const names = Object.values(districts.byId).map((item) => item.name)
    return zipObject(names, names)
  }, [districts])

  const toast = useToast()

  const application = useApplication(applicationId, fields)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])

  const form = useForm({
    defaultValues,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ meta, metaExtra, shop }) => {
      try {
        await dispatch(
          updateApplicationRCAInfo(applicationId, { meta, metaExtra, shop })
        )
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, toast]
  )

  const isIdlc = application.type === 'daraz-idlc'

  return (
    <Box {...props}>
      <Form form={form} onSubmit={onSubmit}>
        <Heading fontSize={5} mb={4}>
          Loanee Information
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="meta.loaneeName"
            label={`Applicant Name`}
            value={get(application, 'meta.loaneeName')}
          />
          <FormDatePicker
            name="meta.loaneeDob"
            label={`Date of Birth`}
            required={isIdlc}
          />
          <FormSelect
            name="metaExtra.loaneeBirthDistrict"
            label={`District of Birth`}
            options={districtOptions}
          />
          <DecoratedFormInput
            name="metaExtra.loaneeBirthCountry"
            label={`Country of Birth`}
            value={get(application, 'metaExtra.loaneeBirthCountry')}
          />
          <DecoratedFormInput
            name="meta.spouseName"
            label={`Spouse's Name`}
            value={get(application, 'meta.spouseName')}
          />
          <DecoratedFormInput
            name="meta.fatherName"
            label={`Father's Name`}
            value={get(application, 'meta.fatherName')}
          />
          <DecoratedFormInput
            name="meta.motherName"
            label={`Mother's Name`}
            value={get(application, 'meta.motherName')}
          />
          <DecoratedFormInput
            name="shop.shopName"
            label={`Business Name`}
            value={get(application, 'shop.shopName')}
          />
          <DecoratedFormInput
            name="meta.loaneeNid"
            label={`NID Number`}
            value={get(application, 'meta.loaneeNid')}
          />
        </SimpleGrid>

        <Heading fontSize={5} mb={4}>
          Permanent Address
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="metaExtra.loaneePermanentAddressLine1"
            label={`Street No / Village`}
            value={get(application, 'metaExtra.loaneePermanentAddressLine1')}
          />
          <DecoratedFormInput
            name="metaExtra.loaneePermanentAddressLine2"
            label={`Street Name / PS / Upazilla`}
            value={get(application, 'metaExtra.loaneePermanentAddressLine2')}
          />
          {/* <DecoratedFormInput */}
          {/*   name="metaExtra.loaneePermanentAddressSubDistrict" */}
          {/*   label={`Upazilla`} */}
          {/*   value={get( */}
          {/*     application, */}
          {/*     'metaExtra.loaneePermanentAddressSubDistrict' */}
          {/*   )} */}
          {/* /> */}
          <FormSelect
            name="metaExtra.loaneePermanentAddressDistrict"
            label={`District`}
            options={districtOptions}
          />
          <DecoratedFormInput
            name="metaExtra.loaneePermanentAddressPostcode"
            label={`Postal Code`}
            value={get(application, 'metaExtra.loaneePermanentAddressPostcode')}
          />
          <DecoratedFormInput
            name="metaExtra.loaneePermanentAddressCountry"
            label={`Country`}
            value={get(application, 'metaExtra.loaneePermanentAddressCountry')}
          />
        </SimpleGrid>

        <Heading fontSize={5} mb={4}>
          Present Address
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="metaExtra.loaneePresentAddressLine1"
            label={`Street No / Village`}
            value={get(application, 'metaExtra.loaneePresentAddressLine1')}
          />
          <DecoratedFormInput
            name="metaExtra.loaneePresentAddressLine2"
            label={`Street Name / PS / Upazilla`}
            value={get(application, 'metaExtra.loaneePresentAddressLine2')}
          />
          {/* <DecoratedFormInput */}
          {/*   name="metaExtra.loaneePresentAddressSubDistrict" */}
          {/*   label={`Upazilla`} */}
          {/*   value={get( */}
          {/*     application, */}
          {/*     'metaExtra.loaneePresentAddressSubDistrict' */}
          {/*   )} */}
          {/* /> */}
          <FormSelect
            name="metaExtra.loaneePresentAddressDistrict"
            label={`District`}
            options={districtOptions}
          />
          <DecoratedFormInput
            name="metaExtra.loaneePresentAddressPostcode"
            label={`Postal Code`}
            value={get(application, 'metaExtra.loaneePresentAddressPostcode')}
          />
          <DecoratedFormInput
            name="metaExtra.loaneePresentAddressCountry"
            label={`Country`}
            value={get(application, 'metaExtra.loaneePresentAddressCountry')}
          />
        </SimpleGrid>

        <Heading fontSize={5} mb={4}>
          Business Address
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="metaExtra.businessPresentAddressLine1"
            label={`Street No / Village`}
            value={get(application, 'metaExtra.businessPresentAddressLine1')}
          />
          <DecoratedFormInput
            name="metaExtra.businessPresentAddressLine2"
            label={`Street Name / PS / Upazilla`}
            value={get(application, 'metaExtra.businessPresentAddressLine2')}
          />
          {/* <DecoratedFormInput */}
          {/*   name="metaExtra.businessPresentAddressSubDistrict" */}
          {/*   label={`Upazilla`} */}
          {/*   value={get( */}
          {/*     application, */}
          {/*     'metaExtra.businessPresentAddressSubDistrict' */}
          {/*   )} */}
          {/* /> */}
          <FormSelect
            name="metaExtra.businessPresentAddressDistrict"
            label={`District`}
            options={districtOptions}
          />
          <DecoratedFormInput
            name="metaExtra.businessPresentAddressPostcode"
            label={`Postal Code`}
            value={get(application, 'metaExtra.businessPresentAddressPostcode')}
          />
          <DecoratedFormInput
            name="metaExtra.businessPresentAddressCountry"
            label={`Country`}
            value={get(application, 'metaExtra.businessPresentAddressCountry')}
          />
        </SimpleGrid>

        <Heading fontSize={5} mb={4}>
          Other ID Document
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <FormSelect
            name="metaExtra.loaneeOtherIdDocType"
            label={`Document Type`}
            options={{
              none: 'None',
              passport: 'Passport',
              driving_license: 'Driving License',
              nationality_certificate: 'Nationality Certificate',
            }}
          />
          <DecoratedFormInput
            name="metaExtra.loaneeOtherIdDocId"
            label={`Document ID`}
            value={get(application, 'metaExtra.loaneeOtherIdDocId')}
          />
          <FormDatePicker
            name="metaExtra.loaneeOtherIdDocIssueDate"
            label={`ID Issue Date`}
          />
          <DecoratedFormInput
            name="metaExtra.loaneeOtherIdDocIssuingCountry"
            label={`ID Issuing County`}
            value={get(application, 'metaExtra.loaneeOtherIdDocIssuingCountry')}
          />
        </SimpleGrid>

        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default memo(CibRCA)
