import { get, isNull } from 'lodash-es'
import { stringify } from 'qs'
import { useEffect, useMemo, useState } from 'react'
import { emptyObject } from 'utils/defaults'
import usePrevious from './usePrevious'
import { useQuery } from './useQuery'

const defaultOptions = {
  maxTryCount: 3,
  paused: false,
}

function usePagination(
  pagination,
  fetchPage,
  queryObject = emptyObject,
  { maxTryCount = 3, paused = false } = defaultOptions
) {
  const query = useQuery()

  const page = useMemo(() => {
    return query.get('page') || 1
  }, [query])

  const prevPage = usePrevious(page)

  const [tryCount, setTryCount] = useState(0)

  useEffect(() => {
    if (paused) {
      return
    }

    const queryHash = stringify(queryObject)

    const prevQueryHash = get(pagination.pages, [page, 'queryHash'], null)

    if (!isNull(prevQueryHash) && queryHash === prevQueryHash) {
      return
    }

    setTryCount((tryCount) =>
      page === prevPage && queryHash === prevQueryHash ? tryCount + 1 : 1
    )

    if (tryCount < maxTryCount) {
      fetchPage({ page, ...queryObject }, queryHash)
    }
  }, [
    fetchPage,
    maxTryCount,
    page,
    pagination.pages,
    paused,
    prevPage,
    queryObject,
    tryCount,
  ])

  return [page]
}

export default usePagination
