import { Box, Input, Stack } from '@chakra-ui/core'
import { get, set } from 'lodash-es'
import React, { useEffect, useMemo, useReducer } from 'react'
import ReactSelect from 'react-select'
import { useDistributorRoutesForLoanProduct } from 'store/distributors/hooks'
import { emptyObject } from 'utils/defaults'

const initialState = {
  filter: emptyObject,
  values: emptyObject,
}

const reducer = (state = initialState, { key, field, operator, value }) => {
  const newState = {
    ...state,
    filter: { ...state.filter },
    value: { ...state.values },
  }

  if (value) {
    set(newState.filter, [key, `${field}${operator}`], value)
  } else {
    set(newState.filter[key], `${field}${operator}`)
  }

  newState.values[`${key}.${field}`] = value

  return newState
}

function UnileverApplicationListFilter({ onChange }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const routes = useDistributorRoutesForLoanProduct(2)
  const routeOptions = useMemo(() => {
    return [{ value: '', label: 'Any - Route' }].concat(
      routes.allIds.map((id) => ({
        value: id,
        label: `${get(routes.byId[id], 'code')} - ${get(
          routes.byId[id],
          'name'
        )}`,
      }))
    )
  }, [routes.allIds, routes.byId])

  useEffect(() => {
    const filterObject = Object.keys(state.filter).reduce((filter, key) => {
      filter[key] = Object.keys(state.filter[key])
        .reduce((conds, field) => {
          if (state.filter[key][field]) {
            conds.push(`${field}${state.filter[key][field]}`)
          }
          return conds
        }, [])
        .join(';')
      return filter
    }, {})

    onChange(filterObject)
  }, [onChange, state.filter])

  return (
    <Stack isInline spacing={2} mb={6}>
      <Box>
        <Input
          placeholder="Retailer ID"
          onChange={(e) => {
            dispatch({
              key: 'retailer',
              field: 'externalId',
              operator: ' like ',
              value: e.target.value ? `${e.target.value}%` : '',
            })
          }}
        />
      </Box>
      <Box minW={200}>
        <ReactSelect
          value={{
            value: get(state.values, 'route.id'),
            label: `${get(
              routes.byId[get(state.values, 'route.id')],
              'code',
              'Any'
            )} - ${get(
              routes.byId[get(state.values, 'route.id')],
              'name',
              'Route'
            )}`,
          }}
          options={routeOptions}
          onChange={(data) => {
            dispatch({
              key: 'route',
              field: 'id',
              operator: ' = ',
              value: data ? data.value : '',
            })
          }}
        />
      </Box>
    </Stack>
  )
}

export default UnileverApplicationListFilter
