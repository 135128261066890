import { get } from 'lodash-es'

export function getCollection(application) {
  const loans = get(application, 'rlAccount.loans', [])

  let totalCollectedAmount = 0
  let totalPrincipalCollected = 0
  let totalInterestCollected = 0

  let totalOdInterest = get(application, 'odAccount.totalAccruedInterest', 0)

  for (let loan of loans) {
    totalCollectedAmount += loan.amountRepaid
    totalPrincipalCollected += loan.repaymentBreakdown.principalRepaid
    totalInterestCollected +=
      loan.repaymentBreakdown.interestRepaid +
      loan.repaymentBreakdown.penaltyRepaid
  }

  let totalShopupFee = totalInterestCollected - totalOdInterest

  return {
    totalCollectedAmount,
    totalPrincipalCollected,
    totalInterestCollected,
    totalOdInterest,
    totalShopupFee,
  }
}
