import {
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core'
import { Form, FormInput, handleAPIError, NavLink } from '@eloan/shared'
import FormButton from 'components/Form/Button'
import Topbar from 'components/Topbar'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { requestPasswordReset, resetPassword } from 'store/currentUser'
import * as Yup from 'yup'

function ResetPassword({ token }) {
  const [done, setDone] = useState(false)

  const toast = useToast()

  const defaultValues = useMemo(
    () => ({ password: '', passwordConfirmation: '' }),
    []
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .min(8, 'Should be atleast 8 characters.')
          .required('Required.'),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref('password'), null], "Passwords don't match")
          .required('Required.'),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ password, passwordConfirmation }) => {
      try {
        await dispatch(resetPassword({ password, passwordConfirmation, token }))
        setDone(true)
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, toast, token]
  )

  if (done) {
    return (
      <Stack spacing={4} textAlign="center">
        <Stack spacing={0}>
          <Text fontSize={2} fontWeight="bold">
            You have successfully reset your password!
          </Text>

          <Text fontSize={2}>You can now login with your new password!</Text>
        </Stack>

        <Divider />

        <Stack>
          <Button
            as={NavLink}
            to="/login"
            variantColor="cyan"
            variant="outline"
          >
            Log In
          </Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack spacing={4}>
      <Heading as="h2" fontSize={6} textAlign="center">
        Reset Password
      </Heading>

      <Form form={form} spacing={2} onSubmit={onSubmit}>
        <FormInput type="password" name="password" label="Enter new password" />
        <FormInput
          type="password"
          name="passwordConfirmation"
          label="Confirm new password"
        />

        <ButtonGroup spacing={2} display="flex" mt={2}>
          <Button
            as={NavLink}
            to="/login"
            variantColor="cyan"
            variant="outline"
          >
            Log In
          </Button>
          <FormButton type="submit" variantColor="blue" flexGrow="1">
            Reset Password
          </FormButton>
        </ButtonGroup>
      </Form>
    </Stack>
  )
}

function RequestPasswordReset({ urlTemplate }) {
  const [sent, setSent] = useState(null)

  const toast = useToast()

  const defaultValues = useMemo(() => ({ email: '' }), [])

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email('Enter valid email.').required('Required.'),
      }),
    []
  )

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        await dispatch(requestPasswordReset({ email, urlTemplate }))
        setSent({ email })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, toast, urlTemplate]
  )

  if (sent) {
    return (
      <Stack spacing={4} textAlign="center">
        <Stack spacing={0}>
          <Text fontSize={2} fontWeight="bold">
            If an account exists for
          </Text>
          <Text
            fontSize={3}
            fontWeight="bold"
            fontStyle="italic"
            color="primary"
          >
            {sent.email}
          </Text>
          <Text fontSize={2} fontWeight="bold">
            You will receive an email with further instructions to reset your
            password.
          </Text>
        </Stack>

        <Divider />

        <Stack>
          <Text fontSize={2} fontStyle="italic">
            Didn't receive the email?
          </Text>

          <Text fontSize={2}>
            Please, <strong>wait a few minutes</strong> and{' '}
            <strong>check your spam folder</strong> too!
          </Text>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack spacing={4}>
      <Heading as="h2" fontSize={6} textAlign="center">
        Forgot Password?
      </Heading>

      <Form form={form} spacing={2} onSubmit={onSubmit}>
        <FormInput
          type="email"
          name="email"
          label="Enter your email address:"
        />

        <ButtonGroup spacing={2} display="flex" mt={2}>
          <Button
            as={NavLink}
            to="/login"
            variantColor="cyan"
            variant="outline"
          >
            Log In
          </Button>
          <FormButton type="submit" variantColor="blue" flexGrow="1">
            Request Password Reset
          </FormButton>
        </ButtonGroup>
      </Form>
    </Stack>
  )
}

function ForgotPassword({ location }) {
  const { token } = useParams()

  const urlTemplate = useMemo(() => {
    const domain = window.location.origin.toString()
    const pathname = location.pathname.replace(/^\/*/, '').replace(/\/*$/, '')
    return `${domain}/${pathname}/{token}`
  }, [location.pathname])

  const status = useSelector((state) => state.user.status)

  if (status.authed) {
    return <Redirect to={location.state ? location.state.from || '/' : '/'} />
  }

  return (
    <>
      <Topbar />

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Stack
          spacing={2}
          borderWidth="1px"
          rounded="lg"
          width="100%"
          maxWidth="480px"
          p={4}
        >
          {token ? (
            <ResetPassword token={token} />
          ) : (
            <RequestPasswordReset urlTemplate={urlTemplate} />
          )}
        </Stack>
      </Flex>
    </>
  )
}

export default ForgotPassword
