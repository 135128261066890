import { Box } from '@chakra-ui/core'
import FormSelect from 'components/Form/Select'
import { zipObject } from 'lodash-es'
import React, { useMemo } from 'react'
import { useAreas } from 'store/areas/hooks'

function AreaFilter({ ...props }) {
  const areas = useAreas()

  const areaOptions = useMemo(() => {
    return zipObject(
      areas.allIds,
      areas.allIds.map((id) => areas.byId[id].name)
    )
  }, [areas.allIds, areas.byId])

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="meta.shopAreaId"
        label={`Filter by Area`}
        options={areaOptions}
      />
    </Box>
  )
}

export default AreaFilter
