import {
  Box,
  ButtonGroup,
  Flex,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import { get, pickBy, zipObject } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toUpper } from '@eloan/shared'

function RepaymentFollowupCallTeamFilter({
  agents,
  tags,
  areas,
  callStatuses,
  isCallAgent,
  onSubmit,
}) {
  const form = useForm()

  const agentOptions = useMemo(
    () =>
      zipObject(
        agents.allIds,
        agents.allIds.map((id) => get(agents.byId[id], 'name'))
      ),
    [agents]
  )

  const tagOptions = useMemo(
    () => tags.reduce((obj, curr) => ({ ...obj, [curr]: curr }), {}),
    [tags]
  )

  const areaOptions = useMemo(
    () =>
      zipObject(
        areas.allIds,
        areas.allIds.map((id) => get(areas.byId[id], 'name'))
      ),
    [areas]
  )

  const callStatusOptions = useMemo(() => {
    return zipObject(
      callStatuses,
      callStatuses.map((status) => toUpper(status ?? 'none'))
    )
  }, [callStatuses])

  const onFormSubmit = useCallback(
    ({ eloanId, tag, areaId, agentId, minDpd, maxDpd, lastCallStatus }) => {
      const filterObject = {
        eloanId,
        tag,
        areaId,
        agentId,
        minDpd: minDpd || maxDpd,
        maxDpd: maxDpd || minDpd,
        lastCallStatus: lastCallStatus === 'null' ? null : lastCallStatus,
      }

      onSubmit(pickBy(filterObject, (item) => typeof item !== 'undefined'))
    },
    [onSubmit]
  )

  const formReset = form.reset

  const onReset = useCallback(() => {
    formReset()
    onSubmit({})
  }, [formReset, onSubmit])

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack
        isInline
        spacing={6}
        marginBottom={6}
        align="flex-end"
        flexWrap="wrap"
      >
        <Box maxW="160px">
          <FormInput
            label={
              <Text color="gray.700" fontSize={2}>
                eLoan ID
              </Text>
            }
            name="eloanId"
            placeholder="eLoan ID..."
          />
        </Box>
        <Box minW="180px">
          <FormSelect
            name="areaId"
            label={<Text color="gray.700">Area</Text>}
            options={areaOptions}
            placeholder="Select Area"
          />
        </Box>

        <Box minW="180px">
          <FormSelect
            name="tag"
            label={<Text color="gray.700">Tag</Text>}
            options={tagOptions}
            placeholder="Select Tag"
          />
        </Box>
        {!isCallAgent && (
          <Box minW="180px">
            <FormSelect
              name="agentId"
              label={<Text color="gray.700">Call Agent</Text>}
              options={agentOptions}
              placeholder="Select Agent"
            />
          </Box>
        )}
        <Box maxW="240px">
          <Text color="gray.700" fontSize={2} mb={1}>
            DPD Range
          </Text>
          <Flex>
            <FormInput placeholder="Min DPD" name="minDpd" />
            <FormInput placeholder="Max DPD" name="maxDpd" />
          </Flex>
        </Box>
        <Box minW="180px">
          <FormSelect
            name="lastCallStatus"
            label={<Text color="gray.700">Call Status</Text>}
            options={callStatusOptions}
            placeholder="Select Call Status"
          />
        </Box>
        <Box>
          <ButtonGroup spacing={2}>
            <IconButton type="button" icon="small-close" onClick={onReset} />
            <FormButton
              type="submit"
              variantColor="cyan"
              textTransform="uppercase"
            >
              Search
            </FormButton>
          </ButtonGroup>
        </Box>
      </Stack>
    </Form>
  )
}

export default RepaymentFollowupCallTeamFilter
