import { Box, Heading, SimpleGrid, useToast } from '@chakra-ui/core'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import { get, set } from 'lodash-es'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { updateApplicationRCAInfo } from 'store/applications'
import { useApplication } from 'store/applications/hooks'
import DecoratedFormInput from '../components/DecoratedFormInput'

const fields = [
  'type',
  'meta.monthlyAnchorSale',
  'meta.monthlyOtherSale',
  'meta.monthlyCostOfGoodsSold',
  'meta.monthlyGrossProfit',
  'meta.monthlyGrossProfitPercentage',

  'meta.monthlyFinancialExpenses',
  'meta.monthlyOperationalExpenses',
  'meta.monthlySalesTurnover',
  'meta.staffsSalary',
  'meta.shopRent',
  'meta.utilityBills',
  'meta.monthlyMarketingExpenses',
  'meta.otherCosts',
  'meta.monthlyTax',

  'meta.monthlyNetProfit',

  'meta.monthlyFamilyExpenses',
  'meta.monthlyFamilyFoodCost',
  'meta.monthlyFamilyEducationCost',
  'meta.tenancyRentAmount',
  'meta.monthlyFamilyTransportCost',
  'meta.monthlyFamilyOtherExpenses',

  'meta.monthlyConsiderableIncome',
  'meta.loanInstallment',
  'meta.monthlySavings',

  'meta.cashInHand',
  'meta.stockValue',
  'meta.receivables',
  'meta.shopValuation',
  'meta.payables',
].join(',')

const getDefaultValues = (application) => {
  return fields.split(',').reduce(
    (values, field) => {
      set(values, field, get(application, field) || '')
      return values
    },
    { meta: {} }
  )
}

function StatementsRCA({ applicationId, ...props }) {
  const toast = useToast()

  const application = useApplication(applicationId, fields)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])

  const form = useForm({
    defaultValues,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ meta, shop }) => {
      try {
        await dispatch(updateApplicationRCAInfo(applicationId, { meta, shop }))
        toast({
          title: 'Successfully Updated!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, toast]
  )

  // const isIdlc = application.type === 'daraz-idlc'

  return (
    <Box {...props}>
      <Form form={form} onSubmit={onSubmit}>
        <Heading fontSize={5} mb={4}>
          Monthly Income Statement
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="meta.monthlyAnchorSale"
            label={`Declare: Anchor monthly sale`}
            value={get(application, 'meta.monthlyAnchorSale')}
          />
          <DecoratedFormInput
            name="meta.monthlyOtherSale"
            label={`Declare: Other monthly sale`}
            value={get(application, 'meta.monthlyOtherSale')}
          />
          <DecoratedFormInput
            name="meta.monthlyCostOfGoodsSold"
            label={`Cost of goods sold (COGS)`}
            value={get(application, 'meta.monthlyCostOfGoodsSold')}
          />
          <DecoratedFormInput
            name="meta.monthlyGrossProfit"
            label={`Gross profit [Both monthly sale - COGS]`}
            value={get(application, 'meta.monthlyGrossProfit')}
            readonly
          />
          <DecoratedFormInput
            name="meta.monthlyGrossProfitPercentage"
            label={`Gross profit percentage`}
            value={get(application, 'meta.monthlyGrossProfitPercentage')}
          />

          <DecoratedFormInput
            name="meta.monthlyFinancialExpenses"
            label={`Financial expenses`}
            value={get(application, 'meta.monthlyFinancialExpenses')}
          />

          <DecoratedFormInput
            name="meta.monthlyOperationalExpenses"
            label={`Declare: Operational expenses`}
            value={get(application, 'meta.monthlyOperationalExpenses')}
            readonly
          />

          <DecoratedFormInput
            name="meta.monthlySalesTurnover"
            label={`Declare: Monthly Sales Turnover`}
            value={get(application, 'meta.monthlySalesTurnover')}
          />

          <DecoratedFormInput
            name="meta.staffsSalary"
            label={`Employee Salary`}
            value={get(application, 'meta.staffsSalary')}
          />
          <DecoratedFormInput
            name="meta.shopRent"
            label={`Office/Shop/Warehouse Rent`}
            value={get(application, 'meta.shopRent')}
          />
          <DecoratedFormInput
            name="meta.utilityBills"
            label={`Utility Bills`}
            value={get(application, 'meta.utilityBills')}
          />
          <DecoratedFormInput
            name="meta.monthlyMarketingExpenses"
            label={`Marketing Expenses`}
            value={get(application, 'meta.monthlyMarketingExpenses')}
          />
          <DecoratedFormInput
            name="meta.otherCosts"
            label={`Misc. Expenses`}
            value={get(application, 'meta.otherCosts')}
          />
          <DecoratedFormInput
            name="meta.monthlyTax"
            label={`Monthly TAX`}
            value={get(application, 'meta.monthlyTax')}
          />

          <DecoratedFormInput
            name="meta.monthlyNetProfit"
            label={`Net profit (Gross profit - Operational expenses)`}
            value={get(application, 'meta.monthlyNetProfit')}
            readonly
          />

          <DecoratedFormInput
            name="meta.monthlyFamilyExpenses"
            label={`Declare: Family expenses`}
            value={get(application, 'meta.monthlyFamilyExpenses')}
            readonly
          />
          <DecoratedFormInput
            name="meta.monthlyFamilyFoodCost"
            label={`Food Cost (Family)`}
            value={get(application, 'meta.monthlyFamilyFoodCost')}
          />
          <DecoratedFormInput
            name="meta.monthlyFamilyEducationCost"
            label={`Education Cost (Family)`}
            value={get(application, 'meta.monthlyFamilyEducationCost')}
          />
          <DecoratedFormInput
            name="meta.tenancyRentAmount"
            label={`House Rent (Family)`}
            value={get(application, 'meta.tenancyRentAmount')}
          />
          <DecoratedFormInput
            name="meta.monthlyFamilyTransportCost"
            label={`Transport Cost (Family)`}
            value={get(application, 'meta.monthlyFamilyTransportCost')}
          />
          <DecoratedFormInput
            name="meta.monthlyFamilyOtherExpenses"
            label={`Misc. Expenses (Family)`}
            value={get(application, 'meta.monthlyFamilyOtherExpenses')}
          />

          <DecoratedFormInput
            name="meta.monthlyConsiderableIncome"
            label={`Considerable income (Net profit - Family expenses)`}
            value={get(application, 'meta.monthlyConsiderableIncome')}
            readonly
          />
          <DecoratedFormInput
            name="meta.loanInstallment"
            label={`Declare: Loan outstanding EMI`}
            value={get(application, 'meta.loanInstallment')}
          />
          <DecoratedFormInput
            name="meta.monthlySavings"
            label={`Declare: DPS & monthly saving`}
            value={get(application, 'meta.monthlySavings')}
          />
        </SimpleGrid>

        <Heading fontSize={5} mt={10} mb={4}>
          Assets and Liabilities
        </Heading>

        <SimpleGrid columns={3} spacing={10}>
          <DecoratedFormInput
            name="meta.cashInHand"
            label={`Cash in hand & bank (Considerable income - Loan outstanding EMI)`}
            value={get(application, 'meta.cashInHand')}
            readonly
          />
          <DecoratedFormInput
            name="meta.stockValue"
            label={`Declare: Stock value`}
            value={get(application, 'meta.stockValue')}
          />
          <DecoratedFormInput
            name="meta.receivables"
            label={`Declare: Receivables`}
            value={get(application, 'meta.receivables')}
          />
          <DecoratedFormInput
            name="meta.shopValuation"
            label={`Declare: Shop advance or valuation`}
            value={get(application, 'meta.shopValuation')}
          />
          <DecoratedFormInput
            name="meta.payables"
            label={`Declare: Payables`}
            value={get(application, 'meta.payables')}
          />
        </SimpleGrid>

        <Box mt={8}>
          <FormButton type="submit">Save Information</FormButton>
        </Box>
      </Form>
    </Box>
  )
}

export default memo(StatementsRCA)
