import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Badge,
  Box,
  Flex,
  Button,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/core'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import LoaneeRepayment from './LoaneeRepayment'
import Authorize from 'components/Authorize'
import { useApplication } from 'store/applications/hooks'
import LoanInfo from './LoanInfo'
import { get, find } from 'lodash-es'
import PartnerRepayment from './PartnerRepayment'
import { Link } from 'react-router-dom'
import { offerStatusDisplayText } from 'utils/offer-status'
import ChangeOfferStatus from './ChangeOfferStatus'
import CloseLoan from './CloseLoan'
import { useAccountsByApplicationId } from 'store/accounts/hooks'
import { usePAUsers } from 'store/users/hooks'
import InfoBox from 'components/Box/InfoBox'
import SendToTweaking from 'pages/offers/View/modals/SendToTweaking'
import { generateAndDownloadPdf } from '@eloan/shared/utils/pdfMake'
import { api } from 'api'

const tabs = [
  {
    id: 'loan-info',
    name: 'Loan Info',
  },
  { id: 'loanee-repayment', name: 'ShopUp - Loanee Repayment' },
  { id: 'partner-repayment', name: 'ShopUp - Partner Repayment' },
]

const fields = [
  '*',
  'offer.*',
  'meta.*',
  'metaExtra.*',
  'bankDetails.*',
  'shop.shopName',
  'paAssignment.paId',
  'paymentDetails.*',
  'redxShopData.*',
].join(',')

function EloanApplicationView() {
  const history = useHistory()

  const { applicationId, tabId } = useParams()

  const application = useApplication(applicationId, fields)

  const paUsers = usePAUsers()
  const pa = useMemo(() => {
    return get(paUsers.byId, get(application, 'paAssignment.paId'))
  }, [application, paUsers.byId])

  const accounts = useAccountsByApplicationId(applicationId)

  const tabsToShow = useMemo(() => {
    if (
      ['active', 'financed', 'ended'].includes(get(application, 'offer.status'))
    ) {
      return tabs
    }
    return [tabs[0]]
  }, [application])

  const onTabChange = useCallback(
    (index) => {
      history.replace(
        `/dash/eloan-applications/${applicationId}/${tabs[index].id}`
      )
    },
    [applicationId, history]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === tabId)
    return index < 0 ? 0 : index
  }, [tabId])

  useEffect(() => {
    onTabChange(tabIndex)
  }, [onTabChange, tabIndex])

  const toast = useToast()

  const generateMaterialize = useCallback(async () => {
    try {
      await api(`POST /eloan/v1/materialized-data/term-loans/`, {
        body: {
          eloanId: applicationId,
          force: true,
        },
      })
      toast({
        title: 'Refreshed account summary',
        status: 'success',
        isClosable: true,
        duration: 5000,
      })

      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error) {
      toast({
        status: 'error',
        description: 'failed to regenerate account summary',
        isClosable: true,
        duration: 5000,
        title: 'Request Failed!',
      })
      console.error(error)
    }
  }, [applicationId, toast])

  const frozenApplicationOffer = async () => {
    try {
      const { data } = await api(
        `PATCH /legacy/v4/eloan/applications/${applicationId}/freeze`
      )
      toast({
        title: 'Application Frozen Success fully',
        status: 'success',
        isClosable: true,
        duration: 3000,
      })
      setTimeout(() => window.location.reload(), 1000)
    } catch (err) {
      toast({
        status: 'error',
        description: 'Failed to froze application',
        isClosable: true,
        duration: 3000,
        title: 'Request Failed!',
      })
      console.error(err)
    }
  }

  const unFrozenApplicationOffer = async () => {
    try {
      const { data } = await api(
        `PATCH /legacy/v4/eloan/applications/${applicationId}/unfreeze`
      )
      toast({
        title: 'Application UnFrozen Success Fully',
        status: 'success',
        isClosable: true,
        duration: 3000,
      })
      setTimeout(() => window.location.reload(), 1000)
    } catch (err) {
      toast({
        status: 'error',
        description: 'Failed to unfroze application',
        isClosable: true,
        duration: 3000,
        title: 'Request Failed!',
      })
      console.error(err)
    }
  }

  const downloadClientRepaymentPdf = useCallback(async () => {
    const pdfJsonFetchUrl = `GET /legacy/v3/eloan/applications/${applicationId}/actions/client-repayment-pdf-json`

    try {
      const { data } = await api(pdfJsonFetchUrl)

      const filename = `Application-${applicationId}-Client-Repayment-Statement.pdf`
      generateAndDownloadPdf(data, filename)
    } catch (error) {
      console.error(error)
      toast({
        status: 'error',
        description: 'failed to fetch client repayment pdf data',
        isClosable: true,
        duration: 5000,
        title: 'Request Failed!',
      })
    }
  }, [applicationId, toast])

  let freezeButton
  const isOfferActive = ['active', 'financed'].includes(
    get(application, 'offer.status')
  )
  if (!isOfferActive) {
    freezeButton = <></>
  } else if (get(application, 'status') === 'frozen') {
    freezeButton = (
      <Button variantColor="green" px={10} onClick={unFrozenApplicationOffer}>
        Unfreeze
      </Button>
    )
  } else {
    freezeButton = (
      <Button variantColor="red" px={10} onClick={frozenApplicationOffer}>
        Freeze
      </Button>
    )
  }

  return (
    <Authorize permissions="eloan:AnyApplication:read, eloan:Application:read">
      <Flex mb={10} justify="space-between">
        <Box>
          <Heading fontSize={5}>
            {get(application, 'meta.loaneeName')}
            {' | '}eloan ID: {applicationId}
            {' | '}
            {get(application, 'meta.loaneePhoneNumber')}
          </Heading>
          <Box mt={2}>
            <Badge variantColor="orange" variant="solid" borderRadius="4px">
              {offerStatusDisplayText[get(application, 'offer.status')]}
            </Badge>
            <Badge
              ml={2}
              variantColor="green"
              variant="solid"
              borderRadius="4px"
            >
              {get(accounts, '0.accountStatus')}
            </Badge>
            {get(application, 'status') === 'frozen' && (
              <Badge variantColor="red" variant="solid" borderRadius="4px">
                FROZEN
              </Badge>
            )}
          </Box>
        </Box>
        <Stack isInline spacing={4}>
          <Button
            variantColor="cyan"
            as={Link}
            to={`/dash/applications/${get(application, 'id')}/rca`}
            px={10}
          >
            RCA
          </Button>
          {freezeButton}
          <Authorize permissions="eloan:DraftAccount:write">
            {'offer_tweaking' === get(application, 'offer.status') && (
              <Button
                variantColor="cyan"
                as={Link}
                to={`/dash/admin/offer/${get(application, 'id')}`}
                px={10}
              >
                Offer
              </Button>
            )}
          </Authorize>
          {[
            'offer_tweaking',
            'offer_negotiating',
            'loanee_agreement_pending',
            'loanee_agreement_signed',
            'financed',
          ].includes(get(application, 'offer.status')) && (
            <ChangeOfferStatus application={application} />
          )}
          {get(application, 'offer.status') === 'active' && (
            <CloseLoan
              fiAccountId={
                find(accounts, ({ accountType }) => accountType === 'FI')?.id
              }
              application={application}
            />
          )}
          <SendToTweaking
            px={10}
            variantColor="teal"
            application={application}
            applicationId={get(application, 'id')}
          />
          {get(application, 'type') === 'redx-shopup' &&
            ['active', 'financed', 'ended'].includes(
              get(application, 'offer.status')
            ) && (
              <Button
                variantColor="cyan"
                onClick={() =>
                  downloadClientRepaymentPdf(get(application, 'id'))
                }
                px={10}
              >
                Client Repayment Statement
              </Button>
            )}
        </Stack>
      </Flex>
      <Tabs index={tabIndex} onChange={onTabChange} variantColor="cyan">
        <TabList mb={4}>
          {tabsToShow.map((tab) => (
            <Tab id={tab.id} key={tab.id} px={0} pt={0} mr={4}>
              {tab.name}
            </Tab>
          ))}
        </TabList>
        <Flex justify="flex-end">
          <Stack isInline spacing={4}>
            <Authorize permissions="eloan:MaterializedTermLoans:write">
              <Button
                variantColor="cyan"
                px={10}
                onClick={() => generateMaterialize()}
              >
                Refresh Account
              </Button>
            </Authorize>
            <InfoBox label="Portfolio Associate" color="gray.500">
              <Text color="black" fontSize="md">
                {get(pa, 'name')}
              </Text>
            </InfoBox>
          </Stack>
        </Flex>
        <TabPanels py={4}>
          <TabPanel>
            <LoanInfo application={application} accounts={accounts} />
          </TabPanel>
          <TabPanel>
            <LoaneeRepayment applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <PartnerRepayment applicationId={applicationId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Authorize>
  )
}

export default EloanApplicationView
