import { keyByDeep } from '@eloan/shared'
import { api } from 'api'
import { get, keyBy, map } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicationData } from 'store/applications'
import { emptyArray, emptyObject } from 'utils/defaults'

export function useApplication(applicationId, fields) {
  const application = useSelector((state) =>
    get(state.applications.byId, applicationId)
  )

  const dataStatus = useSelector((state) =>
    get(state.applications.dataStatus, `${applicationId}:${fields}`)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      applicationId &&
      fields &&
      !['loaded', 'loading'].includes(dataStatus)
    ) {
      dispatch(getApplicationData(applicationId, { fields }))
    }
  }, [applicationId, dispatch, fields, dataStatus])

  return application
}

export function useApplicationHistory({ applicationId, fields }) {
  const [data, setData] = useState({ byId: emptyObject, allIds: emptyArray })

  useEffect(() => {
    if (applicationId) {
      api(`/legacy/v3/eloan/applications/{applicationId}/history{?fields}`, {
        applicationId,
        fields,
      }).then(({ data }) => {
        const byId = keyBy(data.items, 'id')
        const allIds = map(data.items, 'id')
        setData({ byId, allIds })
      })
    }
  }, [applicationId, fields])

  return data
}

export function useApplicationsNearingDue({ loanProductId, dueWithinDays }) {
  const [items, setItems] = useState(emptyArray)

  useEffect(() => {
    api(
      `/legacy/v3/eloan/loan-products/{loanProductId}/filtered-applications/nearing-due{?dueWithinDays}`,
      {
        loanProductId,
        dueWithinDays,
      }
    ).then(({ data }) => setItems(data.items))
  }, [dueWithinDays, loanProductId])

  return items
}

export function useRLSummary({ offset, loanProductId, filter, limit }) {
  const [items, setItems] = useState(emptyArray)
  const [nextLink, setNextLink] = useState(false)
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const fetchMore = useCallback(
    async (nextLink) => {
      setIsFetchingMore(true)
      const { data } = await api(nextLink)

      setItems([...items, ...data.items])
      setNextLink(data.nextLink)
      setIsFetchingMore(false)
    },
    [items]
  )

  useEffect(() => {
    async function fetchData() {
      const { data } = await api(
        `/legacy/v4/eloan/loan-products/{loanProductId}/revolving-loan-withdrawals{?offset,limit,filter*}`,
        {
          loanProductId,
          offset,
          limit,
          filter,
        }
      )
      setItems(data.items)
      setNextLink(data.nextLink)
    }
    fetchData()
  }, [loanProductId, offset, limit, filter])

  return {
    items,
    nextLink,
    fetchMore,
    isFetchingMore,
  }
}

export function useApplicationEntities({ applicationId, partnerId }) {
  const [items, setItems] = useState(emptyArray)

  useEffect(() => {
    if (applicationId && partnerId) {
      api(
        `/legacy/v4/eloan/partners/${partnerId}/applications/${applicationId}/docs`
      ).then(({ data }) => setItems(keyByDeep(data.items, 'id')))
    }
  }, [applicationId, partnerId])

  return items
}
