import React from 'react'
import { ExperimentalDataTable } from '@eloan/shared'

const getDefaultHeaderProps = () => ({
  sx: { color: 'gray.600', fontSize: 'xs', px: 1, py: 2 },
})

const getDefaultCellProps = () => ({
  sx: { color: 'gray.600', fontSize: 'sm', pt: 0, px: 1, py: 4 },
})

function ListTable({
  table,
  getHeaderProps = getDefaultHeaderProps,
  getCellProps = getDefaultCellProps,
  getRowProps,
}) {
  return (
    <ExperimentalDataTable
      {...table}
      getHeaderProps={getHeaderProps}
      getCellProps={getCellProps}
      getRowProps={getRowProps}
    />
  )
}

export default ListTable
