export const FETCH_SETTLEMENTS_DATA_RESOLVED = 'FETCH_SETTLEMENTS_DATA_RESOLVED'

export const SETTLE_DONE = 'SETTLE_DONE'

export const getSettlements = ({ loanProductId }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `/legacy/v4/eloan/loan-products/{loanProductId}/collections`,
    {
      loanProductId,
    }
  )

  dispatch({ type: FETCH_SETTLEMENTS_DATA_RESOLVED, data })

  return data
}

export const settle = ({ ids, settlementDate }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(`POST /legacy/v4/eloan/beftn/bulk-settle`, {
    ids,
    settlementDate,
  })

  dispatch({ type: SETTLE_DONE, ids })

  return data
}
