import {
  Link,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Image,
} from '@chakra-ui/core'
import {
  Form,
  FormButton,
  FormCheckbox,
  FormInput,
  FormTextarea,
  handleAPIError,
  InfoCard,
} from '@eloan/shared'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { manuallyVerifyCibPayment } from 'store/applications'
import * as Yup from 'yup'
import { getPrivateImageSrc } from 'utils/getPrivateImageSrc'

const getValidationSchema = () => {
  return Yup.object({
    amount: Yup.number()
      .integer()
      .typeError('Must be a number.')
      .moreThan(0, 'More than 0.')
      .required('Required.'),
    trxIdIncorrect: Yup.boolean(),
    correctTransactionId: Yup.string().when('trxIdIncorrect', {
      is: true,
      then: Yup.string().required(`Required.`),
      otherwise: Yup.string().oneOf(
        [''],
        'Only required when the given Transaction ID is incorrect.'
      ),
    }),
    note: Yup.string().when('trxIdIncorrect', {
      is: true,
      then: Yup.string().required(`Required.`),
      otherwise: Yup.string().notRequired(),
    }),
    attachment: Yup.mixed().optional(),
  })
}

const getDefaultValues = () => {
  const defaultValues = {
    amount: '',
    trxIdIncorrect: false,
    correctTransactionId: '',
    attachment: [],
  }
  return defaultValues
}

function CibPaymentVerify({
  applicationId,
  application,
  cibPayment,
  ...props
}) {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const toast = useToast()

  const defaultValues = useMemo(() => getDefaultValues(cibPayment), [
    cibPayment,
  ])
  const validationSchema = useMemo(getValidationSchema, [])

  const form = useForm({ defaultValues, validationSchema })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()
  const confirm = useCallback(
    async ({ amount, correctTransactionId, note, attachment = [] }) => {
      try {
        await dispatch(
          manuallyVerifyCibPayment(applicationId, cibPayment.id, {
            paymentMethod: 'bkash',
            amount,
            correctTransactionId: correctTransactionId?.trim(),
            note,
            attachment: attachment[0],
          })
        )
        onClose()
      } catch (error) {
        handleAPIError(error, { form, toast })
      }
    },
    [applicationId, cibPayment.id, dispatch, form, onClose, toast]
  )

  if (
    !cibPayment.bkashTransaction ||
    cibPayment.bkashTransaction.status !== 'unverified'
  ) {
    return null
  }

  const trxIdIncorrect = form.watch('trxIdIncorrect')
  const attachment = cibPayment.bkashTransaction.attachment

  return (
    <>
      <Button variantColor="cyan" leftIcon="edit" {...props} onClick={onOpen}>
        Verify
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={confirm}>
            <ModalHeader>Manually Verify Payment</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack isInline spacing={6} mb={6}>
                <InfoCard
                  label="eLoan ID"
                  value={get(application, 'id')}
                  valueFontSize="md"
                />
                <InfoCard
                  label="Name"
                  value={get(application, 'meta.loaneeName')}
                  valueFontSize="md"
                />
                <InfoCard
                  label="Phone number"
                  value={get(application, 'meta.loaneePhoneNumber', '').slice(
                    -11
                  )}
                  valueFontSize="md"
                />
              </Stack>

              <Stack spacing={4}>
                <Box>
                  <FormControl>
                    <FormLabel>
                      <Stack isInline spacing={4} alignItems="center">
                        <Text>bKash Transaction ID</Text>
                        <Box>
                          <FormCheckbox
                            name="trxIdIncorrect"
                            variantColor="red"
                          >
                            Incorrect
                          </FormCheckbox>
                        </Box>
                      </Stack>
                    </FormLabel>
                    <Input isReadOnly value={cibPayment.bkashTransaction.id} />
                  </FormControl>
                </Box>
                {trxIdIncorrect && (
                  <Box>
                    <FormInput
                      name="correctTransactionId"
                      label="Correct bKash Transaction ID *"
                    />
                  </Box>
                )}
                <Box>
                  <FormInput
                    name="amount"
                    label={`Amount *`}
                    placeholder="Enter amount"
                  />
                </Box>
                <Box>
                  <FormTextarea
                    name="note"
                    label={`Note${trxIdIncorrect ? ' *' : ''}`}
                  />
                </Box>
                <Box>
                  <Text fontSize={2} fontWeight="bold" mb={2}>
                    bKash Payment Slip
                  </Text>

                  {attachment ? (
                    <Box
                      mb={4}
                      as={Link}
                      display="block"
                      href={getPrivateImageSrc(attachment)}
                      target="_blank"
                      size={64}
                      sx={{ position: 'relative' }}
                    >
                      <Image
                        size="100%"
                        objectFit="cover"
                        src={getPrivateImageSrc(attachment)}
                        fallbackSrc="https://via.placeholder.com/128?text=N/A"
                      />
                    </Box>
                  ) : (
                    <FormInput
                      name="attachment"
                      type="file"
                      label="Attachment"
                    />
                  )}
                </Box>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button mr={4} variantColor="green" onClick={onClose}>
                CANCEL
              </Button>
              <FormButton type="submit" variantColor="cyan">
                VERIFY
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CibPaymentVerify
