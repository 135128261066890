import { FormControl, FormLabel } from '@chakra-ui/core'
import ErrorMessage from 'components/Form/ErrorMessage'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import ReactSelect from 'react-select'

function SelectField({
  id,
  name,
  options,
  isLoading,
  value,
  setValue,
  register,
  ...props
}) {
  const formattedOptions = useMemo(() => {
    return Object.entries(options).reduce((options, [value, label]) => {
      return options.concat({ value, label })
    }, [])
  }, [options])

  const formattedValue = useMemo(() => {
    return value ? { value, label: options[value] } : null
  }, [options, value])

  const onChange = useCallback(
    (value) => {
      setValue(name, value ? value.value : null)
    },
    [name, setValue]
  )

  return (
    <ReactSelect
      {...props}
      id={id}
      name={name}
      value={formattedValue}
      options={formattedOptions}
      onChange={onChange}
      ref={register}
    />
  )
}

function FormSelect({
  name,
  id = name,
  label,
  options,
  placeholder,
  loading,
  required,
  validate,
  disabled,
  readonly,
  selectProps,
  ...props
}) {
  const { register, errors, watch, setValue } = useFormContext()

  const value = watch(name)

  return (
    <FormControl
      {...props}
      isRequired={required}
      isDisabled={disabled}
      isInvalid={Boolean(get(errors, name))}
      isReadOnly={readonly}
    >
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <SelectField
        {...selectProps}
        id={id}
        name={name}
        placeholder={placeholder}
        options={options}
        isLoading={loading}
        isDisabled={disabled}
        value={value}
        setValue={setValue}
        register={() => register({ name, required, validate })}
      />

      <ErrorMessage name={name} />
    </FormControl>
  )
}

export default FormSelect
