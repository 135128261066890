import { get, mapValues } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUserByRole, getFieldAgentAreas, getUserById } from 'store/users'
import { emptyArray, emptyObject } from 'utils/defaults'

export function useUser(userId) {
  const user = useSelector((state) => state.users.byId[userId])

  const dispatch = useDispatch()

  useEffect(() => {
    if (!user && userId) {
      dispatch(getUserById(userId))
    }
  }, [dispatch, user, userId])

  return user
}

const OFFLINE_PA_ROLE_ID = 5
const OFFLINE_PA_GROUP_NAME = `byRole:${OFFLINE_PA_ROLE_ID}`

export function useOfflinePAUsers({ fields } = emptyObject) {
  const usersById = useSelector((state) => state.users.byId)
  const userIds = useSelector((state) =>
    get(state.users.groupedIds, OFFLINE_PA_GROUP_NAME, emptyArray)
  )

  const loadingStatus = useSelector((state) =>
    get(state.users.loading, OFFLINE_PA_GROUP_NAME, null)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!['loading', 'loaded'].includes(loadingStatus)) {
      dispatch(getAllUserByRole(OFFLINE_PA_ROLE_ID, { fields }))
    }
  }, [dispatch, fields, loadingStatus])

  const users = useMemo(() => {
    return userIds.reduce(
      (users, id) => {
        users.byId[id] = usersById[id]
        return users
      },
      { byId: {}, allIds: userIds }
    )
  }, [userIds, usersById])

  return users
}

const ONLINE_PA_ROLE_ID = 6
const ONLINE_PA_GROUP_NAME = `byRole:${ONLINE_PA_ROLE_ID}`

export function useOnlinePAUsers({ fields } = emptyObject) {
  const usersById = useSelector((state) => state.users.byId)
  const userIds = useSelector((state) =>
    get(state.users.groupedIds, ONLINE_PA_GROUP_NAME, emptyArray)
  )

  const loadingStatus = useSelector((state) =>
    get(state.users.loading, ONLINE_PA_GROUP_NAME, null)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!['loading', 'loaded'].includes(loadingStatus)) {
      dispatch(getAllUserByRole(ONLINE_PA_ROLE_ID, { fields }))
    }
  }, [dispatch, fields, loadingStatus])

  const users = useMemo(() => {
    return userIds.reduce(
      (users, id) => {
        users.byId[id] = usersById[id]
        return users
      },
      { byId: {}, allIds: userIds }
    )
  }, [userIds, usersById])

  return users
}

export function usePAUsers({ fields } = emptyObject) {
  const offlinePAUsers = useOfflinePAUsers({ fields })
  const onlinePAUsers = useOnlinePAUsers({ fields })

  const users = useMemo(() => {
    const allIds = offlinePAUsers.allIds.concat(onlinePAUsers.allIds)
    const byId = {
      ...mapValues(offlinePAUsers.byId, (user) =>
        Object.assign({ type: 'offline' }, user)
      ),
      ...mapValues(onlinePAUsers.byId, (user) =>
        Object.assign({ type: 'online' }, user)
      ),
    }

    return { byId, allIds }
  }, [
    offlinePAUsers.allIds,
    offlinePAUsers.byId,
    onlinePAUsers.allIds,
    onlinePAUsers.byId,
  ])

  return users
}

const NOBODAR_PA_ROLE_ID = 44
const NOBODAR_PA_GROUP_NAME = `byRole:${NOBODAR_PA_ROLE_ID}`

export function useNobodarPAUsers({ fields } = emptyObject) {
  const usersById = useSelector((state) => state.users.byId)
  const userIds = useSelector((state) =>
    get(state.users.groupedIds, NOBODAR_PA_GROUP_NAME, emptyArray)
  )

  const loadingStatus = useSelector((state) =>
    get(state.users.loading, NOBODAR_PA_GROUP_NAME, null)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!['loading', 'loaded'].includes(loadingStatus)) {
      dispatch(getAllUserByRole(NOBODAR_PA_ROLE_ID, { fields }))
    }
  }, [dispatch, fields, loadingStatus])

  const users = useMemo(() => {
    return userIds.reduce(
      (users, id) => {
        users.byId[id] = usersById[id]
        return users
      },
      { byId: {}, allIds: userIds }
    )
  }, [userIds, usersById])

  return users
}

const FIELD_AGENT_ROLE_ID = 7
const FIELD_AGENT_GROUP_NAME = `byRole:${FIELD_AGENT_ROLE_ID}`

export function useFieldAgentUsers({ fields } = emptyObject) {
  const usersById = useSelector((state) => state.users.byId)
  const userIds = useSelector((state) =>
    get(state.users.groupedIds, FIELD_AGENT_GROUP_NAME, emptyArray)
  )

  const loadingStatus = useSelector((state) =>
    get(state.users.loading, FIELD_AGENT_GROUP_NAME, null)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!['loading', 'loaded'].includes(loadingStatus)) {
      dispatch(getAllUserByRole(FIELD_AGENT_ROLE_ID, { fields }))
    }
  }, [dispatch, fields, loadingStatus])

  const users = useMemo(() => {
    return userIds.reduce(
      (users, id) => {
        users.byId[id] = usersById[id]
        return users
      },
      { byId: {}, allIds: userIds }
    )
  }, [userIds, usersById])

  return users
}

const CALL_AGENT_ROLE_ID = 8
const CALL_AGENT_GROUP_NAME = `byRole:${CALL_AGENT_ROLE_ID}`

export function useCallAgentUsers({ fields } = emptyObject) {
  const usersById = useSelector((state) => state.users.byId)
  const userIds = useSelector((state) =>
    get(state.users.groupedIds, CALL_AGENT_GROUP_NAME, emptyArray)
  )

  const loadingStatus = useSelector((state) =>
    get(state.users.loading, CALL_AGENT_GROUP_NAME, null)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!['loading', 'loaded'].includes(loadingStatus)) {
      dispatch(getAllUserByRole(CALL_AGENT_ROLE_ID, { fields }))
    }
  }, [dispatch, fields, loadingStatus])

  const users = useMemo(() => {
    return userIds.reduce(
      (users, id) => {
        users.byId[id] = usersById[id]
        return users
      },
      { byId: {}, allIds: userIds }
    )
  }, [userIds, usersById])

  return users
}

export function useFieldAgentAreaIds(userId) {
  const areaIds = useSelector((state) =>
    get(state.users.fieldAgentAreasById, userId, emptyArray)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (userId) {
      dispatch(getFieldAgentAreas(userId))
    }
  }, [dispatch, userId])

  return areaIds
}

export function useFieldAgentUserIdsByAreaId(userIds) {
  const areaIdsByUserId = useSelector(
    (state) => state.users.fieldAgentAreasById
  )

  const userIdsByAreaId = useMemo(() => {
    const userIdsByAreaId = {}

    for (const [userId, areaIds] of Object.entries(areaIdsByUserId)) {
      for (const areaId of areaIds) {
        if (userIdsByAreaId[areaId]) {
          userIdsByAreaId[areaId].push(userId)
        } else {
          userIdsByAreaId[areaId] = [userId]
        }
      }
    }

    return userIdsByAreaId
  }, [areaIdsByUserId])

  const dispatch = useDispatch()

  useEffect(() => {
    if (userIds) {
      userIds.forEach((userId) => {
        dispatch(getFieldAgentAreas(userId))
      })
    }
  }, [dispatch, userIds])

  return userIdsByAreaId
}
