import { Box, Grid, Heading, Text, useToast } from '@chakra-ui/core'
import { DataTable, Form, FormButton, FormSelect } from '@eloan/shared'
import { FormDatePicker } from 'components/Form/DatePicker'
import { handleAPIError } from 'components/Form/helpers'
import FormTextarea from 'components/Form/Textarea'
import { omit } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { addNobodarCallLog } from 'store/nobodar'
import { useNobodarApplicationPACallLogs } from 'store/nobodar/hooks'
import { getDate } from 'utils/getDate'
import * as Yup from 'yup'
import { callStatusMapper } from '../../constants'
import { useTable } from 'react-table'

const getColumns = () => [
  {
    Header: 'Date',
    accessor: ({ createdAt }) => getDate(createdAt, DateTime.DATETIME_MED),
  },
  { Header: 'By', accessor: 'pa.fullName' },
  { Header: 'Status', accessor: ({ status }) => callStatusMapper[status] },
  {
    Header: 'Message',
    accessor: ({ note }) => (
      <Text wordBreak="break-word" maxW="400px">
        {note}
      </Text>
    ),
  },
]

function LogForm({ applicationId, callStatusOptions }) {
  const validationSchema = useMemo(
    () =>
      Yup.object({
        status: Yup.string().required(`Required.`),
        note: Yup.string().optional(),
        followupDate: Yup.string().when('status', {
          is: (value) => value === 'need_followup',
          then: Yup.string().required(`Required.`),
          otherwise: Yup.string().notRequired(),
        }),
      }),
    []
  )

  const form = useForm({ validationSchema })

  const dispatch = useDispatch()
  const toast = useToast()

  const onSubmit = useCallback(
    async ({ status, note, followupDate }) => {
      try {
        await dispatch(
          addNobodarCallLog({ status, note, followupDate, applicationId })
        )
        form.reset()
      } catch (error) {
        handleAPIError(error, { form, toast })
      }
    },
    [dispatch, form, toast, applicationId]
  )

  const status = form.watch('status')

  return (
    <>
      <Heading size="md" mb={4}>
        Write your notes here
      </Heading>
      <Form form={form} onSubmit={onSubmit}>
        <FormSelect
          name="status"
          label="Call status"
          options={omit(callStatusOptions, 'pending')}
        />
        <FormTextarea mt={6} name="note" placeholder="Type message here" />

        {status === 'need_followup' && (
          <Box my={2}>
            <FormDatePicker
              name="followupDate"
              minDate={new Date()}
              label="Follow-up Date"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
            />
          </Box>
        )}
        <FormButton type="submit" mt={2} variantColor="blue">
          Save
        </FormButton>
      </Form>
    </>
  )
}

function CallLog({ applicationId }) {
  const paCallLogs = useNobodarApplicationPACallLogs(applicationId)

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => {
    return paCallLogs.allIds.map((id) => paCallLogs.byId[id])
  }, [paCallLogs.allIds, paCallLogs.byId])

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <Grid templateColumns="1fr 2fr" gridColumnGap={10}>
      <Box>
        <LogForm
          applicationId={applicationId}
          callStatusOptions={callStatusMapper}
        />
      </Box>
      <Box>
        <Heading size="md" mb={4}>
          See your notes
        </Heading>
        <DataTable {...table} />
      </Box>
    </Grid>
  )
}

export default CallLog
