import React from 'react'
import {
  Box,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/core'

function CustomAccordionItem({ title, children, ...props }) {
  return (
    <AccordionItem {...props}>
      <AccordionHeader bg="gray.50" borderRadius="4px">
        <Box flex="1" textAlign="left" fontSize="lg" fontWeight={600}>
          {title}
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4}>{children}</AccordionPanel>
    </AccordionItem>
  )
}

export default CustomAccordionItem
