import { useMemo } from 'react'

function useIframeParentHostname() {
  return useMemo(() => {
    const url = new URL(document.referrer)
    return url.hostname
  }, [])
}

export default useIframeParentHostname
