import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { generateShwanirbhorApplication } from 'store/applications'
import * as Yup from 'yup'
import FormButton from 'components/Form/Button'
import Authorize from 'components/Authorize'

const getDefaultValues = () => ({
  shopId: '',
})

const getValidationSchema = () =>
  Yup.object({
    shopId: Yup.number()
      .integer(`invalid shopId`)
      .required(`required`)
      .typeError(`invalid shopId`),
  })

function GenerateShwanirbhorApplication({ ...props }) {
  const toast = useToast()
  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ shopId }) => {
      try {
        await dispatch(generateShwanirbhorApplication({ shopId }))
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, toast, onClose]
  )

  return (
    <Authorize permissions="eloan:ShwanirbhorApplication:create">
      <Box {...props}>
        <Button variantColor="green" onClick={onOpen}>
          Generate
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader>Generate Shwanirbhor eLoan Application</ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <FormInput name="shopId" label={`Shop ID`} />
              </ModalBody>

              <ModalFooter>
                <Button type="button" mr={3} onClick={onClose}>
                  Close
                </Button>
                <FormButton type="submit" mr={3} variantColor="green">
                  Generate
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default GenerateShwanirbhorApplication
