import { keyBy, map, union } from 'lodash-es'
import { PARTNER_LOADING_ALL, PARTNER_SET_ALL } from 'store/partners'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: false,
}

const partnersReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case PARTNER_LOADING_ALL:
      return {
        ...state,
        loading: true,
      }
    case PARTNER_SET_ALL:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        loading: false,
      }
    default:
      return state
  }
}

export default partnersReducer
