import React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
import UnileverOverdueApplications from './unilever'

function OverdueApplicationsPage() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route
        path={`${path}/unilever`}
        component={UnileverOverdueApplications}
      />
      <Redirect to={`${path}/unilever`} />
    </Switch>
  )
}

export default OverdueApplicationsPage
