import React, { useMemo, useCallback, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  Stack,
  Text,
  Badge,
  Box,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { get } from 'lodash-es'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormButton from 'components/Form/Button'
import FormSelect from 'components/Form/Select'
import { offerStatusDisplayText } from 'utils/offer-status'
import FormTextarea from 'components/Form/Textarea'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { handleAPIError } from 'components/Form/helpers'
import { updateOfferStatus } from 'store/applications'

function mapOptionsBasedOnOfferStatus(status) {
  const options = []

  switch (status) {
    case 'offer_negotiating':
      options.push('loanee_agreement_pending')
      break
    case 'loanee_agreement_pending':
      options.push('loanee_agreement_signed', 'offer_negotiating')
      break
    case 'loanee_agreement_signed':
      options.push(
        'shopup-approved',
        'shopup-rejected',
        'loanee_agreement_pending'
      )
      break
    case 'financed':
      options.push('active')
      break
    default:
      break
  }

  return options.reduce(
    (obj, curr) => ({ ...obj, [curr]: offerStatusDisplayText[curr] }),
    {}
  )
}

function ChangeOfferStatus({ application, ...props }) {
  const validationSchema = useMemo(
    () =>
      Yup.object({
        status: Yup.string().required('Required.'),
        note: Yup.string()
          .min(10, 'Minimum 10 characters')
          .required('Required.'),
      }),
    []
  )

  const form = useForm({ validationSchema })

  const { reset } = form

  const statusOptions = useMemo(() => {
    return mapOptionsBasedOnOfferStatus(get(application, 'offer.status'))
  }, [application])

  const { isOpen, onClose, onOpen } = useDisclosure()

  const toast = useToast()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const onSubmit = useCallback(
    async ({ status, note }) => {
      try {
        await dispatch(
          updateOfferStatus(get(application, 'id'), { status, note })
        )
        onClose()
      } catch (error) {
        handleAPIError(error, { toast, form })
      }
    },
    [toast, dispatch, form, onClose, application]
  )

  return (
    <Authorize permissions="eloan:AnyApplication:update, eloan:Application:update">
      <Box {...props}>
        <Button variantColor="teal" px={10} onClick={onOpen}>
          Change Offer Status
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent p={10}>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader>Change Offer Status</ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <Text fontSize="xl">Current Offer Status</Text>
                <Badge variant="solid">
                  {offerStatusDisplayText[get(application, 'offer.status')]}
                </Badge>
                <Stack mt={6} spacing={6}>
                  <Box>
                    <FormSelect
                      name="status"
                      options={statusOptions}
                      label={<Text color="gray.500">Status</Text>}
                    />
                  </Box>
                  <Box>
                    <FormTextarea
                      name="note"
                      label={<Text color="gray.500">Note</Text>}
                    />
                  </Box>
                </Stack>
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button
                  px={10}
                  type="button"
                  variantColor="red"
                  onClick={onClose}
                >
                  CANCEL
                </Button>
                <FormButton px={10} ml={4} type="submit" variantColor="cyan">
                  CONFIRM
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default ChangeOfferStatus
