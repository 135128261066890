import { DateTime } from 'luxon'
import { toDate } from './cast'
import { emptyObject } from './defaults'

const toSQLDateOptions = {
  includeOffset: false,
}

const valueListRegex = /^\{.+\}$/

const getDefaultType = (value) => {
  return valueListRegex.test(value) ? '~=' : '=='
}

export function getFilterString(values = emptyObject, types = emptyObject) {
  const filters = []

  const fields = Object.keys(values).filter((field) => values[field])

  for (const field of fields) {
    switch (types[field]) {
      case 'date': {
        const date = DateTime.fromJSDate(toDate(values[field]), {
          zone: 'utc',
        })

        const startSqlDate = date.toSQL(toSQLDateOptions)
        const endSqlDate = date.plus({ day: 1 }).toSQL(toSQLDateOptions)

        filters.push(`${field}>=${startSqlDate}`, `${field}<${endSqlDate}`)

        break
      }

      case 'date-range': {
        const start = toDate(values[field].start)
        const end = toDate(values[field].end)

        if (!start && !end) {
          break
        }

        const startDate = DateTime.fromJSDate(start || end, {
          zone: 'utc',
        })
        const endDate = DateTime.fromJSDate(end || start, {
          zone: 'utc',
        })

        const startSqlDate = startDate.toSQL(toSQLDateOptions)
        const endSqlDate = endDate.plus({ day: 1 }).toSQL(toSQLDateOptions)

        filters.push(`${field}>=${startSqlDate}`, `${field}<${endSqlDate}`)

        break
      }

      default:
        if (typeof types[field] === 'function') {
          filters.push(`${field}${types[field](values[field])}`)
        } else {
          filters.push(
            `${field}${types[field] || getDefaultType(values[field])}${
              values[field]
            }`
          )
        }
        break
    }
  }

  return filters.length ? filters.join(';') : undefined
}
