import { get, keyBy, map, union } from 'lodash-es'
import {
  REDX_SHOP_OFFER_ADD,
  REDX_SHOP_OFFER_ADD_BULK,
  REDX_SHOP_OFFER_UPDATE,
  REDX_SHOP_OFFER_STATUS_UPDATE,
} from 'store/redx-shop-offers'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
}

const redxShopOfferReducer = (state = initialState, { type, data, id }) => {
  switch (type) {
    case REDX_SHOP_OFFER_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [id]),
      }

    case REDX_SHOP_OFFER_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
      }

    case REDX_SHOP_OFFER_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: {
            ...get(state.byId, data.id),
            ...data,
          },
        },
      }
    case REDX_SHOP_OFFER_STATUS_UPDATE:
      return { ...state }
    default:
      return state
  }
}

export default redxShopOfferReducer
