import React, { memo } from 'react'
import { Box } from '@chakra-ui/core'
import { useApplication } from 'store/applications/hooks'
import LongTermLoanForm from './LongTermLoanForm'
import ShortTermLoanForm from './ShortTermLoanForm'
import BankTransactionForm from './BankTransactionForm'
import FIExperienceForm from './FIExperienceForm'

const fieldName = {
  longTermLoanData: 'longTermLoanData',
  shortTermLoanData: 'shortTermLoanData',
  bankTransactionData: 'bankTransactionData',
  fiExperienceData: 'fiExperienceData',
}

const metaFields = [
  `meta.${fieldName.longTermLoanData}`,
  `meta.${fieldName.shortTermLoanData}`,
  `meta.${fieldName.bankTransactionData}`,
  `meta.${fieldName.fiExperienceData}`,
].join(',')

function ExperienceRCA({ applicationId, ...props }) {
  const application = useApplication(applicationId, metaFields)

  return (
    <Box {...props}>
      <LongTermLoanForm
        application={application}
        fieldName={fieldName.longTermLoanData}
      />
      <ShortTermLoanForm
        application={application}
        fieldName={fieldName.shortTermLoanData}
      />
      <BankTransactionForm
        application={application}
        fieldName={fieldName.bankTransactionData}
      />
      <FIExperienceForm
        application={application}
        fieldName={fieldName.fiExperienceData}
      />
    </Box>
  )
}

export default memo(ExperienceRCA)
