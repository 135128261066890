import React from 'react'
import { Text, Flex, Box, Stack } from '@chakra-ui/core'
import { get } from 'lodash-es'
import Configuration from './Configuration'
import InfoBox from 'components/Box/InfoBox'

export function LoanProductFiClientConfig({
  fiConfigType,
  clientConfigType,
  fiConfig,
  clientConfig,
  ...props
}) {
  return (
    <Box mb={4} borderWidth="1px" borderColor="gray.100" p={4} {...props}>
      <Stack isInline spacing={10} mb={2}>
        <InfoBox label="FI Loan Config Type">{fiConfigType}</InfoBox>
        <InfoBox label="Client Loan Config Type">{clientConfigType}</InfoBox>
      </Stack>
      <Flex
        justify="space-between"
        mb={4}
        bg="gray.50"
        py={2}
        px={4}
        borderWidth="1px "
        borderColor="gray.100"
      >
        <ConfigItem serial="FI" config={fiConfig} mr={10} />
        <Configuration title="FI Configuration" config={fiConfig} />
      </Flex>
      <Flex
        justify="space-between"
        mb={4}
        bg="gray.50"
        py={2}
        px={4}
        borderWidth="1px "
        borderColor="gray.100"
      >
        <ConfigItem serial="ShopUp" config={clientConfig} mr={10} />
        <Configuration title="ShopUp Configuration" config={clientConfig} />
      </Flex>
    </Box>
  )
}

function ConfigItem({ serial, config, ...props }) {
  return (
    <Flex align="center" {...props}>
      <Text>{serial} - Config</Text>
      <Text fontSize="sm" color="gray.500" ml={4}>
        {get(config, 'minTenure')} - {get(config, 'maxTenure')}{' '}
        {get(config, 'tenureUnit')} -{' '}
        {(get(config, 'interestRate') * 100).toFixed(2)}% interest rate
      </Text>
    </Flex>
  )
}

export default ConfigItem
