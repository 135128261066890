import { Flex, Heading, Tag, Tooltip } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { memo } from 'react'
import { Box } from 'reflexbox'

const relationshipMap = {
  'PARENT-MALE': 'Father',
  'PARENT-FEMALE': 'Mother',
  'SPOUSE-MALE': 'Husband',
  'SPOUSE-FEMALE': 'Wife',
  'OFFSPRING-MALE': 'Son',
  'OFFSPRING-FEMALE': 'Daughter',
  'SIBLING-MALE': 'Brother',
  'SIBLING-FEMALE': 'Sister',
  OTHER: 'Other',
}

const GuarantorAgreedAt = memo(({ guarantorAgreedAt }) => {
  return (
    <Tag cursor="pointer">
      <Tooltip
        hasArrow
        placement="top"
        label={
          guarantorAgreedAt
            ? DateTime.fromISO(guarantorAgreedAt).toLocaleString(
                DateTime.DATETIME_MED
              )
            : 'N/A'
        }
      >
        {guarantorAgreedAt ? 'Agreed' : 'Pending'}
      </Tooltip>
    </Tag>
  )
})

function GuarantorInfo({ application, ...props }) {
  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Guarantor Info
      </Heading>

      <Flex flexDirection="row" flexWrap="wrap">
        <InfoBox
          label="Name"
          content={get(application, 'meta.guarantorName')}
        />
        <InfoBox
          label="Phone Number"
          content={get(application, 'meta.guarantorPhoneNumber')}
        />
        <InfoBox
          label="Relationship"
          content={
            relationshipMap[get(application, 'meta.guarantorRelationship')] ||
            'N/A'
          }
        />
        <InfoBox label="Agreement">
          <GuarantorAgreedAt
            guarantorAgreedAt={get(application, 'meta.guarantorAgreedAt')}
          />
        </InfoBox>
      </Flex>
    </Box>
  )
}

export default memo(GuarantorInfo)
