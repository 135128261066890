import { Box, Stack, Text } from '@chakra-ui/core'
import React, { useCallback, useMemo } from 'react'
import Form from 'components/Form/Form'
import FormButton from 'components/Form/Button'
import { useForm } from 'react-hook-form'
import { FormSelect } from '@eloan/shared'

const thresholds = Array(6)
  .fill(0)
  .map((_, index) => index * 1000)

function CloseLoanFilter({ onSubmit }) {
  const defaultValues = useMemo(
    () => ({
      threshold: 1000,
    }),
    []
  )

  const thresholdOptions = useMemo(
    () =>
      thresholds.reduce(
        (obj, curr) => ({
          ...obj,
          [curr]: curr === 0 ? 'Less Than or Equal To 0' : `Less Than ${curr}`,
        }),
        {}
      ),
    []
  )

  const form = useForm({ defaultValues })

  const onFormSubmit = useCallback(
    ({ threshold }) => {
      const filterObject = {}

      filterObject.threshold = threshold

      onSubmit({ ...filterObject })
    },
    [onSubmit]
  )

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack isInline spacing={4} marginBottom={6} align="flex-end">
        <Box minW="200px">
          <FormSelect
            label={
              <Text fontSize="xs" color="gray.500">
                Select Due Range
              </Text>
            }
            name="threshold"
            options={thresholdOptions}
          />
        </Box>
        <Box>
          <FormButton type="submit" minWidth="6rem" variantColor="cyan">
            Filter
          </FormButton>
        </Box>
      </Stack>
    </Form>
  )
}

export default CloseLoanFilter
