import {
  Box,
  Stack,
  ButtonGroup,
  IconButton,
  Flex,
  Text,
} from '@chakra-ui/core'
import React, { useCallback, useMemo } from 'react'
import Form from 'components/Form/Form'
import FormButton from 'components/Form/Button'
import { useForm } from 'react-hook-form'
import FormInput from 'components/Form/Input'
import { get, zipObject, pickBy } from 'lodash-es'
import FormSelect from 'components/Form/Select'

function RepaymentFollowupFieldTeamFilter({
  agents,
  areas,
  isFieldAgent,
  onSubmit,
}) {
  const form = useForm()

  const agentOptions = useMemo(
    () =>
      zipObject(
        agents.allIds,
        agents.allIds.map((id) => get(agents.byId[id], 'name'))
      ),
    [agents]
  )

  const areaOptions = useMemo(
    () =>
      zipObject(
        areas.allIds,
        areas.allIds.map((id) => get(areas.byId[id], 'name'))
      ),
    [areas]
  )

  const onFormSubmit = useCallback(
    ({ eloanId, areaId, agentId, minDpd, maxDpd }) => {
      const filterObject = {
        eloanId,
        areaId,
        agentId,
        minDpd: minDpd || maxDpd,
        maxDpd: maxDpd || minDpd,
      }

      onSubmit(pickBy(filterObject))
    },
    [onSubmit]
  )

  const formReset = form.reset

  const onReset = useCallback(() => {
    formReset()
    onSubmit({})
  }, [formReset, onSubmit])

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack isInline spacing={4} marginBottom={6} align="flex-end">
        <Box>
          <FormInput
            label={
              <Text color="gray.700" fontSize="14px">
                Search
              </Text>
            }
            name="eloanId"
            placeholder="eLoan ID"
          />
        </Box>
        <Box minW="150px">
          <FormSelect
            name="areaId"
            label={<Text color="gray.700">Area</Text>}
            options={areaOptions}
          />
        </Box>
        {!isFieldAgent && (
          <Box minW="150px">
            <FormSelect
              name="agentId"
              label={<Text color="gray.700">Field Agent</Text>}
              options={agentOptions}
            />
          </Box>
        )}
        <Box>
          <Text color="gray.700" mb={1}>
            Select DPD Range
          </Text>
          <Flex>
            <FormInput placeholder="DPD Min" name="minDpd" />
            <FormInput placeholder="DPD Max" name="maxDpd" />
          </Flex>
        </Box>
        <Box>
          <ButtonGroup spacing={2}>
            <IconButton type="button" icon="small-close" onClick={onReset} />
            <FormButton type="submit" variantColor="cyan">
              SEARCH
            </FormButton>
          </ButtonGroup>
        </Box>
      </Stack>
    </Form>
  )
}

export default RepaymentFollowupFieldTeamFilter
