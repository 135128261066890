import {
  Button,
  Heading,
  Icon,
  Image,
  InputLeftElement,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { Global } from '@emotion/core'
import { api } from 'api'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import FormTextarea from 'components/Form/Textarea'
import useIframeParentHostname from 'hooks/useIframeParentHostname'
import { useQuery } from 'hooks/useQuery'
import { zipObject } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Flex } from 'reflexbox'
import * as Yup from 'yup'
import bgImage from './bg.png'
import crossImage from './cross.svg'
import logo from './logo.svg'
import tickImage from './tick.svg'

const getDefaultValues = () => ({
  phoneNumber: '',
  fullName: '',
  businessName: '',
  address: '',
  loanAmount: '',
  loanTenure: '',
})

const getValidationSchema = () => {
  return Yup.object({
    phoneNumber: Yup.string()
      .matches(/^01\d{9}$/, 'expected format: 01XXXXXXXXX')
      .required(`required`),
    fullName: Yup.string().required(`required`),
    businessName: Yup.string().required(`required`),
    address: Yup.string().required(`required`),
    loanAmount: Yup.number().integer().required(`required`),
    loanTenure: Yup.number().integer().min(4).max(12).required(`required`),
  })
}

const validLoanTenure = [4, 5, 6, 7, 8, 9, 10, 11, 12]
const loanTenureBangla = {
  4: '৪',
  5: '৫',
  6: '৬',
  7: '৭',
  8: '৮',
  9: '৯',
  10: '১০',
  11: '১১',
  12: '১২',
}
const loanTenureOptions = zipObject(
  validLoanTenure,
  validLoanTenure.map((tenure) => `${loanTenureBangla[tenure]} মাস`)
)

const Label = (props) => (
  <Text as="span" color="accent" fontWeight="bold" {...props} />
)

function RestrictionScreen({
  isAllowedDomain,
  affiliatePartnerId,
  leadUserId,
}) {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
    >
      {!isAllowedDomain && (
        <Box>
          <Text color="red.500" fontWeight="bold">
            Domain not whitelisted!
          </Text>
        </Box>
      )}
      {!affiliatePartnerId && (
        <Box>
          <Text color="red.500" fontWeight="bold">
            Missing Affiliate Partner ID
          </Text>
        </Box>
      )}
      {!leadUserId && (
        <Box>
          <Text color="red.500" fontWeight="bold">
            Missing Platform User ID
          </Text>
        </Box>
      )}
    </Stack>
  )
}

function CoverScreen({ onOpen }) {
  return (
    <Box
      height="100vh"
      width="100%"
      p={4}
      sx={{
        position: 'relative',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Image
        src={logo}
        alt="ShopUp eLoan Logo"
        width={24}
        position="absolute"
        right={4}
      />

      <Box
        width="100%"
        textAlign="center"
        mx={-4}
        sx={{ position: 'absolute', bottom: 4 }}
      >
        <Button
          onClick={onOpen}
          bg="secondary"
          borderRadius="full"
          height={12}
          px={12}
        >
          APPLY NOW
        </Button>
      </Box>
    </Box>
  )
}

function SuccessScreen() {
  return (
    <Stack textAlign="center" color="accent" spacing={4}>
      <Image src={tickImage} size="100px" mx="auto" />

      <Heading mt={6}>অভিনন্দন</Heading>

      <Text fontSize={2}>
        আপনার এপ্লিকেশন আমরা পেয়েছি। অনুগ্রহ করে অপেক্ষা করুন, আপনার সাথে আমরা
        যোগাযোগ করবো।
      </Text>
    </Stack>
  )
}

function FailedScreen({ onRetry }) {
  return (
    <Stack textAlign="center" color="accent" spacing={4}>
      <Image src={crossImage} size="100px" mx="auto" />

      <Heading mt={6}>দুঃখিত</Heading>

      <Text fontSize={2}>
        সফলভাবে আপনার তথ্য গুলো পেতে ব্যর্থ হয়েছি। অনুগ্রহ করে আবার চেষ্টা
        করুন।
      </Text>

      <Button
        color="initial"
        onClick={onRetry}
        bg="secondary"
        borderRadius="full"
        height={12}
        px={12}
      >
        RETRY
      </Button>
    </Stack>
  )
}

function B2BForm() {
  const query = useQuery()
  const toast = useToast()

  const { cover, height, affiliatePartnerId, leadUserId } = useMemo(() => {
    const c = query.get('c')
    const cover = !['0', 'false'].includes(c)

    const h = query.get('h')
    const height = h ? `${h}px` : '100vh'

    const pid = query.get('pid')
    const uid = query.get('uid')

    return {
      cover,
      height,
      affiliatePartnerId: pid,
      leadUserId: uid,
    }
  }, [query])

  const { isOpen, onOpen } = useDisclosure(!cover)

  const affiliatePartnerDomain = useIframeParentHostname()

  const [isAllowedDomain, setIsAllowedDomain] = useState(true)

  useEffect(() => {
    api(
      `POST /legacy/v4/eloan/affiliate/partners/{affiliatePartnerId}/actions/check-domain`,
      {
        headers: { 'x-affiliate-partner-domain': affiliatePartnerDomain },
        affiliatePartnerId,
      }
    ).then(({ data }) => {
      setIsAllowedDomain(data.allowed)
    })
  }, [affiliatePartnerId, affiliatePartnerDomain])

  const [isSuccessful, setIsSuccessful] = useState(null)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const onSubmit = useCallback(
    async ({
      phoneNumber,
      fullName,
      businessName,
      address,
      loanAmount,
      loanTenure,
    }) => {
      try {
        await api(
          `POST /legacy/v4/eloan/affiliate/partners/:affiliatePartnerId/leads`,
          {
            affiliatePartnerDomain,
            affiliatePartnerId,
            leadUserId,
            phoneNumber,
            fullName,
            businessName,
            address,
            loanAmount,
            loanTenure,
          }
        )

        setIsSuccessful(true)
      } catch (err) {
        if (!err.error) {
          setIsSuccessful(false)
          return
        }

        const { error } = err

        if (error.errors && form) {
          error.errors.forEach(({ location, message }) => {
            form.setError(location, 'FORM', message)
          })
        }

        if (error.message) {
          setIsSuccessful(false)
          toast({
            title: error.message,
            status: 'error',
            duration: 2500,
            isClosable: true,
          })
        }
      }
    },
    [affiliatePartnerDomain, affiliatePartnerId, form, leadUserId, toast]
  )

  if (!isAllowedDomain || !affiliatePartnerId || !leadUserId) {
    return (
      <RestrictionScreen
        isAllowedDomain={isAllowedDomain}
        affiliatePartnerId={affiliatePartnerId}
        leadUserId={leadUserId}
      />
    )
  }

  if (!isOpen) {
    return <CoverScreen onOpen={onOpen} />
  }

  return (
    <Stack overflowY="auto" height={height}>
      <Global
        styles={({ colors }) => ({
          label: {
            fontWeight: 'bold',
            color: colors.accent,
          },
        })}
      />

      <Flex justifyContent="center" py={4} bg="accent">
        <Image src={logo} alt="ShopUp eLoan Logo" width={24} />
      </Flex>

      <Box p={4} maxWidth="32rem" width="100%" mx="auto">
        {isSuccessful === true ? (
          <SuccessScreen />
        ) : isSuccessful === false ? (
          <FailedScreen onRetry={() => setIsSuccessful(null)} />
        ) : (
          <Form form={form} onSubmit={onSubmit}>
            <Stack spacing={4}>
              <FormInput
                name="phoneNumber"
                label={<Label>ফোন নাম্বার</Label>}
                placeholder={`আপনার ফোন নাম্বার`}
                InputLeft={
                  <InputLeftElement>
                    <Icon name="phone" color="accent" opacity="60%" />
                  </InputLeftElement>
                }
              />

              <FormInput
                name="fullName"
                label={<Label>নাম</Label>}
                placeholder={`আপনার নাম`}
                InputLeft={
                  <InputLeftElement>
                    <Icon name="user" color="accent" opacity="60%" />
                  </InputLeftElement>
                }
              />

              <FormInput
                name="businessName"
                label={<Label>ব্যবসার নাম</Label>}
                placeholder={`আপনার ব্যবসার নাম`}
                InputLeft={
                  <InputLeftElement>
                    <Icon name="shop" color="accent" opacity="60%" />
                  </InputLeftElement>
                }
              />

              <FormInput
                name="loanAmount"
                label={<Label>লোনের পরিমাণ</Label>}
                placeholder={`কত টাকা লোন প্রয়োজন?`}
                InputLeft={
                  <InputLeftElement>
                    <Icon name="taka" color="accent" opacity="60%" />
                  </InputLeftElement>
                }
              />

              <FormSelect
                name="loanTenure"
                label={<Label>লোনের মেয়াদ</Label>}
                placeholder={`কতদিনের জন্য লোন নিতে চান?`}
                options={loanTenureOptions}
              />

              <FormTextarea
                name="address"
                label={<Label>ঠিকানা</Label>}
                placeholder={`আপনার ঠিকানা`}
              />
            </Stack>

            <Box my={6}>
              <FormButton
                type="submit"
                bg="secondary"
                width="100%"
                height={12}
                borderRadius="full"
              >
                SUBMIT
              </FormButton>
            </Box>
          </Form>
        )}
      </Box>
    </Stack>
  )
}

export default B2BForm
