export const BUSINESS_MEDIUM_LOADING_ALL = 'BUSINESS_MEDIUM_LOADING_ALL'
export const BUSINESS_MEDIUM_SET_ALL = 'BUSINESS_MEDIUM_SET_ALL'

export const getAllBusinessMediums = () => async (
  dispatch,
  getState,
  { api }
) => {
  const { businessMediums } = getState()

  if (!businessMediums.loading) {
    dispatch({ type: BUSINESS_MEDIUM_LOADING_ALL })

    const { data } = await api(`/legacy/v3/shops/meta/business-mediums`)

    dispatch({ type: BUSINESS_MEDIUM_SET_ALL, data })
  }
}
