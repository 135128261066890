import { get, keyBy, map, union } from 'lodash-es'
import {
  USER_ADD,
  USER_ADD_BULK,
  USER_FIELD_AGENT_AREAS_SET,
  USER_LOADING_DONE,
  USER_LOADING_INIT,
} from 'store/users'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  groupedIds: emptyObject,
  loading: emptyObject,
  fieldAgentAreasById: emptyObject,
}

const usersReducer = (
  state = initialState,
  { type, data, userId, groupName }
) => {
  switch (type) {
    case USER_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [userId]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [userId]),
      }
    case USER_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        groupedIds: {
          ...state.groupedIds,
          [groupName]: union(
            get(state.groupedIds, groupName, emptyArray),
            map(data.items, idKey)
          ),
        },
      }
    case USER_LOADING_INIT:
      return {
        ...state,
        loading: {
          ...state.loading,
          [groupName]: 'loading',
        },
      }
    case USER_LOADING_DONE:
      return {
        ...state,
        loading: {
          ...state.loading,
          [groupName]: 'loaded',
        },
      }
    case USER_FIELD_AGENT_AREAS_SET:
      return {
        ...state,
        fieldAgentAreasById: {
          ...state.fieldAgentAreasById,
          [userId]: map(data.items, 'id'),
        },
      }
    default:
      return state
  }
}

export default usersReducer
