import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

function PrivateRoute({ component: Component, ...rest }) {
  const authed = useSelector((state) => state.user.status.authed)

  return (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: authed === null ? props.location : undefined },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
