import React from 'react'
import { Box, Heading, List, ListItem, Icon } from '@chakra-ui/core'

function InfoMessage() {
  return (
    <Box bg="gray.200" p={4} mt={10} borderRadius={5}>
      <Heading size="sm" mb={2}>
        How it works?
      </Heading>
      <List spacing={3} styleType="disc">
        <ListItem>Add loans to apply the created configuration.</ListItem>
        <ListItem>
          Once implemented you can edit the configuration by clicking ‘Edit
          Config’ on configuration details.
        </ListItem>
        <ListItem>
          Once the configuration period is over the loan will automatically
          resume its normal behaviour.
        </ListItem>
        <ListItem>Add loans to apply the created configuration.</ListItem>
        <ListItem>
          Click on the tooltip <Icon name="info" /> to know more.
        </ListItem>
      </List>
    </Box>
  )
}

export default InfoMessage
