export const DOC_META_LOADING_ALL = 'DOC_META_LOADING_ALL'
export const DOC_META_SET_ALL = 'DOC_META_SET_ALL'

export const getAllDocMeta = () => async (dispatch, getState, { api }) => {
  const { docMeta } = getState()

  if (!docMeta.loading) {
    dispatch({ type: DOC_META_LOADING_ALL })

    const { data } = await api(`/legacy/v4/eloan/docs/meta`)

    dispatch({
      type: DOC_META_SET_ALL,
      data,
    })
  }
}
