import React, { memo } from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Box,
  Heading,
  SimpleGrid,
  Button,
  useDisclosure,
} from '@chakra-ui/core'
import { formOptions } from 'pages/admin/constants/options'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'

function ConfigurationView({ config }) {
  return (
    <Box>
      <Box mb={6} borderBottomWidth="1px" borderBottomColor="gray.200" pb={3}>
        <Heading size="md" mb={4} color="gray.500">
          Configuration
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <InfoBox label="Select tenure unit">
            {get(formOptions, `dayUnits.${get(config, 'tenureUnit')}`)}
          </InfoBox>
          <InfoBox label="User tenure duration">
            {get(config, 'minTenure')}
          </InfoBox>
          <InfoBox label="FI tenure duration">
            {get(config, 'maxTenure')}
          </InfoBox>
        </SimpleGrid>
      </Box>

      <SimpleGrid
        columns={3}
        gridGap={4}
        mb={6}
        borderBottomWidth="1px"
        borderBottomColor="gray.200"
        pb={3}
      >
        <InfoBox label="Installment period unit">
          {get(formOptions, `dayUnits.${get(config, 'installmentPeriodUnit')}`)}
        </InfoBox>
        <InfoBox label="Enter installment period">
          {get(config, 'installmentPeriod')}
        </InfoBox>
      </SimpleGrid>

      <Box mb={6} borderBottomWidth="1px" borderBottomColor="gray.200" pb={3}>
        <Heading size="sm" mb={4} color="gray.500">
          Interest settings
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <InfoBox label="Interest calculation method">
            {get(
              formOptions,
              `interestCalculationMethod.${get(
                config,
                'interestCalculationMethod'
              )}`
            )}
          </InfoBox>
          <InfoBox label="Interest time period count criteria">
            {get(
              formOptions,
              `interestTimePeriodCountCriteria.${get(
                config,
                'interestTimePeriodCountCriteria'
              )}`
            )}
          </InfoBox>
          <InfoBox label="Interest type">
            {get(formOptions, `interestType.${get(config, 'interestType')}`)}
          </InfoBox>
          <InfoBox label="Days in Year">
            {get(formOptions, `daysInYear.${get(config, 'daysInYear')}`)}
          </InfoBox>
          <InfoBox label="Interest Rate">
            {(get(config, 'interestRate') * 100).toFixed(2)}
          </InfoBox>
          <InfoBox label="Interest Charge Frequency">
            {get(
              formOptions,
              `dayUnits.${get(config, 'interestChargeFrequency')}`
            )}
          </InfoBox>
        </SimpleGrid>
      </Box>

      <Box mb={6} borderBottomWidth="1px" borderBottomColor="gray.200" pb={3}>
        <Heading size="sm" mb={4} color="gray.500">
          Should partial payment affect running EMI
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <InfoBox>
            {
              formOptions.booleanOptions[
                get(config, 'shouldPartialPaymentAffectRunningEmi')
              ]
            }
          </InfoBox>
        </SimpleGrid>
      </Box>

      <Box mb={6} borderBottomWidth="1px" borderBottomColor="gray.200" pb={3}>
        <Heading size="sm" mb={4} color="gray.500">
          Repayment strategy (select order of strategy)
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          {get(config, 'repaymentStrategy', []).map((item, index) => (
            <InfoBox key={index} label={`Repayment Stargey ${index + 1}`}>
              {get(formOptions, `repaymentStrategy.${item}`)}
            </InfoBox>
          ))}
        </SimpleGrid>
      </Box>

      <Box mb={6} borderBottomWidth="1px" borderBottomColor="gray.200" pb={3}>
        <Heading size="sm" mb={4} color="gray.500">
          Penalty and tolerance
        </Heading>
        <SimpleGrid columns={3} gridGap={4}>
          <InfoBox label="Penalty calculation method">
            {get(
              formOptions,
              `penaltyCalculationMethod.${get(
                config,
                'penaltyCalculationMethod'
              )}`
            )}
          </InfoBox>
          <InfoBox label="Penalty interest type">
            {get(
              formOptions,
              `interestType.${get(config, 'penaltyInterestType')}`
            )}
          </InfoBox>
          <InfoBox label="Penalty interest rate">
            {(get(config, 'penaltyInterestRate') * 100).toFixed(2)}
          </InfoBox>
          <InfoBox label="Penalty flat fee">
            {get(config, 'penaltyFlatFee')}
          </InfoBox>
          <InfoBox label="Penalty interest charge frequency">
            {get(
              formOptions,
              `dayUnits.${get(config, 'penaltyInterestChargeFrequency')}`
            )}
          </InfoBox>
          <InfoBox label="Penalty grace period unit">
            {get(
              formOptions,
              `dayUnits.${get(config, 'penaltyGracePeriodUnit')}`
            )}
          </InfoBox>
          <InfoBox label="Penalty grace period">
            {get(config, 'penaltyGracePeriod')}
          </InfoBox>
          <InfoBox label="Tolerance amount type">
            {get(
              formOptions,
              `toleranceAmountType.${get(config, 'penaltyToleranceType')}`
            )}
          </InfoBox>
          <InfoBox label="Tolerance amount value">
            {get(config, 'penaltyToleranceAmount')}
          </InfoBox>
        </SimpleGrid>
      </Box>

      <SimpleGrid
        columns={3}
        gridGap={4}
        mb={6}
        borderBottomWidth="1px"
        borderBottomColor="gray.200"
        pb={3}
      >
        <InfoBox label="Min period before account settlement unit">
          {get(
            formOptions,
            `dayUnits.${get(config, 'minPeriodBeforeAccountSettlementUnit')}`
          )}
        </InfoBox>
        <InfoBox label="Min period before account settlement">
          {get(config, 'minPeriodBeforeAccountSettlement')}
        </InfoBox>
        <InfoBox label="Repayment due date offset unit">
          {get(
            formOptions,
            `dayUnits.${get(config, 'repaymentDueDateOffsetUnit')}`
          )}
        </InfoBox>
        <InfoBox label="Repayment due date offset">
          {get(config, 'repaymentDueDateOffset')}
        </InfoBox>
        <InfoBox label="Day End At">{get(config, 'dayEndAt')}</InfoBox>
        <InfoBox label="Adjust Penalty In Last EMI">
          {formOptions.booleanOptions[get(config, 'adjustPenaltyInLastEmi')]}
        </InfoBox>
      </SimpleGrid>
    </Box>
  )
}

function Configuration({ title, config }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button variantColor="cyan" variant="link" size="sm" onClick={onOpen}>
        VIEW
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px" bg="gray.50">
            {title}
          </DrawerHeader>
          <DrawerBody overflowY="auto">
            <ConfigurationView config={config} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default memo(Configuration)
