import React, { useMemo, useCallback, useState } from 'react'
import {
  Box,
  useToast,
  useDisclosure,
  Badge,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/core'
import OfferItems from 'pages/admin/pages/offer/View/CreateTweak/OfferItems'
import { useDraftAccount } from 'store/admin/hooks'
import { get, isEmpty } from 'lodash-es'
import { api } from 'api'
import { handleAPIError } from 'components/Form/helpers'
import { useHistory } from 'react-router-dom'
import { ConfirmOffer } from './ConfirmOffer'
import Loader from 'components/Loader/Loader'
import OfferForm from './OfferForm'
import { useDispatch, batch } from 'react-redux'
import {
  APPLICATION_PAGINATION_PURGE,
  APPLICATION_UPDATE,
} from 'store/applications'

const draftAccountStatus = {
  OPEN: 'OPEN',
  FINANCED: 'FINANCED',
  CLOSED: 'CLOSED',
}

function CreateOffer({ applicationId }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitData, setSubmitData] = useState(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useToast()
  const history = useHistory()
  const dispatch = useDispatch()

  const onConfirmOpen = useCallback(
    ({ data, form }) => {
      onOpen()
      setSubmitData({ data, form })
    },
    [onOpen]
  )

  const onConfirm = useCallback(async () => {
    const { data, form } = submitData

    try {
      setIsSubmitting(true)

      const {
        eloanId,
        offerAmount,
        clientTenure,
        fiTenure,
        loanProductFiClientConfigId,
      } = data

      await api('POST /eloan/v1/accounts/draft', {
        eloanId,
        offerAmount,
        clientTenure,
        fiTenure,
        loanProductFiClientConfigId,
      })

      await api('PUT /legacy/v4/eloan/offers/draft', {
        eloanId,
        offerAmount,
        clientTenure,
        fiTenure,
      })

      toast({
        title: 'Offer updated.',
        status: 'success',
      })

      setIsSubmitting(false)
      setSubmitData(null)
      form.reset()

      onClose()

      history.push(`/dash/eloan-applications/${eloanId}`)

      batch(() => {
        dispatch({
          type: APPLICATION_PAGINATION_PURGE,
        })
        // temporary handled redux update, dont ask
        dispatch({
          type: APPLICATION_UPDATE,
          data: { offer: { status: 'offer_negotiating' } },
          applicationId: eloanId,
        })
      })
    } catch (error) {
      setIsSubmitting(false)
      handleAPIError(error, { toast, form })
    }
  }, [onClose, toast, submitData, history, dispatch])

  return (
    <>
      <Badge
        px={2}
        py={1}
        mb={6}
        borderRadius="5px"
        variantColor="green"
        variant="solid"
      >
        You are currently <Text as="strong">creating</Text> a new offer
      </Badge>
      <OfferForm applicationId={applicationId} onConfirmOpen={onConfirmOpen} />
      <ConfirmOffer
        title="Confirm Offer Creation"
        subtitle="Are you sure you want to create the offer?"
        data={get(submitData, 'data')}
        onConfirm={onConfirm}
        isLoading={isSubmitting}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

function TweakOffer({ applicationId, draftAccount }) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitData, setSubmitData] = useState(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useToast()
  const history = useHistory()
  const dispatch = useDispatch()

  const onConfirmOpen = useCallback(
    ({ data, form }) => {
      onOpen()
      setSubmitData({ data, form })
    },
    [onOpen]
  )

  const onConfirm = useCallback(async () => {
    const { data, form } = submitData

    try {
      setIsSubmitting(true)

      const {
        offerAmount,
        clientTenure,
        fiTenure,
        loanProductFiClientConfigId,
      } = data

      await api(`PATCH /eloan/v1/accounts/draft/{id}`, {
        id: get(draftAccount, 'id'),
        offerAmount,
        clientTenure,
        fiTenure,
        loanProductFiClientConfigId,
      })

      toast({
        title: 'Offer updated.',
        status: 'success',
      })

      setIsSubmitting(false)
      setSubmitData(null)

      onClose()

      history.push(`/dash/eloan-applications/${applicationId}`)

      batch(() => {
        dispatch({
          type: APPLICATION_PAGINATION_PURGE,
        })
        // temporary handled redux update, dont ask
        dispatch({
          type: APPLICATION_UPDATE,
          data: { offer: { status: 'offer_negotiating' } },
          applicationId,
        })
      })
    } catch (error) {
      setIsSubmitting(false)
      handleAPIError(error, { toast, form })
    }
  }, [
    onClose,
    toast,
    draftAccount,
    submitData,
    history,
    dispatch,
    applicationId,
  ])

  return (
    <>
      <Badge
        px={2}
        py={1}
        mb={6}
        borderRadius="5px"
        variantColor="orange"
        variant="solid"
      >
        You are currently <Text as="strong">tweaking</Text> the offer
      </Badge>
      <OfferForm
        applicationId={applicationId}
        draftAccount={draftAccount}
        onConfirmOpen={onConfirmOpen}
      />
      <ConfirmOffer
        title="Confirm Offer Tweaking"
        subtitle="Are you sure you want to tweak the offer?"
        data={get(submitData, 'data')}
        onConfirm={onConfirm}
        isLoading={isSubmitting}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

function CreateTweakOffer({ application }) {
  const { draftAccount, loading } = useDraftAccount(get(application, 'id'))

  const isOfferEditable = useMemo(
    () =>
      draftAccount.draftAccountStatus === draftAccountStatus.OPEN &&
      'offer_tweaking' === get(application, 'offer.status'),
    [draftAccount.draftAccountStatus, application]
  )

  const isOfferCreatable = useMemo(
    () =>
      isEmpty(draftAccount) &&
      get(application, 'offer.status') === 'offer_tweaking',
    [draftAccount, application]
  )

  return (
    <Box>
      <OfferItems application={application} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {isOfferCreatable && (
            <CreateOffer applicationId={get(application, 'id')} />
          )}
          {isOfferEditable && (
            <TweakOffer
              applicationId={get(application, 'id')}
              draftAccount={draftAccount}
            />
          )}
          {!isOfferCreatable && !isOfferEditable && (
            <Alert>
              <AlertIcon />
              Offer can only be updated in{' '}
              <Text as="strong" mx={1}>
                tweaking
              </Text>{' '}
              status
            </Alert>
          )}
        </>
      )}
    </Box>
  )
}

export default CreateTweakOffer
