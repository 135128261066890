import { Box, Stack, ButtonGroup, IconButton } from '@chakra-ui/core'
import React, { useMemo, useCallback } from 'react'
import Form from 'components/Form/Form'
import FormSelect from 'components/Form/Select'
import FormButton from 'components/Form/Button'
import { useForm } from 'react-hook-form'
import FormInput from 'components/Form/Input'
import { FormDateRangePicker } from 'components/Form/DatePicker'
import { DateTime } from 'luxon'

const criteriaOptions = {
  ownerName: 'Retailer Name',
  externalId: 'Retailer Id',
}

function UnileverRLSummaryListFilter({ onSubmit }) {
  const form = useForm()

  const filterCriteriaOptions = useMemo(() => criteriaOptions, [])

  const onFormSubmit = useCallback(
    ({ date, criteria, search }) => {
      const filterObject = {}

      if (criteria) {
        filterObject.retailer = `${criteria} like %${search}%`
      }

      const { start, end } = date

      if (start || end) {
        const formatStartDate = DateTime.fromJSDate(start || end)
        const formatEndDate = DateTime.fromJSDate(end || start)

        const startSqlDate = formatStartDate.toISODate()
        const endSqlDate = formatEndDate.toISODate()

        filterObject.rlWithdrawal = `repaymentDeadline >= ${startSqlDate};repaymentDeadline <= ${endSqlDate}`
      }

      onSubmit({ ...filterObject })
    },
    [onSubmit]
  )

  const formReset = form.reset

  const onReset = useCallback(() => {
    formReset()
    onSubmit({})
  }, [formReset, onSubmit])

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Stack isInline spacing={4} marginBottom={6} align="flex-end">
        <Box zIndex={2}>
          <FormDateRangePicker label="Filter by due date" name="date" />
        </Box>
        <Box minW="200px" zIndex={2}>
          <FormSelect
            label="Filter criteria"
            options={filterCriteriaOptions}
            name="criteria"
          />
        </Box>
        <Box>
          <FormInput label="Search here" name="search" />
        </Box>
        <Box>
          <ButtonGroup spacing={2}>
            <IconButton type="button" icon="small-close" onClick={onReset} />
            <FormButton type="submit" minWidth="6rem" variantColor="green">
              Filter
            </FormButton>
          </ButtonGroup>
        </Box>
      </Stack>
    </Form>
  )
}

export default UnileverRLSummaryListFilter
