import React from 'react'
import { Tooltip, Icon, List, ListItem } from '@chakra-ui/core'

export function PaymentHolidayTooltip() {
  return (
    <Tooltip
      bg="gray.50"
      color="black"
      label={
        <List styleType="disc" p={4}>
          <ListItem>Accounts will get frozen for selected period</ListItem>
          <ListItem>No EMI targets will be applicable</ListItem>
          <ListItem>Interest calculation will continue as usual</ListItem>
        </List>
      }
    >
      <Icon name="info" />
    </Tooltip>
  )
}

export function InterestWaiverTooltip() {
  return (
    <Tooltip
      bg="gray.50"
      color="black"
      label={
        <List styleType="disc" p={4}>
          <ListItem>
            No interest will be calculated for selected period
          </ListItem>
        </List>
      }
    >
      <Icon name="info" />
    </Tooltip>
  )
}
