import { Box } from '@chakra-ui/core'
import { FormDateRangePicker } from 'components/Form/DatePicker'
import React from 'react'

function OfferCreationDateFilter({ ...props }) {
  return (
    <Box {...props}>
      <FormDateRangePicker
        name="offer.createdAt"
        label="Filter by Offer Date"
        inputProps={{ width: '8rem' }}
        startProps={{ placeholderText: 'Start Date' }}
        endProps={{ placeholderText: 'End Date' }}
      />
    </Box>
  )
}

export default OfferCreationDateFilter
