import { DateTime } from 'luxon'

export const getDate = (dateToFormat, format = DateTime.DATE_MED) => {
  if (dateToFormat) return DateTime.fromISO(dateToFormat).toLocaleString(format)
  return null
}

export const getDateTime = (dateToFormat) => {
  if (dateToFormat) {
    const date = new Date(dateToFormat)
    return `${date.toDateString()} ${date.getHours()}:${(
      '0' + date.getMinutes()
    ).slice(-2)}`
  }
}

export const getDateDiff = (dateToDiff) => {
  return DateTime.fromJSDate(new Date()).diff(DateTime.fromISO(dateToDiff), [
    'days',
    'hours',
    'minutes',
  ])
}
