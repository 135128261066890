import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getLoanModifierConfigurations, getLoanModifierConfiguration } from '.'

export function useLoanModifierCongfigurations() {
  const loanModifier = useSelector((state) => state.loanModifier)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLoanModifierConfigurations())
  }, [dispatch])

  return loanModifier
}

export function useLoanModifierCongfiguration(loanModifierId) {
  const loanModifier = useSelector((state) => state.loanModifier)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLoanModifierConfiguration(loanModifierId))
  }, [dispatch, loanModifierId])

  return loanModifier.byId[loanModifierId]
}
