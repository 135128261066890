import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import FormSelect from 'components/Form/Select'
import { get, zipObject } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useDistributorRoutes, useDistributors } from 'store/distributors/hooks'
import { createRetailer } from 'store/retailers'
import { bangladeshMobileNumberPattern } from 'utils/regex'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    externalId: Yup.string().required(`required`),
    ownerName: Yup.string().required(`required`),
    phoneNumber: Yup.string()
      .matches(bangladeshMobileNumberPattern)
      .required(`required`),
    shopName: Yup.string().required(`required`),

    distributorId: Yup.number().integer().required(`required`),
    routeId: Yup.number().integer().required(`required`),
  })
}

const getDefaultValues = () => {
  return {
    externalId: '',
    ownerName: '',
    phoneNumber: '01',
    shopName: '',

    distributorId: '',
    routeId: '',
  }
}

function CreateRetailer() {
  const toast = useToast()

  const distributors = useDistributors()
  const distributorOptions = useMemo(() => {
    return zipObject(
      distributors.allIds,
      distributors.allIds.map((id) => distributors.byId[id].name)
    )
  }, [distributors.allIds, distributors.byId])

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const history = useHistory()
  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({
      externalId,
      ownerName,
      phoneNumber,
      shopName,
      distributorId,
      routeId,
    }) => {
      try {
        await dispatch(
          createRetailer({
            externalId,
            ownerName,
            phoneNumber,
            shopName,
            distributorId,
            routeId,
          })
        )
        history.push('/dash/retailers')
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, history, toast]
  )

  const distributorId = form.watch('distributorId')

  const routes = useDistributorRoutes(distributorId)

  const routeOptions = useMemo(() => {
    return zipObject(
      routes.allIds,
      routes.allIds.map(
        (id) =>
          `${get(routes.byId[id], 'code')} - ${get(routes.byId[id], 'name')}`
      )
    )
  }, [routes.allIds, routes.byId])

  return (
    <Authorize permissions="eloan:Retailer:create">
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Add Retailer</Heading>
        </Box>

        <Box>
          <Button as={Link} to={'/dash/retailers'}>
            Go Back
          </Button>
        </Box>
      </Stack>

      <Form form={form} onSubmit={onSubmit}>
        <Stack spacing={4}>
          <FormInput name="externalId" label={`Retailer ID`} />

          <FormInput name="shopName" label={`Shop Name`} />

          <FormInput name="ownerName" label={`Owner Name`} />

          <FormInput name="phoneNumber" label={`Phone Number`} />

          <FormSelect
            name="distributorId"
            label={`Distributor`}
            options={distributorOptions}
          />

          <FormSelect name="routeId" label={`Route`} options={routeOptions} />

          <ButtonGroup>
            <FormButton type="reset">Reset</FormButton>
            <FormButton type="submit" variantColor="green">
              Create
            </FormButton>
          </ButtonGroup>
        </Stack>
      </Form>
    </Authorize>
  )
}

export default CreateRetailer
