import { Flex, Heading } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import React, { memo } from 'react'
import { Box } from 'reflexbox'

function LoaneeInfo({ application, shopArea, ...props }) {
  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Loanee Info
      </Heading>

      <Flex flexDirection="row" flexWrap="wrap">
        <InfoBox label="Name" content={get(application, 'meta.loaneeName')} />
        <InfoBox
          label="Phone Number"
          content={get(application, 'phoneNumber')}
        />
        <InfoBox label="Area" content={get(shopArea, 'name')} />
      </Flex>
    </Box>
  )
}

export default memo(LoaneeInfo)
