import { useInterval } from '@eloan/shared/hooks'
import React, { useCallback, useState } from 'react'
import { Box } from 'reflexbox'

const getNextDots = (count, dots) => {
  if (dots.length === count) {
    return ''
  }

  return '.'.repeat(dots.length + 1)
}

export function LoadingDots({ count = 3, period = 1800 }) {
  const [dots, setDots] = useState('.')

  const updateDots = useCallback(
    () => setDots((dots) => getNextDots(count, dots)),
    [count]
  )

  useInterval(updateDots, period / count)

  return (
    <Box as="span" sx={{ display: 'inline-block', height: '1em' }}>
      {dots}
    </Box>
  )
}
