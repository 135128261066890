import {
  Box,
  Stack,
  ButtonGroup,
  IconButton,
  Text,
  InputLeftElement,
  Icon,
} from '@chakra-ui/core'
import React, { useCallback, useMemo } from 'react'
import Form from 'components/Form/Form'
import FormButton from 'components/Form/Button'
import { useForm } from 'react-hook-form'
import { FormDateRangePicker } from 'components/Form/DatePicker'
import { toISODate } from 'utils/cast'
import FormInput from 'components/Form/Input'
import { DateTime } from 'luxon'

function FinanceCollectionPageFilter({ onSubmit }) {
  const defaultValues = useMemo(
    () => ({
      invoiceId: '',
      shopId: '',
      eloanId: '',
      date: {
        start: DateTime.local().minus({ days: 7 }).toJSDate(),
        end: DateTime.local().toJSDate(),
      },
    }),
    []
  )

  const form = useForm({ defaultValues })

  const handleSubmit = useCallback(
    ({ date, invoiceId, shopId, eloanId }) => {
      const filterObject = {}

      const { start, end } = date

      if (start || end) {
        filterObject.min = toISODate(start || end)
        filterObject.max = toISODate(end || start)
      }

      filterObject.invoiceId = invoiceId || undefined
      filterObject.shopId = shopId || undefined
      filterObject.eloanId = eloanId || undefined

      onSubmit({ ...filterObject })
    },
    [onSubmit]
  )

  const formReset = form.reset

  const onReset = useCallback(() => {
    formReset()
    onSubmit({})
  }, [formReset, onSubmit])

  return (
    <Form form={form} onSubmit={handleSubmit} flex={1}>
      <Stack isInline spacing={4} align="flex-end" flexWrap="wrap">
        <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                eLoan ID
              </Text>
            }
            placeholder="Search"
            name="eloanId"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
          />
        </Box>
        <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                Invoice ID
              </Text>
            }
            placeholder="Search"
            name="invoiceId"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
          />
        </Box>
        <Box marginBottom={6}>
          <FormInput
            label={
              <Text fontSize="xs" color="gray.500">
                Shop ID
              </Text>
            }
            placeholder="Search"
            name="shopId"
            InputLeft={
              <InputLeftElement>
                <Icon name="search" color="blue.500" />
              </InputLeftElement>
            }
          />
        </Box>
        <Box zIndex={2} marginBottom={6}>
          <FormDateRangePicker
            label={
              <Text fontSize="xs" color="gray.500">
                Select Date Range
              </Text>
            }
            startProps={{ placeholderText: 'Start Date' }}
            endProps={{ placeholderText: 'End Date' }}
            name="date"
          />
        </Box>
        <Box marginBottom={6}>
          <ButtonGroup spacing={2}>
            <IconButton
              type="button"
              variantColor="cyan"
              variant="outline"
              icon="small-close"
              onClick={onReset}
            />
            <FormButton type="submit" minWidth="6rem" variantColor="cyan">
              Filter
            </FormButton>
          </ButtonGroup>
        </Box>
      </Stack>
    </Form>
  )
}

export default FinanceCollectionPageFilter
