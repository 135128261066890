import { get } from 'lodash-es'

export const PARTNER_LOADING_ALL = 'PARTNER_LOADING_ALL'
export const PARTNER_SET_ALL = 'PARTNER_SET_ALL'

export const getAllPartners = () => async (dispatch, getState, { api }) => {
  const { partners } = getState()

  if (!partners.loading) {
    dispatch({ type: PARTNER_LOADING_ALL })

    try {
      const { data } = await api(`/legacy/v4/eloan/partners`)

      dispatch({ type: PARTNER_SET_ALL, data })
    } catch (err) {
      if (get(err.error, 'code') === 403) {
        console.error(err)
      }
    }
  }
}
