import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '.'

const noop = () => {}

export function DataTable({
  rows,
  prepareRow,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,

  tableProps,
  tableBodyProps,
  headerProps,
  headerGroupProps,
  rowProps,
  cellProps,
}) {
  return (
    <Table {...getTableProps(tableProps)}>
      <TableHeader>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps(headerGroupProps)}>
            {headerGroup.headers.map((column) => (
              <TableHeaderCell
                {...column.getHeaderProps(headerProps)}
                column={column}
              >
                {column.render('Header')}
              </TableHeaderCell>
            ))}
          </TableRow>
        ))}
      </TableHeader>

      <TableBody {...getTableBodyProps(tableBodyProps)}>
        {(page || rows).map((row) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps(rowProps)}>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps(cellProps)}>
                    {cell.render('Cell')}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
