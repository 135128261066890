import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { createDistributor } from 'store/distributors'
import * as Yup from 'yup'

const getValidationSchema = () => {
  return Yup.object({
    name: Yup.string().required(`required`),
    areaName: Yup.string().required(`required`),
  })
}

const getDefaultValues = () => {
  return {
    name: '',
    areaName: '',
  }
}

function CreateDistributor() {
  const toast = useToast()

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const history = useHistory()
  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ name, areaName }) => {
      try {
        await dispatch(createDistributor({ name, areaName }))
        history.push('/dash/distributors')
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [dispatch, form, history, toast]
  )

  return (
    <Authorize permissions="eloan:Distributor:create">
      <Stack
        isInline
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Add Distributor</Heading>
        </Box>

        <Box>
          <Button as={Link} to={'/dash/distributors'}>
            Go Back
          </Button>
        </Box>
      </Stack>

      <Form form={form} onSubmit={onSubmit}>
        <Stack spacing={4}>
          <FormInput name="name" label={`Distributor Name`} />

          <FormInput name="areaName" label={`Area Name`} />

          <ButtonGroup>
            <FormButton type="reset">Reset</FormButton>
            <FormButton type="submit" variantColor="green">
              Create
            </FormButton>
          </ButtonGroup>
        </Stack>
      </Form>
    </Authorize>
  )
}

export default CreateDistributor
