import {
  Button,
  Heading,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
} from '@chakra-ui/core'
import ApplicaitonStatuses from 'components/Application/Statuses'
import AcceptRedxApplication from 'components/ApplicationModals/AcceptRedxApplication'
import AssignFieldAgent from 'components/ApplicationModals/AssignFieldAgent'
import ChangePA from 'components/ApplicationModals/ChangePA'
import CloseApplication from 'components/ApplicationModals/CloseApplication'
import RefreshApplicationStatus from 'components/ApplicationModals/RefreshApplicationStatus'
import ReviveApplication from 'components/ApplicationModals/ReviveApplication'
import SendToVerificationApproval from 'components/ApplicationModals/SendToVerificationApproval'
import ShopupApproveOffer from 'components/ApplicationModals/ShopupApproveOffer'
import NavLink from 'components/Link/NavLink'
import { get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import { getApplicationData } from 'store/applications'
import { useApplicationHistory } from 'store/applications/hooks'
import { usePAUsers } from 'store/users/hooks'
import CallInfo from './CallInfo'
import DocTemplates from './DocTemplates'
import GuarantorNID from './GuarantorNID'
import LoanDetails from './LoanDetails'
import LoaneeNID from './LoaneeNID'
import Locations from './Locations'
import ChangeApplicationStatus from './modals/ChangeApplicationStatus'
import ChangeDocSubmissionMethod from './modals/ChangeDocSubmissionMethod'
import Notes from './Notes'
import Scorecard from './Scorecard'
import TpGuarantorNID from './TpGuarantorNID'

const fields = [
  'id',
  'type',
  'status',
  'subStatus',

  'phoneNumber',
  'loanAmount',
  'tenure',

  'meta.*',
  'metaExtra.*',

  'offer.id',
  'offer.status',
  'offer.loanAmount',

  'paAssignment.paId',
  'faAssignment.userId',

  'shop.id',
  'shop.shopName',
  'shop.businessMediums',
  'shop.businessNote',

  'cibPayments.*',

  'scorecards.*',

  'darazMerchantData.*',
  'redxShopData.*',
].join(',')

const tabs = [
  { id: 'loan-details', name: 'Loan Details' },
  { id: 'loanee-nid', name: 'Loanee NID' },
  { id: 'guarantor-nid', name: 'Family Guarantor' },
  { id: 'tp-guarantor-nid', name: 'Business Guarantor' },
  { id: 'locations', name: 'Locations' },
  { id: 'notes', name: 'Notes' },
  { id: 'call-info', name: 'Call Info' },
  { id: 'scorecard', name: 'Scorecard' },
  { id: 'doc-templates', name: 'Doc Templates' },
]

function ApplicationView() {
  const [unauthorized, setUnauthorized] = useState(false)

  const history = useHistory()

  const { applicationId, tabId } = useParams()

  const applicationHistory = useApplicationHistory({
    applicationId,
    fields: 'id',
  })

  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const paUsers = usePAUsers()
  const pa = useMemo(() => {
    return get(paUsers.byId, get(application, 'paAssignment.paId'))
  }, [application, paUsers.byId])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApplicationData(applicationId, { fields })).catch((err) => {
      if (get(err.error, 'code') === 403) {
        setUnauthorized(true)
      }
    })
  }, [applicationId, dispatch])

  const onTabChange = useCallback(
    (index) => {
      history.replace(`/dash/applications/${applicationId}/${tabs[index].id}`)
    },
    [applicationId, history]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === tabId)
    return index < 0 ? 0 : index
  }, [tabId])

  useEffect(() => {
    onTabChange(tabIndex)
  }, [onTabChange, tabIndex])

  const type = get(application, 'type')

  return unauthorized ? (
    <Flex alignItems="center" justifyContent="center" height="100%">
      <Text fontSize={6} color="red.500">
        You are not authorized to access this eLoan Application ({applicationId}
        )
      </Text>
    </Flex>
  ) : application ? (
    <>
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        mb={10}
      >
        <Stack spacing={1} mb={2}>
          <Heading fontSize={5}>
            Application ID: {applicationId} | {get(application, 'phoneNumber')}
          </Heading>
          <Text as="div">
            {type ? (
              <Tag size="md" variantColor="blue" mr={4}>
                {type}
              </Tag>
            ) : null}
            Details information about eLoan Application
          </Text>
          <ApplicaitonStatuses application={application} />
          {applicationHistory.allIds.length ? (
            <Box mt={2}>
              <Text as="span" fontWeight="bold">
                Related eLoan Ids:{' '}
              </Text>
              {applicationHistory.allIds.map((eloanId) => (
                <Button
                  key={eloanId}
                  as={Link}
                  to={`/dash/applications/${eloanId}`}
                  target="_blank"
                  variant="outline"
                  size="sm"
                  p="1"
                  py="0"
                  height="auto"
                >
                  {eloanId}
                </Button>
              ))}
            </Box>
          ) : null}
        </Stack>
        <Stack isInline spacing={2} flexWrap="wrap">
          <Button to={`/dash/applications/${applicationId}/rca`} as={NavLink}>
            RCA
          </Button>
          <ChangeDocSubmissionMethod
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <AssignFieldAgent
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <ChangePA
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <CloseApplication
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <ReviveApplication
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <ChangeApplicationStatus
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <RefreshApplicationStatus
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <AcceptRedxApplication
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <ShopupApproveOffer
            applicationId={applicationId}
            application={application}
            mb={1}
          />
          <SendToVerificationApproval
            applicationId={applicationId}
            application={application}
            mb={1}
          />
        </Stack>{' '}
      </Flex>

      <Tabs index={tabIndex} onChange={onTabChange}>
        <Box sx={{ position: 'relative' }}>
          <TabList mb={4}>
            {tabs.map((tab) => (
              <Tab id={tab.id} key={tab.id}>
                {tab.name}
              </Tab>
            ))}
          </TabList>

          <Stat float="right" textAlign="right" mt={-3}>
            <StatLabel color="gray.500" fontWeight="normal">
              Portfolio Associate
            </StatLabel>
            <StatNumber fontWeight="normal" fontSize={4}>
              {get(pa, 'name')}
            </StatNumber>
          </Stat>
        </Box>

        <TabPanels p={4}>
          <TabPanel>
            <LoanDetails applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <LoaneeNID applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <GuarantorNID applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <TpGuarantorNID applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <Locations applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <Notes applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <CallInfo applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <Scorecard applicationId={applicationId} />
          </TabPanel>
          <TabPanel>
            <DocTemplates applicationId={applicationId} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  ) : null
}

export default ApplicationView
