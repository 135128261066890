import FormSelect from 'components/Form/Select'
import React, { useMemo } from 'react'
import { Box } from 'reflexbox'
import { applicationSubStatusDisplayText } from 'utils/application-status'

function ApplicationSubStatusFilter({ ...props }) {
  const subStatusOptions = useMemo(
    () => ({
      '': 'Any',
      ...applicationSubStatusDisplayText,
    }),
    []
  )

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect
        name="subStatus"
        label={`Filter by Sub Status`}
        options={subStatusOptions}
      />
    </Box>
  )
}

export default ApplicationSubStatusFilter
