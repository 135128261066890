import FormInput from 'components/Form/Input'
import React from 'react'

const getBorderColor = (value) => (value ? 'lime' : 'yellow.300')

function DecoratedFormInput({ name, label, value, ...props }) {
  return (
    <FormInput
      name={name}
      label={label}
      borderColor={getBorderColor(value)}
      {...props}
    />
  )
}

export default DecoratedFormInput
