export const cibPaymentStatusDisplayText = {
  unpaid: 'Unpaid',
  unverified: 'Unverified',
  partially_paid: 'Partially Paid',
  paid: 'Paid',
  verified: 'Verified',
}

export const cibPaymentStatusVariantColor = {
  unpaid: 'red',
  unverified: 'orange',
  partially_paid: 'yellow',
  paid: 'cyan',
  verified: 'green',
}

export const bkashTransactionStatusDisplayText = {
  verified: 'Verified',
  unverified: 'Unverified',
  invalid: 'Invalid',
}

export const bkashTransactionStatusVariantColor = {
  verified: 'green',
  unverified: 'yellow',
  invalid: 'red',
}

export const cibApprovalStatusDisplayText = {
  awaiting: 'Awating',
  approved: 'Approved',
  rejected: 'Rejected',
  need_revision: 'Need Revision',
}

export const cibApprovalStatusVariantColor = {
  awaiting: 'yellow',
  approved: 'green',
  rejected: 'red',
  need_revision: 'orange',
}
