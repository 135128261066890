import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCCAList } from 'store/repaymentFollowup/ccaSlice'
import { DataTable } from '@eloan/shared/components/Table'
import { useTable, useRowSelect } from 'react-table'
import Authorize from 'components/Authorize'
import ApplicationListHeader from 'components/ApplicationList/Header'
import { Text, Checkbox } from '@chakra-ui/core'
import { displayBDT, getDate, emptyObject } from '@eloan/shared'
import useLimitQuery from 'hooks/useLimitQuery'
import LimitBasedFilter from 'components/LimitBasedFilter/LimitBasedFilter'
import { take } from 'lodash-es'
import RemoveCCA from 'pages/repayment-followup/cca/List/RemoveCCA'
import RepaymentFollowupCCAFilter from './Filter'
import { useState } from 'react'
import { emptyArray } from 'utils/defaults'
import AssignAgent from 'pages/repayment-followup/AssignAgent'
import { teamOptionsCCA } from 'pages/repayment-followup/constants'

const getColumns = () => [
  {
    Header: ({ getToggleAllRowsSelectedProps }) => {
      const { checked, ...props } = getToggleAllRowsSelectedProps()
      return <Checkbox isChecked={checked} {...props} mt={1} />
    },
    accessor: 'id',
    Cell: ({ row }) => {
      const { checked, ...props } = row.getToggleRowSelectedProps()
      return <Checkbox isChecked={checked} {...props} mt={1} />
    },
  },
  { Header: 'eloan ID', accessor: 'applicationId' },
  {
    Header: 'Overdue',
    accessor: ({ isOverdue }) => (isOverdue ? 'Yes' : 'No'),
  },
  { Header: 'Loanee Name', accessor: 'loaneeName' },
  { Header: 'Loanee Phone', accessor: 'loaneePhone' },
  { Header: 'DPD', accessor: 'dpd' },
  { Header: 'Guarantor Phone', accessor: 'guarantorPhone' },
  {
    Header: 'Loan Start Date',
    accessor: ({ loanStartTime }) => getDate(loanStartTime),
  },
  {
    Header: 'Loan End Date',
    accessor: ({ loanEndTime }) => getDate(loanEndTime),
  },
  {
    Header: 'OD Amount',
    accessor: ({ remainingDue }) => (
      <Text color="red.500">{displayBDT(remainingDue)}</Text>
    ),
  },
  {
    Header: '',
    id: 'action_button',
    accessor: (row) => <RemoveCCA application={row} />,
  },
]

const getRowId = (row) => row.applicationId

const getQueryObject = ({ eloanId, minDpd, maxDpd }, limit) => ({
  eloanId,
  minDpd,
  maxDpd,
  limit,
})

function RepaymentFollowupCCAList() {
  const dispatch = useDispatch()

  const [selectedIds, setSelectedIds] = useState(emptyArray)

  const [filter, setFilter] = useState(emptyObject)

  const limit = useLimitQuery()

  const queryObject = useMemo(() => getQueryObject(filter, limit), [
    filter,
    limit,
  ])

  useEffect(() => {
    dispatch(fetchCCAList(queryObject))
  }, [queryObject, dispatch])

  const cca = useSelector((state) => state.repaymentFollowup.cca)

  const ccaList = useMemo(() => cca.ids.map((id) => cca.entities[id]), [cca])

  const data = useMemo(() => take(ccaList, limit), [ccaList, limit])

  const columns = useMemo(() => getColumns(), [])

  const table = useTable({ data, columns, getRowId }, useRowSelect)

  useEffect(() => {
    setSelectedIds(
      Object.keys(table.state.selectedRowIds).filter(
        (id) => table.state.selectedRowIds[id]
      )
    )
  }, [table.state.selectedRowIds])

  return (
    <Authorize permissions="eloan:CCA:any">
      <ApplicationListHeader
        title={`Repayment CCA`}
        Right={
          <Authorize permissions="eloan:AnyApplication:update">
            <AssignAgent
              applicationIds={selectedIds}
              teamOptions={teamOptionsCCA}
            />
          </Authorize>
        }
      />
      <RepaymentFollowupCCAFilter onSubmit={setFilter} />
      <DataTable {...table} />
      <LimitBasedFilter
        count={data.length}
        total={cca.paginationData?.totalItems}
        limit={limit}
      />
    </Authorize>
  )
}

export default RepaymentFollowupCCAList
