import {
  Flex,
  Heading,
  IconButton,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { Box } from 'reflexbox'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { useForm } from 'react-hook-form'
import FormInput from 'components/Form/Input'
import * as Yup from 'yup'
import { bangladeshMobileNumberPattern } from 'utils/regex'
import { useDispatch } from 'react-redux'
import { handleAPIError } from 'components/Form/helpers'
import { setLoaneePhoneNumber } from 'store/applications'

const getValidationSchema = () => {
  return Yup.object({
    loaneePhoneNumber: Yup.string()
      .matches(bangladeshMobileNumberPattern)
      .required(`required`),
  })
}

const getDefaultValues = (application) => ({
  loaneePhoneNumber: get(application, 'meta.loaneePhoneNumber') || '',
})

function UpdateLoaneePhoneNumber({ application, applicationId, ...props }) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(application), [
    application,
  ])
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const formReset = form.reset
  useEffect(() => {
    formReset(defaultValues)
  }, [defaultValues, formReset])

  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async ({ loaneePhoneNumber }) => {
      try {
        await dispatch(
          setLoaneePhoneNumber(applicationId, { loaneePhoneNumber })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, onClose, toast]
  )

  return (
    <Authorize permissions="eloan:Application:update">
      <Box display="inline-block" ml={2} {...props}>
        <IconButton
          variantColor="blue"
          aria-label="Update Loanee PhoneNumber"
          size="xs"
          icon="edit"
          onClick={onOpen}
        />

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader></ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <FormInput
                  isRequired={true}
                  placeholder="Loanee PhoneNumber"
                  name="loaneePhoneNumber"
                  label={`Update Loanee PhoneNumber`}
                />
              </ModalBody>

              <ModalFooter>
                <Button type="button" mr={3} onClick={onClose}>
                  Close
                </Button>
                <FormButton type="submit">Update</FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

function LoaneeInfo({ application, shopArea, ...props }) {
  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Loanee Info
      </Heading>

      <Flex flexDirection="row" flexWrap="wrap">
        <InfoBox
          label="RedX Login Name"
          content={get(application, 'meta.loaneeName')}
        />
        <InfoBox
          label="RedX Login Phone Number"
          content={get(application, 'phoneNumber')}
        />
        <InfoBox
          label="Gender"
          content={get(application, 'meta.loaneeGender')}
        />
        <InfoBox label="Merchant RedX Area" content={get(shopArea, 'name')} />
        <InfoBox label="Loanee phone">
          {get(application, 'meta.loaneePhoneNumber') || 'N/A'}
          <UpdateLoaneePhoneNumber
            application={application}
            applicationId={get(application, 'id')}
          />
        </InfoBox>
      </Flex>
    </Box>
  )
}

export default memo(LoaneeInfo)
