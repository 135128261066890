import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormInput from 'components/Form/Input'
import NIDPhoto from 'components/Image/NIDPhoto'
import { get } from 'lodash-es'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { MdFileUpload } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { uploadCibSignedImage } from 'store/applications'

function CibSignedImage({
  application,
  photoField,
  statusField,
  title,
  ...props
}) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const form = useForm()

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ file }) => {
      try {
        await dispatch(
          uploadCibSignedImage(get(application, 'id'), {
            fieldName: photoField,
            file: file[0],
          })
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { toast, form })
      }
    },
    [application, dispatch, form, onClose, photoField, toast]
  )

  return (
    <Authorize permissions="eloan:AnyApplication:read,eloan:Application:read">
      <Box {...props}>
        <Heading fontSize={3} my={4}>
          {title}
        </Heading>

        <NIDPhoto
          applicationId={get(application, 'id')}
          photoField={photoField}
          statusField={statusField}
        >
          <Authorize permissions="eloan:Application:update">
            <Box>
              <Button
                type="button"
                onClick={onOpen}
                title="Upload Photo"
                variantColor="blue"
                isDisabled={get(application, statusField) === 'accepted'}
              >
                <Box as={MdFileUpload} />
              </Button>

              <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                  <Form form={form} onSubmit={onSubmit}>
                    <ModalHeader>{title}</ModalHeader>

                    <ModalCloseButton type="button" />

                    <ModalBody>
                      <FormInput type="file" name="file" label="Select Image" />
                    </ModalBody>

                    <ModalFooter>
                      <Button type="button" mr={3} onClick={onClose}>
                        Close
                      </Button>
                      <FormButton type="submit">Upload</FormButton>
                    </ModalFooter>
                  </Form>
                </ModalContent>
              </Modal>
            </Box>
          </Authorize>
        </NIDPhoto>
      </Box>
    </Authorize>
  )
}

export default CibSignedImage
