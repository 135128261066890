import { Heading, Stack } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import ResetNIDInfoButton from 'components/Button/ResetNIDInfoButton'
import NIDPhoto from 'components/Image/NIDPhoto'
import PorichoyTag from 'components/Tag/PorichoyTag'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Flex } from 'reflexbox'
import { useAreas } from 'store/areas/hooks'
import LoaneeInfo from './sections/LoaneeInfo'
import ResetGuarantorButton from 'components/Button/ResetGuarantorButton'
import TPGuarantorInfo from './sections/TPGuarantorInfo'
import UploadNID, { nidTypes } from './UploadNID'

function TpGuarantorNID({
  applicationId,
  title = 'Third Party Guarantor NID Info',
}) {
  const areas = useAreas()

  const application = useSelector(
    (state) => state.applications.byId[applicationId]
  )

  const shopArea = useMemo(() => {
    return areas.byId[get(application, 'meta.shopAreaId', null)]
  }, [application, areas.byId])

  return (
    <Stack spacing={8} width="100%">
      <LoaneeInfo application={application} shopArea={shopArea} />

      <TPGuarantorInfo application={application} />

      <Box>
        <Heading fontSize={5} mb={4}>
          {title}
        </Heading>

        <Flex flexDirection="row" flexWrap="wrap">
          <InfoBox
            label="NID Number"
            content={get(application, 'meta.tpGuarantorNid')}
          />
          <InfoBox
            label="Name"
            content={get(application, 'meta.tpGuarantorNidName')}
          />
          <InfoBox
            label="Date of Birth"
            content={
              get(application, 'meta.tpGuarantorNidDob')
                ? DateTime.fromISO(
                    get(application, 'meta.tpGuarantorNidDob')
                  ).toLocaleString(DateTime.DATE_MED)
                : null
            }
          />
          <InfoBox
            label="Porichoy Status"
            content={
              <PorichoyTag
                valid={get(
                  application,
                  'metaExtra.tpGuarantorNidPorichoyValid'
                )}
              />
            }
          />
        </Flex>

        <Stack isInline spacing={2} mt={2}>
          <ResetNIDInfoButton
            applicationId={applicationId}
            nidOwner="tp_guarantor"
          />

          {['preparing', 'troubleshooting'].includes(
            get(application, 'status')
          ) && <ResetGuarantorButton applicationId={applicationId} tp={true} />}
        </Stack>
      </Box>

      <Box>
        <Heading fontSize={5} mb={4}>
          Guarantor NID Photos
        </Heading>

        <Flex flexDirection="row" flexWrap="wrap">
          <InfoBox label="NID Selfie">
            <NIDPhoto
              applicationId={applicationId}
              photoField="metaExtra.tpGuarantorNidSelfie"
              statusField="metaExtra.tpGuarantorNidSelfieStatus"
            />
            <UploadNID
              label="Upload NID Selfie"
              type={nidTypes.nidSelfie}
              owner="TP_GUARANTOR"
              applicationId={applicationId}
              status={get(application, 'metaExtra.tpGuarantorNidSelfieStatus')}
            />
          </InfoBox>
          <InfoBox label="NID Front">
            <NIDPhoto
              applicationId={applicationId}
              photoField="metaExtra.tpGuarantorNidPhoto"
              statusField="metaExtra.tpGuarantorNidPhotoFrontStatus"
            />
            <UploadNID
              label="Upload NID Front"
              type={nidTypes.nidPhoto}
              side="FRONT"
              owner="TP_GUARANTOR"
              applicationId={applicationId}
              status={get(
                application,
                'metaExtra.tpGuarantorNidPhotoFrontStatus'
              )}
            />
          </InfoBox>
          <InfoBox label="NID Back">
            <NIDPhoto
              applicationId={applicationId}
              photoField="metaExtra.tpGuarantorNidPhotoBack"
              statusField="metaExtra.tpGuarantorNidPhotoBackStatus"
            />
            <UploadNID
              label="Upload NID Front"
              type={nidTypes.nidPhoto}
              side="BACK"
              owner="TP_GUARANTOR"
              applicationId={applicationId}
              status={get(
                application,
                'metaExtra.tpGuarantorNidPhotoBackStatus'
              )}
            />
          </InfoBox>
        </Flex>
      </Box>
    </Stack>
  )
}

export default TpGuarantorNID
