import React from 'react'
import { Flex, Grid, Text, Box } from '@chakra-ui/core'
import { DateTime } from 'luxon'

function CCADetails({ application = {} }) {
  return (
    <Flex py={4} direction="column">
      <Grid
        borderBottom="1px solid #eee"
        py={4}
        templateColumns="repeat(3, 1fr)"
        gap={4}
      >
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            Partner ID
          </Text>
          <Text>{application.partnerId}</Text>
        </Box>
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            Loan ID
          </Text>
          <Text>{application.applicationId}</Text>
        </Box>
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            Overdue
          </Text>
          <Text>{application.isOverdue ? 'Yes' : 'No'}</Text>
        </Box>
      </Grid>
      <Grid
        borderBottom="1px solid #eee"
        py={4}
        templateColumns="repeat(3, 1fr)"
        gap={4}
      >
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            Loanee Phone
          </Text>
          <Text>{application.loaneePhone}</Text>
        </Box>
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            Guarantor Phone
          </Text>
          <Text>{application.guarantorPhone}</Text>
        </Box>
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            DPD
          </Text>
          <Text>{application.dpd}</Text>
        </Box>
      </Grid>
      <Grid
        borderBottom="1px solid #eee"
        py={4}
        templateColumns="repeat(3, 1fr)"
        gap={4}
      >
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            Loan Start Time
          </Text>
          <Text>
            {DateTime.fromISO(application.loanStartTime).toLocaleString(
              DateTime.DATE_MED
            )}
          </Text>
        </Box>
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            Loan End Time
          </Text>
          <Text>
            {DateTime.fromISO(application.loanEndTime).toLocaleString(
              DateTime.DATE_MED
            )}
          </Text>
        </Box>
        <Box>
          <Text color="gray.500" textTransform="uppercase">
            OD Amount
          </Text>
          <Text>{application.remainingDue}</Text>
        </Box>
      </Grid>
    </Flex>
  )
}

export default CCADetails
