import React, { useMemo, useEffect, useState } from 'react'
import Authorize from 'components/Authorize'
import { Box, Heading, Flex, Badge, Button, Link } from '@chakra-ui/core'
import ListTable from 'components/ListTable/ListTable'
import { useTable } from 'react-table'
import PartnerBeftnListFilter from './Filter'
import UploadSlip from './UploadSlip'
import { useDispatch, useSelector } from 'react-redux'
import { getBankDetailsForEloanId, getBeftns } from 'store/beftn'
import { get, defaults } from 'lodash-es'
import { displayBDT, getDate } from '@eloan/shared'
import { createExcel, downloadExcelFile } from '@eloan/shared/utils/xlsx'
import LimitBasedFilter from 'components/LimitBasedFilter/LimitBasedFilter'
import useLimitQuery from 'hooks/useLimitQuery'
import { useFinancialInstitutions } from 'store/admin/hooks'
import { emptyObject } from 'utils/defaults'
import { toISODate } from 'utils/cast'

const downloadExcel = (data, fiName) => {
  // now data has data.bankDetails
  const bankDetails = get(data, 'bankDetails')
  const transferredAt = get(data, 'transferredAt')
  const preparedData = [
    [
      'SL No.',
      'eLoan ID',
      `${fiName} ID`,
      'Loanee Name',
      'Payment',
      'Bank Name',
      'Bank Account Number',
      'Routing Number',
      'Bank Branch Name',
      'Monthly Repayment Date',
      'Paid Date',
    ],
    ...get(
      data,
      'accountContext',
      []
    ).map(
      (
        { loaneeFiMemberId, eloanId, loaneeName, amount, scheduledEmiDate },
        index
      ) => [
        index + 1,
        eloanId,
        loaneeFiMemberId,
        loaneeName,
        amount,
        get(bankDetails[eloanId], 'bankName'),
        get(bankDetails[eloanId], 'bankAccountNumber'),
        get(bankDetails[eloanId], 'routingNumber'),
        get(bankDetails[eloanId], 'bankBranchName'),
        getDate(scheduledEmiDate),
        getDate(transferredAt),
      ]
    ),
  ]

  const excelData = createExcel({
    bookType: 'xlsx',
    type: 'buffer',
    sheets: [{ name: 'Sheet1', rows: preparedData }],
  })

  downloadExcelFile({
    data: excelData,
    filename: `beftn-${getDate(new Date().toISOString())}.xlsx`,
  })
}

async function fetchBankDetailsAndDownloadExcel(row, fiName) {
  const eloanIds = row.accountContext.map((item) => item.eloanId)

  try {
    const bankDetails = await getBankDetailsForEloanId(eloanIds)

    row.bankDetails = bankDetails

    downloadExcel(row, fiName)
  } catch (error) {
    alert(`could not download csv: ${error.message}`)
  }
}

const getColumns = (financialInstitutions) => [
  { Header: 'Batch ID', accessor: 'id' },
  {
    Header: 'loans No.',
    accessor: ({ accountContext }) => accountContext?.length,
  },
  { Header: 'Total Amt.', accessor: ({ amount }) => displayBDT(amount) },
  { Header: 'Created At', accessor: ({ createdAt }) => getDate(createdAt) },
  {
    Header: 'Partner Payment Date',
    accessor: ({ transferredAt }) => getDate(transferredAt),
  },
  { Header: 'Confirmed By', accessor: 'createdByUserName' },
  {
    Header: 'FI',
    accessor: ({ accountContext }) =>
      financialInstitutions.byId[get(accountContext, '0.fiId')]?.name,
  },
  {
    Header: 'STATUS',
    accessor: ({ isTransferred }) => (
      <Badge
        borderRadius="4px"
        variantColor={isTransferred ? 'green' : 'yellow'}
        variant="solid"
      >
        {isTransferred ? 'confirmed' : 'pending'}
      </Badge>
    ),
  },
  {
    Header: '',
    id: 'action_buttons',
    accessor: (row) => (
      <Flex justify="flex-end">
        <Flex align="center" justify="space-between">
          <Button
            variant="link"
            variantColor="cyan"
            mr={4}
            onClick={() =>
              fetchBankDetailsAndDownloadExcel(
                row,
                financialInstitutions.byId[get(row.accountContext, '0.fiId')]
                  ?.name
              )
            }
          >
            BEFTN EXCEL
          </Button>
          {row.referenceDocument ? (
            <Button
              size="sm"
              variantColor="green"
              variant="link"
              mr={4}
              as={Link}
              href={row.referenceDocument}
              target="_blank"
            >
              VOUCHER
            </Button>
          ) : (
            <Badge mr={4} variantColor="orange" variant="solid">
              no voucher
            </Badge>
          )}
          {!row.transferredAt && (
            <Box>
              <Authorize permissions="eloan:PartnerBeftn:write">
                <UploadSlip beftnId={row.id} />
              </Authorize>
            </Box>
          )}
        </Flex>
      </Flex>
    ),
  },
]

const defaultQueryObject = {
  dateFrom: toISODate(),
  dateTo: toISODate(),
}

const getQueryObject = ({ limit, min, max }) => {
  return defaults(
    {
      limit,
      dateFrom: min,
      dateTo: max,
    },
    defaultQueryObject
  )
}

function PartnerBeftnList() {
  const [filterValues, setFilterValues] = useState(emptyObject)

  const dispatch = useDispatch()

  const limit = useLimitQuery()

  const queryObject = useMemo(
    () => getQueryObject({ limit, ...filterValues }),
    [limit, filterValues]
  )

  useEffect(() => {
    dispatch(getBeftns(queryObject))
  }, [dispatch, queryObject])

  const beftn = useSelector((state) => state.beftn)

  const financialInstitutions = useFinancialInstitutions()

  const data = useMemo(() => beftn.allIds.map((id) => beftn.byId[id]), [beftn])

  const columns = useMemo(() => getColumns(financialInstitutions), [
    financialInstitutions,
  ])

  const table = useTable({
    data,
    columns,
  })

  return (
    <Authorize permissions="eloan:PartnerBeftn:read">
      <Box mb={10}>
        <Heading fontSize={5} mb={4}>
          Partner Payment BEFTN
        </Heading>
        <PartnerBeftnListFilter onSubmit={setFilterValues} />
      </Box>
      <ListTable table={table} />
      <LimitBasedFilter count={data.length} limit={limit} />
    </Authorize>
  )
}

export default PartnerBeftnList
