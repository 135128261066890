import React, { useCallback } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Divider,
  Flex,
  FormLabel,
  Stack,
  useToast,
} from '@chakra-ui/core'
import { useForm } from 'react-hook-form'
import Form from 'components/Form/Form'
import FormSwitch from 'components/Form/Switch'
import FormTextarea from 'components/Form/Textarea'
import * as Yup from 'yup'
import { handleAPIError } from 'components/Form/helpers'
import FormButton from 'components/Form/Button'
import { get } from 'lodash-es'
import ModifierItem from '../Configuration/ModifierItem'
import { getDate } from '@eloan/shared'
import { loanModifierTitle } from '../constants'
import { addLoansInModifier } from 'store/loan-modifier'
import { useDispatch } from 'react-redux'
import InfoMessage from './InfoMessage'

const validationSchema = Yup.object({
  eloanIds: Yup.string().test(
    'is-valid',
    'Must be integer and comma separated.',
    (value) => value && value.trim() && value.match(/^[0-9]+([,.][0-9]+)*$/)
  ),
})

function LoanModifierConfigAddLoans({ loanModifier }) {
  const form = useForm({ validationSchema })

  const { reset, setError, clearError } = form

  const dispatch = useDispatch()
  const toast = useToast()

  const handleSubmit = useCallback(
    async ({ eloanIds, isClient, isFI }) => {
      if (!isClient && !isFI) {
        setError('isClient', 'required', 'Required.')
        setError('isFI', 'required', 'Required.')
        return
      }

      clearError()

      const submitData = {
        eloanIds: eloanIds
          .split(',')
          .filter(Boolean)
          .map((id) => id.trim()),
        accountTypes: [
          ...(isClient ? ['CLIENT'] : []),
          ...(isFI ? ['FI'] : []),
        ],
      }

      try {
        await dispatch(addLoansInModifier(loanModifier.id, submitData))
        reset()
      } catch (error) {
        handleAPIError(error, { toast, form })
      }
    },
    [setError, clearError, loanModifier, toast, dispatch, form, reset]
  )

  return (
    <SimpleGrid columns={2} gridColumnGap={6}>
      <Box>
        <Heading size="md">Add Loans in Modifier</Heading>
        <Divider mb={6} />
        <Form form={form} onSubmit={handleSubmit}>
          <Flex align="flex-start">
            <FormLabel>Add in ShopUp Client Configuration</FormLabel>
            <FormSwitch name="isClient" />
          </Flex>
          <Divider />
          <Flex align="flex-start">
            <FormLabel>Add in FI Configuration</FormLabel>
            <FormSwitch name="isFI" />
          </Flex>
          <Divider mb={6} />
          <FormTextarea
            name="eloanIds"
            label="Paste or enter bulk loan IDs"
            minH="220px"
          />
          <FormButton mt={6} type="submit" variantColor="cyan">
            ADD LOANS
          </FormButton>
        </Form>
        <InfoMessage />
      </Box>
      <Box>
        <Box>
          <Flex justify="space-between" align="center">
            <Heading size="md">{get(loanModifier, 'title')}</Heading>
          </Flex>
          <Stack spacing={4} mt={6}>
            {get(loanModifier, 'config', []).map((item, index) => (
              <ModifierItem
                key={index}
                title={loanModifierTitle[item.id]}
                startDate={getDate(item.startDate)}
                endDate={getDate(item.endDate)}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </SimpleGrid>
  )
}

export default LoanModifierConfigAddLoans
