import { Box, Flex, Heading, SimpleGrid, Stack } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useRetailer } from 'store/retailers/hooks'
import AddRetailerBankInfoModal from './AddBankInfoModal'
import RetailerBankInfo from './BankInfo'

function ViewRetailer() {
  const { retailerId } = useParams()

  const retailer = useRetailer(retailerId)

  const bankInfo = get(retailer, 'bankInfo')

  return (
    <Stack spacing={4}>
      <Box>
        <Heading fontSize={5}>
          Retailer ID: {get(retailer, 'externalId')}
        </Heading>
      </Box>

      <Box borderWidth={1} shadow="md">
        <SimpleGrid p={2}>
          <Box>Shop Name: {get(retailer, 'shopName')}</Box>
          <Box>Owner Name: {get(retailer, 'ownerName')}</Box>
          <Box>Route Code: {get(retailer, 'route.code')}</Box>
          <Box>Route Name: {get(retailer, 'route.name')}</Box>
          <Box>Phone Number: {get(retailer, 'phoneNumber')}</Box>
        </SimpleGrid>
      </Box>

      <Flex
        borderWidth={1}
        shadow="md"
        p={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Heading fontSize={4}>Bank Information</Heading>
        </Box>
        {retailer && !bankInfo && (
          <Box>
            <AddRetailerBankInfoModal retailerId={retailerId} />
          </Box>
        )}
      </Flex>

      {bankInfo && <RetailerBankInfo key={bankInfo.id} bankInfo={bankInfo} />}
    </Stack>
  )
}

export default ViewRetailer
