import { Tag } from '@chakra-ui/core'
import React, { memo } from 'react'

function PorichoyTag({ valid }) {
  return (
    <Tag
      variantColor={
        valid === true ? 'green' : valid === false ? 'red' : 'yellow'
      }
    >
      {valid === true ? 'Valid' : valid === false ? 'Invalid' : 'Pending'}
    </Tag>
  )
}

export default memo(PorichoyTag)
