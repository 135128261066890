import { Stack } from '@chakra-ui/core'
import { getDate } from '@eloan/shared'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import React, { useMemo } from 'react'
import { useAreas } from 'store/areas/hooks'
import { usePAUsers } from 'store/users/hooks'

function LoanDetails({ application }) {
  const areas = useAreas()

  const shopArea = useMemo(
    () => areas.byId[get(application, 'meta.shopAreaId')],
    [application, areas]
  )

  const paUsers = usePAUsers()

  const pa = useMemo(() => {
    return get(paUsers.byId, get(application, 'paAssignment.paId'))
  }, [application, paUsers.byId])

  return (
    <Stack isInline flexWrap="wrap" justifyContent="space-between" spacing={8}>
      <InfoBox label="Loanee Name">
        {get(application, 'meta.loaneeName')}
      </InfoBox>
      <InfoBox label="eLoan ID">{get(application, 'id')}</InfoBox>
      <InfoBox label="Phone">{get(application, 'phoneNumber')}</InfoBox>
      <InfoBox label="Loan Amount">
        {get(application, 'offer.loanAmount')}
      </InfoBox>
      <InfoBox label="Tenure">
        {get(application, 'offer.shopupSelectedTenure')}
      </InfoBox>
      <InfoBox label="Application Date">
        {getDate(get(application, 'createdAt'))}
      </InfoBox>
      <InfoBox label="Area">{get(shopArea, 'name')}</InfoBox>
      <InfoBox label="PA">{get(pa, 'name')}</InfoBox>
    </Stack>
  )
}

export default LoanDetails
