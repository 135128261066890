import FormSelect from 'components/Form/Select'
import React, { useMemo } from 'react'
import { Box } from 'reflexbox'

function ApplicationTypeFilter({ ...props }) {
  const typeOptions = useMemo(
    () => ({
      null: 'Normal',
      'daraz-idlc': 'Daraz-IDLC',
      unilever: 'Unilever',
      'redx-shopup': 'RedX',
      shwanirbhor: 'Shwanirbhor',
      hack: 'Hack',
    }),
    []
  )

  return (
    <Box minWidth="10rem" {...props}>
      <FormSelect name="type" label={`Filter by Type`} options={typeOptions} />
    </Box>
  )
}

export default ApplicationTypeFilter
