import { Box, Button, Flex, Heading, Text } from '@chakra-ui/core'
import { ceilToDecimal, displayBDT } from '@eloan/shared'
import { DataTable } from '@eloan/shared/components/Table'
import Authorize from 'components/Authorize'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTable } from 'react-table'
import useOverdueApplications from 'store/overdue-applications/hooks'

const getColumns = () => [
  {
    Header: 'Eloan Id',
    accessor: 'eloanId',
  },
  {
    Header: 'Retailer Id',
    accessor: 'externalId',
  },
  {
    Header: 'Retailer Name',
    accessor: 'ownerName',
  },
  {
    Header: 'Retailer Phone Number',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Retailer Route Code',
    accessor: 'routeCode',
  },
  {
    Header: 'Total Amount Due',
    accessor: ({ dueAmount }) => displayBDT(ceilToDecimal(dueAmount.total, 0)),
  },
  {
    Header: 'Button',
    accessor: ({ eloanId }) => (
      <Button
        variantColor="blue"
        size="sm"
        px={4}
        as={Link}
        to={`/dash/unilever-applications/${eloanId}?tab=retailer-info`}
      >
        View
      </Button>
    ),
  },
]

const defaultQueryObject = {
  loanProductId: 2,
}

function UnileverOverdueApplications() {
  const { applications } = useOverdueApplications(defaultQueryObject)

  const columns = useMemo(() => getColumns(), [])

  const table = useTable({
    data: applications,
    columns: columns,
  })

  return (
    <Authorize permissions="">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>Unilever Overdue Applications</Heading>
          <Text>Overdue applications for unilever</Text>
        </Box>
      </Flex>
      <DataTable {...table} />
    </Authorize>
  )
}

export default UnileverOverdueApplications
