import {
  Button,
  Checkbox,
  Collapse,
  Heading,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { DataTable } from '@eloan/shared/components/Table'
import { handleAPIError } from 'components/Form/helpers'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTable } from 'react-table'
import { Box, Flex } from 'reflexbox'
import { useDocMeta } from 'store/docMeta/hooks'
import { setApplicationDocRequirement } from 'store/docRequirements'
import { useApplicationDocRequirements } from 'store/docRequirements/hooks'

function StaticEntityGroupItems({
  applicationId,
  entityId,
  items,
  requirementByKey,
}) {
  const toast = useToast()

  const dispatch = useDispatch()
  const onChange = useCallback(
    async (e) => {
      const parts = e.target.name.split('.')
      const staticEntityId = Number(parts[0])
      const field = parts[1]

      try {
        await dispatch(
          setApplicationDocRequirement(applicationId, {
            entityId,
            staticEntityId,
            [field]: e.target.checked,
          })
        )
      } catch (err) {
        handleAPIError(err, { toast })
      }
    },
    [applicationId, dispatch, entityId, toast]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Required',
        id: 'required',
        accessor: (row) => [row.id, row.required],
        Cell: ({ cell: { value } }) => (
          <Checkbox
            name={`${value[0]}.required`}
            isChecked={value[1]}
            onChange={onChange}
          />
        ),
      },
      {
        Header: 'Collected',
        id: 'collected',
        accessor: (row) => [row.id, row.collected],
        Cell: ({ cell: { value } }) => (
          <Checkbox
            isChecked={value[1]}
            name={`${value[0]}.collected`}
            onChange={onChange}
          />
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ cell: { value } }) => <Text textAlign="left">{value}</Text>,
      },
    ],
    [onChange]
  )

  const data = useMemo(() => {
    return items.map((item) => ({
      id: item.id,
      name: item.name,
      required: get(
        requirementByKey[`${entityId}:${item.id}`],
        'required',
        false
      ),
      collected: get(
        requirementByKey[`${entityId}:${item.id}`],
        'collected',
        false
      ),
    }))
  }, [entityId, items, requirementByKey])

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <DataTable
      {...table}
      tableProps={{ sx: { width: 'auto', mb: 4 } }}
      headerProps={{ sx: { p: 1 } }}
      cellProps={{ sx: { p: 1, textAlign: 'center' } }}
    />
  )
}

function StaticEntityGroup({ applicationId, data, docRequirementByKey }) {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Box>
      <Button
        variant="unstyled"
        fontSize={2}
        mb={2}
        onClick={onToggle}
        leftIcon={isOpen ? 'chevron-up' : 'chevron-down'}
      >
        {get(data, 'name')}
      </Button>

      <Collapse isOpen={isOpen}>
        <StaticEntityGroupItems
          applicationId={applicationId}
          entityId={get(data, 'id')}
          items={get(data, 'staticEntities')}
          requirementByKey={docRequirementByKey}
        />
      </Collapse>
    </Box>
  )
}

function DocumentRequirements({ applicationId, ...props }) {
  const docMeta = useDocMeta()
  const docRequirements = useApplicationDocRequirements(applicationId)

  const docRequirementByKey = useMemo(() => {
    return docRequirements.allIds.reduce((byKey, id) => {
      const entityId = get(docRequirements.byId[id], 'entityId', '')
      const staticEntityId = get(docRequirements.byId[id], 'staticEntityId', '')
      const key = [entityId, staticEntityId].join(':')
      byKey[key] = docRequirements.byId[id]
      return byKey
    }, {})
  }, [docRequirements.allIds, docRequirements.byId])

  const ids = useMemo(() => {
    return docMeta.allIds.filter(
      (id) => get(docMeta.byId[id], 'type') === 'static'
    )
  }, [docMeta.allIds, docMeta.byId])

  return (
    <Box {...props}>
      <Heading fontSize={4} mb={3}>
        Document Requirements
      </Heading>

      <Flex flexDirection="row" justifyContent="space-between">
        <Stack spacing={4} mr={4}>
          {ids.map((id) => (
            <StaticEntityGroup
              key={get(docMeta.byId[id], 'key')}
              applicationId={applicationId}
              data={docMeta.byId[id]}
              docRequirementByKey={docRequirementByKey}
            />
          ))}
        </Stack>
      </Flex>
    </Box>
  )
}

export default DocumentRequirements
