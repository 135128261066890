import {
  Badge,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  Stack,
  Tag,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/core'
import {
  displayBDT,
  ExperimentalDataTable,
  handleAPIError,
} from '@eloan/shared'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { MdChatBubbleOutline } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useTable } from 'react-table'
import { Box } from 'reflexbox'
import { sendToFiForCibApproval } from 'store/applications'
import { emptyArray } from 'utils/defaults'
import { getDate } from 'utils/getDate'
import {
  bkashTransactionStatusDisplayText,
  bkashTransactionStatusVariantColor,
  cibApprovalStatusDisplayText,
  cibApprovalStatusVariantColor,
  cibPaymentStatusDisplayText,
  cibPaymentStatusVariantColor,
} from 'utils/meta'
import AddCibBKashTransaction from '../modals/AddCibBKashTransaction'
import CibPaymentAttachment from '../modals/CibPaymentAttachment'
import CibPaymentBankSlip from '../modals/CibPaymentBankSlip'
import CibPaymentVerify from '../modals/CibPaymentVerify'
import CibSignedImage from '../modals/CibSignedImage'

function SendToPartnerForCibApproval({ application }) {
  const [loading, setLoading] = useState(false)

  const toast = useToast()

  const cibApprovalStatus = get(application, 'metaExtra.cibApprovalStatus')

  const dispatch = useDispatch()
  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      await dispatch(sendToFiForCibApproval(get(application, 'id')))
    } catch (err) {
      handleAPIError(err, { toast })
    }
    setLoading(false)
  }, [application, dispatch, setLoading, toast])

  if (['approved', 'rejected', 'awaiting'].includes(cibApprovalStatus)) {
    return null
  }

  return (
    <Button
      variantColor="blue"
      onClick={onSubmit}
      isLoading={loading}
      isDisabled={loading}
    >
      Send to FI for CIB Approval
    </Button>
  )
}

const getColumns = (application) => [
  {
    Header: 'TrxID',
    accessor: 'transactionId',
  },
  {
    Header: 'Status',
    accessor: 'bkashTransaction.status',
    Cell: ({ cell: { value } }) => (
      <Badge variantColor={bkashTransactionStatusVariantColor[value]}>
        {get(bkashTransactionStatusDisplayText, value)}
      </Badge>
    ),
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ cell: { value } }) => displayBDT(value),
  },
  {
    Header: 'Submitted by',
    accessor: 'bkashTransaction.submitter.fullName',
  },
  {
    Header: 'Submission Date',
    accessor: 'bkashTransaction.createdAt',
    Cell: ({ cell: { value } }) => getDate(value, DateTime.DATETIME_MED),
  },
  {
    Header: 'Verified By',
    accessor: 'bkashTransaction.verifier.fullName',
  },
  {
    Header: 'Verification Date',
    accessor: 'bkashTransaction.verifiedAt',
    Cell: ({ cell: { value } }) => getDate(value, DateTime.DATETIME_MED),
  },
  {
    Header: 'Note',
    accessor: 'bkashTransaction.note',
    Cell: ({ cell: { value } }) =>
      value ? (
        <Tooltip label={value} hasArrow>
          <IconButton
            icon={MdChatBubbleOutline}
            variant="ghost"
            type="button"
          />
        </Tooltip>
      ) : null,
  },
  {
    id: 'actions',
    Header: '',
    Cell: ({ row: { original } }) => {
      return (
        <>
          <CibPaymentAttachment cibPayment={original} />
          <CibPaymentVerify
            application={application}
            applicationId={application.id}
            cibPayment={original}
          />
        </>
      )
    },
    Footer: () => {
      return (
        <AddCibBKashTransaction
          applicationId={get(application, 'id')}
          application={application}
        />
      )
    },
  },
]

const cibPaymentsTableStyleProps = {
  enableFooter: true,
  footerProps: {
    sx: {
      borderBottomWidth: 0,
    },
  },
}

function CibInfo({ application, ...props }) {
  const columns = useMemo(() => getColumns(application), [application])

  const data = useMemo(() => {
    const items = get(application, 'cibPayments', emptyArray)
    return items
  }, [application])

  const table = useTable({
    data,
    columns,
  })

  if (get(application, 'type') !== 'daraz-idlc' && !data.length) {
    return <></>
  }

  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        CIB Info
      </Heading>

      <Box mb={4}>
        <ExperimentalDataTable {...cibPaymentsTableStyleProps} {...table} />
      </Box>

      <Flex flexDirection="row" flexWrap="wrap" mb={4}>
        <InfoBox label="CIF" content={get(application, 'metaExtra.fiCif')} />
        <InfoBox
          label="Internal LEAD Ref No"
          content={get(application, 'metaExtra.fiInternalLeadRefNo')}
        />
      </Flex>

      <Stack isInline flexWrap="wrap" spacing={8}>
        <Box>
          <CibSignedImage
            title="Singed CIB Undertaking Letter"
            application={application}
            photoField="metaExtra.cibSignedUndertakingLetter"
            statusField="metaExtra.cibSignedUndertakingLetterStatus"
          />
        </Box>

        <Box>
          <CibSignedImage
            title="Singed CIB Liability Declaration"
            application={application}
            photoField="metaExtra.cibSignedLiabilityDeclaration"
            statusField="metaExtra.cibSignedLiabilityDeclarationStatus"
          />
        </Box>

        <Box>
          <CibPaymentBankSlip application={application} />
        </Box>

        <Box>
          <Heading fontSize={3} my={4}>
            CIB Status
          </Heading>

          <Box mb={5}>
            <Text fontWeight="bold">Payment Status</Text>
            <Tag
              fontSize={4}
              variantColor={
                cibPaymentStatusVariantColor[
                  get(application, 'metaExtra.cibPaymentStatus')
                ]
              }
            >
              {
                cibPaymentStatusDisplayText[
                  get(application, 'metaExtra.cibPaymentStatus')
                ]
              }
            </Tag>
          </Box>

          <Box mb={5}>
            <Text fontWeight="bold">Approval Status</Text>
            <Tag
              fontSize={4}
              variantColor={
                cibApprovalStatusVariantColor[
                  get(application, 'metaExtra.cibApprovalStatus')
                ]
              }
            >
              {cibApprovalStatusDisplayText[
                get(application, 'metaExtra.cibApprovalStatus')
              ] || '...'}
            </Tag>
          </Box>

          <Box>
            <SendToPartnerForCibApproval application={application} />
          </Box>
        </Box>
      </Stack>

      <Flex flexDirection="row" flexWrap="wrap" mt={4}>
        <InfoBox>
          <Link
            fontSize={2}
            href={`${window.location.origin.replace(
              '//eloan.',
              '//sap.'
            )}/shop/capital/rca/rca?eloanId=${application.id}`}
            isExternal
          >
            Please attach bank statement and TL copies (if available){' '}
            <Icon name="external-link" mx="2px" />
          </Link>
        </InfoBox>
      </Flex>
    </Box>
  )
}

export default memo(CibInfo)
