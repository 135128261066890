import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import { handleAPIError } from 'components/Form/helpers'
import { capitalize } from 'lodash-es'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetApplicationNidInfo } from 'store/applications'

function ResetNIDInfoButton({ applicationId, nidOwner, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const cancelRef = useRef()

  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const resetInfo = useCallback(async () => {
    setIsLoading(true)

    try {
      await dispatch(resetApplicationNidInfo(applicationId, { nidOwner }))
    } catch (err) {
      handleAPIError(err, { toast })
    }

    setIsLoading(false)
  }, [applicationId, dispatch, nidOwner, toast])

  return (
    <>
      <Button type="button" variantColor="red" onClick={onOpen} {...props}>
        Reset NID Info
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Reset {capitalize(nidOwner)} NID Information
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards!
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>

            <Button
              type="button"
              variantColor="red"
              isLoading={isLoading}
              isDisabled={isLoading}
              onClick={resetInfo}
              ml={5}
            >
              Reset NID Info
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default ResetNIDInfoButton
