import { useState, useEffect } from 'react'
import { api } from 'api'

export function useMaterializedInfo() {
  const [data, setData] = useState()

  useEffect(() => {
    async function fetchData() {
      const { data } = await api('/eloan/v1/materialized-data/term-loans/info')
      setData(data)
    }

    fetchData()
  }, [])

  return data
}
