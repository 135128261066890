import { map } from 'lodash-es'
import { emptyArray, emptyObject } from 'utils/defaults'

const initialPaginationState = {
  loading: false,
  pages: emptyObject,
  totalPages: 0,
  totalItems: 0,
  itemsPerPage: 0,
  nextLink: null,
}

export const getPaginationReducer = ({
  idKey,
  ADD,
  REMOVE,
  REQUEST,
  PURGE,
}) => {
  if (!idKey) {
    throw new Error('idKey is required')
  }

  return (
    state = initialPaginationState,
    { type, data, page, queryHash = '' }
  ) => {
    switch (type) {
      case ADD:
        return {
          loading: false,
          pages: {
            ...state.pages,
            [page]: {
              loading: false,
              itemIds: map(data.items, idKey),
              queryHash,
            },
          },
          totalPages: data.totalPages,
          totalItems: data.totalItems,
          itemsPerPage: data.itemsPerPage,
          nextLink: data.nextLink,
        }
      case REMOVE:
        return {
          ...state,
          loading: false,
          pages: {
            ...state.pages,
            [page]: undefined,
          },
        }
      case REQUEST:
        return {
          ...state,
          loading: true,
          pages: {
            ...state.pages,
            [page]: {
              loading: true,
              itemIds: emptyArray,
              queryHash,
            },
          },
        }
      case PURGE:
        return initialPaginationState
      default:
        return state
    }
  }
}
