import { Heading, Text } from '@chakra-ui/core'
import { DataTable } from '@eloan/shared/components/Table'
import { api } from 'api'
import Authorize from 'components/Authorize'
import { get, keyBy } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { Box, Flex } from 'reflexbox'
import UpdatePALeave from './UpdatePaLeave'

const usePaAssignmentSummary = () => {
  const [data, setData] = useState(null)

  const refresh = useCallback(async () => {
    const { data } = await api(
      'GET /legacy/v4/eloan/applications/pa-assignments/summary'
    )

    setData(data)
  }, [])

  useEffect(() => {
    refresh()
  }, [refresh])

  return { data, refresh }
}

const getColumns = (dataByPaId, onUpdate) => [
  {
    Header: 'PA Name',
    accessor: 'fullName',
  },
  { Header: 'No. of Open Applications', accessor: 'assignmentCount' },
  {
    Header: 'Transferred Applications',
    accessor: 'transferredAssignments',
    Cell: ({ cell: { value: items } }) => {
      return (
        <>
          {items.map((item) => (
            <Text key={item.id}>
              {item.fullName} ( {item.assignmentCount} )
            </Text>
          ))}
        </>
      )
    },
  },
  {
    Header: 'Status',
    id: 'status',
    accessor: 'transferredAssignments',
    Cell: ({ cell: { value } }) => {
      return (
        <Text color={value.length ? 'red.500' : 'blue.500'}>
          {value.length ? 'On Leave' : 'Active'}
        </Text>
      )
    },
  },

  {
    Header: '',
    id: 'action',
    accessor: 'id',
    Cell: ({ cell: { value } }) => {
      return (
        <UpdatePALeave
          paId={value}
          dataByPaId={dataByPaId}
          onSuccess={onUpdate}
        />
      )
    },
  },
]

function PALeaveManagementPage() {
  const summary = usePaAssignmentSummary()

  const dataByPaId = useMemo(() => {
    return keyBy(get(summary.data, 'items', []), 'id')
  }, [summary.data])

  const columns = useMemo(() => getColumns(dataByPaId, summary.refresh), [
    dataByPaId,
    summary.refresh,
  ])
  const data = useMemo(() => {
    return get(summary.data, 'items', [])
  }, [summary.data])

  const table = useTable({
    data: data,
    columns: columns,
  })

  return (
    <Authorize permissions="eloan:AnyApplicationPAAssignment:set">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={10}
      >
        <Box>
          <Heading fontSize={5}>PA Leave Management</Heading>
        </Box>
      </Flex>

      <DataTable {...table} />
    </Authorize>
  )
}

export default PALeaveManagementPage
