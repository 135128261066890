import { Flex, Heading } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import React, { memo } from 'react'
import { Box } from 'reflexbox'
import { usePartner } from 'store/partners/hooks'

function LoanInfo({ application, ...props }) {
  const partner = usePartner(get(application, 'offer.loanPartner'))

  return (
    <Box {...props}>
      <Heading fontSize={5} mb={4}>
        Offer Info
      </Heading>

      <Flex flexDirection="row" flexWrap="wrap">
        <InfoBox label="Partner" content={get(partner, 'name')} />
        <InfoBox
          label="Amount"
          content={get(application, 'offer.loanAmount')}
        />
        <InfoBox
          label="Min Amount"
          content={get(application, 'offer.minLoanAmount')}
        />
        <InfoBox
          label="Min Tenure"
          content={get(application, 'offer.minGivenTenure')}
        />
        <InfoBox
          label="Max Amount"
          content={get(application, 'offer.maxLoanAmount')}
        />
        <InfoBox
          label="Max Tenure"
          content={get(application, 'offer.maxGivenTenure')}
        />
        <InfoBox
          label="Estimated EMI"
          content={Math.round(
            get(application, 'offer.shopupRepaymentTotal', 0) /
              get(application, 'offer.shopupSelectedTenure', 0)
          )}
        />
        <InfoBox
          label="Platform Fee"
          content={get(application, 'offer.platformFees')}
        />
      </Flex>
    </Box>
  )
}

export default memo(LoanInfo)
