import React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom'
import EloanApplicationView from './View'

function EloanApplicationsPage() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route
        path={`${path}/:applicationId/:tabId?`}
        component={EloanApplicationView}
      />
      <Redirect to={`${path}/:applicationId/:tabId?`} />
    </Switch>
  )
}

export default EloanApplicationsPage
