import React, { useMemo } from 'react'
import { SimpleGrid, Text, Button } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { get } from 'lodash-es'
import { getDate } from 'utils/getDate'
import { toRounded2F2PNumber } from '@eloan/shared'
import { Link } from 'react-router-dom'
import { CountUpNumber } from '@eloan/shared/components/Animated'

function OfferItems({ application }) {
  const isLoading = useMemo(() => !application, [application])
  return (
    <SimpleGrid
      columns={7}
      gridColumnGap={2}
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      pb={0}
      mb={6}
    >
      <InfoBox label="eLoan ID" color="gray.600">
        <Text fontSize="md" color="black">
          {get(application, 'id')}
        </Text>
      </InfoBox>
      <InfoBox label="Applied Amount">
        <Text color="orange.500" fontSize="md">
          <CountUpNumber
            loading={isLoading}
            number={get(application, 'loanAmount')}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox label="Offered Amount" color="gray.600">
        <Text color="green.500" fontSize="md">
          <CountUpNumber
            loading={isLoading}
            number={get(application, 'offer.loanAmount')}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox label="Application Date" color="gray.600">
        <Text fontSize="md" color="black">
          {getDate(get(application, 'createdAt'))}
        </Text>
      </InfoBox>
      <InfoBox label="Name" color="gray.600">
        <Text fontSize="md" color="black">
          {get(application, 'meta.loaneeName')}
        </Text>
      </InfoBox>
      <InfoBox label="Phone Number" color="gray.600">
        <Text fontSize="md" color="black">
          {get(application, 'phoneNumber')}
        </Text>
      </InfoBox>
      <InfoBox label="Link">
        <Button
          leftIcon="external-link"
          variant="link"
          variantColor="cyan"
          size="sm"
          as={Link}
          to={`/dash/applications/${get(application, 'id')}`}
          target="_blank"
          m={0}
          p={0}
        >
          Link to applicant info
        </Button>
      </InfoBox>
    </SimpleGrid>
  )
}

export default OfferItems
