export const productType = {
  TERM: 'Term',
  RL: 'RL',
  OD: 'OD',
}

export const dayUnits = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
}

export const booleanOptions = {
  true: 'Yes',
  false: 'No',
}

export const formOptions = {
  booleanOptions,
  productType,
  dayUnits,
  daysInYear: {
    360: '360',
    365: '365',
  },
  interestCalculationMethod: {
    FLAT: 'Flat',
    DECLINING_BALANCE: 'Declining Balance',
  },
  interestTimePeriodCountCriteria: {
    ACTUAL_DAYS_COUNT: 'Actual Days Count',
    REPAYMENT_PERIODICITY: 'Repayment Periodicity',
  },
  interestType: {
    SIMPLE: 'Simple',
    COMPOUND: 'Comound',
  },
  penaltyCalculationMethod: {
    NONE: 'None',
    INTEREST: 'Interest',
    FLAT_FEE: 'Flat Fee',
    FLAT_FEE_WITH_INTEREST: 'Flat Fee With Interest',
  },
  toleranceAmountType: {
    FIXED: 'Fixed',
    PERCENTAGE: 'Percentage',
  },
  repaymentStrategy: {
    PRINCIPAL: 'Principal',
    INTEREST: 'Interest',
    PENALTY: 'Penalty',
  },
}
