import { Box, Button, Flex, Heading } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import LimitBasedFilter from 'components/LimitBasedFilter/LimitBasedFilter'
import ListTable from 'components/ListTable/ListTable'
import useLimitQuery from 'hooks/useLimitQuery'
import { isNil, omitBy } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { useRedXCollection } from 'store/collections/hooks'
import { emptyObject } from 'utils/defaults'
import { getDate } from 'utils/getDate'
import FinanceCollectionPageFilter from './Filter'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

const getColumns = () => [
  {
    Header: 'COLLECTION DATE',
    accessor: ({ transactionDate }) => getDate(transactionDate),
  },
  {
    Header: 'eLOAN ID',
    accessor: ({ eloanId }) => (
      <Button
        size="sm"
        variantColor="cyan"
        variant="link"
        as={Link}
        to={`/dash/eloan-applications/${eloanId}`}
      >
        {eloanId}
      </Button>
    ),
  },
  {
    Header: 'SHOP ID',
    accessor: 'shopId',
  },
  {
    Header: 'SHOP NAME',
    accessor: 'shopName',
  },
  {
    Header: 'REDX INVOICE',
    accessor: 'invoiceId',
  },
  {
    Header: 'COLLECTION AMOUNT',
    accessor: 'collectionAmount',
  },
  {
    Header: 'PRINCIPAL',
    accessor: 'principalAmount',
  },
  {
    Header: 'INTEREST',
    accessor: 'interestAmount',
  },
]

const getRowProps = (_, row) => ({
  sx: {
    background: row.index % 2 === 0 ? '#f4f7fb' : 'white',
  },
})

const getQueryObject = ({
  limit,
  offset = 0,
  invoiceId,
  shopId,
  eloanId,
  min: startDate = DateTime.local().minus({ days: 7 }).toISODate(),
  max: endDate = DateTime.local().toISODate(),
}) => ({
  limit,
  offset,
  invoiceId,
  shopId,
  eloanId,
  startDate,
  endDate,
})

function FinanceCollectionPage() {
  const [filterValues, setFilterValues] = useState(emptyObject)

  const limit = useLimitQuery({ maxLimit: 50 })

  const queryObject = useMemo(
    () => getQueryObject({ ...filterValues, limit }),
    [filterValues, limit]
  )

  const [collections = [], totalCount = 0] = useRedXCollection(queryObject)

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(() => collections, [collections])

  const table = useTable({
    data,
    columns,
  })

  const getDownloadUrl = useCallback(() => {
    const { invoiceId, shopId, startDate, endDate, eloanId } = queryObject

    const params = new URLSearchParams(
      omitBy({ eloanId, invoiceId, shopId, startDate, endDate }, isNil)
    )

    const url = `/api/eloan/v1/redx/collections/csv${
      params ? `?${params}` : ''
    }`

    return url
  }, [queryObject])

  return (
    <Authorize permissions="eloan:RedXTransaction:read">
      <Box>
        <Heading size="lg" mb={10}>
          Collection
        </Heading>
      </Box>
      <Flex align="flex-start">
        <FinanceCollectionPageFilter onSubmit={setFilterValues} />
        <Button
          as="a"
          href={getDownloadUrl()}
          leftIcon="download"
          variantColor="blue"
          variant="outline"
          ml={4}
        >
          Download CSV
        </Button>
      </Flex>
      <ListTable table={table} getRowProps={getRowProps} />
      <LimitBasedFilter
        limit={limit}
        total={totalCount}
        count={data.length}
        limitList={[20, 30, 40, 50]}
      />
    </Authorize>
  )
}

export default FinanceCollectionPage
