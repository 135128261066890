import { defaultsDeep, keyBy, map, union } from 'lodash-es'
import { emptyArray, emptyObject } from 'utils/defaults'
import { CCA_DATA_LOADING, CCA_DATA_LOADED, CCA_ADD_BULK } from 'store/cca'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  loading: emptyObject,
}
const idKey = 'applicationId'

const ccaReducer = (state = initialState, { type, data, applicationId }) => {
  switch (type) {
    case CCA_DATA_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [`${applicationId}`]: true,
        },
      }
    case CCA_DATA_LOADED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [applicationId]: defaultsDeep(data, state.byId[applicationId]),
        },
        allIds: union(state.allIds, [applicationId]),
        loading: {
          ...state.loading,
          [`${applicationId}`]: false,
        },
      }
    case CCA_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
      }
    default:
      return state
  }
}

export default ccaReducer
