import { useDisclosure } from '@chakra-ui/core'
import Sidebar from 'components/Sidebar'
import Topbar from 'components/Topbar'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Box, Flex } from 'reflexbox'
import ApplicationsPage from './applications'
import CollectionSummaryPage from './collection-summary'
import DistributorsPage from './distributors'
import FieldAgentsPage from './field-agents'
import OffersPage from './offers'
import OverdueApplicationsPage from './overdue-applications'
import RetailersPage from './retailers'
import SettlementsPage from './settlements'
import ShwanirbhorApplicationsPage from './shwanirbhor-applications'
import UnassignedApplicationsPage from './unassigned-applications'
import UnileverApplicationsPage from './unilever-applications'
import NobdarApplicationPage from './nobodar-applications'
import RepaymentFollowupPage from './repayment-followup'
import AdminPage from './admin'
import EloanApplicationsPage from './eloan-applications'
import FinancePage from './finance'
import UnileverRLSummaryPage from './unilever-rl-summary'

function Dashboard() {
  const { isOpen, onToggle, onClose } = useDisclosure(true)

  return (
    <>
      <Topbar />

      <Flex height="100%" sx={{ position: 'relative' }}>
        <Sidebar isOpen={isOpen} onToggle={onToggle} onClose={onClose} />

        <Box
          flexGrow="1"
          overflowY="scroll"
          width="auto"
          p={10}
          sx={{
            transition: '0.2s',
            height: ({ sizes }) => `calc(100% - ${sizes.navbar})`,
            ml: ({ sizes }) => (isOpen ? sizes.sidebar : '1rem'),
            mt: ({ sizes }) => sizes.navbar,
          }}
        >
          <Switch>
            <Redirect exact from="/dash" to="/dash/applications" />
            <Route path="/dash/admin" component={AdminPage} />
            <Route path="/dash/finance" component={FinancePage} />
            <Route
              path="/dash/eloan-applications"
              component={EloanApplicationsPage}
            />
            <Route path="/dash/applications" component={ApplicationsPage} />
            <Route
              path="/dash/repayment-followup"
              component={RepaymentFollowupPage}
            />
            <Route
              path="/dash/shwanirbhor-applications"
              component={ShwanirbhorApplicationsPage}
            />
            <Route path="/dash/distributors" component={DistributorsPage} />
            <Route path="/dash/retailers" component={RetailersPage} />
            <Route
              path="/dash/unilever-applications"
              component={UnileverApplicationsPage}
            />
            <Route
              path="/dash/nobodar-applications"
              component={NobdarApplicationPage}
            />
            <Route
              path="/dash/unassigned-applications"
              component={UnassignedApplicationsPage}
            />
            <Route path="/dash/offers" component={OffersPage} />
            <Route
              path="/dash/users/field-agents"
              component={FieldAgentsPage}
            />
            <Route path="/dash/settlements" component={SettlementsPage} />
            <Route
              path="/dash/overdue-applications"
              component={OverdueApplicationsPage}
            />
            <Route
              path="/dash/collection-summary"
              component={CollectionSummaryPage}
            />
            <Route
              path="/dash/unilever-rl-summary"
              component={UnileverRLSummaryPage}
            />
          </Switch>
        </Box>
      </Flex>
    </>
  )
}

export default Dashboard
