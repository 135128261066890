import { get } from 'lodash-es'
import { saveAs } from 'file-saver'

export function downloadAsCSV({ data = [], fileName = 'download.csv' }) {
  if (!Array.isArray(data)) return

  let csvContent = ''

  const headers = Object.keys(get(data, '0', {})).join(',')

  csvContent += headers

  for (let index in data) {
    if (index < data.length) {
      csvContent += '\r\n'
    }
    csvContent += Object.values(data[index])
      .map((value) => {
        if (typeof value === 'string' && value.search(/("|,|\n)/g) >= 0) {
          return `"${value}"`
        }
        return value
      })
      .join(',')
  }

  const csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  saveAs(csvData, fileName)
}
