import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDocMeta } from 'store/docMeta'

export function useDocMeta(forceRefresh = false) {
  const docMeta = useSelector((state) => state.docMeta)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!docMeta.allIds.length || forceRefresh) {
      dispatch(getAllDocMeta())
    }
  }, [dispatch, docMeta.allIds.length, forceRefresh])

  return docMeta
}
