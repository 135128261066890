import React, { useCallback, useState } from 'react'
import { useToast } from '@chakra-ui/core'
import { FileUpload } from '@eloan/shared'
import { useDispatch } from 'react-redux'
import { handleAPIError } from 'components/Form/helpers'
import { uploadNidSelfie, uploadNidPhoto } from 'store/applications'
import { toLower } from 'lodash-es'
import Authorize from 'components/Authorize'

export const nidTypes = {
  nidSelfie: 'nidSelfie',
  nidPhoto: 'nidPhoto',
}

const MAX_SIZE = 1024 * 1024

function UploadNID({
  label,
  type,
  status,
  side,
  owner = 'LOANEE',
  applicationId,
  ...props
}) {
  const dispatch = useDispatch()
  const toast = useToast()

  const [submitting, setSubmitting] = useState(false)

  const handleChange = useCallback(
    async (file) => {
      if (file?.size > MAX_SIZE) {
        toast({
          title: 'Size must be within 1 MB.',
          status: 'error',
        })
        return
      }

      try {
        setSubmitting(true)

        const formData = new FormData()
        formData.append(type, file)
        formData.append('nidOwner', owner)

        if (type === nidTypes.nidSelfie) {
          await dispatch(uploadNidSelfie(applicationId, formData))
        } else {
          formData.append('nidPhotoSide', side)
          await dispatch(uploadNidPhoto(applicationId, formData))
        }
      } catch (error) {
        handleAPIError(error, { toast })
      }

      setSubmitting(false)
    },
    [type, dispatch, toast, owner, applicationId, side]
  )

  if (toLower(status) === 'accepted') {
    return null
  }

  return (
    <Authorize permissions="eloan:Application:update">
      <FileUpload
        uploading={submitting}
        onChange={handleChange}
        label={label}
        accept="image/jpeg"
        mt={4}
      />
    </Authorize>
  )
}

export default UploadNID
