import { Duration } from 'luxon'

export function convertToDays({ unit, value }) {
  return {
    unit: 'DAY',
    value: Duration.fromObject({
      ...(unit === 'YEAR' && { years: value }),
      ...(unit === 'MONTH' && { months: value }),
      ...(unit === 'WEEK' && { weeks: value }),
      ...(unit === 'DAY' && { days: value }),
    }).as('days'),
  }
}

export function findInstallmentCount(installment, tenure) {
  installment = convertToDays(installment)
  tenure = convertToDays(tenure)

  return tenure.value / installment.value
}
