import React, { useCallback } from 'react'
import Form from 'components/Form/Form'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { FormDateRangePicker } from 'components/Form/DatePicker'
import FormButton from 'components/Form/Button'
import { Heading, Flex } from '@chakra-ui/core'

const validationSchema = Yup.object({
  date: Yup.object({
    start: Yup.date().required('Required.'),
    end: Yup.date().required('Required.'),
  }),
})

function Modifier({ title, onSubmit, rightHeader }) {
  const form = useForm({ validationSchema })

  const { reset } = form

  const handleSubmit = useCallback(
    (values) => {
      onSubmit(values)
      reset()
    },
    [reset, onSubmit]
  )

  return (
    <Form form={form} onSubmit={handleSubmit}>
      <Flex align="center" mb={4}>
        <Heading size="md" mr={4}>
          {title}
        </Heading>
        {rightHeader}
      </Flex>
      <FormDateRangePicker label="Select Date" name="date" />
      <FormButton size="sm" px={6} mt={4} type="submit" variantColor="cyan">
        ADD MODIFIER
      </FormButton>
    </Form>
  )
}

export default Modifier
