import FormInput from 'components/Form/Input'
import React from 'react'
import { Box } from 'reflexbox'

function ApplicationMemberNoFilter({ ...props }) {
  return (
    <Box {...props}>
      <FormInput
        placeholder="Member ID"
        label="Member ID"
        name="paymentDetails.memberNumber"
      />
    </Box>
  )
}

export default ApplicationMemberNoFilter
