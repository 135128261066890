import { defaultsDeep, get, keyBy, map, union } from 'lodash-es'
import {
  APPLICATION_ADD,
  APPLICATION_ADD_BULK,
  APPLICATION_DATA_LOADED,
  APPLICATION_DATA_LOADING,
  APPLICATION_UPDATE,
  APPLICATION_UPDATE_BULK,
} from 'store/applications'
import { emptyArray, emptyObject } from 'utils/defaults'

const idKey = 'id'

const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
  dataStatus: emptyObject,
}

const applicationsReducer = (
  state = initialState,
  { type, data, applicationId, fields }
) => {
  switch (type) {
    case APPLICATION_DATA_LOADING:
      return {
        ...state,
        dataStatus: {
          ...state.dataStatus,
          [`${applicationId}:${fields}`]: 'loading',
        },
      }

    case APPLICATION_DATA_LOADED:
      return {
        ...state,
        byId: {
          ...state.byId,
          [applicationId]: defaultsDeep(data, state.byId[applicationId]),
        },
        allIds: union(state.allIds, [applicationId]),
        dataStatus: {
          ...state.dataStatus,
          [`${applicationId}:${fields}`]: 'loaded',
        },
      }

    case APPLICATION_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [applicationId]: {
            ...data,
          },
        },
        allIds: union(state.allIds, [applicationId]),
      }

    case APPLICATION_UPDATE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [applicationId]: defaultsDeep(data, state.byId[applicationId]),
        },
      }

    case APPLICATION_ADD_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...keyBy(data.items, idKey),
        },
        allIds: union(state.allIds, map(data.items, idKey)),
        dataStatus: emptyObject,
      }

    case APPLICATION_UPDATE_BULK:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...data.items.reduce((byId, item) => {
            byId[item.id] = {
              ...get(state.byId, item.id, emptyObject),
              ...item,
            }
            return byId
          }, {}),
        },
      }

    default:
      return state
  }
}

export default applicationsReducer
