import Authorize from 'components/Authorize'
import FormSelect from 'components/Form/Select'
import { zipObject, get } from 'lodash-es'
import React, { useMemo } from 'react'
import { Box } from 'reflexbox'
import { usePAUsers } from 'store/users/hooks'

function PAAssignmentFilter({ ...props }) {
  const paUsers = usePAUsers()

  const paUserOptions = useMemo(() => {
    return Object.assign(
      zipObject(
        paUsers.allIds,
        paUsers.allIds.map(
          (id) =>
            `[${get(paUsers.byId[id], 'type') === 'online' ? 'O' : 'F'}] ${get(
              paUsers.byId[id],
              'name'
            ).slice(0, 15)}...`
        )
      )
    )
  }, [paUsers])

  return (
    <Authorize permissions="eloan:AnyApplication:read">
      <Box minWidth="12rem" {...props}>
        <FormSelect
          name="paAssignment.paId"
          label={`Filter by PA`}
          options={paUserOptions}
        />
      </Box>
    </Authorize>
  )
}

export default PAAssignmentFilter
