import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RepaymentFollowupCCAList from './List'

function RepaymentFollowupCCA() {
  return (
    <Switch>
      <Route
        path="/dash/repayment-followup/cca"
        component={RepaymentFollowupCCAList}
      />
    </Switch>
  )
}

export default RepaymentFollowupCCA
