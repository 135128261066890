import { FormErrorMessage } from '@chakra-ui/core'
import { get } from 'lodash-es'
import React from 'react'
import { useFormContext } from 'react-hook-form'

function ErrorMessage({ name, as: Component = FormErrorMessage }) {
  const { errors } = useFormContext()

  const error = get(errors, name)

  return error ? <Component color="red.500">{error.message}</Component> : null
}

export default ErrorMessage
