import { Badge, Button, Checkbox, Text } from '@chakra-ui/core'
import { displayBDT } from '@eloan/shared'
import { ExperimentalDataTable } from '@eloan/shared/components/Table'
import ApplicationListHeader from 'components/ApplicationList/Header'
import Authorize from 'components/Authorize'
import LimitBasedFilter from 'components/LimitBasedFilter/LimitBasedFilter'
import useLimitQuery from 'hooks/useLimitQuery'
import { filter, find, get, take, uniq, uniqBy } from 'lodash-es'
import AssignAgent from 'pages/repayment-followup/AssignAgent'
import { teamOptionsCallTeam } from 'pages/repayment-followup/constants'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useRowSelect, useSortBy, useTable } from 'react-table'
import { useAreas } from 'store/areas/hooks'
import { useCurrentUser } from 'store/currentUser/hooks'
import {
  fetchCallTeamLoanList,
  selectAllCallTeamLoan,
} from 'store/repaymentFollowup/callTeamSlice'
import { useCallAgentUsers } from 'store/users/hooks'
import { emptyArray, emptyObject } from 'utils/defaults'
import RepaymentFollowupCallTeamFilter from './Filter'

const getColumns = () => [
  {
    Header: ({ getToggleAllRowsSelectedProps }) => {
      const { checked, ...props } = getToggleAllRowsSelectedProps()
      return <Checkbox isChecked={checked} {...props} mt={1} />
    },
    accessor: 'id',
    Cell: ({ row }) => {
      const { checked, ...props } = row.getToggleRowSelectedProps()
      return <Checkbox isChecked={checked} {...props} mt={1} />
    },
    disableSortBy: true,
  },
  { Header: 'eLoan ID', accessor: 'applicationId' },
  { Header: 'Loanee Name', accessor: 'application.applicationMeta.loaneeName' },
  {
    Header: 'Loanee Phone',
    accessor: 'application.phoneNumber',
    Cell: ({ cell: { value } }) => (value ? value.replace(/^88/, '') : value),
  },
  {
    Header: 'Guarantor Phone',
    accessor: 'application.applicationMeta.guarantorPhoneNumber',
    Cell: ({ cell: { value } }) => (value ? value.replace(/^88/, '') : value),
  },
  { Header: 'Area', accessor: 'areaName' },
  {
    Header: 'Disbursed Amount',
    accessor: 'offer.loanAmount',
    Cell: ({ cell: { value } }) => displayBDT(value),
  },
  {
    Header: 'Due Till Date',
    accessor: 'dueAmount',
    Cell: ({ cell: { value } }) => (
      <Text color="red.500">{displayBDT(value)}</Text>
    ),
  },
  { Header: 'Tag', accessor: 'tag' },
  { Header: 'DPD', accessor: 'dpd' },
  {
    Header: 'Last Call Status',
    accessor: 'lastCallStatus',
    Cell: ({ cell: { value } }) => <Badge variant="solid">{value}</Badge>,
  },
  { Header: 'Assigned To', accessor: 'agentName' },
  {
    Header: '',
    id: 'action_button',
    accessor: 'id',
    Cell: ({ cell: { value } }) => (
      <Authorize permissions="eloan:AnyApplication:read">
        <Button
          as={Link}
          to={`/dash/repayment-followup/call-team/${value}`}
          size="sm"
          variant="link"
          variantColor="cyan"
          rightIcon="chevron-right"
          target="_blank"
        >
          VIEW
        </Button>
      </Authorize>
    ),
    disableSortBy: true,
  },
]

const getRowId = (row) => row.applicationId

const getQueryObject = ({ agentId, minDpd, maxDpd }, limit) => ({
  limit,
  agentId,
  minDpd,
  maxDpd,
})

function RepaymentFollowupCallTeamList() {
  const [selectedIds, setSelectedIds] = useState(emptyArray)

  const [filterValues, setFilterValues] = useState(emptyObject)

  const limit = useLimitQuery()

  const queryObject = useMemo(() => getQueryObject(filterValues, limit), [
    filterValues,
    limit,
  ])

  const user = useCurrentUser()

  const isCallAgent = useMemo(
    () => find(get(user, 'roles', []), (role) => role.id === 8),
    [user]
  )

  const dispatch = useDispatch()

  const fetchList = useCallback(async () => {
    await dispatch(
      fetchCallTeamLoanList({
        // temp hack to filter by agent
        ...queryObject,
        agentId: isCallAgent ? user.id : queryObject.agentId,
      })
    )
  }, [queryObject, dispatch, isCallAgent, user])

  useEffect(() => {
    fetchList()
  }, [fetchList])

  const callAgentUsers = useCallAgentUsers()

  const callTeamLoanList = useSelector(selectAllCallTeamLoan)

  const areas = useAreas()

  const tags = useMemo(() => {
    return uniqBy(callTeamLoanList, 'tag').map((item) => item.tag)
  }, [callTeamLoanList])

  const callStatuses = useMemo(() => {
    return uniq(callTeamLoanList.map((item) => get(item, 'lastCallStatus')))
  }, [callTeamLoanList])

  const data = useMemo(() => {
    const filterAttributes = {
      ...(typeof filterValues.lastCallStatus !== 'undefined' && {
        lastCallStatus: filterValues.lastCallStatus,
      }),
      ...(filterValues.eloanId && {
        applicationId: parseInt(filterValues.eloanId),
      }),
      ...(filterValues.tag && {
        tag: filterValues.tag,
      }),
      ...(filterValues.areaId && {
        application: {
          applicationMeta: {
            shopAreaId: parseInt(filterValues.areaId),
          },
        },
      }),
    }

    const listFiltered = filter(callTeamLoanList, filterAttributes)

    const listMapped = listFiltered.map((item) => ({
      ...item,
      agentName: get(
        callAgentUsers.byId[get(item, 'callAgent.agentId')],
        'name'
      ),
      areaName: get(
        areas.byId[get(item, 'application.applicationMeta.shopAreaId')],
        'name'
      ),
    }))

    return {
      items: listMapped,
      itemsWithLimit: take(listMapped, limit),
    } // handle total count for now
  }, [
    filterValues.lastCallStatus,
    filterValues.eloanId,
    filterValues.tag,
    filterValues.areaId,
    callTeamLoanList,
    limit,
    callAgentUsers.byId,
    areas.byId,
  ])

  const columns = useMemo(() => getColumns(), [])

  const table = useTable(
    {
      data: data.itemsWithLimit,
      columns,
      getRowId,
    },
    useSortBy,
    useRowSelect
  )

  useEffect(() => {
    setSelectedIds(
      Object.keys(table.state.selectedRowIds).filter(
        (id) => table.state.selectedRowIds[id]
      )
    )
  }, [table.state.selectedRowIds])

  return (
    <Authorize permissions="eloan:AnyApplication:read, eloan:Application:read">
      <ApplicationListHeader
        title={`Repayment Call Team`}
        Right={
          <Authorize permissions="eloan:AnyApplication:update">
            <AssignAgent
              applicationIds={selectedIds}
              teamOptions={teamOptionsCallTeam}
              onSubmitCallback={fetchList}
            />
          </Authorize>
        }
      />
      <RepaymentFollowupCallTeamFilter
        isCallAgent={isCallAgent}
        agents={callAgentUsers}
        areas={areas}
        tags={tags}
        callStatuses={callStatuses}
        onSubmit={setFilterValues}
      />
      {filterValues.agentId && (
        <Badge variant="solid" mb={4} py={0.5} px={2}>
          Total Assigned Loans: {callTeamLoanList.length}
        </Badge>
      )}
      <ExperimentalDataTable
        {...table}
        getHeaderProps={(headerProps, column) => ({
          ...headerProps,
          ...column.getSortByToggleProps(),
          children: (
            <>
              {column.render('Header')}
              <span>
                {column.isSorted ? (column.isSortedDesc ? '▼' : '▲') : ''}
              </span>
            </>
          ),
        })}
      />
      <LimitBasedFilter
        count={data.itemsWithLimit?.length}
        total={data.items?.length}
        limit={limit}
      />
    </Authorize>
  )
}

export default RepaymentFollowupCallTeamList
