import {
  Box,
  Button,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import {
  displayBDT,
  Form,
  FormButton,
  FormInput,
  handleAPIError,
} from '@eloan/shared'
import Authorize from 'components/Authorize'
import { get } from 'lodash-es'
import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { uploadCibPaymentAttachment } from 'store/applications'
import { getPrivateImageSrc } from 'utils/getPrivateImageSrc'

function CibPaymentAttachment({ cibPayment, ...props }) {
  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const form = useForm()

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ attachment }) => {
      try {
        await dispatch(
          uploadCibPaymentAttachment(
            get(cibPayment, 'eloanId'),
            get(cibPayment, 'id'),
            { attachment: attachment[0] }
          )
        )
        onClose()
      } catch (err) {
        handleAPIError(err, { toast, form })
      }
    },
    [cibPayment, dispatch, form, onClose, toast]
  )

  const status = get(cibPayment, 'bkashTransaction.status')

  const attachment = get(cibPayment, 'bkashTransaction.attachment')

  if (status !== 'verified') {
    return null
  }

  return (
    <Authorize permissions="eloan:Application:update">
      <Box {...props}>
        <Button
          leftIcon="attachment"
          variantColor={attachment ? 'blue' : 'red'}
          onClick={onOpen}
          children={attachment ? 'Open' : 'Upload'}
        />

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader>CIB Payment Attachment</ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                {attachment ? (
                  <Stack flexDirection="row" justifyContent="center">
                    <Box
                      mb={4}
                      as={Link}
                      display="block"
                      href={getPrivateImageSrc(attachment)}
                      target="_blank"
                      size={64}
                      sx={{ position: 'relative' }}
                    >
                      <Image
                        size="100%"
                        objectFit="cover"
                        src={getPrivateImageSrc(attachment)}
                        fallbackSrc="https://via.placeholder.com/128?text=N/A"
                      />
                    </Box>
                    <Stack ml={4}>
                      <Box>
                        <Text fontWeight="bold">Transaction ID</Text>
                        <Text>{get(cibPayment, 'transactionId')}</Text>
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Amount</Text>
                        <Text>{displayBDT(get(cibPayment, 'amount'))}</Text>
                      </Box>
                    </Stack>
                  </Stack>
                ) : (
                  <FormInput name="attachment" type="file" label="Attachment" />
                )}
              </ModalBody>

              <ModalFooter>
                <Button type="button" mr={3} onClick={onClose}>
                  Close
                </Button>
                {attachment ? null : (
                  <FormButton type="submit">Upload</FormButton>
                )}
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default CibPaymentAttachment
