import { useState, useEffect } from 'react'
import { emptyObject } from 'utils/defaults'
import { api } from 'api'
import { keyBy, map } from 'lodash-es'

export function useFiPayableData({ loanProductId, fiId, eloanId }) {
  const [data, setData] = useState(emptyObject)

  useEffect(() => {
    async function fetchData() {
      const { data } = await api(
        `/eloan/v1/fi-payments{?loanProductId,fiId,eloanId}`,
        {
          loanProductId,
          fiId,
          eloanId,
        }
      )

      setData({
        byId: keyBy(data.items, 'eloanId'),
        allIds: map(data.items, 'eloanId'),
      })
    }
    fetchData()
  }, [loanProductId, fiId, eloanId])

  return data
}
