import React, { useMemo, useState } from 'react'
import { Box, Text, SimpleGrid } from '@chakra-ui/core'
import { displayBDT, getDate, toRounded2F2PNumber } from '@eloan/shared'
import { useLoaneeRepayments } from 'store/admin/hooks'
import { get, map } from 'lodash-es'
import ListTable from 'components/ListTable/ListTable'
import { memo } from 'react'
import InfoBox from 'components/Box/InfoBox'
import { CountUpNumber } from '@eloan/shared/components/Animated'
import { formOptions } from 'pages/admin/constants/options'
import { prepareLoaneeRepayment } from 'pages/eloan-applications/utils/repayments'
import { useTable } from 'react-table'
import RepaymentFilter from './RepaymentFilter'
import { useCollections } from 'store/collections/hooks'
import CollectionInfo from './CollectionInfo'
import { useAccountRedxTransactions } from 'store/accounts/hooks'

const RepaymentSummary = memo(({ summary }) => {
  const isLoading = useMemo(() => typeof summary === 'undefined', [summary])

  return (
    <SimpleGrid columns={8} spacing={2} mb={4}>
      <InfoBox color="gray.500" label="Disbursed Amount">
        <Text fontSize="md" color="black">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'disbursedAmount')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Outstanding Amount">
        <Text fontSize="md" color="orange.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'outstandingAmount')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Total Collection">
        <Text fontSize="md" color="green.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'totalCollection')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Current Month's Payable">
        <Text fontSize="md" color="green.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'currentMonthsPayable.total')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Total Paid to Partner">
        <Text fontSize="md" color="red.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'totalPaidToPartner')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Overdue Till Date">
        <Text fontSize="md" color="red.500">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'overdueTillDate')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="ShopUp Tenure">
        <Text fontWeight={600} fontSize="md" color="black">
          {get(summary, 'tenure.value')}{' '}
          {get(formOptions.dayUnits, get(summary, 'tenure.unit'))}
        </Text>
      </InfoBox>
      <InfoBox color="gray.500" label="Early Payment Bonus">
        <Text fontSize="md" color="black">
          <CountUpNumber
            loading={isLoading}
            number={parseFloat(get(summary, 'totalEarlyPaymentBonus')) || 0}
            prefix="BDT "
            formatter={toRounded2F2PNumber}
          />
        </Text>
      </InfoBox>
    </SimpleGrid>
  )
})

function displayBDTAsFloat(value) {
  return value !== undefined && displayBDT(parseFloat(value))
}

const getColumns = () => [
  { Header: 'SL', Cell: ({ row }) => row.index + 1 },
  {
    Header: 'Actual Repayment Date',
    accessor: ({ endDate }) => getDate(endDate),
  },
  {
    Header: 'Loanee Repayment Date',
    accessor: ({ offsetedEndDate }) => getDate(offsetedEndDate),
  },
  { Header: 'EMI no.', accessor: ({ id }) => id },
  {
    Header: 'Target ShopUp EMI',
    accessor: ({ targetEMI }) => displayBDTAsFloat(targetEMI),
  },
  {
    Header: 'Collection Date',
    accessor: ({ deposits }) => (
      <Box>
        {map(deposits, (deposit, index) => (
          <Text
            key={index}
            {...(index < deposits.length - 1 && {
              mb: 2,
              borderBottomWidth: 1,
              pb: 2,
            })}
          >
            {getDate(deposit.transactionDate)}
          </Text>
        ))}
      </Box>
    ),
  },
  {
    Header: 'Collection Amount',
    accessor: ({ deposits }) => (
      <Box>
        {map(deposits, (deposit, index) => (
          <CollectionInfo
            key={index}
            {...(index < deposits.length - 1 && {
              mb: 2,
              pb: 2,
              borderBottomWidth: 1,
            })}
            amount={displayBDTAsFloat(deposit.amount)}
            collection={deposit.collection}
          />
        ))}
      </Box>
    ),
  },
  {
    Header: 'Outstanding Amount',
    accessor: ({ deposits }) => (
      <Box>
        {map(deposits, (deposit, index) => (
          <Text
            key={index}
            {...(index < deposits.length - 1 && {
              mb: 2,
              borderBottomWidth: 1,
              pb: 2,
            })}
            color="green.500"
          >
            {displayBDTAsFloat(get(deposit, 'outstandingAfter'))}
          </Text>
        ))}
      </Box>
    ),
  },
  {
    Header: 'Overdue',
    accessor: ({ deficit, isRepaymentWindowOver }) =>
      isRepaymentWindowOver && (
        <Text color="red.500">
          {displayBDTAsFloat(deficit <= 0 ? 0 : deficit)}
        </Text>
      ),
  },
  {
    Header: 'DPD (30 Days)',
    accessor: ({ dpd }) =>
      dpd !== undefined && (
        <Text color={dpd ? 'red.500' : 'green.500'}>{dpd ? 'YES' : 'NO'}</Text>
      ),
  },
]

const getRowProps = (_, row) => ({
  sx: {
    backgroundColor: get(row, 'original.isHolidayEMI')
      ? 'gray.100'
      : 'transparent',
  },
})

function LoaneeRepayment({ applicationId }) {
  const [summaryDate, setSummaryDate] = useState()

  const repayment = useLoaneeRepayments(applicationId, { summaryDate })

  const collections = useCollections(applicationId)

  const redxTransactions = useAccountRedxTransactions(applicationId)

  console.log(redxTransactions, repayment)

  const columns = useMemo(() => getColumns(), [])
  const data = useMemo(
    () => prepareLoaneeRepayment({ repayment, collections, redxTransactions }),
    [repayment, collections, redxTransactions]
  )

  const table = useTable({ data, columns })

  return (
    <Box>
      <RepaymentFilter onChange={setSummaryDate} />
      <RepaymentSummary summary={get(repayment, 'summary')} />
      <ListTable table={table} getRowProps={getRowProps} />
    </Box>
  )
}

export default LoaneeRepayment
