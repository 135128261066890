import { keyBy, map, union } from 'lodash-es'
import { emptyArray, emptyObject } from 'utils/defaults'
import {
  LOAN_MODIFIER_CONFIG_SET_ALL,
  LOAN_MODIFIER_CONFIG_ADD,
  LOAN_MODIFIER_CONFIG_SET,
} from '.'

const idKey = 'id'

export const initialState = {
  byId: emptyObject,
  allIds: emptyArray,
}

const loanModifierReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case LOAN_MODIFIER_CONFIG_ADD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.modifierConfig.id]: data.modifierConfig,
        },
        allIds: union(state.allIds, [data.modifierConfig.id]),
      }
    case LOAN_MODIFIER_CONFIG_SET:
      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: data,
        },
        allIds: union(state.allIds, [data.id]),
      }
    case LOAN_MODIFIER_CONFIG_SET_ALL:
      return {
        ...state,
        byId: keyBy(data.modifierConfigs, idKey),
        allIds: map(data.modifierConfigs, idKey),
      }
    default:
      return state
  }
}

export default loanModifierReducer
