import { get } from 'lodash-es'

export const DISTRIBUTOR_LOADING_ALL = 'DISTRIBUTOR_LOADING_ALL'
export const DISTRIBUTOR_SET_ALL = 'DISTRIBUTOR_SET_ALL'
export const DISTRIBUTOR_ADD = 'DISTRIBUTOR_ADD'
export const DISTRIBUTOR_UPDATE = 'DISTRIBUTOR_UPDATE'

export const getAllDistributors = ({ include } = {}) => async (
  dispatch,
  getState,
  { api }
) => {
  const { distributors } = getState()

  if (!distributors.loading) {
    dispatch({ type: DISTRIBUTOR_LOADING_ALL })

    try {
      const { data } = await api(`/legacy/v4/eloan/distributors{?include}`, {
        include,
      })

      dispatch({ type: DISTRIBUTOR_SET_ALL, data })
    } catch (err) {
      if (get(err.error, 'code') === 403) {
        console.error(err)
      }
    }
  }
}

export const getDistributor = (distributorId, { include } = {}) => async (
  dispatch,
  _getState,
  { api }
) => {
  try {
    const { data } = await api(
      `/legacy/v4/eloan/distributors/{distributorId}/{?include}`,
      {
        distributorId,
        include,
      }
    )

    dispatch({ type: DISTRIBUTOR_ADD, data })
  } catch (err) {
    if (get(err.error, 'code') === 403) {
      console.error(err)
    }
  }
}

export const createDistributor = ({ name, areaName }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(`POST /legacy/v3/eloan/distributors`, {
    name,
    areaName,
  })

  dispatch({ type: DISTRIBUTOR_ADD, data })

  return data
}

export const updateDistributor = (distributorId, { name, areaName }) => async (
  dispatch,
  _getState,
  { api }
) => {
  const { data } = await api(
    `PUT /legacy/v3/eloan/distributors/{distributorId}`,
    {
      distributorId,
      name,
      areaName,
    }
  )

  dispatch({ type: DISTRIBUTOR_UPDATE, distributorId, data })

  return data
}

export const getDistributorRoutes = (distributorId) => async (
  _dispatch,
  _getState,
  { api }
) => {
  try {
    const { data } = await api(
      `/legacy/v4/eloan/distributors/{distributorId}/routes`,
      {
        distributorId,
      }
    )

    return data
  } catch (err) {
    if (get(err.error, 'code') === 403) {
      console.error(err)
    }
  }
}

export const getDistributorRoutesForLoanProduct = (loanProductId) => async (
  _dispatch,
  _getState,
  { api }
) => {
  try {
    const {
      data,
    } = await api(
      `/legacy/v4/eloan/loan-products/{loanProductId}/distributor-routes`,
      { loanProductId }
    )

    return data
  } catch (err) {
    if (get(err.error, 'code') === 403) {
      console.error(err)
    }
  }
}
