import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormTextarea from 'components/Form/Textarea'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { refreshApplicationStatus } from 'store/applications'
import { toLower } from 'utils/caseStyle'
import * as Yup from 'yup'

const getDefaultValues = () => ({
  note: '',
})

const getValidationSchema = () => {
  return Yup.object({
    note: Yup.string()
      .min(15, `Please write detailed note`)
      .required(`required`),
  })
}

function RefreshApplicationStatus({ applicationId, application, ...props }) {
  const isInValidStatus = useMemo(() => {
    const currentState = toLower(get(application, 'status'))
    return !['quarantine', 'closed', 'rejected'].includes(currentState)
  }, [application])

  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ note }) => {
      try {
        await dispatch(refreshApplicationStatus(applicationId, { note }))
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, onClose, toast]
  )

  if (!isInValidStatus) {
    return null
  }

  return (
    <Authorize permissions="eloan:AnyApplication:refreshVerificationStatus,eloan:Application:refreshVerificationStatus">
      <Box {...props}>
        <Button variantColor="blue" onClick={onOpen}>
          Refresh Status
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <Form form={form} onSubmit={onSubmit}>
              <ModalHeader color="blue.500">
                Refresh Application Status
              </ModalHeader>

              <ModalCloseButton type="button" />

              <ModalBody>
                <Stack spacing={2}>
                  <Box>
                    <FormTextarea name="note" label={`Note`} />
                  </Box>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <FormButton type="submit" variantColor="blue">
                  Refresh
                </FormButton>
              </ModalFooter>
            </Form>
          </ModalContent>
        </Modal>
      </Box>
    </Authorize>
  )
}

export default RefreshApplicationStatus
