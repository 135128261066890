import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  Text,
  useDisclosure,
  Box,
  SimpleGrid,
  Link,
} from '@chakra-ui/core'
import { get } from 'lodash-es'
import { getDate } from '@eloan/shared'

function CollectionInfo({ amount, collection, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Link as={Text} color="green.500" onClick={onOpen} {...props}>
        {amount}
      </Link>
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalHeader>
            Collection Info {collection?.type && `(${collection?.type})`}{' '}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={3} gridGap={6}>
              <Box>
                <Text color="gray.500" mb={2} fontWeight={600}>
                  Date
                </Text>
                <Text>{getDate(get(collection, 'createdAt'))}</Text>
              </Box>
              <Box>
                <Text color="gray.500" mb={2} fontWeight={600}>
                  Amount
                </Text>
                <Text color="green.500">{amount}</Text>
              </Box>
              <Box>
                <Text color="gray.500" mb={2} fontWeight={600}>
                  Method
                </Text>
                <Text>{get(collection, 'collectionMethod')}</Text>
              </Box>
              <Box>
                <Text color="gray.500" mb={2} fontWeight={600}>
                  Transaction ID
                </Text>
                <Text>{get(collection, 'transactionId')}</Text>
              </Box>
              <Box>
                <Text color="gray.500" mb={2} fontWeight={600}>
                  Verified By
                </Text>
                <Text>{get(collection, 'updatedBy.fullName')}</Text>
              </Box>
              <Box>
                <Text color="gray.500" mb={2} fontWeight={600}>
                  Attachment
                </Text>
                {get(collection, 'deposit.refImage') && (
                  <Link
                    href={get(collection, 'deposit.refImage')}
                    isExternal
                    color="cyan.500"
                  >
                    VIEW
                  </Link>
                )}
              </Box>
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CollectionInfo
