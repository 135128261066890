import { theme as chakraTheme } from '@chakra-ui/core'

export const customColors = {
  white200: 'rgb(251, 251, 251)',
  white300: '#F2F3F7',
  gray200: '#A9AAC0',
}

export const theme = {
  ...chakraTheme,
  colors: {
    ...chakraTheme.colors,
    custom: customColors,
  },
}

export const generateThemeByCustomColors = (colors) => ({
  ...theme,
  colors: {
    ...theme.colors,
    custom: {
      ...theme.colors.custom,
      ...colors,
    },
  },
})
