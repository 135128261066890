import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { handleAPIError } from 'components/Form/helpers'
import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetApplicationGuarantor } from 'store/applications'

function ResetGuarantorButton({ applicationId, tp, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure(false)
  const cancelRef = useRef()

  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const resetGuarantor = useCallback(async () => {
    setIsLoading(true)

    try {
      await dispatch(resetApplicationGuarantor(applicationId, { tp }))
      setIsLoading(false)
      onClose()
    } catch (err) {
      handleAPIError(err, { toast })
      setIsLoading(false)
    }
  }, [applicationId, dispatch, onClose, toast, tp])

  return (
    <Authorize permissions="eloan:Application:resetGuarantor">
      <Button type="button" variantColor="red" onClick={onOpen} {...props}>
        Reset Guarantor
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Reset Guarantor Information Permanently
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards!
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>

            <Button
              type="button"
              variantColor="red"
              isLoading={isLoading}
              isDisabled={isLoading}
              onClick={resetGuarantor}
              ml={5}
            >
              Reset Guarantor
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Authorize>
  )
}

export default ResetGuarantorButton
