import { DateTime } from 'luxon'

export const toDate = (date) => {
  if (!date) return date
  return date instanceof Date ? date : new Date(date)
}

export const toISODate = (date = new Date()) => {
  if (!date instanceof Date) {
    throw new Error('Must be an instance of date.')
  }

  const formatDate = DateTime.fromJSDate(date)

  return formatDate.toISODate()
}
