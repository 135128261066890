import React, { memo, useMemo } from 'react'
import { Box, Text, SimpleGrid, Heading, Link, Image } from '@chakra-ui/core'
import InfoBox from 'components/Box/InfoBox'
import { map, get, find } from 'lodash-es'
import { getDate, displayBDT, getPrivateSrc } from '@eloan/shared'
import { formOptions } from 'pages/admin/constants/options'
import DraftAccountInfo from './DraftAccountInfo'
import ApplicationInfo from './ApplicationInfo/ApplicationInfo'
import { getPrivateImageSrc } from 'utils/getPrivateImageSrc'
import { getDocDataByKey, SIGNED_AGREEMENT_KEYS } from '../utils/getDocImage'
import { useApplicationEntities } from 'store/applications/hooks'

const dataMapper = [
  {
    label: 'Loanee name',
    key: 'meta.loaneeName',
  },
  {
    label: 'eLoan ID',
    key: 'id',
  },
  {
    label: 'Shop ID',
    key: 'shopId',
  },
  {
    label: 'Created on',
    getValue: ({ createdAt }) => getDate(createdAt),
  },
  {
    label: 'Loan type',
    key: 'type',
  },
  {
    label: 'Business Name',
    key: 'shop.shopName',
  },
  {
    label: 'Business Type',
    key: 'meta.businessType',
  },
  {
    label: 'Phone',
    key: 'meta.loaneePhoneNumber',
  },
  {
    label: 'Bank Name',
    key: 'paymentDetails.bankName',
  },
  {
    label: 'Disburse A/C No.',
    key: 'paymentDetails.bankAccountNumber',
  },
  {
    label: 'e-mail',
    key: 'meta.loaneeEmail',
  },
  {
    label: 'NID Number',
    key: 'meta.loaneeNid',
  },
  {
    label: 'Gender',
    key: 'meta.loaneeGender',
  },
  {
    label: 'Member ID',
    key: 'paymentDetails.memberNumber',
  },
]

function LoanInfo({ application = {}, accounts }) {
  const clientAccount = useMemo(
    () => find(accounts, ({ accountType }) => accountType === 'CLIENT'),
    [accounts]
  )

  const docs = useApplicationEntities({
    applicationId: get(application, 'id'),
    partnerId: get(application, 'offer.loanPartner'),
  })

  return (
    <Box>
      <SimpleGrid
        columns={5}
        gridColumnGap={2}
        borderBottomWidth="1px"
        pb={4}
        mb={4}
      >
        {map(dataMapper, ({ label, key, getValue }) => (
          <InfoBox key={label} label={label} color="gray.500">
            <Text color="black" fontSize="md" wordBreak="break-word">
              {getValue ? getValue(application) : get(application, key)}
            </Text>
          </InfoBox>
        ))}
      </SimpleGrid>
      <Box>
        <Heading size="md">Loan info</Heading>
      </Box>
      <SimpleGrid columns={5} spacing={2} borderBottomWidth="1px" mt={4}>
        <InfoBox label="Applied Amount" color="gray.500">
          <Text fontSize="md" color="black">
            {displayBDT(get(application, 'loanAmount'))}
          </Text>
        </InfoBox>
        <InfoBox label="Applied Tenure" color="gray.500">
          <Text fontSize="md" color="black">
            {get(application, 'tenure')}
          </Text>
        </InfoBox>
        <InfoBox label="Approved Loan Amount" color="gray.500">
          <Text fontSize="md" color="green.500">
            {displayBDT(get(clientAccount, 'accountSettings.amount'))}
          </Text>
        </InfoBox>
        <InfoBox label="Approved Loan Tenure" color="gray.500">
          <Text fontSize="md" color="green.500">
            {get(clientAccount, 'accountSettings.tenure.value')}{' '}
            {get(
              formOptions.dayUnits,
              get(clientAccount, 'accountSettings.tenure.unit')
            )}
          </Text>
        </InfoBox>
        <InfoBox label="Approved Interest Rate" color="gray.500">
          <Text fontSize="md" color="green.500">
            {get(
              clientAccount,
              'accountSettings.interestSettings.interestRateSettings.interestRate'
            ) &&
              (
                get(
                  clientAccount,
                  'accountSettings.interestSettings.interestRateSettings.interestRate'
                ) * 100
              ).toFixed(2)}
          </Text>
        </InfoBox>
        <InfoBox label="RedX Payment Deduction %" color="gray.500">
          <Text fontSize="md" color="black">
            {get(application, 'redxShopData.paymentDeductionPercentage') ??
              'N/A'}
          </Text>
        </InfoBox>
        <InfoBox label="Selected Waiting Period" color="gray.500">
          <Text fontSize="md" color="black">
            {get(application, 'redxShopData.deductionWaitingPeriodInDays')
              ? `${get(
                  application,
                  'redxShopData.deductionWaitingPeriodInDays'
                )} Days`
              : 'N/A'}
          </Text>
        </InfoBox>
        <InfoBox label="Avg daily nmv" color="gray.500">
          <Text fontSize="md" color="black">
            {get(application, 'redxShopData.dailyAvgNmv') ?? 'N/A'}
          </Text>
        </InfoBox>
        <InfoBox label="Disbursement Date" color="gray.500">
          <Text fontSize="md" color="black">
            {getDate(get(clientAccount, 'disbursementDate'))}
          </Text>
        </InfoBox>
        <InfoBox label="Signed Agreement Copy" color="gray.500">
          {getDocDataByKey(docs, SIGNED_AGREEMENT_KEYS) ? (
            <Link
              color="cyan.500"
              href={getPrivateSrc(getDocDataByKey(docs, SIGNED_AGREEMENT_KEYS))}
              isExternal
            >
              VIEW
            </Link>
          ) : (
            <Text fontSize="md" color="black">
              N/A
            </Text>
          )}
        </InfoBox>
        <InfoBox label="Transfer Copy" color="gray.500">
          {get(application, 'metaExtra.disbursementAttachment') ? (
            <Link
              href={getPrivateImageSrc(
                get(application, 'metaExtra.disbursementAttachment')
              )}
              isExternal
            >
              <Image
                size="50px"
                objectFit="cover"
                src={getPrivateImageSrc(
                  get(application, 'metaExtra.disbursementAttachment')
                )}
              />
            </Link>
          ) : (
            <Text fontSize="md" color="black">
              N/A
            </Text>
          )}
        </InfoBox>
      </SimpleGrid>
      {[
        'offer_negotiating',
        'loanee_agreement_pending',
        'loanee_agreement_signed',
        'shopup-approved',
        'partner-approved',
        'financed',
        'active',
        'ended',
      ].includes(get(application, 'offer.status')) && (
        <DraftAccountInfo
          applicationId={get(application, 'id')}
          title={
            ['financed', 'active', 'ended'].includes(
              get(application, 'offer.status')
            )
              ? 'Account Info'
              : 'Draft Account Info'
          }
        />
      )}
      <Box mt={6}>
        <ApplicationInfo application={application} />
      </Box>
    </Box>
  )
}

export default memo(LoanInfo)
