import Authorize from 'components/Authorize'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { usePartners } from 'store/partners/hooks'
import OfferList from './List'
import ApplicationView from './View'

function OffersPage() {
  usePartners()

  return (
    <Authorize permissions="eloan:Offer:read">
      <Switch>
        <Route exact path="/dash/offers" component={OfferList} />
        <Route path="/dash/offers/:applicationId" component={ApplicationView} />
      </Switch>
    </Authorize>
  )
}

export default OffersPage
