import {
  Accordion,
  AccordionHeader,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import NavLink from 'components/Link/NavLink'
import React, { memo } from 'react'
import { useRouteMatch } from 'react-router-dom'

function ParentItem({ link, title, match, children, items }) {
  const linkGenerated = link ? link : items?.[0]?.link

  return (
    <AccordionItem borderWidth={0} _last={{ borderWidth: 0 }} isOpen={!!match}>
      <AccordionHeader
        as={linkGenerated ? NavLink : undefined}
        to={linkGenerated}
        py={3}
        color={match ? 'white' : null}
        bg={match ? 'primary' : null}
        _hover={{ bg: 'primary', color: 'white' }}
        fontWeight={500}
      >
        <Box flexGrow={1}>{title}</Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel p={2}>{children}</AccordionPanel>
    </AccordionItem>
  )
}

const Item = memo(({ link, title, permissions, children, isChild, items }) => {
  const match = useRouteMatch(
    link ? `${link}` : [...items.map((item) => item.link)]
  )

  return (
    <Authorize permissions={permissions}>
      {children ? (
        <ParentItem
          link={link}
          title={title}
          children={children}
          match={match}
          items={items}
        />
      ) : (
        <AccordionItem
          borderWidth={0}
          _last={{ borderWidth: 0 }}
          isOpen={!!match}
        >
          <AccordionHeader
            as={NavLink}
            to={link}
            py={isChild ? 2 : 3}
            color={match ? (isChild ? 'primary' : 'white') : null}
            bg={match && !isChild ? 'primary' : null}
            _hover={{
              bg: isChild ? null : 'primary',
              color: isChild ? 'primary' : 'white',
            }}
          >
            <Box>{title}</Box>
          </AccordionHeader>
        </AccordionItem>
      )}
    </Authorize>
  )
})

const ItemsFactory = ({ items, isChild }) => {
  return items.map(({ ...props }) => (
    <ItemFactory
      key={props.link || props.title}
      isParent={!!props.items}
      isChild={isChild}
      {...props}
    />
  ))
}

const ItemFactory = ({ items, isParent: hasParent, ...props }) => (
  <Item
    key={props.link}
    children={items ? <ItemsFactory items={items} isChild={hasParent} /> : null}
    items={items}
    {...props}
  />
)

const Items = ({ items }) => <ItemsFactory items={items} />

function SidebarMenuItems({ items }) {
  return (
    <Accordion allowMultiple={false}>
      <Items items={items} />
    </Accordion>
  )
}

export default memo(SidebarMenuItems)
