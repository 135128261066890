import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormSelect from 'components/Form/Select'
import { capitalize, get, zipObject } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { assignPAsToApplications } from 'store/applications'
import { usePAUsers } from 'store/users/hooks'
import * as Yup from 'yup'

const getDefaultValues = (paId) => ({
  paId,
})

const getValidationSchema = () => {
  return Yup.object({
    paId: Yup.number().integer().required(`required`),
  })
}

function SetPAAssignment({
  applicationId,
  paId,
  docSubmissionMethod,
  isOpen,
  onClose,
}) {
  const toast = useToast()

  const paUsers = usePAUsers()
  const paUserOptions = useMemo(() => {
    const ids = paUsers.allIds.filter(
      (id) => get(paUsers.byId[id], 'type') === docSubmissionMethod
    )
    return zipObject(
      ids,
      ids.map((id) => get(paUsers.byId[id], 'name'))
    )
  }, [docSubmissionMethod, paUsers.allIds, paUsers.byId])

  const defaultValues = useMemo(() => getDefaultValues(paId), [paId])
  const validationSchema = useMemo(() => getValidationSchema(), [])

  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ paId }) => {
      try {
        await dispatch(
          assignPAsToApplications({
            applicationIds: [applicationId],
            paId,
          })
        )

        toast({
          title: `Assigned PA(${get(
            paUsers.byId[paId],
            'fullName'
          )}) to Application(${applicationId})`,
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
        form.reset()
        onClose()
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, onClose, paUsers.byId, toast]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Form form={form} onSubmit={onSubmit}>
          <ModalHeader>Change PA for Application: {applicationId}</ModalHeader>

          <ModalCloseButton type="button" />

          <ModalBody>
            <FormSelect
              name="paId"
              options={paUserOptions}
              label={`${capitalize(docSubmissionMethod)} Portfolio Associate:`}
            />
          </ModalBody>

          <ModalFooter>
            <Button type="button" mr={3} onClick={onClose}>
              Close
            </Button>
            <FormButton type="submit" variantColor="green">
              Assign
            </FormButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}

function ChangePA({ applicationId, application, ...props }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Authorize permissions="eloan:AnyApplicationPAAssignment:set">
      <Box {...props}>
        <Button onClick={onOpen}>Change PA</Button>
        <SetPAAssignment
          applicationId={applicationId}
          docSubmissionMethod={get(application, 'meta.docSubmissionMethod')}
          paId={get(application, 'paAssignment.paId')}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Box>
    </Authorize>
  )
}

export default ChangePA
