import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/core'
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Authorize from 'components/Authorize'
import ApplicationListHeader from 'components/ApplicationList/Header'
import { useMemo } from 'react'
import { useCallback } from 'react'
import CreateProduct from './View/Create'
import ProductList from './View/Product/List'

const tabs = [
  { id: 'create', name: 'Create loan product' },
  { id: 'view', name: 'View loan products' },
]

function AdminLoanProduct() {
  const { tabId } = useParams()

  const history = useHistory()

  const onTabChange = useCallback(
    (index) => {
      history.replace(`/dash/admin/loan-product/${tabs[index].id}`)
    },
    [history]
  )

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex((tab) => tab.id === tabId)
    return index < 0 ? 0 : index
  }, [tabId])

  return (
    <Authorize permissions="eloan:LoanProduct:read">
      <ApplicationListHeader title={`eLoan Loan Product`} />
      <Tabs index={tabIndex} onChange={onTabChange} variantColor="cyan">
        <TabList mb={4}>
          {tabs.map((tab) => (
            <Tab id={tab.id} key={tab.id} px={0} pt={0} mr={4}>
              {tab.name}
            </Tab>
          ))}
        </TabList>

        <TabPanels py={4}>
          <TabPanel>
            <CreateProduct />
          </TabPanel>
          <TabPanel>
            <ProductList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Authorize>
  )
}

export default AdminLoanProduct
