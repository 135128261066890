import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFinancialInstitutions, getLoanConfigs, getLoanProducts } from '.'
import { emptyObject } from 'utils/defaults'
import { api } from 'api'

export function useFinancialInstitutions() {
  const financialInstitutions = useSelector(
    (state) => state.admin.financialInstitutions
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!financialInstitutions.allIds.length) {
      dispatch(getFinancialInstitutions())
    }
  }, [financialInstitutions.allIds.length, dispatch])

  return financialInstitutions
}

export function useLoanConfigs() {
  const loanConfigs = useSelector((state) => state.admin.loanConfigs)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!loanConfigs.allIds.length) {
      dispatch(getLoanConfigs())
    }
  }, [dispatch, loanConfigs.allIds.length])

  return loanConfigs
}

export function useLoanProducts() {
  const loanProducts = useSelector((state) => state.admin.loanProducts)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!loanProducts.allIds.length) {
      dispatch(getLoanProducts())
    }
  }, [dispatch, loanProducts.allIds.length])

  return loanProducts
}

export function useLoanProductById(loanProductId) {
  const [data, setData] = useState(emptyObject)

  useEffect(() => {
    async function fetchData() {
      const { data } = await api('/eloan/v1/loan-products/{loanProductId}', {
        loanProductId,
      })
      setData(data.loanProduct)
    }
    if (loanProductId) {
      fetchData()
    }
  }, [loanProductId])

  return data
}

export function useLoaneeRepayments(applicationId, { summaryDate }) {
  const [data, setData] = useState(emptyObject)

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api(
          '/eloan/v1/repayments/client/{applicationId}/{?summaryDate}',
          {
            applicationId,
            summaryDate,
          }
        )
        setData(data)
      } catch {}
    }
    if (applicationId) {
      fetchData()
    }
  }, [applicationId, summaryDate])

  return data
}

export function usePartnerRepayments(applicationId, filters = emptyObject) {
  const { summaryDate } = filters

  const [data, setData] = useState(emptyObject)

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await api(
          '/eloan/v1/repayments/partner/{applicationId}/{?summaryDate}',
          {
            applicationId,
            summaryDate,
          }
        )
        setData(data)
      } catch {}
    }
    if (applicationId) {
      fetchData()
    }
  }, [applicationId, summaryDate])

  return data
}

export function useDraftAccount(applicationId) {
  const [data, setData] = useState(emptyObject)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true)

        const { data } = await api('/eloan/v1/accounts/draft{?eloanId}', {
          eloanId: applicationId,
        })
        setData(data.draftAccount)
      } catch {}

      setLoading(false)
    }
    if (applicationId) {
      fetchData()
    }
  }, [applicationId])

  return { draftAccount: data, loading }
}
