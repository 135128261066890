import { Badge, Box, Button } from '@chakra-ui/core'
import { displayBDT, getDate } from '@eloan/shared'
import { DataTable } from '@eloan/shared/components/Table'
import styled from '@emotion/styled'
import ApplicationListHeader from 'components/ApplicationList/Header'
import Authorize from 'components/Authorize'
import NavLink from 'components/Link/NavLink'
import Loader from 'components/Loader/Loader'
import { debounce, get } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTable } from 'react-table'
import { useRLSummary } from 'store/applications/hooks'
import UnileverRLSummaryListFilter from './Filter'

const CustomBox = styled(Box)`
  overflowy: scroll;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b0b0b0;
  }
`

const getColumns = () => [
  { Header: '#', Cell: ({ row }) => row.index + 1 },
  {
    Header: 'eLoan ID',
    accessor: ({ application }) => (
      <Button
        as={NavLink}
        variant="link"
        to={`/dash/unilever-applications/${application.id}`}
      >
        {application.id}
      </Button>
    ),
  },
  { Header: 'Retailer ID', accessor: 'application.retailer.externalId' },
  { Header: 'Retailer Name', accessor: 'application.retailer.ownerName' },
  { Header: 'Route Code', accessor: 'application.retailer.route.code' },
  {
    Header: 'Withdrawal Date',
    accessor: ({ withdrawlDate }) => getDate(withdrawlDate),
  },
  {
    Header: 'Due Date',
    accessor: ({ repaymentDeadline }) => getDate(repaymentDeadline),
  },
  { Header: 'Total Withdrawal', accessor: ({ amount }) => displayBDT(amount) },
  {
    Header: 'Total Repayable',
    accessor: ({ repayable }) => displayBDT(get(repayable, 'primary.total')),
  },
  {
    Header: 'Remaining Repayable',
    accessor: ({ repayable }) =>
      displayBDT(get(repayable, 'outstanding.total')),
  },
  {
    Header: 'Total Interest',
    accessor: ({ repayable }) => displayBDT(get(repayable, 'primary.interest')),
  },
  {
    Header: 'Status',
    accessor: ({ repaymentStatus }) => <Badge>{repaymentStatus}</Badge>,
  },
]

const getQueryObject = (filter) => {
  return {
    offset: 0,
    limit: 20,
    loanProductId: 2,
    filter,
  }
}

function UnileverRLSummaryPage() {
  const containerRef = useRef()

  const [filter, setFilter] = useState()

  const columns = useMemo(() => getColumns(), [])

  const queryObject = useMemo(() => getQueryObject(filter), [filter])

  const { items, nextLink, fetchMore, isFetchingMore } = useRLSummary(
    queryObject
  )

  const fetchMoreDebounced = debounce(fetchMore, 300)

  const handleFetchMore = useCallback(async () => {
    const element = containerRef.current

    const scrolledToEnd =
      element.scrollTop + element.clientHeight >= element.scrollHeight

    const shouldFetchMore = scrolledToEnd && nextLink

    if (shouldFetchMore) {
      fetchMoreDebounced(nextLink)
    }
  }, [nextLink, fetchMoreDebounced])

  useEffect(() => {
    const element = containerRef.current
    element.addEventListener('scroll', handleFetchMore)
    return () => element.removeEventListener('scroll', handleFetchMore)
  }, [handleFetchMore])

  const headerProps = useMemo(
    () => ({
      bg: 'white',
      position: 'sticky',
      top: 0,
      zIndex: 1,
    }),
    []
  )

  const table = useTable({
    data: items,
    columns: columns,
  })

  return (
    <Authorize permissions="">
      <ApplicationListHeader
        title={`eLoan Unilever Applications (RL Summary)`}
        subtitle={`List of eLoan Unilever Applications (RL Summary)`}
      />

      <UnileverRLSummaryListFilter onSubmit={setFilter} />

      <CustomBox
        ref={containerRef}
        height="calc(100vh - 360px)"
        overflowY="auto"
        mb={4}
      >
        <DataTable {...table} headerProps={headerProps} />
      </CustomBox>
      {isFetchingMore && <Loader />}
    </Authorize>
  )
}

export default UnileverRLSummaryPage
