import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ApplicationList from './List'
import ApplicationRCAPage from './RCA'
import ApplicationView from './View'

function ApplicationsPage() {
  return (
    <>
      <Switch>
        <Route exact path="/dash/applications" component={ApplicationList} />
        <Route
          path="/dash/applications/:applicationId/rca/:tabId?"
          component={ApplicationRCAPage}
        />
        <Route
          path="/dash/applications/:applicationId/:tabId?"
          component={ApplicationView}
        />
      </Switch>
    </>
  )
}

export default ApplicationsPage
