import {
  Button,
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
} from '@chakra-ui/core'
import React, { useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box } from 'reflexbox'

const fields = [
  'meta.loaneeNidSelfie',
  'meta.loaneeNidPhoto',
  'meta.loaneeNidPhotoBack',
  'meta.guarantorPhoneNumber',
  'meta.guarantorNidSelfie',
  'meta.guarantorNidPhoto',
  'meta.guarantorNidPhotoBack',
  'meta.guarantorAgreedAt',
  'meta.initialHomePhoto',
  'meta.initialShopPhoto',
  'meta.initialMarketplacePhoto',
]

function ApplicationCriteriaFilter({ ...props }) {
  const { setValue, watch, register, unregister } = useFormContext()

  const meta = watch('meta')

  useEffect(() => {
    for (const field of fields) {
      register({ name: field })
    }

    return () => {
      for (const field of fields) {
        unregister(field)
      }
    }
  }, [register, unregister])

  const onChange = useCallback(
    (e) => {
      setValue(e.target.name, e.target.checked ? 'null' : '')
    },
    [setValue]
  )

  return (
    <Box {...props}>
      <Popover>
        <PopoverTrigger>
          <Button rightIcon="chevron-down" variant="outline">
            Application Criteria
          </Button>
        </PopoverTrigger>

        <PopoverContent zIndex={4} p={2} w="auto">
          <Stack>
            <Checkbox
              name="meta.loaneeNidSelfie"
              isChecked={meta.loaneeNidSelfie === 'null'}
              onChange={onChange}
            >
              Loanee Selfie
            </Checkbox>

            <Checkbox
              name="meta.loaneeNidPhoto"
              isChecked={meta.loaneeNidPhoto === 'null'}
              onChange={onChange}
            >
              Loanee NID Front
            </Checkbox>
            <Checkbox
              name="meta.loaneeNidPhotoBack"
              isChecked={meta.loaneeNidPhotoBack === 'null'}
              onChange={onChange}
            >
              Loanee NID Back
            </Checkbox>

            <Checkbox
              name="meta.guarantorPhoneNumber"
              isChecked={meta.guarantorPhoneNumber === 'null'}
              onChange={onChange}
            >
              Guarantor Phone
            </Checkbox>
            <Checkbox
              name="meta.guarantorNidSelfie"
              isChecked={meta.guarantorNidSelfie === 'null'}
              onChange={onChange}
            >
              Guarantor Selfie
            </Checkbox>
            <Checkbox
              name="meta.guarantorNidPhoto"
              isChecked={meta.guarantorNidPhoto === 'null'}
              onChange={onChange}
            >
              Guarantor NID Front
            </Checkbox>
            <Checkbox
              name="meta.guarantorNidPhotoBack"
              isChecked={meta.guarantorNidPhotoBack === 'null'}
              onChange={onChange}
            >
              Guarantor NID Back
            </Checkbox>
            <Checkbox
              name="meta.guarantorAgreedAt"
              isChecked={meta.guarantorAgreedAt === 'null'}
              onChange={onChange}
            >
              Guarantor Agreement
            </Checkbox>

            <Checkbox
              name="meta.initialHomePhoto"
              isChecked={meta.initialHomePhoto === 'null'}
              onChange={onChange}
            >
              Home Photo
            </Checkbox>
            <Checkbox
              name="meta.initialShopPhoto"
              isChecked={meta.initialShopPhoto === 'null'}
              onChange={onChange}
            >
              Shop Photo
            </Checkbox>
            <Checkbox
              name="meta.initialMarketplacePhoto"
              isChecked={meta.initialMarketplacePhoto === 'null'}
              onChange={onChange}
            >
              Marketplace Photo
            </Checkbox>
          </Stack>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default ApplicationCriteriaFilter
