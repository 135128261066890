import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import FormButton from 'components/Form/Button'
import Form from 'components/Form/Form'
import { handleAPIError } from 'components/Form/helpers'
import FormTextarea from 'components/Form/Textarea'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import { sendOfferToTweaking } from 'store/applications'
import { getOldApplicationStatus } from 'utils/application-status'
import { toLower } from 'utils/caseStyle'
import * as Yup from 'yup'

const getDefaultValues = () => ({
  note: '',
})

const getValidationSchema = () => {
  return Yup.object({
    note: Yup.string()
      .min(10, `Please write detailed note`)
      .required(`required`),
  })
}

function SendToTweaking({ applicationId, application, ...props }) {
  const inValidState = useMemo(() => {
    const applicationState = toLower(get(application, 'status'))
    const offerState = toLower(get(application, 'offer.status'))
    return (
      !['closed', getOldApplicationStatus('closed')].includes(
        applicationState
      ) && ['offer_negotiating'].includes(offerState)
    )
  }, [application])

  const toast = useToast()

  const { isOpen, onClose, onOpen } = useDisclosure(false)

  const defaultValues = useMemo(() => getDefaultValues(), [])
  const validationSchema = useMemo(() => getValidationSchema(), [])
  const form = useForm({
    defaultValues,
    validationSchema,
  })

  const dispatch = useDispatch()
  const onSubmit = useCallback(
    async ({ note }) => {
      try {
        await dispatch(sendOfferToTweaking(applicationId, { note }))
      } catch (err) {
        handleAPIError(err, { form, toast })
      }
    },
    [applicationId, dispatch, form, toast]
  )

  if (!inValidState) {
    return null
  }

  return (
    <Authorize permissions="eloan:AnyApplication:requestOfferTweaking">
      <Button variantColor="yellow" onClick={onOpen} {...props}>
        Send to Tweaking
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <Form form={form} onSubmit={onSubmit}>
            <ModalHeader>Send to Tweaking</ModalHeader>

            <ModalCloseButton type="button" />

            <ModalBody>
              <Stack spacing={2}>
                <Box>
                  <FormTextarea name="note" label={`Note`} />
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <Button type="button" mr={3} onClick={onClose}>
                Close
              </Button>

              <FormButton type="submit" variantColor="yellow">
                Send to Tweaking
              </FormButton>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Authorize>
  )
}

export default SendToTweaking
