import { api } from 'api'

export const LOAN_MODIFIER_CONFIG_ADD = 'LOAN_MODIFIER_CONFIG_ADD'
export const LOAN_MODIFIER_CONFIG_SET_ALL = 'LOAN_MODIFIER_CONFIG_SET_ALL'
export const LOAN_MODIFIER_CONFIG_SET = 'LOAN_MODIFIER_CONFIG_SET'

export const LOAN_MODIFIER_ADD_LOANS = 'LOAN_MODIFIER_ADD_LOANS'

export const getLoanModifierConfigurations = () => async (
  dispatch,
  getState
) => {
  const { data } = await api('/eloan/v1/loan-modifiers/modifier-configs')

  dispatch({ type: LOAN_MODIFIER_CONFIG_SET_ALL, data })

  return data
}

export const getLoanModifierConfiguration = (loanModifierId) => async (
  dispatch,
  getState
) => {
  const { data } = await api(
    `/eloan/v1/loan-modifiers/modifier-config/{loanModifierId}`,
    {
      loanModifierId,
    }
  )

  dispatch({ type: LOAN_MODIFIER_CONFIG_SET, data })

  return data
}

export const addLoanModifierConfiguration = ({
  title,
  loanType,
  config,
}) => async (dispatch, getState) => {
  const { data } = await api('POST /eloan/v1/loan-modifiers/modifier-config', {
    title,
    loanType,
    config,
  })

  dispatch({ type: LOAN_MODIFIER_CONFIG_ADD, data })

  return data
}

export const addLoansInModifier = (
  modifierConfigId,
  { eloanIds, accountTypes }
) => async (dispatch, getState) => {
  const { data } = await api(
    'POST /eloan/v1/loan-modifiers/modifier-config/{modifierConfigId}',
    {
      modifierConfigId,
      eloanIds,
      accountTypes,
    }
  )

  dispatch({ type: LOAN_MODIFIER_ADD_LOANS, data })

  return data
}
