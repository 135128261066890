import { Button, Image, Link, Stack, Tag, useToast } from '@chakra-ui/core'
import Authorize from 'components/Authorize'
import { handleAPIError } from 'components/Form/helpers'
import CoordinateLink from 'components/Link/CoordinateLink'
import { get } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'reflexbox'
import {
  acceptApplicationImage,
  getApplicationData,
  rejectApplicationImage,
} from 'store/applications'
import { useApplication } from 'store/applications/hooks'
import { getPrivateImageSrc } from 'utils/getPrivateImageSrc'

const variantColor = {
  rejected: 'red',
  accepted: 'green',
  pending: 'yellow',
}

function LocationPhoto({
  applicationId,
  photoField,
  statusField,
  latField,
  lonField,
  metaField,
}) {
  const toast = useToast()

  const fields = useMemo(
    () => [photoField, statusField, latField, lonField, metaField].join(','),
    [latField, lonField, metaField, photoField, statusField]
  )

  const application = useApplication(applicationId, fields)

  const dispatch = useDispatch()

  const refresh = useCallback(() => {
    dispatch(getApplicationData(applicationId, { fields }))
  }, [applicationId, dispatch, fields])

  const accept = useCallback(
    async (photoValue) => {
      try {
        await dispatch(
          acceptApplicationImage(applicationId, {
            photoField,
            photoValue,
          })
        )
      } catch (err) {
        if (get(err.error, 'code') === 409) {
          refresh()
        }

        handleAPIError(err, { toast })
      }
    },
    [applicationId, dispatch, photoField, refresh, toast]
  )

  const reject = useCallback(
    async (photoValue) => {
      try {
        await dispatch(
          rejectApplicationImage(applicationId, {
            photoField,
            photoValue,
          })
        )
      } catch (err) {
        if (get(err.error, 'code') === 409) {
          refresh()
        }

        handleAPIError(err, { toast })
      }
    },
    [applicationId, dispatch, photoField, refresh, toast]
  )

  const photoValue = get(application, photoField)
  const statusValue = (get(application, statusField) || '').toLowerCase()

  return (
    <>
      <Box
        mb={2}
        as={Link}
        display="block"
        href={getPrivateImageSrc(photoValue)}
        target="_blank"
        size={64}
        sx={{ position: 'relative' }}
      >
        <Image
          size="100%"
          objectFit="cover"
          src={getPrivateImageSrc(photoValue)}
          fallbackSrc="https://via.placeholder.com/128?text=N/A"
        />
        <Tag
          size="sm"
          position="absolute"
          bottom={0}
          right={0}
          variantColor={variantColor[statusValue]}
        >
          {statusValue}
        </Tag>
      </Box>

      <CoordinateLink
        lat={get(application, latField)}
        lon={get(application, lonField)}
        display="block"
        mb={4}
      />

      {['pending', 'accepted', 'rejected'].includes(statusValue) && (
        <Authorize permissions="eloan:ApplicationPhoto:update">
          <Stack isInline spacing={4} flexWrap="wrap">
            <Button
              type="button"
              variantColor="red"
              variant={statusValue === 'rejected' ? 'solid' : 'outline'}
              isDisabled={statusValue === 'rejected'}
              onClick={() => reject(photoValue)}
            >
              Reject
            </Button>
            <Button
              type="button"
              variantColor="green"
              variant={statusValue === 'accepted' ? 'solid' : 'outline'}
              isDisabled={statusValue === 'accepted'}
              onClick={() => accept(photoValue)}
            >
              Accept
            </Button>
          </Stack>
        </Authorize>
      )}
    </>
  )
}

export default LocationPhoto
